import React, { useEffect, useState } from "react";

const PercentageBar = ({ value }: any) => {
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setCompleted(value);
    }, 500);
  }, []);

  const progress: any = {
    width: `${100}%`,
  };

  const progressValue: any = {
    width: `${completed}%`,
    transition: "width  4s ease-in-out",
  };

  return (
    <div className=" mx-2 progress " style={progress}>
        <div className="progress-value" style={progressValue}></div>
    </div>
    
  );
};

export default PercentageBar;
