import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "../../App";
import { routes } from "../../constants/routes";
import { MembersProvider } from "../../context/MembersProvider";
import ListMembers from "./ListMembers";
import UpdateProfileOrMember from "../settings/UpdateProfileOrMember";
import File404 from "../../components/404/File404";
import { useTranslation } from "react-i18next";

const MembersPages = ({ userContext }: any) => {
  const { t } = useTranslation();
  const roleOptions = [
    // { value: "Select", label: "Select" },
    { value: "MANAGER", label: `${t("MANAGER")}` },
    { value: "SUPERVISOR", label: `${t("SUPERVISOR")}` },
    { value: "TECHNICIAN", label: `${t("TECHNICIAN")}` },
  ];

  const vehicleOptions = [
    { value: "CRANE", label: `${t("CRANE")}` },
    { value: "PICKUP", label: `${t("PICKUP")}` },
    { value: "SEDAN", label: `${t("SEDAN")}` },
    { value: "TRUCK", label: `${t("TRUCK")}` },
  ];
  const departmentOptions = [
    // { value: "Select", label: "Select" },
    { value: "CLEANING", label: `${t("CLEANING")}` },
    { value: "ELECTRICAL", label: `${t("ELECTRICAL")}` },
    { value: "INSTALLATION", label: `${t("INSTALLATION")}` },
    { value: "MONITORING", label: `${t("MONITORING")}` },
    { value: "SPARE_PARTS", label: `${t("SPARE_PARTS")}` },
    // { value: "STAFF_MANAGEMENT", label: `${t("STAFF_MANAGEMENT")}` },
    // { value: "STORE_MANAGEMENT", label: `${t("STORE_MANAGEMENT")}` },
  ];

  return (
    userContext?.roleAccess && (
      <MembersProvider>
        <Routes>
          <Route
            path={routes.listMembers}
            element={
              <RequireAuth>
                {userContext?.roleAccess["USER"].includes("READ") ? (
                  <ListMembers
                    userContext={userContext}
                    roleOptions={roleOptions}
                    departmentOptions={departmentOptions}
                    vehicleOptions={vehicleOptions}
                  />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />

          <Route
            path={routes.personalProfile}
            element={
              <RequireAuth>
                {userContext?.roleAccess["USER"].includes("READ") ? (
                  <UpdateProfileOrMember
                    userContext={userContext}
                    roleOptions={roleOptions}
                    departmentOptions={departmentOptions}
                    vehicleOptions={vehicleOptions}
                  />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.companyProfile}
            element={
              <RequireAuth>
                {userContext?.roleAccess["USER"].includes("READ") ? (
                  <UpdateProfileOrMember
                    userContext={userContext}
                    roleOptions={roleOptions}
                    departmentOptions={departmentOptions}
                    vehicleOptions={vehicleOptions}
                  />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.vehicleInformation}
            element={
              <RequireAuth>
                {userContext?.roleAccess["USER"].includes("READ") ? (
                  <UpdateProfileOrMember
                    userContext={userContext}
                    roleOptions={roleOptions}
                    departmentOptions={departmentOptions}
                    vehicleOptions={vehicleOptions}
                  />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.adminPersonalProfile}
            element={
              <RequireAuth>
                {userContext?.roleAccess["USER"].includes("READ") ? (
                  <UpdateProfileOrMember
                    userContext={userContext}
                    roleOptions={roleOptions}
                    departmentOptions={departmentOptions}
                    vehicleOptions={vehicleOptions}
                  />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.admincompanyProfile}
            element={
              <RequireAuth>
                {userContext?.roleAccess["USER"].includes("READ") ? (
                  <UpdateProfileOrMember
                    userContext={userContext}
                    roleOptions={roleOptions}
                    departmentOptions={departmentOptions}
                    vehicleOptions={vehicleOptions}
                  />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.adminvehicleInformation}
            element={
              <RequireAuth>
                {userContext?.roleAccess["USER"].includes("READ") ? (
                  <UpdateProfileOrMember
                    userContext={userContext}
                    roleOptions={roleOptions}
                    departmentOptions={departmentOptions}
                    vehicleOptions={vehicleOptions}
                  />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.adminChangePassword}
            element={
              <RequireAuth>
                {userContext?.roleAccess["USER"].includes("READ") ? (
                  <UpdateProfileOrMember
                    userContext={userContext}
                    rroleOptionsoleOptions={roleOptions}
                    departmentOptions={departmentOptions}
                    vehicleOptions={vehicleOptions}
                  />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.memberPasswordReset}
            element={
              <RequireAuth>
                {userContext?.roleAccess["USER"].includes("READ") ? (
                  <UpdateProfileOrMember
                    userContext={userContext}
                    roleOptions={roleOptions}
                    departmentOptions={departmentOptions}
                    vehicleOptions={vehicleOptions}
                  />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
        </Routes>
      </MembersProvider>
    )
  );
};

export default MembersPages;
