import { Document, Image, Page, Text, View } from "@react-pdf/renderer";

export default function BOMPDF({ data }: any) {
  return (
    <Document>
      <Page size="A4">
        <View
          style={{
            margin: 30,
          }}
        >
          <View style={{ justifyContent: "flex-end", alignItems: "flex-end" }}>
            <Image
              style={{ height: 100 }}
              src={require("../assets/pdfLogo.jpeg")}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <Text
              style={{
                borderBottomWidth: 1,
                borderBottomColor: "black",
              }}
            >
              Bill Of Materials
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontSize: 10 }}>BOM Name :</Text>
              <Text
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: "black",
                  fontSize: 10,
                }}
              >
                {data?.bom_name}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontSize: 10 }}>BillBoard Type :</Text>
              <Text
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: "black",
                  fontSize: 10,
                }}
              >
                {data?.billboard_type}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontSize: 10 }}>Board Size :</Text>
              <Text
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: "black",
                  fontSize: 10,
                }}
              >
                {data?.board_size || "                "}
              </Text>
            </View>
          </View>

          <View style={{ flexDirection: "row", marginTop: 20 }}>
            <View style={{ flex: 1, border: 1, fontSize: 10 }}>
              <Text>Stock Code</Text>
            </View>
            <View style={{ flex: 1, border: 1, fontSize: 10 }}>
              <Text>Part No</Text>
            </View>
            <View style={{ flex: 1, border: 1, fontSize: 10 }}>
              <Text>QTY</Text>
            </View>
            <View style={{ flex: 1, border: 1, fontSize: 10 }}>
              <Text>Unit</Text>
            </View>
            <View style={{ flex: 1, border: 1, fontSize: 10 }}>
              <Text>Order No</Text>
            </View>
            <View style={{ flex: 1, border: 1, fontSize: 10 }}>
              <Text>Country</Text>
            </View>
          </View>
          {data?.stocks?.map((item: any) => (
            <View style={{ flexDirection: "row" }}>
              <View
                style={{ flex: 1, border: 1, flexWrap: "wrap", fontSize: 10 }}
              >
                <Text>{item?.stock_code?.stock_code}</Text>
              </View>
              <View
                style={{ flex: 1, border: 1, flexWrap: "wrap", fontSize: 10 }}
              >
                <Text>{item?.stock_code?.supplier_part_no}</Text>
              </View>
              <View
                style={{ flex: 1, border: 1, flexWrap: "wrap", fontSize: 10 }}
              >
                <Text>{item?.quantity}</Text>
              </View>
              <View
                style={{ flex: 1, border: 1, flexWrap: "wrap", fontSize: 10 }}
              >
                <Text>{item?.unit}</Text>
              </View>
              <View
                style={{ flex: 1, border: 1, flexWrap: "wrap", fontSize: 10 }}
              >
                <Text>{item?.order_no}</Text>
              </View>
              <View
                style={{ flex: 1, border: 1, flexWrap: "wrap", fontSize: 10 }}
              >
                <Text>{item?.country}</Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
}
