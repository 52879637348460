import React, { ReactElement, useState } from "react";
import { api } from "../utils/api";

interface InventeryProviderProps {
  children: ReactElement;
}

export interface InventeryContextProp {
  isLoading: boolean;
  meta: any;
  suppliers: any;
  stocks: any;
  purchases: any;
  purchase: any;
  billBoards: any;
  members: any;
  returnMaterialToSupplier: any;
  returnMaterialToSuppliers: any;
  replaceMaterialToSupplier: any;
  materialToTechnician: any;
  materialToTechnicians: any;
  returnMaterials: any;
  returnMaterial: any;
  updateStockDetail: Function;
  setSuppliers: Function;
  setIsLoading: Function;
  currentMaterialToTechnicianId: any;
  setCurrentMaterialToTechnicianId: Function;
  setMaterialToTechnician: Function;
  setReturnMaterialToSupplier: Function;
  setReplaceMaterialToSupplier: Function;
  loadInventorySupplier: Function;
  addInventorySupplier: Function;
  getSupplierDetail: Function;
  updateSupplierDetail: Function;
  deleteSupplierAccount: Function;
  loadInventoryMaterials: Function;
  addInventoryMaterials: Function;
  getMaterialDetail: Function;
  loadInventoryPurchases: Function;
  addInventoryPurchases: Function;
  getPurchaseDetail: Function;
  updatePurchaseDetail: Function;
  deletePurchaseAccount: Function;
  loadBillBoardsMaterialToTechnician: Function;
  autoCompleteApi: Function;
  loadMembers: Function;
  loadMaterialToTechnician: Function;
  addMaterialToTechnician: Function;
  getMaterialToTechnician: Function;
  updateMaterialToTechnicianDetail: Function;
  deleteMaterialToTechnicianAccount: Function;
  loadReturnMaterialFromTechnician: Function;
  addReturnMaterialFromTechnician: Function;
  getReturnMaterialFromTechnician: Function;
  updateReturnMaterialFromTechnicianDetail: Function;
  deletereturnMaterialFromTechnicianAccount: Function;
  loadReturnMaterialToSupplier: Function;
  addReturnMaterialToSupplier: Function;
  getReturnMaterialToSupplier: Function;
  updateReturnMaterialToSupplierDetail: Function;
  deleteReturnMaterialToSupplierAccount: Function;
  loadReplaceMaterialToSupplier: Function;
  addReplaceMaterialToSupplier: Function;
  getReplaceMaterialToSupplier: Function;
  updateReplaceMaterialToSupplierDetail: Function;
  deleteReplaceMaterialToSupplierAccount: Function;
  downloadMaterialExcel: Function;
  downloadPurchaseExcel: Function;
  downloadSupplierExcel: Function;
  downloadMaterialToTechnicianExcel: Function;
  downloadReturnMaterialToSupplierExcel: Function;
  downloadReturnMaterialFromSupplierExcel: Function;
  downloadReturnMaterialFromTechnicianExcel: Function;
  downloadSupplierPdf: Function;
  downloadPurchasePdf: Function;
  downloadMaterialPdf: Function;
  downloadMaterialToTechnicianPdf: Function;
  downloadReturnMaterialToSupplierPdf: Function;
  downloadReturnMaterialFromSupplierPdf: Function;
  downloadReturnMaterialFromTechnicianPdf: Function;
  stockAutoComplete: Function;
  setPurchase: Function;
  setReturnMaterial: Function;
  generateReports: Function;
  getStockAdjustments: Function;
  addStockAdjustment: Function;
  generateTechnicianReport: Function;
}

const InventeryContext = React.createContext<InventeryContextProp | null>(null);

const InventeryProvider = ({ children }: InventeryProviderProps) => {
  const [isLoading, setIsLoading]: [boolean, Function] = useState(false);
  const [meta, setMeta] = useState<any>({});
  const [suppliers, setSuppliers] = useState<any>([]);
  const [stocks, setStocks] = useState<any>([]);
  const [purchases, setPurchases] = useState<any>([]);
  const [purchase, setPurchase] = useState<any>([]);
  const [billBoards, setBillBoards] = useState<any>([]);
  const [members, setMembers] = useState<any>([]);
  const [materialToTechnicians, setMaterialToTechnicians] = useState<any>([]);
  const [materialToTechnician, setMaterialToTechnician] = useState<any>([]);
  const [currentMaterialToTechnicianId, setCurrentMaterialToTechnicianId] =
    useState("");
  const [returnMaterials, setReturnMaterials] = useState<any>([]);
  const [returnMaterial, setReturnMaterial] = useState<any>([]);
  const [returnMaterialToSuppliers, setReturnMaterialToSuppliers] =
    useState<any>();
  const [returnMaterialToSupplier, setReturnMaterialToSupplier] =
    useState<any>();
  const [replaceMaterialToSupplier, setReplaceMaterialToSupplier] =
    useState<any>();

  // ----------------------------------------------------------------Suppliers------------------------------------------------------

  const loadInventorySupplier = async (params: any) => {
    setIsLoading(true);
    const { suppliers, success, meta } = await api.inventorySupplier(params);
    if (success) {
      setSuppliers(suppliers);
    }
    setIsLoading(false);
    setMeta(meta);
    return { suppliers, success, meta };
  };

  const addInventorySupplier = async (params: any) => {
    setIsLoading(true);
    const res = await api.addSupplier(params);
    const { success, message, error } = res.data;
    setIsLoading(false);
    if (success) {
      return { success, message };
    } else {
      return {
        success,
        errors: error,
      };
    }
  };

  const getSupplierDetail = async (supplierId: any) => {
    if (supplierId) {
      setIsLoading(true);
      const { success, supplier, error } = await api.supplier(supplierId);
      if (success) {
        setSuppliers(supplier);
      }
      setIsLoading(false);
      return { success, supplier, error };
    }
  };

  const updateSupplierDetail = async (supplierId: string, params: any) => {
    if (supplierId) {
      setIsLoading(true);
      const data = await api.updateSupplier(supplierId, params);
      const { success, supplier, error } = data?.data;
      if (success) {
        setSuppliers(supplier);
      }
      setIsLoading(false);
      return { success, supplier, error };
    }
  };
  const deleteSupplierAccount = async (supplierId: string) => {
    setIsLoading(true);
    const { success, error } = await api.deleteSupplier(supplierId);
    setIsLoading(false);
    return { success, error };
  };

  // ----------------------------------------------------------------Suppliers------------------------------------------------------

  // ----------------------------------------------------------------Materials------------------------------------------------------

  const loadInventoryMaterials = async (params: any) => {
    setIsLoading(true);
    const { stocks, success, meta } = await api.inventoryMaterial(params);
    if (success) {
      setStocks(stocks);
    }
    setIsLoading(false);
    setMeta(meta);
    return { stocks, success, meta };
  };

  const addInventoryMaterials = async (params: any) => {
    setIsLoading(true);
    const res = await api.addMaterial(params);
    const { success, message, error } = res.data;
    setIsLoading(false);
    return { success, message, error };
  };

  const getMaterialDetail = async (id: any) => {
    if (id) {
      setIsLoading(true);
      const { success, stock, error } = await api.material(id);
      if (success) {
        setStocks(stock);
      }
      setIsLoading(false);
      return { success, stock, error };
    }
  };

  const updateStockDetail = async (materialId: string, params: any) => {
    if (materialId) {
      setIsLoading(true);
      const data = await api.updateMaterial(materialId, params);
      const { success, stock, error } = data?.data;
      if (success) {
        setStocks(stock);
      }
      setIsLoading(false);
      return { success, stock, error };
    }
  };

  // ----------------------------------------------------------------Materials------------------------------------------------------
  // ----------------------------------------------------------------Purchases------------------------------------------------------
  const loadInventoryPurchases = async (params: any) => {
    setIsLoading(true);
    const { purchase, success, meta } = await api.inventoryPurchase(params);
    if (success) {
      setPurchases(purchase);
    }
    setIsLoading(false);
    setMeta(meta);
  };

  const addInventoryPurchases = async (params: any) => {
    setIsLoading(true);
    const res = await api.addPurchase(params);
    const { sucess: success, message, error } = res.data;
    setIsLoading(false);
    return { success, message, error };
  };

  const getPurchaseDetail = async (purchaseId: any) => {
    if (purchaseId) {
      setIsLoading(true);
      const { success, purchase, error } = await api.purchase(purchaseId);
      if (success) {
        setPurchase(purchase);
      }
      setIsLoading(false);
      return { success, purchase, error };
    }
  };

  const updatePurchaseDetail = async (purchaseId: string, params: any) => {
    if (purchaseId) {
      setIsLoading(true);
      const data = await api.updatePurchase(purchaseId, params);
      const { success, purchase, error } = data?.data;
      if (success) {
        setPurchases(purchase);
      }
      setIsLoading(false);
      return { success, purchase, error };
    }
  };
  const deletePurchaseAccount = async (purchaseId: string) => {
    setIsLoading(true);
    const { success, error } = await api.deletePurchase(purchaseId);
    setIsLoading(false);
    return { success, error };
  };

  // ----------------------------------------------------------------Purchases------------------------------------------------------
  // ----------------------------------------------------------------materialToTechnician------------------------------------------------------

  const loadBillBoardsMaterialToTechnician = async (params: any) => {
    setIsLoading(true);
    const { billboards, success } = await api.billBoards(params);
    setBillBoards(billboards);
    setIsLoading(false);
    return { success, billboards };
  };

  const autoCompleteApi = async (params: any) => {
    setIsLoading(true);
    const { success, users, meta } = await api.autoCompleteApi(params);
    setIsLoading(false);
    return { success, users, meta };
  };

  const loadMembers = async (params: any) => {
    const { success, user, meta } = await api.members(params);
    if (success) {
      setMembers(user);
      setMeta(meta);
    }
    return { success, user, meta };
  };

  const loadMaterialToTechnician = async (params: any) => {
    setIsLoading(true);
    const { material_to_technicain, success, meta } =
      await api.inventoryMaterialToTechnician(params);
    if (success) {
      setMaterialToTechnicians(material_to_technicain);
    }
    setIsLoading(false);
    setMeta(meta);
  };

  const addMaterialToTechnician = async (params: any) => {
    setIsLoading(true);
    const res = await api.addNewMaterialToTechnician(params);
    const { success, message, error } = res.data;
    setIsLoading(false);
    return { success, message, error };
  };

  const getMaterialToTechnician = async (id: any) => {
    setCurrentMaterialToTechnicianId(id);
    if (id) {
      setIsLoading(true);
      const { success, material, error } = await api.materialToTechnician(id);
      if (success) {
        setMaterialToTechnician(material);
      }
      setIsLoading(false);
      return { success, material, error };
    }
  };

  const updateMaterialToTechnicianDetail = async (
    materialToTechnicianId: string,
    params: any
  ) => {
    if (materialToTechnicianId) {
      setIsLoading(true);
      const data = await api.updateMaterialToTechnician(
        materialToTechnicianId,
        params
      );
      const { success, material, error } = data?.data;
      if (success) {
        setMaterialToTechnician(material);
      }
      setIsLoading(false);
      return { success, material, error };
    }
  };
  const deleteMaterialToTechnicianAccount = async (
    materialToTechnicianId: string
  ) => {
    setIsLoading(true);
    const { success, error } = await api.deleteMaterialToTechnician(
      materialToTechnicianId
    );
    setIsLoading(false);
    return { success, error };
  };

  // ----------------------------------------------------------------materialToTechnician------------------------------------------------------
  // ----------------------------------------------------------------ReturnmaterialFromTechnician------------------------------------------------------

  const addReturnMaterialFromTechnician = async (params: any) => {
    setIsLoading(true);
    const res = await api.addNewReturnMaterialFromTechnician(params);
    const { success, message, error } = res.data;
    setIsLoading(false);
    return { success, message, error };
  };

  const loadReturnMaterialFromTechnician = async (params: any) => {
    setIsLoading(true);
    const { returnMaterial, success, meta } =
      await api.inventoryReturnMaterialFromTechnician(params);
    if (success) {
      setReturnMaterials(returnMaterial);
    }
    setIsLoading(false);
    setMeta(meta);
  };

  const getReturnMaterialFromTechnician = async (id: any) => {
    setCurrentMaterialToTechnicianId(id);
    if (id) {
      setIsLoading(true);
      const { success, return_material, error } =
        await api.returnMaterialFromTechnician(id);
      if (success) {
        setReturnMaterial(return_material);
      }
      setIsLoading(false);
      return { success, return_material, error };
    }
  };

  const updateReturnMaterialFromTechnicianDetail = async (
    returnMaterialFromTechnicianId: string,
    params: any
  ) => {
    if (returnMaterialFromTechnicianId) {
      setIsLoading(true);
      const data = await api.updateReturnMaterialFromTechnician(
        returnMaterialFromTechnicianId,
        params
      );
      const { success, return_material, error } = data?.data;
      if (success) {
        setReturnMaterials(return_material);
      }
      setIsLoading(false);
      return { success, return_material, error };
    }
  };
  const deletereturnMaterialFromTechnicianAccount = async (
    returnMaterialFromTechnicianId: string
  ) => {
    setIsLoading(true);
    const { success, error } = await api.deleteReturnMaterialFromTechnician(
      returnMaterialFromTechnicianId
    );
    setIsLoading(false);
    return { success, error };
  };

  // ----------------------------------------------------------------ReturnmaterialFromTechnician------------------------------------------------------
  // ----------------------------------------------------------------ReturnmaterialToSupplier------------------------------------------------------

  const loadReturnMaterialToSupplier = async (params: any) => {
    setIsLoading(true);
    const { returnMaterial, success, meta } =
      await api.inventoryReturnMaterialToSupplier(params);
    if (success) {
      setReturnMaterialToSuppliers(returnMaterial);
    }
    setIsLoading(false);
    setMeta(meta);
  };

  const addReturnMaterialToSupplier = async (params: any) => {
    setIsLoading(true);
    const res = await api.addReturnMaterialToSupplier(params);
    const { success, message, error } = res.data;
    setIsLoading(false);
    return { success, message, error };
  };

  const getReturnMaterialToSupplier = async (id: any) => {
    if (id) {
      setIsLoading(true);
      const { success, return_material, error } =
        await api.ReturnMaterialToSupplier(id);
      if (success) {
        setReturnMaterialToSupplier(return_material);
      }
      setIsLoading(false);
      return { success, return_material, error };
    }
  };
  const updateReturnMaterialToSupplierDetail = async (
    id: string,
    params: any
  ) => {
    if (id) {
      setIsLoading(true);
      const data = await api.updateReturnMaterialToSupplier(id, params);
      const { success, return_material, error } = data?.data;
      if (success) {
        setReturnMaterialToSupplier(return_material);
      }
      setIsLoading(false);
      return { success, return_material, error };
    }
  };
  const deleteReturnMaterialToSupplierAccount = async (id: string) => {
    setIsLoading(true);
    const { success, error } = await api.deleteReturnMaterialToSupplier(id);
    setIsLoading(false);
    return { success, error };
  };
  // ----------------------------------------------------------------ReturnmaterialToSupplier------------------------------------------------------
  // ----------------------------------------------------------------ReplacematerialToSupplier------------------------------------------------------

  const loadReplaceMaterialToSupplier = async (params: any) => {
    setIsLoading(true);
    const { replaceMaterial, success, meta } =
      await api.inventoryReplaceMaterialToSupplier(params);
    if (success) {
      setReplaceMaterialToSupplier(replaceMaterial);
    }
    setIsLoading(false);
    setMeta(meta);
  };

  const addReplaceMaterialToSupplier = async (params: any) => {
    setIsLoading(true);
    const res = await api.addReplaceMaterialToSupplier(params);
    const { success, message, error } = res.data;
    setIsLoading(false);
    return { success, message, error };
  };

  const getReplaceMaterialToSupplier = async (id: any) => {
    if (id) {
      setIsLoading(true);
      const { success, replaceMaterial, error } =
        await api.ReplaceMaterialToSupplier(id);
      if (success) {
        setReplaceMaterialToSupplier(replaceMaterial);
      }
      setIsLoading(false);
      return { success, replaceMaterial, error };
    }
  };
  const updateReplaceMaterialToSupplierDetail = async (
    id: string,
    params: any
  ) => {
    if (id) {
      setIsLoading(true);
      const data = await api.updateReplaceMaterialToSupplier(id, params);
      const { success, replaceMaterial, error } = data?.data;
      if (success) {
        setReplaceMaterialToSupplier(replaceMaterial);
      }
      setIsLoading(false);
      return { success, replaceMaterial, error };
    }
  };
  const deleteReplaceMaterialToSupplierAccount = async (id: string) => {
    setIsLoading(true);
    const { success, error } = await api.deleteReplaceMaterialToSupplier(id);
    setIsLoading(false);
    return { success, error };
  };
  // ----------------------------------------------------------------ReplacematerialToSupplier------------------------------------------------------

  const downloadMaterialExcel = async (params: any) => {
    setIsLoading(true);
    const res = await api.materialDownload(params);
    setIsLoading(false);
    return res;
  };

  const downloadPurchaseExcel = async (params: any) => {
    setIsLoading(true);
    const res = await api.purchaseDownload(params);
    setIsLoading(false);
    return res;
  };

  const downloadSupplierExcel = async (params: any) => {
    setIsLoading(true);
    const res = await api.supplierDownload(params);
    setIsLoading(false);
    return res;
  };

  const downloadSupplierPdf = async (params: any) => {
    setIsLoading(true);
    const res = await api.supplierPdfDownload(params);
    setIsLoading(false);
    return res;
  };

  const downloadPurchasePdf = async (params: any) => {
    setIsLoading(true);
    const res = await api.purchasePdfDownload(params);
    setIsLoading(false);
    return res;
  };

  const downloadMaterialPdf = async (params: any) => {
    setIsLoading(true);
    const res = await api.materialPdfDownload(params);
    setIsLoading(false);
    return res;
  };

  const downloadMaterialToTechnicianPdf = async (params: any) => {
    setIsLoading(true);
    const res = await api.materialToTechnicianPdfDownload(params);
    setIsLoading(false);
    return res;
  };

  const downloadReturnMaterialToSupplierPdf = async (params: any) => {
    setIsLoading(true);
    const res = await api.returnMaterialToSupplierPdfDownload(params);
    setIsLoading(false);
    return res;
  };

  const downloadReturnMaterialFromSupplierPdf = async (params: any) => {
    setIsLoading(true);
    const res = await api.replaceMaterialFromSupplierPdfDownload(params);
    setIsLoading(false);
    return res;
  };

  const downloadReturnMaterialFromTechnicianPdf = async (params: any) => {
    setIsLoading(true);
    const res = await api.returnMaterialFromTechnicianPdfDownload(params);
    setIsLoading(false);
    return res;
  };

  const downloadMaterialToTechnicianExcel = async (params: any) => {
    setIsLoading(true);
    const res = await api.materialToTechnicianDownload(params);
    setIsLoading(false);
    return res;
  };

  const downloadReturnMaterialToSupplierExcel = async (params: any) => {
    setIsLoading(true);
    const res = await api.returnMaterialToSupplierDownload(params);
    setIsLoading(false);
    return res;
  };

  const downloadReturnMaterialFromSupplierExcel = async (params: any) => {
    setIsLoading(true);
    const res = await api.replaceMaterialFromSupplierDownload(params);
    setIsLoading(false);
    return res;
  };

  const downloadReturnMaterialFromTechnicianExcel = async (params: any) => {
    setIsLoading(true);
    const res = await api.returnMaterialFromTechnicianDownload(params);
    setIsLoading(false);
    return res;
  };

  const stockAutoComplete = async (params: any) => {
    setIsLoading(true);
    const res = await api.stockAutoComplete(params);
    setIsLoading(false);
    return res;
  };

  const generateReports = async (params: any) => {
    setIsLoading(true);
    const res = await api.generateReports(params);
    setIsLoading(false);
    return res;
  };

  const generateTechnicianReport = async (params: any) => {
    setIsLoading(true);
    const res = await api.generateTechnicianReport(params);
    setIsLoading(false);
    return res;
  };

  const addStockAdjustment = async (params: any) => {
    setIsLoading(true);
    const res = await api.addStockAdjustment(params);
    setIsLoading(false);
    return res;
  };

  const getStockAdjustments = async (params: any) => {
    setIsLoading(true);
    const res = await api.getStockAdjustments(params);
    setIsLoading(false);
    return res;
  };

  const value: InventeryContextProp = {
    isLoading,
    setIsLoading,
    meta,
    suppliers,
    stocks,
    purchases,
    purchase,
    billBoards,
    members,
    materialToTechnician,
    materialToTechnicians,
    currentMaterialToTechnicianId,
    returnMaterials,
    returnMaterial,
    returnMaterialToSupplier,
    returnMaterialToSuppliers,
    replaceMaterialToSupplier,
    setSuppliers,
    updateStockDetail,
    setMaterialToTechnician,
    setReturnMaterialToSupplier,
    setReplaceMaterialToSupplier,
    loadInventorySupplier,
    addInventorySupplier,
    getSupplierDetail,
    updateSupplierDetail,
    deleteSupplierAccount,
    loadInventoryMaterials,
    addInventoryMaterials,
    getMaterialDetail,
    loadInventoryPurchases,
    addInventoryPurchases,
    getPurchaseDetail,
    updatePurchaseDetail,
    deletePurchaseAccount,
    loadBillBoardsMaterialToTechnician,
    autoCompleteApi,
    loadMembers,
    loadMaterialToTechnician,
    addMaterialToTechnician,
    getMaterialToTechnician,
    updateMaterialToTechnicianDetail,
    deleteMaterialToTechnicianAccount,
    setCurrentMaterialToTechnicianId,
    loadReturnMaterialFromTechnician,
    addReturnMaterialFromTechnician,
    getReturnMaterialFromTechnician,
    updateReturnMaterialFromTechnicianDetail,
    deletereturnMaterialFromTechnicianAccount,
    loadReturnMaterialToSupplier,
    addReturnMaterialToSupplier,
    getReturnMaterialToSupplier,
    updateReturnMaterialToSupplierDetail,
    deleteReturnMaterialToSupplierAccount,
    loadReplaceMaterialToSupplier,
    addReplaceMaterialToSupplier,
    getReplaceMaterialToSupplier,
    updateReplaceMaterialToSupplierDetail,
    deleteReplaceMaterialToSupplierAccount,
    downloadMaterialExcel,
    downloadPurchaseExcel,
    downloadSupplierExcel,
    downloadMaterialToTechnicianExcel,
    downloadReturnMaterialToSupplierExcel,
    downloadReturnMaterialFromSupplierExcel,
    downloadReturnMaterialFromTechnicianExcel,
    downloadSupplierPdf,
    downloadPurchasePdf,
    downloadMaterialPdf,
    downloadMaterialToTechnicianPdf,
    downloadReturnMaterialToSupplierPdf,
    downloadReturnMaterialFromSupplierPdf,
    downloadReturnMaterialFromTechnicianPdf,
    stockAutoComplete,
    setPurchase,
    setReturnMaterial,
    generateReports,
    getStockAdjustments,
    addStockAdjustment,
    generateTechnicianReport,
  };

  return (
    <InventeryContext.Provider value={value}>
      {children}
    </InventeryContext.Provider>
  );
};

const useInventery = () => React.useContext(InventeryContext);

export { InventeryProvider, useInventery };
