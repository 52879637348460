import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "../../App";
import File404 from "../../components/404/File404";
import { routes } from "../../constants/routes";
import { CleaningProvider } from "../../context/CleaningProvider";
import CreateOrViewCleaning from "./CreateOrViewCleaning";
import ListCleaning from "./ListCleaning";

const CleaningPages = ({ userContext }: any) => {
  return (
    userContext?.roleAccess && (
      <CleaningProvider>
        <Routes>
          <Route
            path={routes.listCleaning}
            element={
              <RequireAuth>
                {userContext?.roleAccess["CLEANING"].includes("READ") ? (
                  <ListCleaning userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.newCleaning}
            element={
              <RequireAuth>
                {userContext?.roleAccess["CLEANING"].includes("READ") ? (
                  <CreateOrViewCleaning userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />

          <Route
            path={routes.editCleaning}
            element={
              <RequireAuth>
                {userContext?.roleAccess["CLEANING"].includes("READ") ? (
                  <CreateOrViewCleaning userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.getCleaningTicketsId}
            element={
              <RequireAuth>
                {userContext?.roleAccess["CLEANING"].includes("READ") ? (
                  <CreateOrViewCleaning userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />

          <Route
            path={routes.getCleaningTicketsActivityFeed}
            element={
              <RequireAuth>
                {userContext?.roleAccess["CLEANING"].includes("READ") ? (
                  <CreateOrViewCleaning userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
        </Routes>
      </CleaningProvider>
    )
  );
};

export default CleaningPages;
