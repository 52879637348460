import { useLocation } from "react-router-dom";
import BillBoardNested from "../../pages/BillBoards/NestedTabBillBorad/BillBoardNested";
import CreateNewTicketForm from "../../pages/Tickets/CreateNewTicketForm";
import ViewAndActivityFeed from "../../pages/BillBoards/NestedTabBillBorad/ViewAndActivityFeed";

const NestedForm = ({ context, userContext }: any) => {
  const location = useLocation();

  return (
    <BillBoardNested userContext={userContext} context={context}>
      {location.pathname?.includes("/billboards/") &&
        location?.pathname?.includes("/tickets/new") && (
          <CreateNewTicketForm userContext={userContext} context={context} />
        )}
      {location.pathname?.includes("/billboards/") &&
        location?.pathname?.includes("/tickets/") &&
        location?.pathname?.includes("/edit") && (
          <ViewAndActivityFeed userContext={userContext} context={context} />
        )}
      {location.pathname?.includes("/billboards/") &&
        location?.pathname?.includes("/tickets/") &&
        location?.pathname?.includes("/activities") && (
          <ViewAndActivityFeed userContext={userContext} context={context} />
        )}
    </BillBoardNested>
  );
};

export default NestedForm;
