import { Card, Modal } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";
import { routes } from "../constants/routes";
import moment from "moment";
import { RxCross2 } from "react-icons/rx";
import CommonAvatar from "./Avatar/CommonAvatar";

const Notification = ({
  setNotificationShowPopUp,
  notificationShowPopUp,
  context,
  t,
  navigate,
}: any) => (
  <Modal
    show={notificationShowPopUp}
    onHide={() => {
      setNotificationShowPopUp(false);
    }}
  >
    <div className="d-flex justify-content-between align-items-center p-3">
      <h4 className=" m-0 fw-semibold">{t("notification.Notification")}</h4>
      <div
        onClick={() => {
          setNotificationShowPopUp(false);
        }}
        className=" pointer"
      >
        <RxCross2 fontSize={24} color="#b1b1b1" />
      </div>
    </div>
    {context?.notifications?.length > 0 && (
      <Modal.Body className=" overflow-scroll" style={{ height: "80vh" }}>
        {context?.notifications
          ?.sort((prev: any, curr: any) =>
            moment(curr?.date).format("MMMM Do YYYY, h:mm:ss a") >
            moment(prev?.date).format("MMMM Do YYYY, h:mm:ss a")
              ? 1
              : -1
          )
          ?.map((item: any, index: any) => {
            let messageValue = JSON?.parse(item?.message);
            return (
              <Card
                className={`${
                  context?.user?.notification_count > index
                    ? "notification-card mb-3 notification-unread"
                    : "notification-card mb-3"
                }`}
                onClick={() => {
                  setNotificationShowPopUp(false);
                  if (
                    item?.title === "billboard_non_operation" ||
                    item?.title === "billboard_approved" ||
                    item?.title === "billboard_created" ||
                    item?.title === "billboard_operational"
                  ) {
                    navigate(routes?.editBillBoardUrl(messageValue?.id));
                  } else if (
                    item?.title === "ticket_created" ||
                    item?.title === "ticket_viewed" ||
                    item?.title === "ticket_submitted" ||
                    item?.title === "ticket_verified" ||
                    item?.title === "ticket_reassigned" ||
                    item?.title === "ticket_approved" ||
                    item?.title === "ticket_rejected"
                  ) {
                    navigate(routes?.editTicketUrl(messageValue?.id));
                  } else if (item?.title === "billboard_created") {
                    navigate(
                      routes?.editBillBoardUrl(messageValue?.billboard?.id)
                    );
                  } else if (item?.title === "ticket_updated") {
                    navigate(routes?.editTicketUrl(messageValue?.ticket?.id));
                  } else if (item?.title === "user_accept_object") {
                    navigate(routes?.personalProfileUrl(messageValue?.id));
                  } else if (item?.title === "excel_upload") {
                    navigate(routes?.listBillBoard);
                  } else if (item?.title === "member_invite") {
                    navigate(routes?.listMembers);
                  } else if (item?.title === "billboard_updated") {
                    navigate(
                      routes?.editBillBoardUrl(messageValue?.billboard?.id)
                    );
                  }
                }}
              >
                {context?.user?.notification_count > 0 &&
                  context?.user?.notification_count > index && (
                    <div className="notification-unread_circle"></div>
                  )}
                <div
                  className={
                    context?.user?.notification_count > 0
                      ? "d-flex p-2"
                      : "d-flex"
                  }
                >
                  <CommonAvatar
                    name={context?.userName(item, messageValue)}
                    imageSrc={context?.userAvatar(item, messageValue)}
                    size="50px"
                    style={{
                      backgroundColor: "#6b5ca4",
                    }}
                  />

                  {item?.title === "billboard_operational" && (
                    <div className="px-2">
                      {messageValue?.board_no}{" "}
                      {t(
                        "notification.has been requested to mark it as the operational billboard made by"
                      )}{" "}
                      - {messageValue?.requested_by?.name}
                    </div>
                  )}
                  {item?.title === "billboard_non_operation" && (
                    <div className="px-2">
                      {messageValue?.board_no}{" "}
                      {t(
                        "notification.has been requested to mark it as the non - operational billboard requested by"
                      )}{" "}
                      - {messageValue?.requested_by?.name}
                    </div>
                  )}
                  {item?.title === "billboard_approved" && (
                    <div className="px-2">
                      {messageValue?.board_no}{" "}
                      {t(
                        "notification.has been Approved as the non - operational billboard Approved by"
                      )}{" "}
                      - {messageValue?.approved_by?.name}
                    </div>
                  )}
                  {item?.title === "billboard_created" && (
                    <div className="px-2">
                      {messageValue?.created_by?.name}{" "}
                      {t("notification.has been created")} -{" "}
                      {messageValue?.board_no}
                    </div>
                  )}
                  {item?.title === "billboard_updated" && (
                    <div className="px-2">
                      {messageValue?.updated_info?.name}{" "}
                      {t("notification.has been updated")} -{" "}
                      {messageValue?.billboard?.board_no}
                      {messageValue?.meta &&
                        Object.keys(messageValue?.meta).map((key, index) => {
                          if (key !== "_id") {
                            let value = messageValue?.meta[key];
                            if (typeof value === "object" && value !== null) {
                              if (value.type && value.coordinates) {
                                value = `type: ${
                                  value.type
                                }, coordinates: [${value.coordinates.join(
                                  ", "
                                )}]`;
                              } else {
                                value = JSON.stringify(value);
                              }
                            }

                            return (
                              <span key={key}>
                                {Object.keys(messageValue?.meta).length - 2 ===
                                index
                                  ? " and "
                                  : ", "}
                                the {key.replace("_", " ")} -{" "}
                                {key === "operational_date" ||
                                key === "installation_date"
                                  ? moment(value).format("YYYY-MM-DD")
                                  : key === "screen_size"
                                  ? `screen width ${
                                      messageValue?.meta?.screen_size
                                        .replace("X", " ")
                                        .split(" ")[0]
                                    }, ` +
                                    `screen height ${
                                      messageValue?.meta?.screen_size
                                        .replace("X", " ")
                                        .split(" ")[1]
                                    }, ` +
                                    `screen units ${
                                      messageValue?.meta?.screen_size
                                        .replace("X", " ")
                                        .split(" ")[2]
                                    }`
                                  : value}
                              </span>
                            );
                          }
                        })}
                    </div>
                  )}
                  {item?.title === "ticket_created" && (
                    <div className="px-2">
                      {messageValue?.created_by?.name}{" "}
                      {t("Activityfeed.has created a new ticket")} -{" "}
                      {messageValue?.ticket_no}{" "}
                      {t("Activityfeed.for the billboard")} -{" "}
                      {messageValue?.bill_board_id?.board_no}{" "}
                      {t("Activityfeed.and assigned to")} -{" "}
                      {messageValue?.assignee?.name}
                    </div>
                  )}
                  {item?.title === "ticket_updated" && (
                    <div className="px-2">
                      {messageValue?.updated_info?.name}{" "}
                      {t("Activityfeed.has updated the ticket")} -{" "}
                      {messageValue?.ticket?.ticket_no}
                      {messageValue?.meta &&
                        Object.keys(messageValue?.meta).map(
                          (item: any, index: any) => {
                            if (item !== "_id") {
                              return (
                                <>
                                  {Object.keys(messageValue?.meta).length -
                                    2 ===
                                  index
                                    ? " and "
                                    : ", "}
                                  the {item?.replace("_", " ")} -{" "}
                                  {item === "start_date" || item === "end_date"
                                    ? moment(messageValue?.meta[item]).format(
                                        "YYYY-MM-DD"
                                      )
                                    : item === "pictures" &&
                                      messageValue?.meta?.pictures?.length
                                    ? messageValue?.meta?.pictures?.length
                                    : item === "videos" &&
                                      messageValue?.meta?.videos?.length
                                    ? messageValue?.meta?.videos?.length
                                    : item === "audios" &&
                                      messageValue?.meta?.audios?.length
                                    ? messageValue?.meta?.audios?.length
                                    : messageValue?.meta[item]}
                                </>
                              );
                            }
                          }
                        )}
                    </div>
                  )}
                  {item?.title === "ticket_viewed" && (
                    <div className="px-2">
                      {messageValue?.assignee?.name}{" "}
                      {t("Activityfeed.has viewed the ticket")} -{" "}
                      {messageValue?.ticket_no}
                    </div>
                  )}
                  {item?.title === "ticket_submitted" && (
                    <div className="px-2">
                      {messageValue?.assignee?.name}{" "}
                      {t("Activityfeed.has submitted the")} -{" "}
                      {messageValue?.ticket_no}
                    </div>
                  )}
                  {item?.title === "ticket_verified" && (
                    <div className="px-2">
                      {messageValue?.approved_by_supervisor?.name}{" "}
                      {t("Activityfeed.has verified the ticket")}
                    </div>
                  )}
                  {item?.title === "ticket_reassigned" && (
                    <div className="px-2">
                      {messageValue?.created_by?.name}{" "}
                      {t("Activityfeed.has reassigned the ticket")} -{" "}
                      {messageValue?.ticket_no} {t("Activityfeed.to the")} -{" "}
                      {messageValue?.assignee?.name}
                    </div>
                  )}
                  {item?.title === "ticket_approved" && (
                    <div className="px-2">
                      {messageValue?.approved_by_manager?.name}{" "}
                      {t("Activityfeed.has approved the ticket")}
                    </div>
                  )}
                  {item?.title === "ticket_rejected" && (
                    <div className="px-2">
                      {messageValue?.rejected_by?.name}{" "}
                      {t("Activityfeed.has rejected the ticket")}
                    </div>
                  )}
                  {item?.title === "user_accept_object" && (
                    <div className="px-2">
                      {messageValue?.name}{" "}
                      {t("notification.has accepted the intivation")}
                    </div>
                  )}
                  {item?.title === "excel_upload" && (
                    <div className="px-2">
                      {messageValue?.updated_by?.name}{" "}
                      {t(
                        "notification.has uploaded the billbard excel which contains of "
                      )}{" "}
                      - {messageValue?.document_count} {t("header.bill")}
                    </div>
                  )}
                  {item?.title === "member_invite" && (
                    <div className="px-2">
                      {messageValue?.email}{" "}
                      {t("notification.has accepted invitation")}
                    </div>
                  )}
                </div>
              </Card>
            );
          })}
      </Modal.Body>
    )}
  </Modal>
);

export default Notification;
