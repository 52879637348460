import React from "react";
import Select from "react-select";
import { customStyles } from "../../interfaces/IUser";

const ReactCustomSelect = ({
  isFieldDisabled,
  feildName,
  defaultOptions,
  updateFieldValue,
  dataOptions,
  isMulti,
  className = "",
  searchDisabled = true,
  isClearable = false,
}: any) => {
  return (
    <>
      <Select
        value={defaultOptions}
        name={feildName}
        isMulti={isMulti}
        styles={customStyles}
        options={dataOptions}
        className={`${
          isFieldDisabled && "disabled-field"
        } basic-multi-select ${className}`}
        isDisabled={isFieldDisabled}
        classNamePrefix="select"
        isSearchable={searchDisabled}
        onChange={(e: any) => {
          updateFieldValue(e);
        }}
        isClearable={isClearable}
      />
    </>
  );
};

export default ReactCustomSelect;
