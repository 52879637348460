import { ChangeEvent } from "react";
import "./CommonToggleSwitch.scss";

export interface SwitchProps {
  toggle?: boolean;
  textOff: string;
  textOn: string;
  isSwitchDefault?: boolean;
  checked?: boolean;
  isSwitchEligibility?: boolean;
  className?: string;
  onChange?: ((e: ChangeEvent<HTMLInputElement>) => void) | undefined;
  isDisabled?: boolean;
}

const CommonToggleSwitch = ({
  textOff,
  textOn,
  isSwitchDefault = false,
  onChange,
  className,
  isDisabled,
  checked,
  isSwitchEligibility = false,
}: SwitchProps) => {
  const getSwitchClasses = (
    isSwitchDefault: boolean,
    isSwitchEligibility: boolean
  ) => {
    if (isSwitchEligibility) {
      return "toggle_switch_eligibility";
    } else if (isSwitchDefault) {
      return "toggle_switch switch_default";
    } else {
      return "toggle_switch switch_custom";
    }
  };

  const getOnClasses = (
    isSwitchDefault: boolean,
    isSwitchEligibility: boolean
  ) => {
    if (isSwitchEligibility) {
      return "on_eligibility";
    } else if (isSwitchDefault) {
      return "on_default";
    } else {
      return "on";
    }
  };

  const getOffClasses = (
    isSwitchDefault: boolean,
    isSwitchEligibility: boolean
  ) => {
    if (isSwitchEligibility) {
      return "off_eligibility";
    } else if (isSwitchDefault) {
      return "off_default";
    } else {
      return "off";
    }
  };
  return (
    <div className={` ${className}`}>
      <label className="common_toggle_switch">
        <input
          type="checkbox"
          id="togBtn"
          disabled={isDisabled}
          checked={checked}
          onChange={onChange}
        />
        <div className={getSwitchClasses(isSwitchDefault, isSwitchEligibility)}>
          <div
            className={`pe-4 fw-semibold ${getOnClasses(
              isSwitchDefault,
              isSwitchEligibility
            )}`}
          >
            {textOn}
          </div>
          <div
            className={`ps-4 pe-1 fw-semibold ${getOffClasses(
              isSwitchDefault,
              isSwitchEligibility
            )}`}
          >
            {textOff}
          </div>
        </div>
      </label>
    </div>
  );
};

export default CommonToggleSwitch;
