/* eslint-disable react-hooks/exhaustive-deps */
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import "chartjs-plugin-doughnut-innertext";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { FiFilter } from "react-icons/fi";
import SuccessErrorToast from "../../components/PopupToast/SuccessErrorToast";
import ToastPopup from "../../components/PopupToast/ToastPopup";
import { useDashboard } from "../../context/DashboardProvider";
import {
  stackedBaroptionsHorizontal,
  stackedBaroptionsVertical,
} from "../../interfaces/Chart/BarChart";
import {
  datamap,
  datamapticket,
  optionsmap,
  optionsmapticket,
} from "../../interfaces/Chart/GeoChart";
import AppLayout from "../../layouts/AppLayout";
import DashBoardDountChart from "./DashBoardDountChart";
import DashboardAreaWisePieChart from "./DashboardAreaWisePieChart";
import DashboardBarAndLineChart from "./DashboardBarAndLineChart";
import DashboardBarChart from "./DashboardBarChart";
import DashboardDatePicker from "./DashboardDatePicker";
import DashboardMapChart from "./DashboardMapChart";
import DashboardOverview from "./DashboardOverviewInside";
import MapWithAMarker from "./MapWithAMarker";
import CommonLoadingBar from "../../components/LoadingBar/CommonLoadingBar";

ChartJS.register(
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const Dashboard = ({ billBoard, dateValue }: any) => {
  const context = useDashboard();
  const { t, i18n } = useTranslation();
  const [overViewData, setOverViewData] = useState<any>("year");
  const [ticketStatus, setTicketStatus] = useState<any>({});
  const [billBoardData, setBillBoardData] = useState<any>({});
  const [ticketPriority, setTicketPriority] = useState<any>({});
  const [departmentPriority, setDepartmentPriority] = useState<any>({});
  const [dateValues, setDateValues] = useState<any>();

  const convertDate = useCallback(() => {
    return dateValues === undefined
      ? moment(new Date()).format("YYYY-MM-DD")
      : overViewData === "day" ||
        overViewData === "year" ||
        overViewData === "month"
      ? moment?.unix(dateValues?.unix).format("yyyy-MM-DD")
      : moment?.unix(dateValues[0]?.unix).format("yyyy-MM-DD");
  }, [dateValues, overViewData]);

  const getDashBoardOverview = useCallback(async () => {
    await context?.getStatsOverview(convertDate(), overViewData);
  }, [convertDate, overViewData]);

  const getAreaWiseBillboard = useCallback(async () => {
    const result = await context?.getAreaWiseBillboard(
      convertDate(),
      overViewData
    );
    setBillBoardData(result);
  }, [convertDate, overViewData]);

  const getDashBoardTicketStatus = useCallback(async () => {
    const result = await context?.getStatusTicket(convertDate(), overViewData);
    setTicketStatus(result);
  }, [convertDate, overViewData]);

  const getDashBoardTicketPriority = useCallback(async () => {
    const resultPriority = await context?.getPriorityTicket(
      convertDate(),
      overViewData
    );
    setTicketPriority(resultPriority);
  }, [convertDate, overViewData]);

  const getDepartmenTicket = useCallback(async () => {
    const resultPriority = await context?.getDepartmenTicket(
      convertDate(),
      overViewData
    );
    setDepartmentPriority(resultPriority);
  }, [convertDate, overViewData]);

  const getLocationBasedTicket = useCallback(async () => {
    await context?.getLocationBasedTicket(convertDate(), overViewData);
  }, [convertDate, overViewData]);

  const hitApiCall = useCallback(async () => {
    getDashBoardOverview();
    getDashBoardTicketStatus();
    getDashBoardTicketPriority();
    // getDashBoardStatusPriorityTicket();
    getDepartmenTicket();
    getLocationBasedTicket();
    getAreaWiseBillboard();
  }, [
    getAreaWiseBillboard,
    getDashBoardOverview,
    getDashBoardTicketPriority,
    getDashBoardTicketStatus,
    getDepartmenTicket,
    getLocationBasedTicket,
  ]);

  useEffect(() => {
    hitApiCall();
    const intervalId = setInterval(hitApiCall, 240000);

    return () => {
      clearInterval(intervalId);
    };
  }, [overViewData, dateValues, hitApiCall]);

  return (
    <AppLayout>
      <div
        className={`${
          isMobile ? "list_members position-relative  " : "list_members p-3 "
        }`}
      >
        <div
          className={`${
            isMobile ? "container-fluid position-relative " : "container "
          }`}
        >
          <div className="pt-4">
            {context?.isLoading && <CommonLoadingBar />}
            <div
              className={
                isMobileOnly
                  ? "d-flex justify-content-between align-items-center pb-3"
                  : "d-flex justify-content-between align-items-center pb-5"
              }
            >
              <div>
                <h6 className=" fw-semibold"> {t("dashboard.dashboard")}</h6>
              </div>
              <div className=" d-flex justify-content-between align-items-center">
                <div
                  className={
                    isMobileOnly ? "fordashboard " : "fordashboard px-3"
                  }
                >
                  <Dropdown className="">
                    <Dropdown.Toggle className=" bg-transparent">
                      {overViewData === "day"
                        ? `${t("dashboard.Today")}`
                        : // : overViewData === "by_yesterday"
                        // ? `${t("dashboard.Yesterday")}`
                        overViewData === "week"
                        ? `${t("dashboard.Week")}`
                        : overViewData === "month"
                        ? `${t("dashboard.Month")}`
                        : // : overViewData === "quater"
                        //   ? `${t("dashboard.Quater")}`
                        overViewData === "year"
                        ? `${t("dashboard.Year")}`
                        : `${t("dashboard.This Month")}`}
                      <span style={{ paddingInlineStart: "10px" }}>
                        <FiFilter color="#2a2b2f" />
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setOverViewData("day");
                          setDateValues(undefined);
                        }}
                      >
                        {t("dashboard.Today")}
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => {
                          setOverViewData("week");
                          setDateValues(undefined);
                        }}
                      >
                        {t("dashboard.Week")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setOverViewData("month");
                          setDateValues(undefined);
                        }}
                      >
                        {t("dashboard.Month")}
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => {
                          setOverViewData("year");
                          setDateValues(undefined);
                        }}
                      >
                        {t("dashboard.Year")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className={isMobileOnly ? "d-none" : ""}>
                  <DashboardDatePicker
                    overViewData={overViewData}
                    setDateValues={setDateValues}
                    dateValues={dateValues}
                  />
                </div>
              </div>
            </div>
            <div
              className={
                isMobileOnly ? "dateRangeformobile1 pt-3 pb-5" : "d-none"
              }
            >
              <div>
                <DashboardDatePicker
                  overViewData={overViewData}
                  setDateValues={setDateValues}
                  dateValues={dateValues}
                />
              </div>
            </div>
            <div className="overviewCard p-4">
              <div className=" d-flex justify-content-between align-items-center">
                <h6 className=" fw-semibold"> {t("dashboard.stats")}</h6>
              </div>
              <div>
                <DashboardOverview context={context} />
              </div>
            </div>
            {/* -----------------------------------------------------------------barChart------------------------------- */}
            <div className="pt-5">
              <div className="row">
                <div
                  className={
                    i18n.language === "en"
                      ? "col-lg-6 col-12 pe-md-4"
                      : "col-lg-6 col-12 pe-md-4"
                  }
                >
                  <div>
                    <DashboardBarChart
                      options={stackedBaroptionsVertical}
                      title={t("dashboard.Tickets Status")}
                      info={ticketStatus}
                    />
                  </div>
                </div>
                <div
                  className={
                    isMobile
                      ? " col-lg-6 col-12 ps-md-4 pt-5"
                      : " col-lg-6 col-12 ps-md-4 "
                  }
                >
                  <div>
                    <DashboardBarChart
                      options={stackedBaroptionsHorizontal}
                      title={t("dashboard.Tickets Based On Priority")}
                      info={ticketPriority}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-5">
              <div className="row">
                <div
                  className={
                    i18n.language === "en"
                      ? "col-lg-6 col-12 pe-md-4"
                      : "col-lg-6 col-12 pe-md-4"
                  }
                >
                  <div>
                    <DashboardBarAndLineChart
                      options={stackedBaroptionsVertical}
                      title={t("dashboard.Department")}
                      info={departmentPriority}
                    />
                  </div>
                </div>
                <div
                  className={
                    i18n.language === "en"
                      ? "col-lg-6 col-12 pe-md-4"
                      : "col-lg-6 col-12 pe-md-4"
                  }
                >
                  <div>
                    {billBoardData?.datasets && (
                      <DashboardAreaWisePieChart
                        title={t("dashboard.Area Wise Ticket")}
                        data={billBoardData}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="linedivider pt-5"></div>
            {/* ---------------------------------------------------------dount chart---------------------------------------------- */}
            <div className="pt-5">
              <div className="row">
                <div
                  className={
                    i18n.language === "en"
                      ? "col-lg-6 col-12 pe-md-4"
                      : "col-lg-6 col-12 pe-md-4"
                  }
                >
                  <div>
                    <DashBoardDountChart
                      title={`${t("dashboard.Team Viewer")}`}
                      socketData={{
                        active: context?.socketData?.active_teamviewer,
                        inActive: context?.socketData?.inactive_teamviewer,
                        non_operational: context?.socketData?.non_operational,
                        pending: context?.socketData?.pending,
                        activeColor: "#2edc4b",
                        InactiveColor: "#db2c59",
                        non_operationalColor: "#6b5ca4",
                        pendingColor: "#f2c94c",
                      }}
                    />
                  </div>
                </div>
                <div
                  className={
                    isMobile
                      ? " col-lg-6 col-12 ps-md-4 pt-5"
                      : " col-lg-6 col-12 ps-md-4 "
                  }
                >
                  <div>
                    <DashBoardDountChart
                      title={`${t("dashboard.Novastar")}`}
                      socketData={{
                        active:
                          context?.socketData?.active_novastar +
                          context?.socketData?.alarm_novastar +
                          context?.socketData?.fault_novastar,
                        inActive: context?.socketData?.inactive_novastar,
                        alarm: context?.socketData?.alarm_novastar,
                        fault: context?.socketData?.fault_novastar,
                        non_operational: context?.socketData?.non_operational,
                        pending: context?.socketData?.pending,
                        novastar_na: context?.socketData?.novastar_na,
                        alarmColor: "#4dc4ff",
                        faultColor: "#858780",
                        novastar_naColor: "#2a2b2f",
                        activeColor: "#2edc4b",
                        InactiveColor: "#db2c59",
                        non_operationalColor: "#6b5ca4",
                        pendingColor: "#f2c94c",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-5">
              <div className="row">
                <div
                  className={
                    i18n.language === "en"
                      ? "col-lg-6 col-12 pe-md-4"
                      : "col-lg-6 col-12 pe-md-4"
                  }
                >
                  <div>
                    <DashBoardDountChart
                      title={`${t("dashboard.IVMS 4200")}`}
                      socketData={{
                        active: context?.socketData?.active_ivms,
                        inActive: context?.socketData?.inactive_ivms,
                        non_operational: context?.socketData?.non_operational,
                        pending: context?.socketData?.pending,
                        ivsm_na: context?.socketData?.ivsm_na,
                        ivsm_naColor: "#2a2b2f",
                        activeColor: "#2edc4b",
                        InactiveColor: "#db2c59",
                        non_operationalColor: "#6b5ca4",
                        pendingColor: "#f2c94c",
                      }}
                    />
                  </div>
                </div>
                <div
                  className={
                    isMobile
                      ? " col-lg-6 col-12 ps-md-4 pt-5"
                      : " col-lg-6 col-12 ps-md-4 "
                  }
                >
                  <div>
                    <DashBoardDountChart
                      title={`${t("dashboard.Active & InActive BillBoards")}`}
                      socketData={{
                        active: context?.socketData?.overall_active,
                        inActive: context?.socketData?.overall_inactive,
                        non_operational: context?.socketData?.non_operational,
                        pending: context?.socketData?.pending,
                        activeColor: "#2edc4b",
                        InactiveColor: "#db2c59",
                        non_operationalColor: "#6b5ca4",
                        pendingColor: "#f2c94c",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="linedivider pt-5"></div>
            {/* -------------------------------------------------------map-------------------------------------------------- */}
            <div className="pt-5">
              <div className="row d-none">
                <div
                  className={
                    i18n.language === "en"
                      ? "col-lg-6 col-12 pe-md-4"
                      : "col-lg-6 col-12 pe-md-4"
                  }
                >
                  <div>
                    <DashboardMapChart
                      data={datamap}
                      options={optionsmap}
                      title={"BilBoards Map"}
                    />
                  </div>
                </div>
                <div
                  className={
                    isMobile
                      ? " col-lg-6 col-12 ps-md-4 pt-5"
                      : " col-lg-6 col-12 ps-md-4 "
                  }
                >
                  <div>
                    <DashboardMapChart
                      data={datamapticket}
                      options={optionsmapticket}
                      title={"Tickets Map"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <MapWithAMarker context={context} />
            <ToastPopup
              trigger={context?.isLoading}
              setTrigger={context?.setIsLoading}
            >
              <SuccessErrorToast isLoading={true} />
            </ToastPopup>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default Dashboard;
