import React, { useEffect, useState } from "react";
import AppLayout from "../../../layouts/AppLayout";
import { isMobile, isMobileOnly } from "react-device-detect";
import { MdArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import { routes } from "../../../constants/routes";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import { Button, Form, FormGroup, Spinner } from "react-bootstrap";
import {
  InventeryContextProp,
  useInventery,
} from "../../../context/InventeryProvider";
import CommonAsyncSelect from "../../../components/CommonComponents/CommonAsyncSelect";
import DatePicker from "react-multi-date-picker";
import moment from "moment";
import ReactCustomSelect from "../../../components/CommonComponents/ReactCustomSelect";
import ToastPopup from "../../../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../../../components/PopupToast/SuccessErrorToast";
import StockList from "../Purchase/StockList";
import CommonButton from "../../../components/CommonButton/CommonButton";

const CreateNewReturnTechnician = ({ userContext }: any) => {
  const context = useInventery();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const urlParams = useParams();
  const pathName = window?.location?.pathname;

  return (
    <>
      <AppLayout>
        <div
          className="edit_members p-3 "
          style={
            isMobile
              ? { background: "#FAFAFA", height: "auto" }
              : { background: "#FAFAFA", minHeight: "80vh" }
          }
        >
          <div className="members py-4 mt-2 ">
            <h5
              className={`${isMobile ? "fs-6  mb-0 membertitle" : "  mb-0 membertitle"
                }`}
              onClick={() =>
                navigate(routes.inventoryReturnMaterialFromTechnician)
              }
            >
              {t("inventory.All Returns")}
            </h5>
            <>
              <span className={i18n.language === "en" ? "px-2 " : "d-none"}>
                <MdOutlineArrowForwardIos />
              </span>
              <span className={i18n.language === "en" ? "d-none" : "px-2"}>
                <MdArrowBackIos />
              </span>

              <h5
                className={`${isMobile ? "fs-6 text-capitalize m-0  " : " m-0 "
                  }`}
              >
                {urlParams?.returnMaterialFromTechnicianId &&
                  pathName?.includes("/edit")
                  ? `${t("inventory.Edit Return")}`
                  : pathName?.includes("/view")
                    ? `${t("inventory.View Return")}`
                    : `${t("inventory.New Return")}`}
              </h5>
            </>
          </div>

          {context && (
            <NewTechnicianForm
              context={context}
              urlParams={urlParams}
              pathName={pathName}
            />
          )}
        </div>
      </AppLayout>
    </>
  );
};

interface NewTechnicianFormProps {
  context: InventeryContextProp;
  urlParams: any;
  pathName: string;
}
interface billboardAutocompleteProps {
  readonly label: string;
  readonly value: string;
}

interface StockCodeAutocompleteProps {
  readonly label: string;
  readonly value: string;
}

export const actionsReasons = [
  { value: "SCRAP", label: "SCRAP" },
  { value: "REPAIR", label: "REPAIR" },
  { value: "RETURN_TO_STOCK", label: "RETURN_TO_STOCK" },
];

const NewTechnicianForm = ({
  context,
  urlParams,
  pathName,
}: NewTechnicianFormProps) => {
  const initialStockValue = {
    supplier_part_no: "",
    stock_code: "",
    stock_code_name: "",
    quantity: 0,
    action: "",
    date_returned: moment(new Date()).format("YYYY-MM-DD"),
    defect_type: "",
    part_description: "",
    isEdit: true,
    isDelete: false,
  };

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [bodyMessage, setBodyMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [billBoardList, setBillBoardList] = useState<any>([]);
  const [stocks, setStocks] = useState([...[initialStockValue]]);
  const [assigneeList, setAssigneeList] = useState<any>([]);

  useEffect(() => {
    getUserDetails();
    getBillBoardDetails();
  }, []);

  useEffect(() => {
    editreturnMaterialFromTechnician();
  }, [urlParams?.returnMaterialFromTechnicianId]);

  const editreturnMaterialFromTechnician = async () => {
    const { success, return_material } =
      await context?.getReturnMaterialFromTechnician(
        urlParams?.returnMaterialFromTechnicianId
      );
    if (success) {
      const updatedStocks = return_material?.stocks?.map((item: any) => ({
        supplier_part_no: item?.supplier_part_no,
        stock_code: item?.stock_code?.id,
        stock_code_name: item?.stock_code?.stock_code,
        quantity: item?.quantity,
        action: item?.action,
        date_returned: moment(item?.date_returned).format("YYYY-MM-DD"),
        defect_type: item?.defect_type,
        part_description: item?.part_description,
        isEdit: false,
        isDelete: false,
      }));
      setStocks([...updatedStocks]);
    }
  };

  const getUserDetails = async () => {
    const { success, user } = await context?.loadMembers({
      status: "ACTIVE",
      sort: "name",
      role: "TECHNICIAN",
    });
    if (success) {
      let res = user.map((d: any) => {
        return {
          label: d.name,
          value: d.id,
        };
      });
      setAssigneeList(res);
    }
  };

  const getBillBoardDetails = async () => {
    const { success, billboards } =
      await context?.loadBillBoardsMaterialToTechnician({
        status: "ACTIVE",
        sort: "board_no",
      });
    if (success) {
      let res = billboards.map((d: any) => {
        return {
          label: d.board_no,
          value: d.id,
          type_of_screen: d.board_type,
        };
      });
      setBillBoardList(res);
    }
  };

  const getBillboardOptions: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const { billboards } = await context?.loadBillBoardsMaterialToTechnician({
      status: "ACTIVE",
      sort: "board_no",
      search: inputValue,
    });
    callback(
      billboards.map((d: any) => ({
        label: d?.board_no,
        value: d?.id,
        type_of_screen: d.board_type,
      }))
    );
  };

  const getAssigneeOptions: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const { users } = await context?.autoCompleteApi({
      name: inputValue,
      role: "TECHNICIAN",
    });
    callback(
      users.map((d: any) => ({
        label: d?.name,
        value: d?.id,
      }))
    );
  };

  //   {
  //   "stocks": [
  //     {
  //       "supplier_part_no": "string",
  //       "stock_code": "string",
  //       "quantity": 0,
  //       "part_description": "string",
  //       "action": "string",
  //       "date_returned": "2023-09-14T07:30:33.944Z",
  //       "defect_type": "string"
  //     }
  //   ],
  //   "received_by": "string",
  //   "supplier_name": "string",
  //   "suppleir_detail": "string",
  //   "type_of_screen": "string",
  //   "used_in_board": "string",
  //   "remarks": "string"
  // }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      received_by: context?.returnMaterial?.received_by?.id || "",
      remarks: context?.returnMaterial?.remarks || "",
      type_of_screen: context?.returnMaterial?.type_of_screen || "",
      used_in_board: context?.returnMaterial?.used_in_board?.id || "",
      bill_board_no: context?.returnMaterial?.used_in_board?.board_no || "",
      billboard_id: context?.returnMaterial?.used_in_board?.id || "",
      recieved_by_name: context?.returnMaterial?.received_by?.name || "",
    },
    validationSchema: Yup.object({
      received_by: Yup.string().required("Received by  is required"),
      remarks: Yup.string(),
      type_of_screen: Yup.string(),
      used_in_board: Yup.string().required("Used in board  is required"),
      contact_number: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
      billboard_id: Yup.string(),
    }),
    onSubmit: async (values) => {
      const params = { ...values, stocks: stocks };
      const { success, error } = context?.returnMaterial?.id
        ? await context.updateReturnMaterialFromTechnicianDetail(
          context?.returnMaterial?.id,
          params
        )
        : await context.addReturnMaterialFromTechnician(params);
      setIsSuccess(success);
      setOpenPopup(true);
      if (success) {
        setBodyMessage(
          context?.returnMaterial?.id
            ? `${t("inventory.Damage info updated Successfully")}`
            : `${t("inventory.Damage info added Successfully")}`
        );
        formik.resetForm();
        timeOutPopUp(true);
      } else {
        setBodyMessage(error);
        timeOutPopUp(false);
      }
    },
  });
  const timeOutPopUp = (value: any) => {
    let timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        navigate(routes.inventoryReturnMaterialFromTechnician);
        setOpenPopup(false);
        setBodyMessage("");
      } else {
        setOpenPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  const { errors, handleSubmit, isSubmitting, isValid, dirty, resetForm } =
    formik;

  const buttonDisabled = () =>
    context?.returnMaterial?.id
      ? dirty ||
      (stocks?.filter((item: any) => !item?.isEdit)?.length &&
        stocks?.filter((item: any) => !item?.isEdit)?.length !==
        context?.returnMaterial?.stocks?.length)
      : dirty &&
      stocks?.filter((item: any) => !item?.isEdit)?.length &&
      stocks?.filter((item: any) => !item?.isEdit)?.length !==
      context?.returnMaterial?.stocks?.length;

  return (
    <>
      <div className="px-4 pb-5">
        <FormikProvider value={formik}>
          <Form noValidate={true} onSubmit={handleSubmit}>
            <div className="">
              <div
                className="row px-3 pb-4 mt-4"
                style={{ background: "#fefefe" }}
              >
                <div className="col-12">
                  <div className="row ">
                    <div className="col-md-6 col-12  pe-md-4 formprofile">
                      <FormGroup className="mt-4 roleForm position-relative">
                        <label>
                          {t("inventory.Used in board")}
                          <span className="">*</span>
                        </label>
                        <CommonAsyncSelect
                          className={"select-wrapper"}
                          isMulti={false}
                          values={{
                            label: formik?.values?.bill_board_no,
                            value: formik?.values?.used_in_board,
                          }}
                          options={billBoardList}
                          cacheOptions
                          loadOptionValue={getBillboardOptions}
                          updateField={(option: any) => {
                            formik?.setFieldValue(
                              "type_of_screen",
                              option.type_of_screen
                            );
                            formik?.setFieldValue(
                              "used_in_board",
                              option.value
                            );
                            formik?.setFieldValue(
                              "bill_board_no",
                              option.label
                            );
                          }}
                          isFieldDisabled={pathName?.includes("/view")}
                        />
                      </FormGroup>
                    </div>

                    <div
                      className={
                        i18n.language === "en"
                          ? "col-md-6 col-12 ps-md-5 formprofile"
                          : "col-md-6 col-12  formprofile pe-md-5"
                      }
                    >
                      <FormGroup className="mt-4 roleForm position-relative">
                        <label>
                          {t("inventory.Type of screen")}
                          <span className="">*</span>
                        </label>
                        <Field
                          name="type_of_screen"
                          type="text"
                          maxLength={20}
                          className={`form-control ${errors.type_of_screen ? "form-control-invalid" : ""
                            }`}
                          disabled
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-12  pe-md-4 formprofile">
                      <FormGroup className="mt-4 roleForm position-relative">
                        <label>
                          {t("inventory.Recieved By")}
                          <span className="">*</span>
                        </label>
                        <CommonAsyncSelect
                          className={"select-wrapper"}
                          isMulti={false}
                          values={{
                            label: formik?.values?.recieved_by_name,
                            value: formik.values.received_by,
                          }}
                          options={assigneeList}
                          cacheOptions
                          loadOptionValue={getAssigneeOptions}
                          updateField={(option: any) => {
                            formik?.setErrors({});
                            formik?.setFieldValue("received_by", option.value);
                            formik?.setFieldValue(
                              "recieved_by_name",
                              option.label
                            );
                            formik?.setErrors({});
                          }}
                          isFieldDisabled={pathName?.includes("/view")}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-md-6 col-12  pe-md-4 formprofile">
                      <FormGroup className="mt-4 roleForm position-relative">
                        <label>{t("inventory.Remarks")}</label>
                        <Field
                          name="remarks"
                          type="text"
                          maxLength={20}
                          className={`form-control ${errors.remarks ? "form-control-invalid" : ""
                            }`}
                          disabled={pathName?.includes("/view")}
                        />
                        <Form.Control.Feedback
                          style={{
                            display: !!errors.remarks ? "block" : "none",
                          }}
                          type={!!errors.remarks ? "invalid" : "valid"}
                        >
                          <ErrorMessage name="remarks" />
                        </Form.Control.Feedback>
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
              <StockList
                type="returnTechnician"
                context={context}
                setStocks={setStocks}
                stocks={stocks}
                initialStockValue={initialStockValue}
              />
              {!pathName?.includes("/view") && (
                <div className="d-flex justify-content-end py-5">
                  <CommonButton
                    text={t("inventory.Cancel")}
                    isLoading={isSubmitting}
                    variant="outline-danger"
                    className="bg-transparent text-danger"
                    isDisabled={isSubmitting}
                    onClick={() => resetForm()}
                  />
                  <CommonButton
                    text={
                      context?.returnMaterial?.id
                        ? `${t("inventory.Update Return")}`
                        : `${t("inventory.Add Return")}`
                    }
                    isLoading={isSubmitting}
                    type="submit"
                    variant="success"
                    className="btn-i18n text-white"
                    isDisabled={isSubmitting || !isValid || !buttonDisabled()}
                  />
                </div>
              )}
            </div>
          </Form>
        </FormikProvider>
        <ToastPopup
          trigger={context?.isLoading}
          setTrigger={context?.setIsLoading}
        >
          <SuccessErrorToast isLoading={true} />
        </ToastPopup>
        <ToastPopup trigger={openPopup} setTrigger={setOpenPopup}>
          <SuccessErrorToast
            bodyMessage={bodyMessage}
            isSuccess={isSuccess}
            footButton={false}
          />
        </ToastPopup>
      </div>
    </>
  );
};

export default CreateNewReturnTechnician;
