import { useTranslation } from "react-i18next";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import moment from "moment";
import ReactCustomSelect from "../../components/CommonComponents/ReactCustomSelect";
import CustomLocationSelect from "../../components/CommonComponents/CustomLocationSelect";
import { routes } from "../../constants/routes";
import { updateText } from "../../utils/api";
import CommonAsyncSelect from "../../components/CommonComponents/CommonAsyncSelect";
import CommonButton from "../../components/CommonButton/CommonButton";
import FormikField from "../../components/FormikField/FormikField";
import FormikDateField from "../../components/FormikDateField/FormikDateField";
import CustomInputField from "../../components/InputField/CustomInputField";

const CompanyProfileForm = ({
  memberId,
  isProfile,
  setBodyMessage,
  userContext,
  setUpdateProfileDetail,
  context,
  setTextMessage,
  setFormikFunction,
  setShowPopUp,
  setHeaderMessage,
  roleOptions,
}: any) => {
  useEffect(() => {
    getMemberDetail();
  }, []);
  const [departMentList, setDepartMentList] = useState<any>([]);
  useEffect(() => {
    getDepartmentDetail();
  }, []);

  const getDepartmentDetail = async () => {
    const { success, config } = await userContext?.getAllConfigDetails({
      config_type: "DEPARTMENT",
    });
    if (success) {
      let res = config?.map((d: any) => {
        return {
          label: updateText(d?.title?.replace("_", " ")),
          value: d?.title,
        };
      });
      setDepartMentList(res);
    }
  };

  const getDepartmentOptions: any = async (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    const { config } = await userContext?.getAllConfigDetails({
      search: inputValue,
      config_type: "DEPARTMENT",
    });
    callback(
      config?.map((d: any) => ({
        label: updateText(d?.title?.replace("_", " ")),
        value: d?.title,
      }))
    );
  };

  const getMemberDetail = async () => {
    if (context && isProfile) {
      await context.getUserDetail();
    } else if (context && !isProfile) {
      await context.getMemberDetail(memberId);
    }
  };

  const { t } = useTranslation();

  const initialValues = {
    work_space_id: isProfile
      ? context?.user?.work_space_id
        ? context?.user?.work_space_id
        : ""
      : context?.member?.work_space_id
      ? context?.member?.work_space_id
      : "",
    department: isProfile
      ? context?.user?.department
        ? context?.user?.department?.map((obj: any) => ({
            label: `${t(obj)}`,
            value: obj,
          }))
        : ""
      : context?.member?.department
      ? context?.member?.department?.map((obj: any) => ({
          label: `${t(obj)}`,
          value: obj,
        }))
      : "",
    role: isProfile
      ? context?.user?.role
        ? context?.user?.role
        : ""
      : context?.member?.role
      ? context?.member?.role
      : "",
    date_of_joining: isProfile
      ? context?.user?.date_of_joining
        ? moment(context?.user?.date_of_joining).format("yyyy-MM-DD")
        : ""
      : context?.member?.date_of_joining
      ? moment(context?.member?.date_of_joining).format("yyyy-MM-DD")
      : "",

    responsible_area: isProfile
      ? context?.user?.responsible_area
        ? context?.user?.responsible_area
        : ""
      : context?.member?.responsible_area
      ? context?.member?.responsible_area
      : "",
    biography: isProfile
      ? context?.user?.biography
        ? context?.user?.biography
        : ""
      : context?.member?.biography
      ? context?.member?.biography
      : "",
    branch_location: isProfile
      ? context?.user?.branch_location
        ? context?.user?.branch_location
        : ""
      : context?.member?.branch_location
      ? context?.member?.branch_location
      : "",
    shiftTiming: "",
    duration: "",
    depart: isProfile
      ? context?.user?.role === "SUPERVISOR"
        ? context?.user?.department[0]
        : ""
      : context?.member?.role === "SUPERVISOR"
      ? context?.member?.department[0]
      : "",
  };

  const validationSchema = Yup.object({
    work_space_id: Yup.number()
      .min(1, "Please enter id more than 1 number")
      .required("This field is required"),
    department: isProfile
      ? context?.user?.role !== "ADMIN"
        ? Yup.array().required("Department is required")
        : Yup.array()
      : context?.member?.role !== "ADMIN"
      ? Yup.array().required("Department is required")
      : Yup.array(),
    role: isProfile
      ? context?.user?.role !== "ADMIN"
        ? Yup.string().required("Role is required")
        : Yup.string()
      : context?.member?.role !== "ADMIN"
      ? Yup.string().required("Role is required")
      : Yup.string(),
    date_of_joining: Yup.string().required("Required!"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setTextMessage(`${t("successError.update")}`);
      setBodyMessage(`${t("successError.Company profile updated")}`);
      setHeaderMessage(`${t("successError.Updated Company profile details")}`);
      if (values.role !== "SUPERVISOR") {
        delete values?.depart;
      }
      setUpdateProfileDetail({
        ...values,
        work_space_id: values.work_space_id.toString(),
        department:
          values.role !== "SUPERVISOR"
            ? values?.department?.map((value: any) => value.value)
            : [values?.depart],
        role: values?.role,
        date_of_joining: moment(values?.date_of_joining).format("yyyy-MM-DD"),
      });
      setShowPopUp(true);
      setFormikFunction(formik);
    },
  });
  const handleKeyPress = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const { errors, handleSubmit, isSubmitting, isValid, dirty, setFieldValue } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form
        validated={true}
        onSubmit={handleSubmit}
        onKeyPress={handleKeyPress}
      >
        <Row>
          <Col md={6} className=" my-4">
            <FormikField
              name="work_space_id"
              type="number"
              autoFocus
              validationSchema={validationSchema}
              label={t("companyprofile.name")}
              errors={errors as Record<string, string>}
              disabled={
                !isProfile &&
                !userContext?.roleAccess["USER"].includes("UPDATE")
              }
            />
          </Col>
          <Col md={6} className="formprofile my-4">
            <FormikDateField
              name={"date_of_joining"}
              label={t("companyprofile.dateOfJoining")}
              isDisabled={
                !isProfile &&
                !userContext?.roleAccess["USER"].includes("UPDATE")
              }
            />
          </Col>

          {window?.location.pathname !== routes.admincompanyProfile &&
            context?.member?.role !== "ADMIN" && (
              <>
                <Col md={6} className="formprofile my-4">
                  <Field name={"role"}>
                    {({ field: formikField }: FieldProps<string>) => (
                      <CustomInputField
                        validationSchema={validationSchema}
                        label={t("companyprofile.role")}
                        field={formikField}
                        inputId={"role"}
                        error={formik?.errors["role"] as string}
                      >
                        <ReactCustomSelect
                          isFieldDisabled={
                            !isProfile &&
                            !userContext?.roleAccess["USER"].includes("UPDATE")
                          }
                          className={"select-wrapper"}
                          feildName={"role"}
                          defaultOptions={{
                            label: `${t(formik?.values?.role)}`,
                            value: formik?.values?.role,
                          }}
                          isMulti={false}
                          updateFieldValue={(data: any) =>
                            setFieldValue("role", data.value)
                          }
                          dataOptions={roleOptions}
                          searchDisabled={false}
                        />
                      </CustomInputField>
                    )}
                  </Field>
                </Col>

                <Col md={6} className="formprofile my-4">
                  <Field name={"department"}>
                    {({ field: formikField }: FieldProps<string>) => (
                      <CustomInputField
                        validationSchema={validationSchema}
                        label={t("companyprofile.department")}
                        field={formikField}
                        inputId={"department"}
                        error={formik?.errors["department"] as string}
                      >
                        {formik.values.role !== "SUPERVISOR" ? (
                          <CommonAsyncSelect
                            className={"select-wrapper"}
                            isFieldDisabled={
                              !isProfile &&
                              !userContext?.roleAccess["USER"].includes(
                                "UPDATE"
                              )
                            }
                            isMulti={true}
                            options={departMentList}
                            values={formik?.values?.department}
                            cacheOptions
                            loadOptionValue={getDepartmentOptions}
                            updateField={(data: any) =>
                              formik?.setFieldValue("department", data)
                            }
                          />
                        ) : (
                          <CommonAsyncSelect
                            className={"select-wrapper"}
                            isFieldDisabled={
                              !isProfile &&
                              !userContext?.roleAccess["USER"].includes(
                                "UPDATE"
                              )
                            }
                            isMulti={false}
                            values={{
                              label: updateText(formik?.values?.depart),
                              value: formik?.values?.depart,
                            }}
                            options={departMentList}
                            cacheOptions
                            loadOptionValue={getDepartmentOptions}
                            updateField={(data: any) => {
                              setFieldValue("depart", data.value);
                            }}
                          />
                        )}
                      </CustomInputField>
                    )}
                  </Field>
                </Col>
              </>
            )}

          <Col md={6} className="formprofile my-4">
            <Field name="responsible_area">
              {({ field }: FieldProps<string>) => {
                return (
                  <CustomInputField
                    label={t("companyprofile.response")}
                    error={errors["responsible_area"] as string}
                    validationSchema={validationSchema}
                    inputId={""}
                    field={field}
                  >
                    <CustomLocationSelect
                      fiedName={"responsible_area"}
                      isFieldDisabled={
                        !isProfile &&
                        !userContext?.roleAccess["USER"].includes("UPDATE")
                      }
                      className={"autolocation"}
                      updateField={(data: any) =>
                        formik.setFieldValue("responsible_area", data?.name)
                      }
                      defaultValue={formik?.values?.responsible_area}
                    />
                  </CustomInputField>
                );
              }}
            </Field>
          </Col>

          <Col md={6} className="formprofile my-4">
            <Field name="branch_location">
              {({ field }: FieldProps<string>) => {
                return (
                  <CustomInputField
                    label={t("companyprofile.branchLocation")}
                    error={errors["branch_location"] as string}
                    validationSchema={validationSchema}
                    inputId={""}
                    field={field}
                  >
                    <CustomLocationSelect
                      fiedName={"branch_location"}
                      isFieldDisabled={
                        !isProfile &&
                        !userContext?.roleAccess["USER"].includes("UPDATE")
                      }
                      className={"autolocation"}
                      updateField={(data: any) =>
                        formik.setFieldValue("branch_location", data?.name)
                      }
                      defaultValue={formik?.values?.branch_location}
                    />
                  </CustomInputField>
                );
              }}
            </Field>
          </Col>
        </Row>
        {(isProfile
          ? true
          : userContext?.roleAccess["USER"].includes("UPDATE")) && (
          <div className="d-flex justify-content-end my-5">
            <CommonButton
              text={t("companyprofile.cancel")}
              type="button"
              isLoading={isSubmitting}
              variant="outline-danger"
              className="bg-transparent text-danger"
              isDisabled={isSubmitting || !dirty}
              onClick={() => {
                setHeaderMessage(`${t("successError.cancel the changes")}`);
                setTextMessage(`${t("successError.cancel it")}`);
                setBodyMessage(`${t("successError.cancel it")}`);
                setFormikFunction(formik);
                setShowPopUp(true);
              }}
            />
            <CommonButton
              text={t("companyprofile.Update")}
              isLoading={isSubmitting}
              type="submit"
              variant="success"
              className="btn-i18n text-white"
              isDisabled={
                context?.user?.role === "ADMIN"
                  ? isSubmitting || !isValid || !dirty
                  : isSubmitting ||
                    !isValid ||
                    !dirty ||
                    (formik.values.role !== "SUPERVISOR"
                      ? !formik?.values?.department?.length
                      : !formik?.values?.depart)
              }
            />
          </div>
        )}
      </Form>
    </FormikProvider>
  );
};

export default CompanyProfileForm;
