import React from "react";
import { isMobileOnly } from "react-device-detect";
import Chart from "react-google-charts";

const DashboardMapChart = ({ data, options, title }: any) => {
  return (
    <div className="chartCard rounded-3 p-4 d-flex flex-column justify-content-between">
      <div>
        <h6 className=" fw-bold ">{title}</h6>
      </div>

      <div className=" ">
        <div
          className={
            isMobileOnly ? " d-flex justify-content-center py-3 " : " map py-3"
          }
        >
          <Chart
            chartType="GeoChart"
            width="100%"
            height="100%"
            data={data}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardMapChart;
