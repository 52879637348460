import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import { BootstrapLayoutProvider } from "./context/BootstrapLayoutProvider";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import App from "./App";
import { AuthenticationProvider } from "./context/AuthenticationProvider";
import {} from "react-bootstrap";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    lng: sessionStorage.getItem("i18nextLng") === "ar" ? "ar" : "en",
    fallbackLng: sessionStorage.getItem("i18nextLng") === "ar" ? "ar" : "en",
    detection: {
      order: ["localStorage", "sessionStorage", "htmlTag", "path", "subdomain"],
      caches: ["sessionStorage"],
    },
    backend: {
      loadPath: "/assetslanguage/i18/{{lng}}/translation.json",
    },

    react: { useSuspense: false },
  });

// Sentry.init({
//   dsn: "http://867a84329bbf417ea341253dc12b8ad3@64.227.178.100:9000/2",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <BootstrapLayoutProvider>
        <AuthenticationProvider>
          <App />
        </AuthenticationProvider>
      </BootstrapLayoutProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
