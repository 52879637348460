import { useEffect, useState } from "react";
import { Button, Dropdown, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  isDesktop,
  isMobile,
  isMobileOnly,
  isTablet,
} from "react-device-detect";
import ToastPopup from "../../../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../../../components/PopupToast/SuccessErrorToast";
import ToastPopupSecond from "../../../components/PopupToast/ToastPopupSecond";
import { useInventery } from "../../../context/InventeryProvider";
import Pagination from "../../../components/Pagination";
import { routes } from "../../../constants/routes";
import { BsThreeDots } from "react-icons/bs";
import { convertExcel, convertPdf } from "../../../utils/api";
import DeleteBlockToast from "../../../components/PopupToast/DeleteBlockToast";
import DownloadPopUp from "../../../components/DownloadPopUp";
import CommonButton from "../../../components/CommonButton/CommonButton";
import CommonSearch from "../../../components/CommonSearch/CommonSearch";
import CommonSort from "../../../components/CommonSort/CommonSort";
import CommonFilter from "../../../components/CommonFilter/CommonFilter";
import CommonLoadingBar from "../../../components/LoadingBar/CommonLoadingBar";

let timedPopupSetTimeOut: any = undefined;

const ReplaceSupplier = ({ userContext }: any) => {
  const queryParams = new URLSearchParams(window.location.search);
  const context = useInventery();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [timedPopup, setTimedPopup] = useState(false);
  const [role, setRole] = useState(
    queryParams.get("role") ? queryParams.get("role") : ""
  );
  const [sort, setSort] = useState(
    queryParams.get("sort") ? queryParams.get("sort") : "-createdAt"
  );
  const [search, setSearch] = useState<any>(
    queryParams.get("search") ? queryParams.get("search") : ""
  );
  const [mobileSearch, setMobileSearch] = useState(
    queryParams.get("search") ? queryParams.get("search") : ""
  );
  const [status, setStatus] = useState(
    queryParams.get("status") ? queryParams.get("status") : ""
  );
  const [department, setDepartment] = useState(
    queryParams.get("department") ? queryParams.get("department") : ""
  );
  const [responsibleArea, setResponsibleArea] = useState(
    queryParams.get("responsible_area")
      ? queryParams.get("responsible_area")
      : ""
  );
  const [bodyMessage, setBodyMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [openfilter, setOpenfilter] = useState(false);
  const [opensort, setOpenSort] = useState(false);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [
    currentReplaceMaterialToSupplierId,
    setCurrentReplaceMaterialToSupplierId,
  ] = useState("");
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    loadData();
  }, [search, sort, currentPage]);

  const queryParamsDetails = () => {
    let params = {
      search,
      sort: sort ? sort : "-updatedAt",
      role,
      status,
      department,
      responsibleArea,
      limit: 10,
      page: currentPage,
    };
    return params;
  };

  const loadData = () => {
    navigate(routes.listreplaceMaterialFromSupplierUrl(queryParamsDetails()));
    context?.loadReplaceMaterialToSupplier(queryParamsDetails());
  };
  const resetloadReplaceMaterialToSupplier = async () => {
    let params = {
      search,
      sort,
      limit: 10,
      page: currentPage,
    };
    navigate(routes.listreplaceMaterialFromSupplierUrl(params));
    await context?.loadReplaceMaterialToSupplier(params);
  };

  const download = async (type: string) => {
    switch (type) {
      case "excel":
        const res = await context?.downloadReturnMaterialFromSupplierExcel(
          queryParamsDetails()
        );
        convertExcel(res, "Return_Material_From_Supplier_List");
        break;
      case "pdf":
        const pdfRes = await context?.downloadReturnMaterialFromSupplierPdf(
          queryParamsDetails()
        );
        convertPdf(pdfRes, "Return_Material_From_Supplier_List");
        break;
      default:
        break;
    }
  };
  const sortByOpen = () => {
    setOpenfilter(false);
    setOpenSort(!opensort);
  };

  const applyFilter = () => {
    loadData();
    filterByOpen();
  };

  const resetFilter = () => {
    setRole("");
    setStatus("");
    setDepartment("");
    setResponsibleArea("");
    loadData();
    filterByOpen();
    resetloadReplaceMaterialToSupplier();
  };

  const filterByOpen = () => {
    setOpenSort(false);
    setOpenfilter(!openfilter);
  };

  const handleEnterKey = (e: any) => {
    if (e.target.value.length === 0) {
      setSearch(e.target.value);
    }
    if (e.key === "Enter") {
      setSearch(e.target.value);
      setCurrentPage(1);
    }
  };

  const timeOutPopUp = (value: any) => {
    timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        setTimedPopup(false);
        setBodyMessage("");
      } else {
        setTimedPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  const deleteReplaceMaterialToSupplier = async () => {
    const { success, error } =
      await context?.deleteReplaceMaterialToSupplierAccount(
        currentReplaceMaterialToSupplierId
      );
    setIsSuccess(success);
    if (success) {
      setDeletePopUp(false);
      loadData();
      timeOutPopUp(true);
      setTimedPopup(true);
      setBodyMessage(`${t("inventory.Damage info deleted successfully")}`);
    } else {
      setDeletePopUp(false);
      setTimedPopup(true);
      timeOutPopUp(false);
      setBodyMessage(error);
    }
  };

  return (
    <div className={`${isMobile ? " position-relative  " : "  rounded-1 "}`}>
      {context?.isLoading && <CommonLoadingBar />}
      <div
        className={`${
          isMobile ? "container-fluid position-relative " : "container "
        }`}
      >
        {/* --------------------------------------------nested inventory------------------------------------------------- */}

        <div
          className={`${
            isMobile
              ? "d-none "
              : "pt-4 d-flex align-items-center justify-content-between "
          }`}
        >
          <div className="members ">
            <h5 className=" mb-0 ">
              {t("inventory.All Replaces")} (
              {context?.meta ? context?.meta?.total_count : 0})
            </h5>
          </div>
          <div
            className={`${
              isMobile ? "d-none " : "container-fluid p-0 forinput"
            }`}
          >
            <CommonSearch
              placeholder={t("inventory.search") || ""}
              onKeyDown={(e: any) => handleEnterKey(e)}
              onChange={(e: any) =>
                e.target.value.length === 0 && setSearch(e.target.value)
              }
            />
          </div>

          <div className="d-flex  align-items-center justify-content-end">
            <CommonSort
              sort={sort}
              opensort={opensort}
              opensortClick={sortByOpen}
              setCurrentPage={setCurrentPage}
              setSort={setSort}
            />

            <CommonFilter
              openfilterClick={filterByOpen}
              openfilter={openfilter}
              resetClick={resetFilter}
              filterClose={filterByOpen}
              filterApply={applyFilter}
            >
              <div></div>
            </CommonFilter>
            <div>
              <CommonButton
                text={t("listmember.Download")}
                variant="outline-secondary"
                className=" bg-transparent text-secondary btn-i18n"
                onClick={() => setShow(true)}
              />
            </div>
            {userContext?.roleAccess["INVENTORY"].includes("WRITE") && (
              <CommonButton
                onClick={() => {
                  context?.setReturnMaterialToSupplier({});
                  navigate(routes.createInventoryreplaceMaterialFromSupplier);
                }}
                text={t("inventory.New Replace")}
                variant="success"
                className=" btn-i18n text-white"
              />
            )}
          </div>
        </div>
        <div className={`${isDesktop ? "d-none " : " pt-4"}`}>
          <div
            className={`${
              isDesktop
                ? "d-none "
                : " d-flex align-items-center justify-content-between flex-wrap"
            }`}
          >
            <div className="members ">
              <h5 className="m-0">
                {t("inventory.All Replaces")} (
                {context?.meta ? context?.meta?.total_count : 0})
              </h5>
            </div>
            <div className="d-flex">
              <div className={`${isTablet ? " d-flex" : "d-none  "}`}>
                <CommonSort
                  sort={sort}
                  opensort={opensort}
                  opensortClick={sortByOpen}
                  setCurrentPage={setCurrentPage}
                  setSort={setSort}
                />

                <CommonFilter
                  openfilterClick={filterByOpen}
                  openfilter={openfilter}
                  resetClick={resetFilter}
                  filterClose={filterByOpen}
                  filterApply={applyFilter}
                >
                  <div></div>
                </CommonFilter>
              </div>
              <div>
                <CommonButton
                  text={t("listmember.Download")}
                  variant="outline-secondary"
                  className=" bg-transparent text-secondary btn-i18n"
                  onClick={() => setShow(true)}
                />
              </div>
              {userContext?.roleAccess["INVENTORY"].includes("WRITE") && (
                <div className="d-flex align-items-center ">
                  <CommonButton
                    onClick={() => {
                      context?.setReturnMaterialToSupplier({});
                      navigate(
                        routes.createInventoryreplaceMaterialFromSupplier
                      );
                    }}
                    text={t("inventory.New Replace")}
                    variant="success"
                    className=" btn-i18n text-white"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="pt-5 position-relative">
            <CommonSearch
              placeholder={t("inventory.search") || ""}
              onKeyDown={(e: any) => handleEnterKey(e)}
              onChange={(e: any) => {
                e.target.value.length === 0 && setSearch(e.target.value);
                if (isMobile) {
                  setMobileSearch(e.target.value);
                }
              }}
            />
            <Button
              className={
                i18n.language === "en"
                  ? "mobile-search-btn"
                  : "mobile-search-btn-right"
              }
              onClick={() =>
                handleEnterKey({
                  key: "Enter",
                  target: { value: mobileSearch },
                })
              }
            >
              {t("chat.Search")}
            </Button>
          </div>
        </div>

        <div className=" pt-4 table-responsive">
          <Table responsive="sm table_class">
            <thead>
              <tr className="table_white_head">
                <th
                  className={`${isMobileOnly ? "  py-3" : " "}`}
                  style={{ paddingInlineStart: "1.5rem" }}
                >
                  {t("inventory.Supplier ID")}
                </th>
                <th className={`${isMobileOnly ? " d-none " : " py-4 "}`}>
                  {t("inventory.Supplier No")}
                </th>
                <th className={`${isMobileOnly ? "  d-none" : " "}`}>
                  {t("inventory.Billboard ID")}
                </th>
                <th className={`${isMobileOnly ? "py-3 " : "py-4 "}`}>
                  {t("inventory.Stocks Counts")}
                </th>
                <th className={`${isMobileOnly ? "d-none " : " "}`}>
                  {t("inventory.Quantity")}
                </th>
                {(userContext?.roleAccess["INVENTORY"].includes("UPDATE") ||
                  userContext?.roleAccess["INVENTORY"].includes("READ") ||
                  userContext?.roleAccess["INVENTORY"].includes("DELETE")) && (
                  <th className={`${isMobileOnly ? " " : " "}`}>
                    {t("memberTable.action")}
                  </th>
                )}
              </tr>
            </thead>
            {context?.replaceMaterialToSupplier &&
              context?.replaceMaterialToSupplier?.length > 0 && (
                <tbody className="table_white">
                  {context?.replaceMaterialToSupplier?.map(
                    (replaceMaterial: any, index: number) => {
                      return (
                        <tr key={replaceMaterial?.id} className="table_white">
                          <td
                            style={{ paddingInlineStart: "1.5rem" }}
                            className={`${
                              isMobileOnly ? "  d-none" : " py-4 "
                            }`}
                          >
                            {replaceMaterial?.supplier_info?.supplier_id || "-"}
                          </td>
                          <td
                            className={`${
                              isMobileOnly ? "  d-none" : " py-4 "
                            }`}
                          >
                            {replaceMaterial?.supplier_info?.name?.length > 15
                              ? replaceMaterial?.supplier_info?.name?.slice(
                                  0,
                                  15
                                ) + "..."
                              : replaceMaterial?.supplier_info?.name || "-"}
                          </td>
                          <td className={`${isMobileOnly ? "d-none " : " "}`}>
                            {replaceMaterial?.used_in_board?.board_no || "-"}
                          </td>
                          <td className={`${isMobileOnly ? "d-none " : " "}`}>
                            {replaceMaterial?.stocks?.length || "-"}
                          </td>
                          <td className={`${isMobileOnly ? "d-none " : " "}`}>
                            {replaceMaterial?.stocks?.reduce(
                              (prev: any, crrnt: any) => prev + crrnt?.quantity,
                              0
                            ) || "-"}
                          </td>
                          {(userContext?.roleAccess["INVENTORY"].includes(
                            "UPDATE"
                          ) ||
                            userContext?.roleAccess["INVENTORY"].includes(
                              "READ"
                            ) ||
                            userContext?.roleAccess["INVENTORY"].includes(
                              "DELETE"
                            )) && (
                            <td className={`${isMobileOnly ? "" : " "}`}>
                              <div className="pt-2 Actions">
                                <Dropdown className="Actions_title">
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basicActions"
                                  >
                                    <BsThreeDots fontSize={24} />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="create-dropdown-css">
                                    {userContext?.roleAccess[
                                      "INVENTORY"
                                    ].includes("UPDATE") && (
                                      <Dropdown.Item
                                        className="sort-by"
                                        onClick={() => {
                                          navigate(
                                            routes.getInventoryEditreplaceMaterialFromSupplier(
                                              replaceMaterial.id
                                            )
                                          );
                                        }}
                                      >
                                        {t("inventory.Edit")}
                                      </Dropdown.Item>
                                    )}
                                    {userContext?.roleAccess[
                                      "INVENTORY"
                                    ].includes("READ") && (
                                      <Dropdown.Item
                                        className="sort-by"
                                        onClick={() => {
                                          navigate(
                                            routes.getInventoryViewreplaceMaterialFromSupplier(
                                              replaceMaterial?.id
                                            )
                                          );
                                        }}
                                      >
                                        {t("inventory.View")}
                                      </Dropdown.Item>
                                    )}
                                    {userContext?.roleAccess[
                                      "INVENTORY"
                                    ].includes("DELETE") && (
                                      <Dropdown.Item
                                        className="sort-by"
                                        onClick={() => {
                                          setCurrentReplaceMaterialToSupplierId(
                                            replaceMaterial?.id
                                          );
                                          setDeletePopUp(true);
                                        }}
                                      >
                                        {t("inventory.Delete")}
                                      </Dropdown.Item>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    }
                  )}
                </tbody>
              )}
          </Table>
          {!context?.replaceMaterialToSupplier?.length && (
            <div className=" d-flex justify-content-center align-items-center">
              {t("inventory.No Replace Found")}
            </div>
          )}
        </div>
        {context?.meta &&
          context?.replaceMaterialToSupplier &&
          context?.replaceMaterialToSupplier?.length > 0 && (
            <Pagination
              meta={context?.meta}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              currentPageCount={context?.replaceMaterialToSupplier?.length}
              component={"Replace"}
            />
          )}
      </div>
      <ToastPopup
        trigger={context?.isLoading}
        setTrigger={context?.setIsLoading}
      >
        <SuccessErrorToast isLoading={true} />
      </ToastPopup>
      <ToastPopup trigger={timedPopup} setTrigger={setTimedPopup}>
        <SuccessErrorToast
          bodyMessage={bodyMessage}
          isSuccess={isSuccess}
          footButton={false}
        />
      </ToastPopup>

      <ToastPopupSecond trigger={deletePopUp} setTrigger={setDeletePopUp}>
        <DeleteBlockToast
          setTrigger={(status: boolean) => {
            loadData();
            setDeletePopUp(status);
          }}
          closePopUp={(data: any) => setDeletePopUp(data)}
          submit={() => {
            setDeletePopUp(false);
            deleteReplaceMaterialToSupplier();
          }}
          textMessage={`${t("inventory.delete damage received From supplier")}`}
          headerMessage={`${t(
            "inventory.delete damage received From supplier"
          )}`}
        />
      </ToastPopupSecond>
      <div className={`${isMobileOnly ? " position-relative" : "d-none"}`}>
        <div className="sortfilterMobile row m-0">
          <CommonSort
            sort={sort}
            opensort={opensort}
            opensortClick={sortByOpen}
            setCurrentPage={setCurrentPage}
            setSort={setSort}
            isSortMobile
          />

          <div className="col p-0 d-flex justify-content-evenly align-items-center ">
            <CommonFilter
              isFilterMobile
              openfilterClick={filterByOpen}
              openfilter={openfilter}
              resetClick={resetFilter}
              filterClose={filterByOpen}
              filterApply={applyFilter}
            >
              <div></div>
            </CommonFilter>
          </div>
        </div>
      </div>
      <DownloadPopUp show={show} setShow={setShow} download={download} />
    </div>
  );
};

export default ReplaceSupplier;
