import moment from "moment";
import { RiShareForwardFill } from "react-icons/ri";
import ChatOptions from "./ChatOptions";
import { RiCheckDoubleLine } from "react-icons/ri";
import { isMobileOnly } from "react-device-detect";
import VideoPlayer from "react-video-player-extended";
import { useTranslation } from "react-i18next";

const ChatSent = ({
  item,
  index,
  userContext,
  getCheckBoxValues,
  setCurrentImage,
  setTimedPopup,
  setImageShow,
  context,
  chatContext,
  currentAttachmentVideo,
  setCurrentAttachmentVideo,
}: any) => {
  // const [base64Value, setBase64Value] = useState("");
  // useEffect(() => {
  //   if (item?.attachment_type === "AUDIO") {
  //     convertUrltoBase64();
  //   } else if (item?.reply_message_id?.attachment_type === "AUDIO") {
  //     convertUrltoBase64();
  //   }
  // }, []);

  // const convertUrltoBase64 = async () => {
  //   const data = await userContext?.urlToBase64(
  //     item?.attachment_type === "AUDIO"
  //       ? item?.attachment
  //       : item?.reply_message_id?.attachment
  //   );
  //   if (data?.success) {
  //     setBase64Value("data:audio/mp3;base64," + data.base64);
  //   }
  // };
  const { i18n } = useTranslation();

  return (
    <li
      className={
        chatContext?.checkBoxValue?.includes(item?.id)
          ? "chat__bubble-time-me-bg"
          : "chat__bubble-time-me"
      }
      id={item?.id}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="forwardcheckbox me-3">
          {chatContext?.action === "Forward" && (
            <div>
              <input
                type="checkbox"
                className="custom-control-input"
                id={item?.id}
                value={item?.id}
                checked={chatContext?.checkBoxValue?.includes(item?.id)}
                onChange={getCheckBoxValues}
              />
            </div>
          )}
        </div>

        <div className="d-flex align-items-center">
          {!item?.attachment_type && !item?.is_forwarded && !item?.is_reply && (
            <div className="chat__bubble">
              <div
                className={
                  i18n.language === "en"
                    ? "chat__bubble--me d-flex align-items-center position-relative"
                    : "chat__bubble_meArabic d-flex align-items-center position-relative"
                }
              >
                <div>
                  <h6 className="py-3 px-2 m-0"> {item?.message}</h6>
                </div>
                <div className="chatArrow">
                  <ChatOptions
                    context={context}
                    item={item}
                    chatContext={chatContext}
                  />
                </div>

                <div className="doubletick">
                  <div className=" d-flex align-items-center">
                    <div className="chat__time d-flex justify-content-end">
                      {moment(item?.createdAt).format("hh:mm a")}
                    </div>
                    <div>
                      {!item?.is_forwarded && !item?.is_reply && (
                        <RiCheckDoubleLine
                          color={!item?.seenstatus ? "#9b9b9b" : "#6b5ca4"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  i18n.language === "en"
                    ? "chat__bubble--you_triangle_me"
                    : "chat__bubble_you_triangle_meArabic"
                }
              ></div>
            </div>
          )}
          {item?.attachment_type === "IMAGE" &&
            !item?.is_forwarded &&
            !item?.is_reply && (
              <div className="chat__bubble">
                <div
                  className={
                    i18n.language === "en"
                      ? "chat__bubble chat__bubble--me chat__bubble-image position-relative"
                      : "chat__bubble_meArabic chat__bubble  chat__bubble-image position-relative"
                  }
                >
                  <div
                    className="py-3"
                    onClick={() => {
                      setCurrentImage(item?.attachment);
                      setImageShow(true);
                    }}
                  >
                    <img
                      src={item?.attachment}
                      alt="img"
                      style={
                        isMobileOnly ? { width: "150px" } : { width: "300px" }
                      }
                    />
                  </div>
                  <div className="chatArrow">
                    <ChatOptions
                      context={context}
                      item={item}
                      chatContext={chatContext}
                      attachment={item?.attachment_type?.length > 0}
                      attachmentFile={item?.attachment}
                    />
                  </div>
                  <div className="doubletick">
                    <div className=" d-flex align-items-center">
                      <div className="chat__time d-flex justify-content-end">
                        {moment(item?.createdAt).format("hh:mm a")}
                      </div>
                      <div>
                        {!item?.is_forwarded && !item?.is_reply && (
                          <RiCheckDoubleLine
                            color={!item?.seenstatus ? "#9b9b9b" : "#6b5ca4"}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    i18n.language === "en"
                      ? "chat__bubble--you_triangle_me"
                      : "chat__bubble_you_triangle_meArabic"
                  }
                ></div>
              </div>
            )}
          {item?.attachment_type === "VIDEO" &&
            !item?.is_forwarded &&
            !item?.is_reply && (
              <div className="chat__bubble">
                <div
                  className={
                    i18n.language === "en"
                      ? "chat__bubble chat__bubble--me chat__bubble-image position-relative"
                      : "chat__bubble_meArabic chat__bubble  chat__bubble-image position-relative"
                  }
                >
                  <div className="py-3">
                    <VideoPlayer
                      volume={currentAttachmentVideo?.volume}
                      url={item?.attachment}
                      isPlaying={currentAttachmentVideo?.index === index}
                      onPlay={() =>
                        setCurrentAttachmentVideo({
                          index: index,
                        })
                      }
                      onPause={() =>
                        setCurrentAttachmentVideo({
                          index: null,
                        })
                      }
                      onVolume={(value: any) =>
                        setCurrentAttachmentVideo({
                          index: index,
                          volume: value,
                        })
                      }
                      height="360px"
                      width="435px"
                    />
                    {/* <video width="100%" height="300" controls>
                      <source src={item?.attachment} />
                      Your browser does not support HTML5 video.
                    </video> */}
                  </div>
                  <div className="chatArrow">
                    <ChatOptions
                      context={context}
                      item={item}
                      chatContext={chatContext}
                      attachment={item?.attachment_type?.length > 0}
                      attachmentFile={item?.attachment}
                    />
                  </div>
                  <div className="doubletick">
                    <div className=" d-flex align-items-center">
                      <div className="chat__time d-flex justify-content-end">
                        {moment(item?.createdAt).format("hh:mm a")}
                      </div>
                      <div>
                        {!item?.is_forwarded && !item?.is_reply && (
                          <RiCheckDoubleLine
                            color={!item?.seenstatus ? "#9b9b9b" : "#6b5ca4"}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    i18n.language === "en"
                      ? "chat__bubble--you_triangle_me"
                      : "chat__bubble_you_triangle_meArabic"
                  }
                ></div>
              </div>
            )}
          {item?.attachment_type === "AUDIO" &&
            !item?.is_forwarded &&
            !item?.is_reply && (
              <div className="chat__bubble">
                <div
                  className={
                    i18n.language === "en"
                      ? "chat__bubble chat__bubble--me chat__bubble-image position-relative"
                      : "chat__bubble_meArabic chat__bubble  chat__bubble-image position-relative"
                  }
                >
                  <div className="py-3">
                    <div
                      className="sound sound-wave"
                      style={{ width: "280px" }}
                    >
                      {/* <Waveform audio={item?.attachment} /> */}
                      <audio src={item?.attachment} controls></audio>
                    </div>
                  </div>
                  <div className="chatArrow">
                    <ChatOptions
                      context={context}
                      item={item}
                      chatContext={chatContext}
                      attachment={item?.attachment_type?.length > 0}
                      attachmentFile={item?.attachment}
                    />
                  </div>
                  <div className="doubletick">
                    <div className=" d-flex align-items-center">
                      <div className="chat__time d-flex justify-content-end">
                        {moment(item?.createdAt).format("hh:mm a")}
                      </div>
                      <div>
                        {!item?.is_forwarded && !item?.is_reply && (
                          <RiCheckDoubleLine
                            color={!item?.seenstatus ? "#9b9b9b" : "#6b5ca4"}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    i18n.language === "en"
                      ? "chat__bubble--you_triangle_me"
                      : "chat__bubble_you_triangle_meArabic"
                  }
                ></div>
              </div>
            )}
          {(item?.is_reply || item?.is_forwarded) && (
            <div
              className="ctext-wrap-content position-relative"
              style={
                isMobileOnly
                  ? { maxWidth: "180px" }
                  : { maxWidth: "420px", minWidth: "160px" }
              }
            >
              <div className="chatArrowForward">
                <ChatOptions
                  chatContext={chatContext}
                  context={context}
                  item={item}
                />
              </div>
              <div>
                {item?.is_forwarded && (
                  <span className="px-2 Forwardedcolor">
                    <RiShareForwardFill color="#9b9b9b" /> Forwarded
                  </span>
                )}
                {item?.is_reply && (
                  <a href={`#${item?.reply_message_id?.id}`}>
                    <div className="">
                      <div className="replymessage-block mb-2 d-flex align-items-start">
                        <div className=" flex-grow-1">
                          <h5 className="conversation-name">
                            {item?.reply_message_id?.from !==
                            userContext?.user?.id
                              ? item?.to?.name
                              : "You"}
                          </h5>
                          <div className="m-0 replywd">
                            {!item?.reply_message_id?.attachment_type && (
                              <>{item?.reply_message_id?.message}</>
                            )}
                            {item?.reply_message_id?.attachment_type ===
                              "IMAGE" && (
                              <img
                                src={item?.reply_message_id?.attachment}
                                alt="img"
                                style={
                                  isMobileOnly
                                    ? { width: "150px" }
                                    : { width: "80px" }
                                }
                              />
                            )}
                            {item?.reply_message_id?.attachment_type ===
                              "VIDEO" && (
                              <VideoPlayer
                                volume={currentAttachmentVideo?.volume}
                                url={item?.reply_message_id?.attachment}
                                isPlaying={
                                  currentAttachmentVideo?.index === index
                                }
                                onPlay={() =>
                                  setCurrentAttachmentVideo({
                                    index: index,
                                  })
                                }
                                onPause={() =>
                                  setCurrentAttachmentVideo({
                                    index: null,
                                  })
                                }
                                onVolume={(value: any) =>
                                  setCurrentAttachmentVideo({
                                    index: index,
                                    volume: value,
                                  })
                                }
                                height="300px"
                                width="200px"
                                // height="360px"
                                // width="435px"
                              />
                              // <video width="100%" height="300" controls>
                              //   <source
                              //     src={item?.reply_message_id?.attachment}
                              //   />
                              //   Your browser does not support HTML5 video.
                              // </video>
                            )}
                            {item?.reply_message_id?.attachment_type ===
                              "AUDIO" && (
                              <div
                                className="sound sound-wave"
                                style={{ width: "280px" }}
                              >
                                {/* <Waveform
                                  audio={item?.reply_message_id?.attachment}
                                /> */}
                                <audio
                                  src={item?.reply_message_id?.attachment}
                                  controls
                                ></audio>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                )}
                <div className="mb-4 ps-3">
                  <div
                    style={{ height: "auto" }}
                    className=" d-flex align-items-center py-3"
                  >
                    {!item?.attachment_type && (
                      <h6 className="px-2 forwardwd"> {item?.message}</h6>
                    )}
                  </div>

                  {item?.attachment_type === "IMAGE" && (
                    <div
                      className="p-4"
                      onClick={() => {
                        setCurrentImage(item?.attachment);
                        setImageShow(true);
                      }}
                    >
                      <img
                        src={item?.attachment}
                        alt="img"
                        style={
                          isMobileOnly ? { width: "150px" } : { width: "300px" }
                        }
                      />
                    </div>
                  )}

                  {item?.attachment_type === "VIDEO" && (
                    <VideoPlayer
                      volume={currentAttachmentVideo?.volume}
                      url={item?.attachment}
                      isPlaying={currentAttachmentVideo?.index === index}
                      onPlay={() =>
                        setCurrentAttachmentVideo({
                          index: index,
                        })
                      }
                      onPause={() =>
                        setCurrentAttachmentVideo({
                          index: null,
                        })
                      }
                      onVolume={(value: any) =>
                        setCurrentAttachmentVideo({
                          index: index,
                          volume: value,
                        })
                      }
                      height="300px"
                      width="200px"
                      // height="360px"
                      // width="405px"
                    />
                    // <video width="100%" height="300" controls>
                    //   <source src={item?.attachment} />
                    //   Your browser does not support HTML5 video.
                    // </video>
                  )}
                  {item?.attachment_type === "AUDIO" && (
                    <div
                      className="sound sound-wave"
                      style={{ width: "280px" }}
                    >
                      {/* <Waveform audio={item?.attachment} /> */}
                      <audio src={item?.attachment} controls></audio>
                    </div>
                  )}
                </div>
              </div>

              <div className="doubletick">
                <div className=" d-flex align-items-center">
                  <div className="chat__time d-flex justify-content-end">
                    {moment(item?.createdAt).format("hh:mm a")}
                  </div>
                  <div>
                    <RiCheckDoubleLine
                      color={!item?.seenstatus ? "#9b9b9b" : "#6b5ca4"}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

export default ChatSent;
