/* eslint-disable no-dupe-keys */
import { Chart } from "react-chartjs-2";
import { isMobileOnly } from "react-device-detect";

export default function DashboardAreaWisePieChart({ title, data }: any) {
  return (
    <div className="chartCard chartCardheight rounded-3 d-flex flex-column justify-content-between">
      <div>
        <h6 className=" fw-bold px-3 m-0 pt-3">{title}</h6>
      </div>
      <div className=" px-1">
        <div
          className={
            isMobileOnly
              ? " d-flex justify-content-center py-3 bar"
              : " bar py-3"
          }
        >
          <Chart
            data={{
              labels: data?.label,
              datasets: data?.datasets || [],
            }}
            options={{
              plugins: {
                title: {
                  display: true,
                },
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
            type={"bar"}
            className="forbarChat"
          />
        </div>
      </div>
    </div>
  );
}
