import { useRef, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { TbArrowsDownUp } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import CommonAvatar from "../../components/Avatar/CommonAvatar";
import { routes } from "../../constants/routes";

const TicketList = ({ context }: any) => {
  const urlParmas = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [viewMore, setViewMore] = useState(10);
  const listRef = useRef<any>(null);

  const getTicketDetail = async (data: any) => {
    context?.removeTicket(null);
    if (data.ticketId) {
      if (window.location.pathname.includes("/activities")) {
        navigate(
          routes.getBillBoardTicketsActivityFeedUrl(
            data.billboardId,
            data?.ticketId
          )
        );
      } else {
        navigate(
          routes.getBillBoardTicketsIdUrl(data.billboardId, data.ticketId)
        );
      }
    }
  };

  const viewMoreDetails = () => {
    setViewMore(viewMore + 10);
  };

  return (
    <>
      {context?.tickets?.length > 0 && (
        <div>
          <h6 className=" m-0 py-3 fw-semibold">
            {t("CreateOrViewTicket.Tickets")} ({context?.tickets?.length || 0})
          </h6>
          <div
            className=" px-3 my-3 view_tickets"
            style={{ height: isMobileOnly ? "" : "70vh" }}
          >
            <div id="ticketList" ref={listRef}></div>
            {context?.tickets.slice(0, viewMore).map((ticket: any) => {
              return (
                <div
                  className={`d-flex py-4 border-bottom border-2 ${
                    window.location.pathname ===
                      routes.getBillBoardTicketsIdUrl(
                        urlParmas.billboardId,
                        ticket.id
                      ) ||
                    window.location.pathname ===
                      routes.getBillBoardTicketsActivityFeedUrl(
                        urlParmas.billboardId,
                        ticket.id
                      )
                      ? "opactiyOnhover-active"
                      : "opactiyOnhover"
                  }`}
                  onClick={() => {
                    if (urlParmas?.ticketId !== ticket?.id)
                      getTicketDetail({
                        billboardId: urlParmas.billboardId,
                        ticketId: ticket.id,
                      });
                  }}
                >
                  {ticket?.assignee?.avatar &&
                  (ticket?.assignee?.avatar !== null ||
                    ticket?.assignee?.avatar !== undefined) ? (
                    <div>
                      <img
                        src={ticket?.assignee?.avatar}
                        alt=""
                        width={50}
                        height={50}
                        className=" rounded-circle"
                      />
                    </div>
                  ) : (
                    <CommonAvatar
                      name={
                        ticket?.assignee?.name
                          ? ticket?.assignee?.name?.toUpperCase()
                          : ticket?.assignee?.email?.toUpperCase()
                      }
                      size={(isMobileOnly ? 50 : 50).toString()}
                      style={{
                        backgroundColor: "#6b5ca4",
                      }}
                    />
                  )}
                  <div className="mx-3  ">
                    <h6 className=" m-0 fw-semibold">
                      {ticket?.assignee?.name}
                    </h6>
                    <p className=" d-inline-block text-capitalize">
                      {ticket?.title}
                    </p>
                  </div>
                </div>
              );
            })}
            <div className=" d-flex justify-content-center  p-4 more btn">
              {context?.tickets?.length >= viewMore && (
                <span onClick={() => viewMoreDetails()}>{t("View More")}</span>
              )}
              {context?.tickets?.length > 8 && (
                <span
                  className="mx-2"
                  onClick={() =>
                    listRef!.current!.scrollIntoView!({
                      behavior: "smooth",
                    })
                  }
                >
                  <TbArrowsDownUp color="#6b5ca4" fontSize={14} />
                </span>
              )}
            </div>
          </div>
        </div>
      )}
      {!context?.tickets?.length && (
        <div className=" d-flex justify-content-center align-items-center">
          <div>No Tickets Found</div>
        </div>
      )}
    </>
  );
};

export default TicketList;
