import { useEffect, useState } from "react";
import AppLayout from "../../../layouts/AppLayout";
import { isMobile } from "react-device-detect";
import { MdArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import { routes } from "../../../constants/routes";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import ToastPopup from "../../../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../../../components/PopupToast/SuccessErrorToast";
import CustomLocationSelect from "../../../components/CommonComponents/CustomLocationSelect";
import { useMasters } from "../../../context/MasterProider";
import { isValidPhoneNumber } from "react-phone-number-input";
import CommonButton from "../../../components/CommonButton/CommonButton";
import FormikField from "../../../components/FormikField/FormikField";
import FormikPhoneNumber from "../../../components/FormikPhoneNumber/FormikPhoneNumber";
import CustomInputField from "../../../components/InputField/CustomInputField";

const CreateNewSupplier = ({ userContext }: any) => {
  const context = useMasters();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const urlParams = useParams();
  const pathName = window?.location?.pathname;

  return (
    <>
      <AppLayout>
        <div
          className="edit_members p-3 "
          style={
            isMobile
              ? { background: "#FAFAFA", height: "auto" }
              : { background: "#FAFAFA", minHeight: "80vh" }
          }
        >
          <div className="members py-4 mt-2 ">
            <h5
              className={`${
                isMobile ? "fs-6  mb-0 membertitle" : "  mb-0 membertitle"
              }`}
              onClick={() => navigate(routes.mastersSupplier)}
            >
              {t("inventory.all Supplier")}
            </h5>
            <>
              <span className={i18n.language === "en" ? "px-2 " : "d-none"}>
                <MdOutlineArrowForwardIos />
              </span>
              <span className={i18n.language === "en" ? "d-none" : "px-2"}>
                <MdArrowBackIos />
              </span>

              <h5
                className={`${
                  isMobile ? "fs-6 text-capitalize m-0  " : " m-0 "
                }`}
              >
                {}

                {urlParams?.suppliersId && pathName?.includes("/edit")
                  ? `${t("inventory.Edit Supplier")}`
                  : pathName?.includes("/view")
                  ? `${t("inventory.View Supplier")}`
                  : `${t("inventory.new Supplier")}`}
              </h5>
            </>
          </div>

          {context && (
            <NewSupplierForm
              context={context}
              urlParams={urlParams}
              pathName={pathName}
            />
          )}
        </div>
      </AppLayout>
    </>
  );
};

interface NewSupplierFormProps {
  context: any;
  urlParams: any;
  pathName: string;
}

const NewSupplierForm = ({
  context,
  urlParams,
  pathName,
}: NewSupplierFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [bodyMessage, setBodyMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    editSupplier();
  }, [urlParams?.suppliersId]);

  const editSupplier = async () => {
    await context?.getSupplierDetail(urlParams?.suppliersId);
  };

  const initialValues = {
    name: context?.supplier?.name || "",
    description: context?.supplier?.description || "",
    email: context?.supplier?.email || "",
    city: context?.supplier?.city || "",
    country: context?.supplier?.country || "",
    contact_person: context?.supplier?.contact_person || "",
    contact_number: context?.supplier?.contact_number || "",
    supplier_id: context?.supplier?.supplier_id || "",
  };
  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Name is required"),
    description: Yup.string().min(2, "Too Short!").max(1000),
    supplier_id: Yup.string()
      .matches(/^[0-9]*\.?[0-9]*$/, "Please enter only valid number")
      .max(20),
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9.]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        "Invalid email address"
      )
      .email("Invalid email address")
      .required("E-mail address is required"),
    city: Yup.string().required("City is required"),
    country: Yup.string().required("Country is required"),
    contact_person: Yup.string().required("Contact person is required"),
    contact_number: Yup.string().required("Contact Number is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const { success, errors } = context?.supplier?.id
        ? await context.updateSupplierDetail(context?.supplier?.id, {
            ...values,
            supplier_id: parseInt(values?.supplier_id),
          })
        : await context.addInventorySupplier({
            ...values,
            supplier_id: parseInt(values?.supplier_id),
          });
      setIsSuccess(success);
      setOpenPopup(true);
      if (success) {
        setBodyMessage(
          context?.supplier?.id
            ? `${t("inventory.Supplier updated sucessfully")}`
            : `${t("inventory.Supplier Added sucessfully")}`
        );
        formik.resetForm();
        timeOutPopUp(true);
      } else {
        setBodyMessage(errors);
        timeOutPopUp(false);
      }
    },
  });

  const { errors, handleSubmit, isSubmitting, isValid, dirty, resetForm } =
    formik;

  const timeOutPopUp = (value: any) => {
    let timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        navigate(routes.mastersSupplier);
        setOpenPopup(false);
        setBodyMessage("");
      } else {
        setOpenPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  return (
    <>
      <div className="px-4 pb-5">
        <FormikProvider value={formik}>
          <Form noValidate={true} onSubmit={handleSubmit}>
            <Row>
              <Col md={6} className=" mb-4  formprofile">
                <FormikField
                  name="name"
                  type="text"
                  autoFocus
                  validationSchema={validationSchema}
                  label={t("inventory.Supplier Name")}
                  errors={errors as Record<string, string>}
                  disabled={context?.supplier?.id}
                />
              </Col>
              <Col md={6} className=" mb-4 formprofile">
                <FormikField
                  name="supplier_id"
                  type="text"
                  validationSchema={validationSchema}
                  label={t("inventory.Supplier ID")}
                  errors={errors as Record<string, string>}
                  disabled={context?.supplier?.id}
                />
              </Col>
              <Col md={6} className=" mb-4 formprofile">
                <FormikField
                  name="contact_person"
                  type="text"
                  validationSchema={validationSchema}
                  label={t("inventory.Contact Person")}
                  errors={errors as Record<string, string>}
                  disabled={pathName?.includes("/view")}
                />
              </Col>
              <Col md={6} className=" mb-4 formprofile">
                <FormikPhoneNumber
                  validationSchema={validationSchema}
                  name="contact_number"
                  label={t("inventory.Contact Number")}
                  // errors={
                  //   formik?.values?.contact_number
                  //     ? isValidPhoneNumber(formik?.values?.contact_number)
                  //       ? undefined
                  //       : "Invalid phone number"
                  //     : "Phone number required"
                  // }
                  isDisabled={pathName?.includes("/view")}
                />
              </Col>
              <Col md={6} className=" mb-4 formprofile">
                <FormikField
                  name="email"
                  type="email"
                  validationSchema={validationSchema}
                  label={t("inventory.E-mail address")}
                  errors={errors as Record<string, string>}
                  disabled={pathName?.includes("/view")}
                />
              </Col>

              <Col md={6} className="formprofile mb-4 formprofile">
                <Field name="city">
                  {({ field }: FieldProps<string>) => {
                    return (
                      <CustomInputField
                        label={t("inventory.City")}
                        error={errors["city"] as string}
                        validationSchema={validationSchema}
                        inputId={""}
                        field={field}
                      >
                        <CustomLocationSelect
                          fiedName={"city"}
                          className={"autolocation"}
                          updateField={(data: any) =>
                            formik.setFieldValue("city", data?.name)
                          }
                          defaultValue={formik?.values?.city}
                          isFieldDisabled={pathName?.includes("/view")}
                        />
                      </CustomInputField>
                    );
                  }}
                </Field>
              </Col>
              <Col md={12} className=" mb-4 formprofile">
                <FormikField
                  name="country"
                  type="text"
                  validationSchema={validationSchema}
                  label={t("inventory.Country")}
                  errors={errors as Record<string, string>}
                  disabled={pathName?.includes("/view")}
                />
              </Col>
              <Col md={12} className=" mb-4 formprofile">
                <FormikField
                  name="description"
                  type="text"
                  as="textarea"
                  validationSchema={validationSchema}
                  label={t("inventory.Description")}
                  errors={errors as Record<string, string>}
                  disabled={pathName?.includes("/view")}
                />
              </Col>
            </Row>

            {!pathName?.includes("/view") && (
              <div className="d-flex justify-content-end py-5">
                <CommonButton
                  text={t("inventory.Cancel")}
                  isLoading={isSubmitting}
                  type="button"
                  variant="outline-danger"
                  className="bg-transparent text-danger"
                  isDisabled={isSubmitting || !dirty}
                  onClick={() => resetForm()}
                />

                <CommonButton
                  text={
                    context?.supplier?.id
                      ? `${t("inventory.Update Supplier")}`
                      : `${t("inventory.Add Supplier")}`
                  }
                  isLoading={isSubmitting}
                  type="submit"
                  variant="success"
                  className="btn-i18n text-white"
                  isDisabled={isSubmitting || !isValid || !dirty}
                />
              </div>
            )}
          </Form>
        </FormikProvider>
        <ToastPopup
          trigger={context?.isLoading}
          setTrigger={context?.setIsLoading}
        >
          <SuccessErrorToast isLoading={true} />
        </ToastPopup>
        <ToastPopup trigger={openPopup} setTrigger={setOpenPopup}>
          <SuccessErrorToast
            bodyMessage={bodyMessage}
            isSuccess={isSuccess}
            footButton={false}
          />
        </ToastPopup>
      </div>
    </>
  );
};

export default CreateNewSupplier;
