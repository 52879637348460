import PhoneInput from "react-phone-number-input";

export default function PhoneNumber({
  placeholder,
  value,
  name,
  onChange,
  error,
  className,
  disabled,
}: any) {
  return (
    <PhoneInput
      placeholder={placeholder}
      value={value}
      name={name}
      onChange={onChange}
      error={error}
      className={className}
      disabled={disabled}
      defaultCountry="SA"
    />
  );
}
