import { Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import x from "../../assets/x.png";
import { useMasters } from "../../context/MasterProider";
import NewConfigModalForm from "./NewConfigModalForm";
import NewConfigCleaningModalForm from "./NewConfigCleaningModalForm";
import ReasonPopUp from "../../components/PopupToast/ReasonPopUp";

const NewConfigModal = (props: any) => {
  const context = useMasters();
  const { t } = useTranslation();
  const {
    setTimedPopup,
    setIsSuccess,
    setBodyMessage,
    setTrigger,
    timeOutPopUp,
    configType = "",
    type = "",
    loadData,
    isReasonPop = false,
    setReasonValue,
    reasonValue,
    updateStatus,
  } = props || {};

  return (
    <Container>
      <Col>
        <div className="d-flex align-items-center justify-content-between">
          <h4 className={`${isMobile ? " inmember" : " inmember"}`}>
            {isReasonPop
              ? "Reason"
              : context?.config?.id
              ? `${t("masters.Edit")} ${
                  configType ? type : t("CreateBillBoardForm.BillBoard_Type")
                }`
              : `${t("masters.Add")} ${
                  configType ? type : t("CreateBillBoardForm.BillBoard_Type")
                }`}
          </h4>
          <div onClick={() => setTrigger(false)} className=" py-3">
            <img src={x} alt="" width={24} className="pointer" />
          </div>
        </div>

        {isReasonPop ? (
          <ReasonPopUp
            setReasonValue={setReasonValue}
            reasonValue={reasonValue}
            updateStatus={updateStatus}
          />
        ) : (
          context &&
          (configType ? (
            <NewConfigModalForm
              context={context}
              onClose={setTrigger}
              popup={setTimedPopup}
              setSuccess={setIsSuccess}
              setBodyMsg={setBodyMessage}
              timeOutPopUp={timeOutPopUp}
              configType={configType}
              type={type}
              loadData={loadData}
            />
          ) : (
            <NewConfigCleaningModalForm
              context={context}
              onClose={setTrigger}
              popup={setTimedPopup}
              setSuccess={setIsSuccess}
              setBodyMsg={setBodyMessage}
              timeOutPopUp={timeOutPopUp}
              loadData={loadData}
            />
          ))
        )}
      </Col>
    </Container>
  );
};
export default NewConfigModal;
