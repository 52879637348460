import { useEffect, useState } from "react";
import ToastPopup from "../../../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../../../components/PopupToast/SuccessErrorToast";
import Pagination from "../../../components/Pagination";
import { Button, Dropdown, Table } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import {
  isDesktop,
  isMobile,
  isMobileOnly,
  isTablet,
} from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMasters } from "../../../context/MasterProider";
import { routes } from "../../../constants/routes";
import NewConfigModal from "../NewConfigModal";
import Modal from "../../../components/PopupToast/Modal";
import CommonButton from "../../../components/CommonButton/CommonButton";
import CommonSearch from "../../../components/CommonSearch/CommonSearch";
import CommonSort from "../../../components/CommonSort/CommonSort";
import CommonLoadingBar from "../../../components/LoadingBar/CommonLoadingBar";

export default function BomList({ userContext }: any) {
  let timedPopupSetTimeOut: any;
  const queryParams = new URLSearchParams(window.location.search);
  const configType = queryParams.get("config_type");
  const context = useMasters();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [timedPopup, setTimedPopup] = useState(false);
  const [sort, setSort] = useState(
    queryParams.get("sort") ? queryParams.get("sort") : "-createdAt"
  );
  const [search, setSearch] = useState<any>(
    queryParams.get("search") ? queryParams.get("search") : ""
  );
  const [mobileSearch, setMobileSearch] = useState(
    queryParams.get("search") ? queryParams.get("search") : ""
  );
  const [bodyMessage, setBodyMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [openfilter, setOpenfilter] = useState(false);
  const [opensort, setOpenSort] = useState(false);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [buttonPopup, setButtonPopup] = useState(false);

  useEffect(() => {
    loadData();
  }, [search, sort, currentPage, configType]);

  const queryParamsDetails = () => {
    let params = {
      config_type: configType,
      search,
      sort: sort ? sort : "-updatedAt",
      limit: 10,
      page: currentPage,
    };
    return params;
  };

  const loadData = async () => {
    navigate(routes.getBOMList(queryParamsDetails()));
    context?.getAllBom(queryParamsDetails());
  };

  const resetLoadMaterial = async () => {
    let params = {
      search,
      sort,
      limit: 10,
      page: currentPage,
    };
    navigate(routes.getBOMList(params));
    await context?.getAllBom(params);
  };

  const sortByOpen = () => {
    setOpenfilter(false);
    setOpenSort(!opensort);
  };

  const applyFilter = () => {
    loadData();
    filterByOpen();
  };

  const resetFilter = () => {
    loadData();
    filterByOpen();
    resetLoadMaterial();
  };

  const filterByOpen = () => {
    setOpenSort(false);
    setOpenfilter(!openfilter);
  };

  const handleEnterKey = (e: any) => {
    if (e.target.value.length === 0) {
      setSearch(e.target.value);
    }
    if (e.key === "Enter") {
      setSearch(e.target.value);
      setCurrentPage(1);
    }
  };

  const timeOutPopUp = (value: any) => {
    timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        setTimedPopup(false);
        setBodyMessage("");
      } else {
        setTimedPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  const deleteBom = async (id: any) => {
    const { success, error } = await context?.deleteBom(id);
    setIsSuccess(success);
    if (success) {
      loadData();
      timeOutPopUp(true);
      setTimedPopup(true);
      setBodyMessage("Stock deleted successfully");
    } else {
      // setDeletePopUp(false);
      setTimedPopup(true);
      timeOutPopUp(false);
      setBodyMessage(error);
    }
  };

  const getCurrentConfig = async (id: string) => {
    const { success } = await context?.getCleaningConfigById(id);
    if (success) {
      setButtonPopup(true);
    }
  };

  return (
    <div className={`${isMobile ? " position-relative  " : "  rounded-1 "}`}>
      {context?.isLoading && <CommonLoadingBar />}
      <div
        className={`${
          isMobile ? "container-fluid position-relative " : "container "
        }`}
      >
        {/* --------------------------------------------nested inventory------------------------------------------------- */}

        <div
          className={`${
            isMobile
              ? "d-none "
              : "pt-4 d-flex align-items-center justify-content-between "
          }`}
        >
          <div className="members ">
            <h5 className=" mb-0 ">
              {t(`masters.BOM Masters`)}(
              {context?.meta ? context?.meta?.total_count : 0})
            </h5>
          </div>
          <div
            className={`${
              isMobile ? "d-none " : "container-fluid p-0 forinput"
            }`}
          >
            <CommonSearch
              placeholder={`Search ${t(`masters.BOM Masters`)}` || ""}
              onKeyDown={(e: any) => handleEnterKey(e)}
              onChange={(e: any) =>
                e.target.value.length === 0 && setSearch(e.target.value)
              }
            />
          </div>

          <div className="d-flex  align-items-center justify-content-end">
            <CommonSort
              sort={sort}
              opensort={opensort}
              opensortClick={sortByOpen}
              setCurrentPage={setCurrentPage}
              setSort={setSort}
              sortAtoZ="billboard_type"
              sortZtoA="-billboard_type"
            />
            {userContext?.roleAccess["MASTER"].includes("WRITE") && (
              <CommonButton
                onClick={() => {
                  context?.setBom({});
                  navigate(routes.AddBomList);
                }}
                text={` ${t("masters.New")} ${t(`masters.BOM Masters`)} `}
                variant="success"
                className=" btn-i18n text-white"
              />
            )}
          </div>
        </div>
        <div className={`${isDesktop ? "d-none " : " pt-4"}`}>
          <div
            className={`${
              isDesktop
                ? "d-none "
                : " d-flex align-items-center justify-content-between "
            }`}
          >
            <div className="members ">
              <h5 className="m-0">
                {t(`masters.BOM Masters`)} (
                {context?.meta ? context?.meta?.total_count : 0})
              </h5>
            </div>
            <div className="d-flex">
              <div className={`${isTablet ? " d-flex" : "d-none  "}`}>
                <CommonSort
                  sort={sort}
                  opensort={opensort}
                  opensortClick={sortByOpen}
                  setCurrentPage={setCurrentPage}
                  setSort={setSort}
                  sortAtoZ="billboard_type"
                  sortZtoA="-billboard_type"
                />
              </div>
              {userContext?.roleAccess["MASTER"].includes("WRITE") && (
                <div className="d-flex align-items-center ">
                  <CommonButton
                    onClick={() => {
                      context?.setBom({});
                      navigate(routes.AddBomList);
                    }}
                    text={` ${t("masters.New")} ${t(`masters.BOM Masters`)} `}
                    variant="success"
                    className=" btn-i18n text-white"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="pt-5 position-relative">
            <CommonSearch
              placeholder={`Search ${t(`masters.BOM Masters`)}` || ""}
              onKeyDown={(e: any) => handleEnterKey(e)}
              onChange={(e: any) => {
                e.target.value.length === 0 && setSearch(e.target.value);
                if (isMobile) {
                  setMobileSearch(e.target.value);
                }
              }}
            />

            <Button
              className={
                i18n.language === "en"
                  ? "mobile-search-btn"
                  : "mobile-search-btn-right"
              }
              onClick={() =>
                handleEnterKey({
                  key: "Enter",
                  target: { value: mobileSearch },
                })
              }
            >
              {t("chat.Search")}
            </Button>
          </div>
        </div>

        <div className=" pt-4 table-responsive">
          <Table responsive="sm table_class">
            <thead>
              <tr className="table_white_head gapAtMobileView">
                <th
                  className={`${isMobileOnly ? "" : " "}`}
                  style={{ paddingInlineStart: "1.5rem" }}
                >
                  {t("masters.BOM Name")}
                </th>
                <th className={`${isMobileOnly ? " " : " py-4"}`}>
                  {t("masters.Board Type")}
                </th>
                <th className={`${isMobileOnly ? " " : " "}`}>
                  {t("masters.Board Size")}
                </th>
                <th className={`${isMobileOnly ? "" : " "}`}>
                  {t("inventory.Stocks Counts")}
                </th>
                <th className={`${isMobileOnly ? "" : " "}`}>
                  {t("inventory.Quantity")}
                </th>
                {(userContext?.roleAccess["MASTER"].includes("UPDATE") ||
                  userContext?.roleAccess["MASTER"].includes("READ") ||
                  userContext?.roleAccess["MASTER"].includes("DELETE")) && (
                  <th className={`${isMobileOnly ? " " : " "}`}>
                    {t("memberTable.action")}
                  </th>
                )}
              </tr>
            </thead>
            {context?.boms && context?.boms?.length > 0 && (
              <tbody className="table_white">
                {context?.boms.map((bom: any, index: number) => {
                  return (
                    <tr key={bom?._id} className="table_white">
                      <td
                        className={`${isMobileOnly ? " " : " "}`}
                        style={{ paddingInlineStart: "1.5rem" }}
                      >
                        {bom?.bom_name}
                      </td>
                      <td className={`${isMobileOnly ? " " : " py-4"}`}>
                        {bom?.billboard_type}
                      </td>
                      <td className={`${isMobileOnly ? " " : " "}`}>
                        {bom?.board_size || "-"}
                      </td>
                      <td className={`${isMobileOnly ? " " : " "}`}>
                        {bom?.stocks?.length}
                      </td>
                      <td className={`${isMobileOnly ? " " : " "}`}>
                        {bom?.stocks?.reduce(
                          (prev: any, crrnt: any) => prev + crrnt?.quantity,
                          0
                        ) || "-"}
                      </td>
                      {(userContext?.roleAccess["MASTER"].includes("UPDATE") ||
                        userContext?.roleAccess["MASTER"].includes("READ") ||
                        userContext?.roleAccess["MASTER"].includes(
                          "DELETE"
                        )) && (
                        <td className={`${isMobileOnly ? " " : "py-4 "}`}>
                          <div className="pt-2 Actions">
                            <Dropdown className="Actions_title">
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basicActions"
                              >
                                <BsThreeDots fontSize={24} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="create-dropdown-css">
                                {userContext?.roleAccess["MASTER"].includes(
                                  "UPDATE"
                                ) && (
                                  <Dropdown.Item
                                    className="sort-by"
                                    onClick={() => {
                                      navigate(
                                        routes?.getEditBOMList(bom?._id)
                                      );
                                    }}
                                  >
                                    {t("editdropdown.edit")}
                                  </Dropdown.Item>
                                )}
                                {userContext?.roleAccess["MASTER"].includes(
                                  "READ"
                                ) && (
                                  <Dropdown.Item
                                    className="sort-by"
                                    onClick={() => {
                                      navigate(
                                        routes?.getViewBOMList(bom?._id)
                                      );
                                    }}
                                  >
                                    {t("editdropdown.view")}
                                  </Dropdown.Item>
                                )}
                                {userContext?.roleAccess["MASTER"].includes(
                                  "DELETE"
                                ) && (
                                  <Dropdown.Item
                                    className="sort-by"
                                    onClick={() => {
                                      deleteBom(bom?._id);
                                    }}
                                  >
                                    {t("editdropdown.delete")}
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </Table>
          {!context?.boms?.length && (
            <div className=" d-flex justify-content-center align-items-center">
              {t("masters.No")} {t(`masters.BOM Masters`)} {t("masters.Found")}
            </div>
          )}
        </div>
        {context?.meta && context?.boms && context?.boms?.length > 0 && (
          <Pagination
            meta={context?.meta}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            currentPageCount={context?.boms?.length}
            component={t(`masters.BOM Masters`)}
            isConfig={true}
          />
        )}
      </div>
      <ToastPopup
        trigger={context?.isLoading}
        setTrigger={context?.setIsLoading}
      >
        <SuccessErrorToast isLoading={true} />
      </ToastPopup>
      <ToastPopup trigger={timedPopup} setTrigger={setTimedPopup}>
        <SuccessErrorToast
          bodyMessage={bodyMessage}
          isSuccess={isSuccess}
          footButton={false}
        />
      </ToastPopup>

      <div className={`${isMobileOnly ? " position-relative" : "d-none"}`}>
        <div className="sortfilterMobile row m-0">
          <CommonSort
            sort={sort}
            opensort={opensort}
            opensortClick={sortByOpen}
            setCurrentPage={setCurrentPage}
            setSort={setSort}
            sortAtoZ="billboard_type"
            sortZtoA="-billboard_type"
            isSortMobile
          />
        </div>
      </div>
      <Modal trigger={buttonPopup} setTrigger={setButtonPopup}>
        <NewConfigModal
          setTrigger={(status: boolean) => {
            setButtonPopup(status);
          }}
          setTimedPopup={setTimedPopup}
          setIsSuccess={setIsSuccess}
          setBodyMessage={setBodyMessage}
          timeOutPopUp={timeOutPopUp}
          loadData={loadData}
        />
      </Modal>
    </div>
  );
}
