import { useParams } from "react-router-dom";

import { routes } from "../../constants/routes";
import ProgressBar from "../BillBoards/ProgressBar/ProgressBar";
import CreateNewGroupForm from "./CreateNewGroupForm";

const ViewTickets = ({ context, userContext }: any) => {
  const urlParams = useParams();
  //

  return (
    <>
      {context?.ticket && context?.ticket?.ticket_no && (
        <div className=" d-flex justify-content-between pt-3">
          <h6 className=" m-0 fw-semibold ticket_no">
            {context?.ticket.ticket_no}
          </h6>
          <h6
            className={
              context?.ticket?.approved_by_manager
                ? "m-0 fw-semibold status"
                : "m-0 fw-semibold inActivestatus"
            }
          >
            {context?.ticket?.approved_on || context?.ticket?.rejected_at
              ? context?.ticket?.rejected_at?.length > 0
                ? "REJECTED"
                : "APPROVED"
              : context?.ticket?.status}

            {/* {context?.ticket?.rejected_at?.length > 0 ? "REJECTED" : "APPROVED"}
            {" / "}
            {context?.ticket.status} */}
          </h6>
        </div>
      )}
      {context?.ticket?.status &&
        !(
          window?.location?.pathname ===
            routes.getCleaningTicketsIdUrl(
              urlParams?.cleaningId,
              urlParams?.ticketId
            ) && context?.ticket?.status === "CLOSED"
        ) && (
          <div className="pb-2 pt-5">
            <ProgressBar context={context} />
          </div>
        )}
      {<CreateNewGroupForm userContext={userContext} context={context} />}
    </>
  );
};

export default ViewTickets;
