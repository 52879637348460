import React, { useState } from "react";
import upload from "../../../src/assets/upload.png";
import download from "../../../src/assets/download.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ToastPopup from "../../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../../components/PopupToast/SuccessErrorToast";
import { routes } from "../../constants/routes";
let timedPopupSetTimeOut: any = undefined;

const UploadBillBoard = ({ context }: any) => {
  const [buttonPopup, setButtonPopup] = useState(false);
  const [bodyMessage, setBodyMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();

  const handleUploadbillboards = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file[0]);
    const { success, error, counts } = await context?.UploadBillBoardDetail(
      formData
    );
    setIsSuccess(success && !counts.error);
    if (success && !counts.error) {
      timeOutPopUp(true);
      setButtonPopup(true);
      setBodyMessage(
        `${
          counts.success + counts.updated === 1
            ? "1 Billboard was"
            : `${counts.success + counts.updated} Billboards were`
        } uploaded successfully `
      );
    } else {
      setButtonPopup(true);
      timeOutPopUp(false);
      setBodyMessage(
        error
          ? error[0]
          : `${
              counts.success + counts.updated
            } Billboards were uploaded successfully and ${
              counts.error === 1
                ? "1 Billboard was"
                : `${counts.error} Billboards were`
            } rejected.`
      );
    }
  };

  const timeOutPopUp = (value: any) => {
    timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        setBodyMessage("");
        setButtonPopup(false);
        navigate(routes.listBillBoard);
      } else {
        setBodyMessage("");
        setButtonPopup(false);
      }
    }, 10000);
  };

  const { t } = useTranslation();
  return (
    <>
      <div>
        <div className="row pt-4">
          <div className="col-12  ">
            <div className=" d-flex d-block uploads align-items-center justify-content-center position-relative fileupload ">
              <div>
                <img src={upload} alt="" width={29} />
              </div>
              <input
                type={"file"}
                name="myfile"
                accept=".xlsx"
                onChange={(e: any) => handleUploadbillboards(e.target.files)}
              />

              <h6 className=" mb-0 mx-3 fw-semibold ">
                {t("CreateNewBillBoard.uploadbills")} (xlsx)
              </h6>
            </div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-12  ">
            <div className=" d-flex d-block uploads align-items-center justify-content-center position-relative fileupload ">
              <div>
                <img src={download} alt="" width={29} />
              </div>

              <h6 className=" mb-0 mx-3 fw-semibold ">
                <a
                  href="https://alarabia.blob.core.windows.net/alarabia-dev/file/fbeabb42-ba55-44f8-9193-dc6cf3889b89/Sample_billboard_formate.xlsx"
                  download
                  className="text-black text-decoration-none"
                >
                  {t("CreateNewBillBoard.download")}
                </a>
              </h6>
            </div>
          </div>
        </div>
      </div>
      <ToastPopup trigger={buttonPopup} setTrigger={setButtonPopup}>
        <SuccessErrorToast
          bodyMessage={bodyMessage}
          isSuccess={isSuccess}
          // okButton={() => isSuccess ? loginNow() : closeErrorPopUp()}
          // buttonName='Login Now!'
          footButton={false}
        />
      </ToastPopup>
    </>
  );
};

export default UploadBillBoard;
