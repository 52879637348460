import { useLocation } from "react-router-dom";
import MastersNestedTab from "./mastersNestedTab";
import Supplier from "./Supplier/Supplier";
import Material from "./Material/Material";
import Config from "./Config/Config";
import Cleaning from "./Cleaning/cleaning";
import BomList from "./BOM/bomList";

const ListMasters = ({ userContext }: any) => {
  const location = useLocation();
  return (
    <MastersNestedTab>
      {location.pathname?.includes("/masters/suppliers") && (
        <Supplier userContext={userContext} />
      )}
      {location.pathname?.includes("/masters/materials") && (
        <Material userContext={userContext} />
      )}
      {window.location.search?.includes("config_type=") && (
        <Config userContext={userContext} />
      )}
      {location.pathname?.includes("/masters/billBoardType") && (
        <Cleaning userContext={userContext} />
      )}
      {location.pathname?.includes("/masters/bom") && (
        <BomList userContext={userContext} />
      )}
    </MastersNestedTab>
  );
};

export default ListMasters;
