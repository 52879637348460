import { useEffect, useState } from "react";
import { Button, Dropdown, Table } from "react-bootstrap";
import {
  isDesktop,
  isMobile,
  isMobileOnly,
  isTablet,
} from "react-device-detect";
import { useTranslation } from "react-i18next";
import { BsThreeDots } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import avatar from "../../../assets/avatarprofile.png";
import CommonButton from "../../../components/CommonButton/CommonButton";
import CommonFilter from "../../../components/CommonFilter/CommonFilter";
import CommonSearch from "../../../components/CommonSearch/CommonSearch";
import CommonSort from "../../../components/CommonSort/CommonSort";
import DownloadPopUp from "../../../components/DownloadPopUp";
import CommonLoadingBar from "../../../components/LoadingBar/CommonLoadingBar";
import DeleteBlockToast from "../../../components/PopupToast/DeleteBlockToast";
import SuccessErrorToast from "../../../components/PopupToast/SuccessErrorToast";
import ToastPopup from "../../../components/PopupToast/ToastPopup";
import ToastPopupSecond from "../../../components/PopupToast/ToastPopupSecond";
import { routes } from "../../../constants/routes";
import { useInventery } from "../../../context/InventeryProvider";
import { convertExcel, convertPdf } from "../../../utils/api";
import Pagination from "../../../components/Pagination";
import CommonAvatar from "../../../components/Avatar/CommonAvatar";

let timedPopupSetTimeOut: any = undefined;

const MaterialToTechnician = ({ userContext }: any) => {
  const queryParams = new URLSearchParams(window.location.search);
  const context = useInventery();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const pathname = window?.location?.pathname;
  const [timedPopup, setTimedPopup] = useState(false);
  const [role, setRole] = useState(
    queryParams.get("role") ? queryParams.get("role") : ""
  );
  const [sort, setSort] = useState(
    queryParams.get("sort") ? queryParams.get("sort") : "-createdAt"
  );
  const [search, setSearch] = useState<any>(
    queryParams.get("search") ? queryParams.get("search") : ""
  );
  const [mobileSearch, setMobileSearch] = useState(
    queryParams.get("search") ? queryParams.get("search") : ""
  );
  const [status, setStatus] = useState(
    queryParams.get("status") ? queryParams.get("status") : ""
  );
  const [department, setDepartment] = useState(
    queryParams.get("department") ? queryParams.get("department") : ""
  );
  const [responsibleArea, setResponsibleArea] = useState(
    queryParams.get("responsible_area")
      ? queryParams.get("responsible_area")
      : ""
  );
  const [bodyMessage, setBodyMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [openfilter, setOpenfilter] = useState(false);
  const [opensort, setOpenSort] = useState(false);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [show, setShow] = useState(false);

  const [currentMaterialToTechnicianId, setCurrentMaterialToTechnicianId] =
    useState("");
  const [deletePopUp, setDeletePopUp] = useState(false);

  useEffect(() => {
    loadData();
  }, [search, sort, currentPage]);

  const queryParamsDetails = () => {
    let params = {
      search,
      sort: sort ? sort : "-updatedAt",
      role,
      status: pathname?.includes("materialRequest") ? "REQUESTED" : "DELIVERED",
      department,
      responsibleArea,
      limit: 10,
      page: currentPage,
    };
    return params;
  };

  const loadData = async () => {
    navigate(
      pathname?.includes("materialRequest")
        ? routes.listmaterialRequestUrl(queryParamsDetails())
        : routes.listmaterialToTechnicianUrl(queryParamsDetails())
    );
    context?.loadMaterialToTechnician(queryParamsDetails());
  };

  const resetLoadMaterialToTechnician = async () => {
    let params = {
      search,
      sort,
      limit: 10,
      page: currentPage,
    };
    navigate(
      pathname?.includes("materialRequest")
        ? routes.listmaterialRequestUrl(params)
        : routes.listmaterialToTechnicianUrl(params)
    );
    await context?.loadMaterialToTechnician(params);
  };

  const download = async (type: string) => {
    switch (type) {
      case "excel":
        const res = await context?.downloadMaterialToTechnicianExcel(
          queryParamsDetails()
        );
        convertExcel(res, "Material_to_Technician_List");
        break;
      case "pdf":
        const pdfRes = await context?.downloadMaterialToTechnicianPdf(
          queryParamsDetails()
        );
        convertPdf(pdfRes, "Material_to_Technician_List");
        break;
      default:
        break;
    }
  };

  const sortByOpen = () => {
    setOpenfilter(false);
    setOpenSort(!opensort);
  };

  const applyFilter = () => {
    loadData();
    filterByOpen();
  };

  const resetFilter = () => {
    setRole("");
    setStatus("");
    setDepartment("");
    setResponsibleArea("");
    loadData();
    filterByOpen();
    resetLoadMaterialToTechnician();
  };

  const filterByOpen = () => {
    setOpenSort(false);
    setOpenfilter(!openfilter);
  };

  const handleEnterKey = (e: any) => {
    if (e.target.value.length === 0) {
      setSearch(e.target.value);
    }
    if (e.key === "Enter") {
      setSearch(e.target.value);
      setCurrentPage(1);
    }
  };

  const timeOutPopUp = (value: any) => {
    timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        setTimedPopup(false);
        setBodyMessage("");
      } else {
        setTimedPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  const deleteMaterialToTechnician = async () => {
    const { success, error } = await context?.deleteMaterialToTechnicianAccount(
      currentMaterialToTechnicianId
    );
    setIsSuccess(success);
    if (success) {
      setDeletePopUp(false);
      loadData();
      timeOutPopUp(true);
      setTimedPopup(true);
      setBodyMessage(`${t("inventory.Material info deleted successfully")}`);
    } else {
      setDeletePopUp(false);
      setTimedPopup(true);
      timeOutPopUp(false);
      setBodyMessage(error);
    }
  };

  return (
    <div className={`${isMobile ? " position-relative  " : "  rounded-1 "}`}>
      {context?.isLoading && <CommonLoadingBar />}
      <div
        className={`${
          isMobile ? "container-fluid position-relative " : "container "
        }`}
      >
        {/* --------------------------------------------nested inventory------------------------------------------------- */}

        <div
          className={`${
            isMobile
              ? "d-none "
              : "pt-4 d-flex align-items-center justify-content-between "
          }`}
        >
          <div className="members ">
            <h5 className=" mb-0 ">
              {t(
                pathname?.includes("materialRequest")
                  ? "inventory.All Requests"
                  : "inventory.All Buyers"
              )}
              ({context?.meta ? context?.meta?.total_count : 0})
            </h5>
          </div>
          <div
            className={`${
              isMobile ? "d-none " : "container-fluid p-0 forinput"
            }`}
          >
            <CommonSearch
              placeholder={t("inventory.Search Technician") || ""}
              onKeyDown={(e: any) => handleEnterKey(e)}
              onChange={(e: any) =>
                e.target.value.length === 0 && setSearch(e.target.value)
              }
            />
          </div>

          <div className="d-flex  align-items-center justify-content-end">
            <CommonSort
              sort={sort}
              opensort={opensort}
              opensortClick={sortByOpen}
              setCurrentPage={setCurrentPage}
              setSort={setSort}
            />

            <CommonFilter
              openfilterClick={filterByOpen}
              openfilter={openfilter}
              resetClick={resetFilter}
              filterClose={filterByOpen}
              filterApply={applyFilter}
            >
              <div></div>
            </CommonFilter>
            <div>
              <CommonButton
                text={t("listmember.Download")}
                variant="outline-secondary"
                className=" bg-transparent text-secondary btn-i18n"
                onClick={() => setShow(true)}
              />
            </div>
            {userContext?.roleAccess["INVENTORY"].includes("WRITE") &&
              pathname?.includes("materialRequest") && (
                <CommonButton
                  onClick={() => {
                    context?.setMaterialToTechnician({});
                    navigate(
                      pathname?.includes("materialRequest")
                        ? routes?.createInventoryMaterialRequest
                        : routes.createInventoryMaterialToTechnician
                    );
                  }}
                  text={t(
                    pathname?.includes("materialRequest")
                      ? "inventory.New Request"
                      : "inventory.New Buyer"
                  )}
                  variant="success"
                  className=" btn-i18n text-white"
                />
              )}
          </div>
        </div>
        <div className={`${isDesktop ? "d-none " : " pt-4"}`}>
          <div
            className={`${
              isDesktop
                ? "d-none "
                : " d-flex align-items-center justify-content-between flex-wrap"
            }`}
          >
            <div className="members ">
              <h5 className="m-0">
                {t(
                  pathname?.includes("materialRequest")
                    ? "inventory.All Requests"
                    : "inventory.All Buyers"
                )}{" "}
                ({context?.meta ? context?.meta?.total_count : 0})
              </h5>
            </div>
            <div className="d-flex">
              <div className={`${isTablet ? " d-flex" : "d-none  "}`}>
                <CommonSort
                  sort={sort}
                  opensort={opensort}
                  opensortClick={sortByOpen}
                  setCurrentPage={setCurrentPage}
                  setSort={setSort}
                />

                <CommonFilter
                  openfilterClick={filterByOpen}
                  openfilter={openfilter}
                  resetClick={resetFilter}
                  filterClose={filterByOpen}
                  filterApply={applyFilter}
                >
                  <div></div>
                </CommonFilter>
              </div>
              <div>
                <CommonButton
                  text={t("listmember.Download")}
                  variant="outline-secondary"
                  className=" bg-transparent text-secondary btn-i18n"
                  onClick={() => setShow(true)}
                />
              </div>
              {userContext?.roleAccess["INVENTORY"].includes("WRITE") &&
                pathname?.includes("materialRequest") && (
                  <div className="d-flex align-items-center ">
                    <CommonButton
                      onClick={() => {
                        context?.setMaterialToTechnician({});
                        navigate(
                          pathname?.includes("materialRequest")
                            ? routes?.createInventoryMaterialRequest
                            : routes.createInventoryMaterialToTechnician
                        );
                      }}
                      text={t(
                        pathname?.includes("materialRequest")
                          ? "inventory.New Request"
                          : "inventory.New Buyer"
                      )}
                      variant="success"
                      className=" btn-i18n text-white"
                    />
                  </div>
                )}
            </div>
          </div>
          <div className="pt-5 position-relative">
            <CommonSearch
              placeholder={t("inventory.Search Technician") || ""}
              onKeyDown={(e: any) => handleEnterKey(e)}
              onChange={(e: any) => {
                e.target.value.length === 0 && setSearch(e.target.value);
                if (isMobile) {
                  setMobileSearch(e.target.value);
                }
              }}
            />

            <Button
              className={
                i18n.language === "en"
                  ? "mobile-search-btn"
                  : "mobile-search-btn-right"
              }
              onClick={() =>
                handleEnterKey({
                  key: "Enter",
                  target: { value: mobileSearch },
                })
              }
            >
              {t("chat.Search")}
            </Button>
          </div>
        </div>

        <div className=" pt-4 table-responsive">
          <Table responsive="sm table_class">
            <thead>
              <tr className="table_white_head">
                <th
                  className={`${
                    isMobile ? "" : "  d-flex justify-content-center py-4"
                  }`}
                >
                  <div className="memberimageshape">
                    <img src={avatar} alt="logo" />
                  </div>
                </th>
                <th className={`${isMobileOnly ? "d-none " : " "}`}>
                  {t("inventory.Recieved By")}
                </th>
                <th className={`${isMobileOnly ? "  d-none" : " "}`}>
                  {t("inventory.Billboard ID")}
                </th>
                {!pathname?.includes("materialRequest") && (
                  <th className={`${isMobileOnly ? "  d-none" : " "}`}>
                    {t("cleaning.Created By")}
                  </th>
                )}
                <th className={`${isMobileOnly ? "  py-3" : " "}`}>
                  {t("inventory.Material Id")}
                </th>
                <th className={`${isMobileOnly ? "d-none " : " "}`}>
                  {t("CreateBillBoardForm.City")}
                </th>
                <th className={`${isMobileOnly ? "d-none " : " "}`}>
                  {t("filter.status")}
                </th>
                {(userContext?.roleAccess["INVENTORY"].includes("UPDATE") ||
                  userContext?.roleAccess["INVENTORY"].includes("READ") ||
                  userContext?.roleAccess["INVENTORY"].includes("DELETE")) && (
                  <th className={`${isMobileOnly ? "  " : " "}`}>
                    {t("memberTable.action")}
                  </th>
                )}
              </tr>
            </thead>
            {context?.materialToTechnicians &&
              context?.materialToTechnicians?.length > 0 && (
                <tbody className="table_white">
                  {context?.materialToTechnicians.map(
                    (materialToTechnician: any, index: number) => {
                      return (
                        <tr
                          key={materialToTechnician._id}
                          className="table_white"
                        >
                          <td
                            className={`${
                              isMobile
                                ? ""
                                : "  d-flex justify-content-center py-4"
                            }`}
                          >
                            <div className=" position-relative memberimageshape">
                              {materialToTechnician?.requested_by?.avatar !==
                                null &&
                              materialToTechnician?.requested_by?.avatar !==
                                undefined ? (
                                <>
                                  <img
                                    src={
                                      materialToTechnician?.requested_by?.avatar
                                    }
                                    alt="logo"
                                    width={50}
                                  />
                                </>
                              ) : (
                                <>
                                  <CommonAvatar
                                    name={materialToTechnician?.requested_by?.name?.toUpperCase()}
                                    size={(isMobileOnly ? 50 : 50).toString()}
                                    style={{
                                      backgroundColor: "#6b5ca4",
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </td>
                          <td
                            style={{ paddingInlineStart: "1.5rem" }}
                            className={`${
                              isMobileOnly ? "  d-none" : " py-4 "
                            }`}
                          >
                            {materialToTechnician.requested_by?.name || "-"}
                          </td>
                          <td
                            className={`${
                              isMobileOnly ? "  d-none" : " py-4 "
                            }`}
                          >
                            {materialToTechnician?.billboard_id || "-"}
                          </td>

                          {!pathname?.includes("materialRequest") && (
                            <td className={`${isMobileOnly ? "d-none " : " "}`}>
                              {materialToTechnician?.created_by?.name || "-"}
                            </td>
                          )}
                          <td className={`${isMobileOnly ? "d-none " : " "}`}>
                            {materialToTechnician?.material_id || "-"}
                          </td>
                          <td className={`${isMobileOnly ? "d-none " : " "}`}>
                            {materialToTechnician?.city || "-"}
                          </td>
                          <td className={`${isMobileOnly ? "d-none " : " "}`}>
                            {materialToTechnician?.status || "-"}
                          </td>
                          {(userContext?.roleAccess["INVENTORY"].includes(
                            "UPDATE"
                          ) ||
                            userContext?.roleAccess["INVENTORY"].includes(
                              "READ"
                            ) ||
                            userContext?.roleAccess["INVENTORY"].includes(
                              "DELETE"
                            )) && (
                            <td className={`${isMobileOnly ? " " : " "}`}>
                              <div className="pt-2 Actions">
                                <Dropdown className="Actions_title">
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basicActions"
                                  >
                                    <BsThreeDots fontSize={24} />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="create-dropdown-css">
                                    {userContext?.roleAccess[
                                      "INVENTORY"
                                    ].includes("UPDATE") &&
                                      !pathname?.includes(
                                        "materialToTechnician"
                                      ) &&
                                      materialToTechnician?.status !==
                                        "DELIVERED" && (
                                        <Dropdown.Item
                                          className="sort-by"
                                          onClick={() => {
                                            navigate(
                                              pathname?.includes(
                                                "materialRequest"
                                              )
                                                ? routes.getInventoryEditMaterialRequest(
                                                    materialToTechnician._id
                                                  )
                                                : routes.getInventoryEditMaterialToTechnician(
                                                    materialToTechnician._id
                                                  )
                                            );
                                          }}
                                        >
                                          {t("inventory.Edit")}
                                        </Dropdown.Item>
                                      )}
                                    {userContext?.roleAccess[
                                      "INVENTORY"
                                    ].includes("READ") && (
                                      <Dropdown.Item
                                        className="sort-by"
                                        onClick={() => {
                                          navigate(
                                            pathname?.includes(
                                              "materialRequest"
                                            )
                                              ? routes.getInventoryViewMaterialRequest(
                                                  materialToTechnician._id
                                                )
                                              : routes.getInventoryViewMaterialToTechnician(
                                                  materialToTechnician._id
                                                )
                                          );
                                        }}
                                      >
                                        {t("inventory.View")}
                                      </Dropdown.Item>
                                    )}
                                    {userContext?.roleAccess[
                                      "INVENTORY"
                                    ].includes("DELETE") &&
                                      materialToTechnician?.status !==
                                        "DELIVERED" && (
                                        <Dropdown.Item
                                          className="sort-by"
                                          onClick={() => {
                                            setCurrentMaterialToTechnicianId(
                                              materialToTechnician?._id
                                            );
                                            setDeletePopUp(true);
                                          }}
                                        >
                                          {t("inventory.Delete")}
                                        </Dropdown.Item>
                                      )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    }
                  )}
                </tbody>
              )}
          </Table>
          {!context?.materialToTechnicians?.length && (
            <div className=" d-flex justify-content-center align-items-center">
              {t(
                pathname?.includes("materialRequest")
                  ? "inventory.No Request Found"
                  : "inventory.No Technician Found"
              )}
            </div>
          )}
        </div>
        {context?.meta &&
          context?.materialToTechnicians &&
          context?.materialToTechnicians?.length > 0 && (
            <Pagination
              meta={context?.meta}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              currentPageCount={context?.materialToTechnicians?.length}
              component={"Buyer"}
            />
          )}
      </div>
      <ToastPopup
        trigger={context?.isLoading}
        setTrigger={context?.setIsLoading}
      >
        <SuccessErrorToast isLoading={true} />
      </ToastPopup>
      <ToastPopup trigger={timedPopup} setTrigger={setTimedPopup}>
        <SuccessErrorToast
          bodyMessage={bodyMessage}
          isSuccess={isSuccess}
          // okButton={() => isSuccess ? loginNow() : closeErrorPopUp()}
          // buttonName='Login Now!'
          footButton={false}
        />
      </ToastPopup>
      <ToastPopupSecond trigger={deletePopUp} setTrigger={setDeletePopUp}>
        <DeleteBlockToast
          setTrigger={(status: boolean) => {
            loadData();
            setDeletePopUp(status);
          }}
          closePopUp={(data: any) => setDeletePopUp(data)}
          submit={() => {
            setDeletePopUp(false);
            deleteMaterialToTechnician();
          }}
          textMessage={`${t(
            pathname?.includes("materialRequest")
              ? "inventory.delete material Request to Request"
              : "inventory.delete material issued to technician"
          )}`}
          headerMessage={`${t(
            pathname?.includes("materialRequest")
              ? "inventory.delete material Request to Request"
              : "inventory.delete material issued to technician"
          )}`}
        />
      </ToastPopupSecond>
      <div className={`${isMobileOnly ? " position-relative" : "d-none"}`}>
        <div className="sortfilterMobile row m-0">
          <CommonSort
            sort={sort}
            opensort={opensort}
            opensortClick={sortByOpen}
            setCurrentPage={setCurrentPage}
            setSort={setSort}
            isSortMobile
          />

          <div className="col p-0 d-flex justify-content-evenly align-items-center ">
            <CommonFilter
              isFilterMobile
              openfilterClick={filterByOpen}
              openfilter={openfilter}
              resetClick={resetFilter}
              filterClose={filterByOpen}
              filterApply={applyFilter}
            >
              <div></div>
            </CommonFilter>
          </div>
        </div>
      </div>
      <DownloadPopUp show={show} setShow={setShow} download={download} />
    </div>
  );
};

export default MaterialToTechnician;
