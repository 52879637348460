import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "../../App";
import { routes } from "../../constants/routes";
import { AuthenticationContextProp } from "../../context/AuthenticationProvider";
import { InventeryProvider } from "../../context/InventeryProvider";
import ListInventory from "./ListInventory";
import CreateNewMaterialToTechnician from "./MaterialToTechnician/CreateNewMaterialToTechnician";
import CreateNewPurchase from "./Purchase/CreateNewPurchase";
import CreateNewReplaceToSupplier from "./ReplaceSupplier/CreateNewReplaceToSupplier";
import Reports from "./Reports/reports";
import CreateNewReturnSupplier from "./ReturnSupplier/CreateNewReturnSupplier";
import CreateNewReturnTechnician from "./ReturnTechnician/CreateNewReturnTechnician";
import { AddStockAdjustment } from "./StockAdjestment/AddStockAdjestment";

const InventeryPages = ({
  userContext,
}: {
  userContext: AuthenticationContextProp | null;
}) => {
  return (
    userContext?.roleAccess && (
      <InventeryProvider>
        <Routes>
          {/* ----------------------------------------------------------Purchaases---------------------------------- */}
          <Route
            path={routes.inventoryPurchase}
            element={
              <RequireAuth>
                <ListInventory userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.createInventoryPurchase}
            element={
              <RequireAuth>
                <CreateNewPurchase userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.inventoryEditPurchase}
            element={
              <RequireAuth>
                <CreateNewPurchase userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.inventoryViewPurchase}
            element={
              <RequireAuth>
                <CreateNewPurchase userContext={userContext} />
              </RequireAuth>
            }
          />
          {/* ----------------------------------------------------------Purchaases---------------------------------- */}
          {/* ----------------------------------------------------------MaterialToTechnician---------------------------------- */}
          <Route
            path={routes.inventoryMaterialToTechnician}
            element={
              <RequireAuth>
                <ListInventory userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.createInventoryMaterialToTechnician}
            element={
              <RequireAuth>
                <CreateNewMaterialToTechnician userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.viewInventoryMaterialToTechnician}
            element={
              <RequireAuth>
                <CreateNewMaterialToTechnician userContext={userContext} />
              </RequireAuth>
            }
          />{" "}
          <Route
            path={routes.editInventoryMaterialToTechnician}
            element={
              <RequireAuth>
                <CreateNewMaterialToTechnician userContext={userContext} />
              </RequireAuth>
            }
          />
          {/* ----------------------------------------------------------MaterialToTechnician---------------------------------- */}
          {/* ----------------------------------------------------------MaterialRequest---------------------------------- */}
          <Route
            path={routes.inventoryMaterialRequest}
            element={
              <RequireAuth>
                <ListInventory userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.createInventoryMaterialRequest}
            element={
              <RequireAuth>
                <CreateNewMaterialToTechnician userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.viewInventoryMaterialRequest}
            element={
              <RequireAuth>
                <CreateNewMaterialToTechnician userContext={userContext} />
              </RequireAuth>
            }
          />{" "}
          <Route
            path={routes.editInventoryMaterialRequest}
            element={
              <RequireAuth>
                <CreateNewMaterialToTechnician userContext={userContext} />
              </RequireAuth>
            }
          />
          {/* ----------------------------------------------------------MaterialRequest---------------------------------- */}
          {/* ----------------------------------------------------------ReturnMaterialFromTechnician---------------------------------- */}
          <Route
            path={routes.inventoryReturnMaterialFromTechnician}
            element={
              <RequireAuth>
                <ListInventory userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.createInventoryReturnMaterialFromTechnician}
            element={
              <RequireAuth>
                <CreateNewReturnTechnician userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.editInventoryReturnMaterialFromTechnician}
            element={
              <RequireAuth>
                <CreateNewReturnTechnician userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.viewInventoryReturnMaterialFromTechnician}
            element={
              <RequireAuth>
                <CreateNewReturnTechnician userContext={userContext} />
              </RequireAuth>
            }
          />
          {/* ----------------------------------------------------------ReturnMaterialFromTechnician---------------------------------- */}
          {/* ----------------------------------------------------------ReturnMaterialToSupplier---------------------------------- */}
          <Route
            path={routes.inventoryReturnMaterialToSupplier}
            element={
              <RequireAuth>
                <ListInventory userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.createInventoryReturnMaterialToSupplier}
            element={
              <RequireAuth>
                <CreateNewReturnSupplier userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.viewInventoryReturnMaterialToSupplier}
            element={
              <RequireAuth>
                <CreateNewReturnSupplier userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.editInventoryReturnMaterialToSupplier}
            element={
              <RequireAuth>
                <CreateNewReturnSupplier userContext={userContext} />
              </RequireAuth>
            }
          />
          {/* ----------------------------------------------------------ReturnMaterialToSupplier---------------------------------- */}
          {/* ----------------------------------------------------------replaceMaterialFromSupplier---------------------------------- */}
          <Route
            path={routes.inventoryreplaceMaterialFromSupplier}
            element={
              <RequireAuth>
                <ListInventory userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.createInventoryreplaceMaterialFromSupplier}
            element={
              <RequireAuth>
                <CreateNewReplaceToSupplier userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.viewInventoryreplaceMaterialFromSupplier}
            element={
              <RequireAuth>
                <CreateNewReplaceToSupplier userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.editInventoryreplaceMaterialFromSupplier}
            element={
              <RequireAuth>
                <CreateNewReplaceToSupplier userContext={userContext} />
              </RequireAuth>
            }
          />
          {/* ----------------------------------------------------------replaceMaterialFromSupplier---------------------------------- */}
          <Route
            path={routes.report}
            element={
              <RequireAuth>
                <Reports />
              </RequireAuth>
            }
          />
          <Route
            path={routes.stockAdjustment}
            element={
              <RequireAuth>
                <ListInventory userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.stockAdjustmentAdd}
            element={
              <RequireAuth>
                <AddStockAdjustment />
              </RequireAuth>
            }
          />
        </Routes>
      </InventeryProvider>
    )
  );
};

export default InventeryPages;
