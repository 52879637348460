import { isDesktop, isMobile, isMobileOnly } from "react-device-detect";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import { routes } from "../../constants/routes";
import { Button, Form, FormGroup, Spinner } from "react-bootstrap";
import edit from "../../assets/edit.png";
import DeleteBlockToast from "../../components/PopupToast/DeleteBlockToast";
import ToastPopupSecond from "../../components/PopupToast/ToastPopupSecond";
import ToastPopup from "../../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../../components/PopupToast/SuccessErrorToast";
import Modal from "../../components/PopupToast/Modal";

import { Col, Container } from "react-bootstrap";

import x from "../../assets/x.png";
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { BiErrorCircle } from "react-icons/bi";

let timedPopupSetTimeOut: any = undefined;
const TicketTable = ({ context, userContext }: any) => {
  const { t, i18n } = useTranslation();
  const urlParams = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const [textMessage, setTextMessage] = useState<any>("");
  const [conformationPopUp, setConformationPopUp] = useState(false);
  const [bodyMessage, setBodyMessage] = useState("");
  const [timedPopup, setTimedPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [headerMessage, setHeaderMessage] = useState<any>("");

  const [status, setStatus] = useState<any>(
    queryParams.get("status") ? queryParams.get("status") : "CREATED"
  );

  const [buttonPopup, setButtonPopup] = useState(false);

  const formik = useFormik({
    initialValues: {
      reject_reason: "",
    },
    validationSchema: Yup.object({
      reject_reason: Yup.string().required(`Reject reason is required`),
    }),
    onSubmit: async (values) => {
      formik.values.reject_reason = "";
      setButtonPopup(false);
      setConformationPopUp(true);
      setTextMessage(t("successError.reject it"));
      setHeaderMessage(t("successError.reject the selected tickets"));
    },
  });

  useEffect(() => {
    if (
      urlParams?.cleaningId &&
      !window.location.pathname.includes(
        `/cleaning/${urlParams?.cleaningId}/tickets`
      )
    ) {
      loadData();
    }
  }, [status]);

  const loadData = async () => {
    if (urlParams?.cleaningId) {
      await context?.loadCleaningTickets(urlParams?.cleaningId, { status });
      navigate(routes.editCleaningUrl(urlParams?.cleaningId, { status }));
    }
  };

  useEffect(() => {
    if (urlParams?.cleaningId && !urlParams?.ticketId) {
      // Reset selected ticket items when the 'status' query parameter changes
      setSelectedTicketItems([]);
      // Reset selected all checkbox when the 'status' query parameter changes
      setSelectAll(false);
    }
  }, [location.search]);
  // checkbox
  const [selectedTicketItems, setSelectedTicketItems] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false); // State for "Select All" checkbox

  const handleCheckboxClick = (ticketItemId: string) => {
    const isSelected = selectedTicketItems.includes(ticketItemId);

    if (isSelected) {
      setSelectedTicketItems((prevSelected) =>
        prevSelected.filter((item) => item !== ticketItemId)
      );
    } else {
      setSelectedTicketItems((prevSelected) => [...prevSelected, ticketItemId]);
    }

    // Check if all individual checkboxes are selected or not
    const allSelected = context?.cleaningTickets?.every((item: any) =>
      selectedTicketItems.includes(item.id)
    );

    setSelectAll(allSelected);
  };
  const handleSelectAll = () => {
    if (!selectAll) {
      const allTicketItemIds = context?.cleaningTickets?.map(
        (item: any) => item.id
      );
      setSelectedTicketItems(allTicketItemIds);
    } else {
      setSelectedTicketItems([]);
    }

    setSelectAll(!selectAll);
  };

  const ticketActions = async (
    action: string,
    idList: any,
    rejected_reason?: string
  ) => {
    const { success, error } = await context?.ticketActions(
      action,
      idList,
      rejected_reason
    );

    setIsSuccess(success);
    if (success) {
      setTimedPopup(true);
      timeOutPopUp(true);
      setBodyMessage(
        action === "VERIFIED"
          ? `${t("successError.verified the ticket successfully")}`
          : action === "APPROVED"
          ? `${t("successError.approved the ticket successfully")}`
          : `${t("successError.rejected the ticket successfully")}`
      );
    } else {
      setTimedPopup(true);
      timeOutPopUp(false);
      setBodyMessage(error);
    }
  };

  const timeOutPopUp = (value: any) => {
    timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        setBodyMessage("");
        setTimedPopup(false);
        if (
          headerMessage !== t("successError.cancel the changes") &&
          !location.pathname.includes("/tickets") &&
          location.pathname.includes("/cleaning")
        ) {
          loadData();
        } else {
          setTimedPopup(false);
        }
      } else {
        setBodyMessage("");
        setTimedPopup(false);
      }
    }, 2000);
  };

  return (
    <>
      <div
        className="p-md-5"
        style={!isDesktop ? {} : { background: "#FAFAFA", height: "100%" }}
      >
        {/* ticket status scrollbar */}
        <div
          className={`${isMobile ? "container-fluid p-0 " : "container p-0"}  `}
        >
          <div
            className={`${isMobile ? " overflow-scroll" : " overflow-scroll"}`}
          >
            <ul className="d-flex nestedul">
              <li className=" m-0">
                <span
                  className={`edit_link_routes ${
                    queryParams.get("status") === "CREATED" && "active"
                  }  `}
                  onClick={() => setStatus("CREATED")}
                >
                  {t("CREATED")}
                </span>
              </li>
              <li className="mx-4">
                <span
                  className={`edit_link_routes ${
                    queryParams.get("status") === "VIEWED" && "active"
                  }  `}
                  onClick={() => setStatus("VIEWED")}
                >
                  {t("VIEWED")}
                </span>
              </li>
              <li className="mx-4">
                <span
                  className={`edit_link_routes ${
                    queryParams.get("status") === "OVERDUE" && "active"
                  }  `}
                  onClick={() => setStatus("OVERDUE")}
                >
                  {t("Overdue")}
                </span>
              </li>

              <li className="mx-4">
                <span
                  className={`edit_link_routes ${
                    queryParams.get("status") === "SUBMITTED" && "active"
                  }  `}
                  onClick={() => setStatus("SUBMITTED")}
                >
                  {t("SUBMITTED")}
                </span>
              </li>

              <li className=" m-0">
                <span
                  className={`edit_link_routes ${
                    queryParams.get("status") === "VERIFIED" && "active"
                  }  `}
                  onClick={() => setStatus("VERIFIED")}
                >
                  {t("VERIFIED")}
                </span>
              </li>
              <li className="mx-4">
                <span
                  className={`edit_link_routes ${
                    queryParams.get("status") === "REASSIGNED" && "active"
                  }  `}
                  onClick={() => setStatus("REASSIGNED")}
                >
                  {t("REASSIGNED")}
                </span>
              </li>

              <li className="mx-4">
                <span
                  className={`edit_link_routes ${
                    queryParams.get("status") === "CLOSED" && "active"
                  }  `}
                  onClick={() => setStatus("CLOSED")}
                >
                  {t("CLOSED")}
                </span>
              </li>
            </ul>
          </div>
        </div>

        {/* tickets table */}

        <div className=" pt-1 table-responsive">
          <Table responsive="sm table_class">
            <thead>
              <tr className="table_white_head">
                {urlParams?.cleaningId &&
                  (userContext?.roleAccess["TICKETS"].includes("VERIFY") ||
                    userContext?.roleAccess["TICKETS"].includes("REASSIGN") ||
                    userContext?.roleAccess["TICKETS"].includes(
                      "APPROVE/REJECT"
                    )) &&
                  (queryParams.get("status") === "SUBMITTED" ||
                    queryParams.get("status") === "VERIFIED") && (
                    <th className={`${isMobileOnly ? "py-3 " : " py-3"}`}>
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onClick={handleSelectAll}
                      />
                    </th>
                  )}
                <th className={`${isMobileOnly ? "py-3 " : " py-3"}`}>
                  {t("cleaning.Billboard Name")}
                </th>

                <th className={`${isMobileOnly ? " py-3" : " "}`}>
                  {t("cleaning.Ticket Number")}
                </th>

                <th className={`${isMobile ? "d-none " : " "}`}>
                  {t("cleaning.Location")}
                </th>
                <th className={`${isMobile ? "d-none " : " "}`}>
                  {t("cleaning.Updated By")}
                </th>
                <th className={`${isMobile ? "d-none" : " "}`}>
                  {t("cleaning.Updated On")}
                </th>
                {urlParams?.cleaningId &&
                  queryParams.get("status") === "CLOSED" && (
                    <th className={`${isMobileOnly ? "py-3 " : " py-3"}`}>
                      Status
                    </th>
                  )}
                <th
                  className={`${
                    isMobileOnly ? "  d-none" : "py-2 text-center"
                  }`}
                >
                  {t("cleaning.Action")}
                </th>
              </tr>
            </thead>
            {context?.cleaningTickets &&
              context?.cleaningTickets?.length > 0 && (
                <tbody className="table_white ">
                  {context?.cleaningTickets?.map(
                    (ticketItem: any, index: number) => {
                      return (
                        <tr
                          key={ticketItem.id || ticketItem._id}
                          className="table_white"
                        >
                          {urlParams?.cleaningId &&
                            (userContext?.roleAccess["TICKETS"].includes(
                              "VERIFY"
                            ) ||
                              userContext?.roleAccess["TICKETS"].includes(
                                "REASSIGN"
                              ) ||
                              userContext?.roleAccess["TICKETS"].includes(
                                "APPROVE/REJECT"
                              )) &&
                            (queryParams.get("status") === "SUBMITTED" ||
                              queryParams.get("status") === "VERIFIED") && (
                              <td>
                                <input
                                  type="checkbox"
                                  checked={selectedTicketItems.includes(
                                    ticketItem.id
                                  )}
                                  onClick={() =>
                                    handleCheckboxClick(ticketItem.id)
                                  }
                                />
                              </td>
                            )}

                          <td className={`${isMobile ? "" : ""} fw-normal `}>
                            {ticketItem.bill_board_id?.board_no || "-"}
                          </td>
                          <td
                            className={`${
                              isMobile
                                ? " py-4 d-flex justify-content-between align-items-center"
                                : " "
                            } fw-normal `}
                          >
                            <div>{ticketItem.ticket_no || "-"}</div>
                          </td>
                          <td
                            className={`${
                              isMobile ? "d-none " : " "
                            } fw-normal `}
                          >
                            <p className="m-0">
                              {ticketItem.bill_board_id?.board_location.substring(
                                0,
                                30
                              ) + "..." || "-"}
                            </p>
                          </td>

                          <td
                            className={`${
                              isMobileOnly ? "d-none " : " "
                            } fw-normal`}
                          >
                            {ticketItem.created_by.name || "-"}
                          </td>

                          <td
                            className={`${
                              isMobileOnly ? "d-none " : " "
                            } fw-normal`}
                          >
                            <div>
                              <Moment
                                format="DD/MM/yyyy"
                                style={{ color: "#202020" }}
                              >
                                {ticketItem.updatedAt || "-"}
                              </Moment>
                            </div>
                          </td>
                          {urlParams?.cleaningId &&
                            queryParams.get("status") === "CLOSED" && (
                              <td
                                className={`${
                                  isMobileOnly ? "py-3 " : " py-3"
                                }`}
                              >
                                <p
                                  className={
                                    ticketItem?.approved_by_manager
                                      ? "m-0 fw-semibold status"
                                      : "m-0 fw-semibold inActivestatus"
                                  }
                                  style={{ width: "124px" }}
                                >
                                  {ticketItem?.rejected_at?.length > 0
                                    ? "REJECTED"
                                    : "APPROVED"}
                                </p>
                              </td>
                            )}
                          <td className={`${isMobileOnly ? "d-none" : ""}`}>
                            {userContext?.roleAccess["CLEANING"].includes(
                              "UPDATE"
                            ) && (
                              <div
                                className=" d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  context?.removeTicket(null);
                                  navigate(
                                    routes.getEditTicketUrl(
                                      ticketItem?.id || ticketItem?._id,
                                      {
                                        status,
                                        id: urlParams?.cleaningId,
                                        name: "cleaning",
                                      }
                                    )
                                  );
                                  window.location.reload();
                                }}
                              >
                                <img
                                  src={edit}
                                  alt=""
                                  width={22}
                                  className="pointer"
                                />
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              )}
          </Table>
          {!context?.cleaningTickets?.length && (
            <div className=" d-flex justify-content-center align-items-center">
              <div>{t("cleaning.No Data")}</div>
            </div>
          )}
        </div>

        {/* ticket list buttons */}
        {urlParams?.cleaningId &&
          userContext?.roleAccess["TICKETS"].includes("VERIFY") &&
          queryParams.get("status") === "SUBMITTED" && (
            <div
              className={`${
                isMobileOnly
                  ? "d-flex justify-content-end py-5"
                  : "d-flex justify-content-end py-5"
              }`}
            >
              {context?.cleaningTickets.length > 0 && (
                <Button
                  onClick={() => {
                    setConformationPopUp(true);
                    setTextMessage(t("successError.verify it"));
                    setHeaderMessage(
                      t("successError.verify the selected tickets")
                    );
                  }}
                  className=" btn_update rounded-1 d-flex justify-content-center align-items-center"
                  disabled={!(selectedTicketItems.length > 0)}
                >
                  <span className="fw-semibold">
                    {t("CreateBillBoardForm.verifyticket")}
                  </span>
                </Button>
              )}
            </div>
          )}

        {urlParams?.cleaningId &&
          userContext?.roleAccess["TICKETS"].includes("APPROVE/REJECT") &&
          queryParams.get("status") === "VERIFIED" && (
            <div
              className={`${
                isMobileOnly
                  ? "d-flex justify-content-end py-5"
                  : "d-flex justify-content-end py-5"
              }`}
            >
              {context?.cleaningTickets.length > 0 && (
                <>
                  <Button
                    onClick={() => {
                      setButtonPopup(true);
                    }}
                    className={`${
                      isMobileOnly
                        ? " btn_reject rounded-1 d-flex justify-content-center mx-3 align-items-center"
                        : " mx-3 btn_reject rounded-1 d-flex justify-content-center align-items-center"
                    }`}
                    disabled={!(selectedTicketItems.length > 0)}
                  >
                    <span className="fw-semibold">
                      {t("CreateBillBoardForm.reject")}
                    </span>
                  </Button>
                  <Button
                    onClick={() => {
                      setConformationPopUp(true);
                      setTextMessage(t("successError.approve it"));
                      setHeaderMessage(
                        t("successError.approve the selected tickets")
                      );
                    }}
                    className=" btn_update rounded-1 d-flex justify-content-center align-items-center"
                    disabled={!(selectedTicketItems.length > 0)}
                  >
                    <span className="fw-semibold">
                      {t("CreateBillBoardForm.approvedticket")}
                    </span>
                  </Button>
                </>
              )}
            </div>
          )}
      </div>
      <ToastPopup trigger={timedPopup} setTrigger={setTimedPopup}>
        <SuccessErrorToast
          bodyMessage={bodyMessage}
          isSuccess={isSuccess}
          okButton={() => setTimedPopup(false)}
          buttonName="Close"
        />
      </ToastPopup>

      <ToastPopupSecond
        trigger={conformationPopUp}
        setTrigger={setConformationPopUp}
      >
        <DeleteBlockToast
          closePopUp={(data: any) => setConformationPopUp(data)}
          submit={() => {
            setConformationPopUp(false);

            if (
              headerMessage === t("successError.verify the selected tickets")
            ) {
              ticketActions("VERIFIED", selectedTicketItems);
            } else if (
              headerMessage === t("successError.approve the selected tickets")
            ) {
              ticketActions("APPROVED", selectedTicketItems);
            } else if (
              headerMessage === t("successError.reject the selected tickets")
            ) {
              ticketActions(
                "REJECTED",
                selectedTicketItems,
                formik.values.reject_reason
              );
            }
          }}
          headerMessage={headerMessage}
          textMessage={textMessage}
        />
      </ToastPopupSecond>

      <Modal trigger={buttonPopup}>
        <Container>
          <Col>
            <div className="d-flex align-items-center justify-content-between">
              <h4 className={`${isMobile ? " inmember" : " inmember"}`}>
                {t("cleaning.Reject Reason")}
              </h4>
              <div onClick={() => setButtonPopup(false)} className=" py-3">
                <img src={x} alt="" width={24} className="pointer" />
              </div>
            </div>
            <FormikProvider value={formik}>
              <Form
                validated={true}
                onSubmit={formik.handleSubmit}
                className="floating"
              >
                <FormGroup className="my-4 position-relative">
                  <label>{t("cleaning.Enter Reason")}</label>
                  <div>
                    <Field
                      name="reject_reason"
                      type="text"
                      autoFocus
                      className={"form-control"}
                      maxLength={150}
                      id={i18n.language === "en" ? "" : "ArabicDate"}
                    />
                  </div>
                  {formik?.errors?.reject_reason && (
                    <div
                      className={
                        i18n.language === "en" ? "eyeicon" : "eyeicon-right"
                      }
                    >
                      <BiErrorCircle color="#db2c59" />
                    </div>
                  )}
                  <small className="text-danger">
                    <ErrorMessage name="reject_reason" />
                  </small>
                </FormGroup>
                <Button
                  type="submit"
                  variant="primary"
                  className="my-4 mx-0 py-2 float-end rounded-1 btn btn_invite_member "
                  disabled={
                    formik.isSubmitting || !formik.isValid || !formik.dirty
                  }
                >
                  <span
                    className={` ${!formik.isValid ? "" : "fw-semibold  "}`}
                  >
                    {t("cleaning.Submit")}
                  </span>
                </Button>
              </Form>
            </FormikProvider>
          </Col>
        </Container>
      </Modal>
    </>
  );
};

export default TicketTable;
