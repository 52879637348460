import { useEffect, useRef, useState } from "react";
import ChatReceived from "./ChatReceived";
import ChatUserName from "./ChatUserName";
import ChatSent from "./ChatSent";
import MessageBox from "./MessageBox";
import Waveform from "../../../components/Audiofile/Waveform";
import { AiOutlineInfoCircle, AiOutlineSearch } from "react-icons/ai";
import { isMobileOnly } from "react-device-detect";
import { TiDelete } from "react-icons/ti";
import { useMessageChat } from "../messageContext";
// import InfiniteScroll from "react-infinite-scroll-component";
import ToastPopup from "../../../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../../../components/PopupToast/SuccessErrorToast";
import { useTranslation } from "react-i18next";
import VideoPlayer from "react-video-player-extended";
import moment from "moment";

const RightChatBox = ({
  userContext,
  setShow,
  setShowGroupInfo,
  setShowConversation,
  currentGroupId,
  getForwardList,
  setCurrentImage,
  setTimedPopup,
  setImageShow,
  dataSent,
  setIsFooterButton,
  setBodyMessage,
  chatContext,
  setHideChat,
  hideChat,
}: any) => {
  const { t, i18n } = useTranslation();
  const context = useMessageChat();
  const messageRef = useRef<any>(null);
  const [currentAttachmentVideo, setCurrentAttachmentVideo] = useState<any>({});

  useEffect(() => {
    context?.setConversationId(chatContext?.currentConversationId);
  }, [chatContext?.currentConversationId]);

  const getCheckBoxValues = (event: any) => {
    let newArray = [...chatContext?.checkBoxValue, event.target.id];
    if (chatContext?.checkBoxValue.includes(event.target.id)) {
      newArray = newArray.filter((day) => day !== event.target.id);
    }
    if (30 >= newArray?.length) {
      chatContext?.setCheckBoxValue(newArray);
    } else {
      setIsFooterButton(true);
      setBodyMessage("You can only share with up to 30 Message");
      setTimedPopup(true);
    }
  };

  useEffect(() => {
    messageRef?.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [context?.conversation]);

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        chatContext?.setAction("");
        chatContext?.setCheckBoxValue([]);
      }
    };
    window.addEventListener("keyup", close);
    return () => window.removeEventListener("keyup", close);
  }, []);
  let result: any;
  if (context?.conversation?.length > 0) {
    let data = context?.conversation?.reduce((r: any, e: any) => {
      let group: any =
        moment(new Date()).format("dddd, MMMM D, YYYY") ===
        moment(e?.createdAt).format("dddd, MMMM D, YYYY")
          ? "Today"
          : moment(new Date())
              .subtract(1, "day")
              .format("dddd, MMMM D, YYYY") ===
            moment(e?.createdAt).format("dddd, MMMM D, YYYY")
          ? "Yesterday"
          : moment(e?.createdAt).format("D MMMM, YYYY");
      if (!r[group]) r[group] = { group, children: [e] };
      else r[group].children.push(e);
      return r;
    }, {});
    result = Object?.values(data);
  }

  return (
    <>
      <div
        className={
          i18n.language === "en"
            ? " chat chatline ps-0 pl-md-1"
            : "chatline_Arabic ps-0 pl-md-1"
        }
        style={{ height: "100%", backgroundColor: "#fefefe" }}
      >
        <div className="container-fluid p-0" style={{ height: "100%" }}>
          <div
            className="chat__wrapper py-2 pt-mb-2 pb-md-3 position-relative"
            style={{ height: "100%" }}
          >
            <div className="chat__messaging container-fluid messaging-member--online pb-2 pb-md-2 pl-2 pl-md-4 pr-2 ">
              <div className="chat__previous d-flex d-none ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="svg-icon svg-icon--previous"
                  viewBox="0 0 45.5 30.4"
                >
                  <path
                    d="M43.5,13.1H7l9.7-9.6A2.1,2.1,0,1,0,13.8.6L.9,13.5h0L.3,14v.6c0,.1-.1.1-.1.2v.4a2,2,0,0,0,.6,1.5l.3.3L13.8,29.8a2.1,2.1,0,1,0,2.9-2.9L7,17.2H43.5a2,2,0,0,0,2-2A2.1,2.1,0,0,0,43.5,13.1Z"
                    fill="#f68b3c"
                  />
                </svg>
              </div>
              <div className="chat__notification d-flex  chat__notification--new d-none">
                <span>1</span>
              </div>
              {/* <div className="d-flex justify-content-between align-items-center"></div> */}
              <div className="chat__infos pl-2 pl-md-0">
                <ChatUserName
                  context={context}
                  userContext={userContext}
                  chatContext={chatContext}
                  setHideChat={setHideChat}
                  hideChat={hideChat}
                />
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <div className="container-fluid p-0">
                  <div className="position-relative">
                    <div className="msg-lens position-absolute">
                      <AiOutlineSearch color="#2a2b2f" />
                    </div>
                    <div className="">
                      <input
                        id="doc_searchQueryChat"
                        type="text"
                        name="doc_searchQueryChat"
                        placeholder={t("chat.Search Messages") || ""}
                        maxLength={50}
                        onChange={(e: any) => {
                          context?.setSearchMessage(e?.target?.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {currentGroupId && (
                  <div
                    className="info mx-2 pointer"
                    onClick={() => {
                      setShowGroupInfo(true);
                      setShowConversation(false);
                      context?.setConversationId("");
                      chatContext?.setCurrentConversationId("");
                    }}
                  >
                    <AiOutlineInfoCircle color="#b1b1b1" fontSize={24} />
                  </div>
                )}
              </div>
            </div>
            <div
              className="chat__content pt-4 px-3 mx-2"
              style={isMobileOnly ? { height: "vh" } : { height: "70vh" }}
            >
              <ul className="chat__list-messages">
                {context?.conversation?.length > 0 && (
                  <>
                    {context?.conversation?.length > 0 &&
                      !(context?.metaData?.total_count <= context?.limit) && (
                        <div
                          className="d-flex justify-content-center membertitle"
                          onClick={() => {
                            context?.setIsLoadMore(true);
                            context?.setLimit(context?.limit + 50);
                          }}
                        >
                          {t("chat.View more")}
                        </div>
                      )}
                    {result?.map((groupValue: any, index: any) => {
                      return (
                        <>
                          <div className="d-flex justify-content-center pt-4 pb-4">
                            <span className="date_center px-3 py-2">
                              {groupValue?.group}
                            </span>
                          </div>
                          {groupValue?.children?.map((item: any) => {
                            return item?.from?.id === userContext?.user?.id ||
                              item?.from === userContext?.user?.id ? (
                              <ChatSent
                                userContext={userContext}
                                item={item}
                                index={index}
                                getCheckBoxValues={getCheckBoxValues}
                                getForwardList={getForwardList}
                                setCurrentImage={setCurrentImage}
                                setTimedPopup={setTimedPopup}
                                setImageShow={setImageShow}
                                context={context}
                                chatContext={chatContext}
                                currentAttachmentVideo={currentAttachmentVideo}
                                setCurrentAttachmentVideo={
                                  setCurrentAttachmentVideo
                                }
                              />
                            ) : (
                              <ChatReceived
                                userContext={userContext}
                                item={item}
                                index={index}
                                getCheckBoxValues={getCheckBoxValues}
                                getForwardList={getForwardList}
                                setCurrentImage={setCurrentImage}
                                setTimedPopup={setTimedPopup}
                                setImageShow={setImageShow}
                                context={context}
                                chatContext={chatContext}
                                currentAttachmentVideo={currentAttachmentVideo}
                                setCurrentAttachmentVideo={
                                  setCurrentAttachmentVideo
                                }
                              />
                            );
                          })}
                        </>
                      );
                    })}
                  </>
                )}
                {!context?.isLoadMore && <div ref={messageRef}></div>}
              </ul>
            </div>
            <div className=" chatRightBoxBottom">
              {chatContext?.action === "Reply" && (
                <div className="chat-reply-section d-flex align-items-center justify-content-between mx-4 my-1 p-2">
                  <div className=" flex-grow-1">
                    <div>
                      {context?.replyObject?.from?.id === userContext?.user?.id
                        ? "You"
                        : context?.replyObject?.from?.name}
                    </div>
                    <div className="reply replywd">
                      {context?.replyObject?.message}
                      {context?.replyObject?.attachment_type === "IMAGE" && (
                        <img
                          src={context?.replyObject?.attachment}
                          alt="img"
                          width={30}
                        />
                      )}
                      {context?.replyObject?.attachment_type === "VIDEO" && (
                        // <video width="50%" height="100" controls>
                        //   <source src={context?.replyObject?.attachment} />
                        //   Your browser does not support HTML5 video.
                        // </video>
                        <VideoPlayer
                          volume={currentAttachmentVideo?.volume}
                          url={context?.replyObject?.attachment}
                          isPlaying={
                            currentAttachmentVideo?.index ===
                            context?.replyObject?.id
                          }
                          onPlay={() =>
                            setCurrentAttachmentVideo({
                              index: context?.replyObject?.id,
                            })
                          }
                          onPause={() =>
                            setCurrentAttachmentVideo({
                              index: null,
                            })
                          }
                          onVolume={(value: any) =>
                            setCurrentAttachmentVideo({
                              index: context?.replyObject?.id,
                              volume: value,
                            })
                          }
                          height="150px"
                          width="150px"
                        />
                      )}
                      {context?.replyObject?.attachment_type === "AUDIO" && (
                        <div
                          className="sound sound-wave"
                          style={{ width: "280px" }}
                        >
                          {/* <Waveform audio={context?.base64Value} /> */}
                          <audio
                            src={context?.replyObject?.attachment}
                            controls
                          ></audio>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className=" fw-semibold pointer"
                    onClick={() => {
                      chatContext?.setAction("");
                      context?.setReplyObject({});
                    }}
                  >
                    <TiDelete color="db2c59" fontSize={24} />
                  </div>
                </div>
              )}

              <div className="chat__send-container px-2 px-md-3 pt-1 container-fluid">
                {(chatContext?.chatType === "GROUPS"
                  ? context?.metaData?.participants?.group_id?.group_member?.includes(
                      userContext?.user?.id
                    )
                  : true) && (
                  <MessageBox
                    context={context}
                    dataSent={dataSent}
                    userContext={userContext}
                    setShow={setShow}
                    chatContext={chatContext}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastPopup
        trigger={context?.isLoading}
        setTrigger={context?.setIsLoading}
      >
        <SuccessErrorToast isLoading={true} />
      </ToastPopup>
    </>
  );
};

export default RightChatBox;
