import AppLayout from "../../../layouts/AppLayout";
import { isMobile } from "react-device-detect";
import { MdArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import { routes } from "../../../constants/routes";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMasters } from "../../../context/MasterProider";
import NewBomForm from "./bomForm";

const CreateNewBom = ({ userContext, billboardContext }: any) => {
  const context = useMasters();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const urlParams = useParams();
  const pathName = window?.location?.pathname;

  return (
    <>
      <AppLayout>
        <div
          className="edit_members p-3 "
          style={
            isMobile
              ? { background: "#FAFAFA", height: "auto" }
              : { background: "#FAFAFA", minHeight: "80vh" }
          }
        >
          <div className="members py-4 mt-2 ">
            <h5
              className={`${
                isMobile ? "fs-6  mb-0 membertitle" : "  mb-0 membertitle"
              }`}
              onClick={() => navigate(routes.bomList)}
            >
              {t(`masters.BOM Masters`)}
            </h5>
            <>
              <span className={i18n.language === "en" ? "px-2 " : "d-none"}>
                <MdOutlineArrowForwardIos />
              </span>
              <span className={i18n.language === "en" ? "d-none" : "px-2"}>
                <MdArrowBackIos />
              </span>

              <h5
                className={`${
                  isMobile ? "fs-6 text-capitalize m-0  " : " m-0 "
                }`}
              >
                {}

                {urlParams?.bomId && pathName?.includes("/edit")
                  ? `${t("masters.Edit")} ${t("masters.BOM")}`
                  : pathName?.includes("/view")
                  ? `${t("masters.View")} ${t("masters.BOM")}`
                  : `${t("masters.New")} ${t("masters.BOM")}`}
              </h5>
            </>
          </div>

          {context && (
            <NewBomForm
              context={context}
              urlParams={urlParams}
              pathName={pathName}
              userContext={userContext}
              billboardContext={billboardContext}
            />
          )}
        </div>
      </AppLayout>
    </>
  );
};
export default CreateNewBom;
