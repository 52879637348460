import { Button } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { AiOutlineSearch } from "react-icons/ai";

const ChatSearch = ({
  setSearchKey,
  setShow,
  setSearchMember,
  searchKey,
  context,
  userContext,
  setCreateGroup,
  setCheckBoxMemberValue,
  setShowName,
}: any) => {
  let unReadCount = context?.conversationList?.reduce(
    (prev: any, crrnt: any) => prev + crrnt?.unread,
    0
  );
  const { t } = useTranslation();
  return (
    <>
      <div
        className="newchat-button d-flex justify-content-between   align-items-center mb-3"
        style={isMobile ? { height: "9vh" } : { height: "5vh" }}
      >
        <div className="message-text ">
          <h5 className="chat-head fw-bold">
            {context?.chatType === "CHATS"
              ? `${t("chat.People")}`
              : `${t("chat.Ticket")}`}
          </h5>
          {unReadCount > 0 && (
            <p className="unread-text fw-lighter">
              {t("chat.You have")} {unReadCount} {t("chat.unread messasges")}
            </p>
          )}
        </div>
        {context?.chatType === "CHATS" ? (
          <div className="newchat-button ">
            <Button
              className=" "
              onClick={() => {
                setShow(true);
                setCreateGroup(false);
                setSearchMember("");
              }}
            >
              {t("chat.New People")}
            </Button>
          </div>
        ) : context?.chatType === "GROUPS" &&
          userContext?.roleAccess["CHAT"].includes("CREATE_GROUP") ? (
          <div className="newchat-button ">
            <Button
              className=" "
              onClick={() => {
                setShow(true);
                setCreateGroup(true);
                setShowName(false);
                setSearchMember("");
                setCheckBoxMemberValue([]);
              }}
            >
              Create Group
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="container-fluid p-0">
        <div className="position-relative">
          <div className="msg-lens position-absolute">
            <AiOutlineSearch />
          </div>
          <div className="">
            <input
              id="doc_searchQueryChat"
              type="text"
              name="doc_searchQueryChat"
              placeholder={t("chat.Search") || ""}
              maxLength={50}
              value={searchKey}
              onChange={(e: any) => {
                setSearchKey(
                  context?.chatType === "CHATS"
                    ? e?.target?.value.replace(/^[0-9]*$/g, "")
                    : e?.target?.value
                );
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatSearch;
