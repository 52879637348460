import React from "react";
import { isMobile } from "react-device-detect";

// interface modalProps{
//     props : react;

// }

const ToastPopupSecond = (props: any) => {
  return props.trigger ? (
    <>
      <div className="popup">
        <div
          className={`${isMobile ? "toastsecond p-4 m-3" : " toastsecond px-5 py-4"}`}
        >
          {props.children}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default ToastPopupSecond;
