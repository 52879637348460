import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

const Pagination = ({
  meta,
  currentPage,
  setCurrentPage,
  currentPageCount,
  component,
  isConfig = false,
}: any) => {
  const { t } = useTranslation();
  currentPage = parseInt(currentPage);
  let maxPages: any = Math.ceil(meta?.total_count / meta?.pageLimit);
  let lastPageHideShow = maxPages;
  let nextPageHideShow = maxPages;
  let items = [];
  let leftSide = currentPage - 0;
  if (leftSide <= 0) leftSide = 1;
  let rightSide = currentPage + 2;
  if (rightSide > maxPages) rightSide = maxPages;
  for (let number = leftSide; number <= rightSide; number++) {
    items.push(
      <div
        key={number}
        className={
          number === currentPage ? "round-effect activeEffect" : "round-effect"
        }
        onClick={() => {
          setCurrentPage(number);
        }}
      >
        {number}
      </div>
    );
  }
  const nextPage = () => {
    if (currentPage < maxPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const fristPage = () => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }
  };

  const lastPage = () => {
    if (currentPage < maxPages) {
      setCurrentPage(Math.ceil(maxPages));
    }
  };

  return (
    <>
      <div
        className={`${
          isMobile
            ? "d-flex justify-content-between align-items-center flex-column pb-5 mb-5"
            : " d-flex justify-content-between align-items-center"
        }`}
      >
        <div>
          {t("header.Showing")}
          {meta?.pageLimit * meta?.currentPage - (meta?.pageLimit - 1)} -&nbsp;
          {meta?.currentPage === 1
            ? currentPageCount
            : currentPageCount + (meta.currentPage - 1) * meta?.pageLimit}
          {t("header.from")} {meta?.total_count}{" "}
          {isConfig ? component : t("header." + component)}
          {/* <div> currentPage : {currentPage} </div> */}
        </div>

        <div className=" d-flex">
          {items[0]?.props?.children > 2 && (
            <div className="round-effect" onClick={fristPage}>
              &lsaquo; &lsaquo;
            </div>
          )}
          {items[0]?.props?.children !== 1 && (
            <div className="round-effect" onClick={prevPage}>
              &lsaquo;
            </div>
          )}
          {items}
          {items[0]?.props?.children < nextPageHideShow - 1 && (
            <div
              className="round-effect"
              onClick={() => (items?.length > 1 ? nextPage() : "")}
            >
              &rsaquo;
            </div>
          )}
          {items[0]?.props?.children < lastPageHideShow - 2 && (
            <div
              className="round-effect"
              onClick={() => (items?.length > 1 ? lastPage() : "")}
            >
              &rsaquo; &rsaquo;
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Pagination;
