import { useEffect } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import { useMembers } from "../../context/MembersProvider";
import AppLayout from "../../layouts/AppLayout";
import CommonAvatar from "../../components/Avatar/CommonAvatar";
import { useAuthentication } from "../../context/AuthenticationProvider";

const EditPage = (props: any) => {
  const context: any = useMembers();
  const { t, i18n } = useTranslation();
  const { memberId, isProfile, setCurrentImage, setImageShow } = props;

  useEffect(() => {
    getMemberDetail();
  }, []);

  const getMemberDetail = async () => {
    if (context && isProfile) {
      await context.getUserDetail();
    } else if (context && !isProfile) {
      await context.getMemberDetail(memberId);
    }
  };

  const DepartmentDisplay: any = ({ departments }: any) => {
    const capitalizeFirstLetter = (str: string) => {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };
    const formattedDepartments = departments
      ?.map(capitalizeFirstLetter)
      .join(", ");
    return <div>{formattedDepartments}</div>;
  };

  const navigate = useNavigate();
  const userContext = useAuthentication();
  return (
    <AppLayout>
      <div
        className="edit_members p-3 "
        style={!isDesktop ? {} : { background: "#FAFAFA", height: "100%" }}
      >
        <div className="members py-4 mt-2 d-flex align-items-center ">
          {!isProfile && (
            <h5
              className={`${
                isMobile ? "fs-6  mb-0 membertitle" : "  mb-0 membertitle"
              }`}
              onClick={() =>
                navigate(routes.listMembersUrl(context?.queryParamsDetails()))
              }
            >
              {t("editpage.members")}
            </h5>
          )}
          {isProfile && (
            <div className="memberimageshape position-relative">
              {(window.location.pathname.includes("/personal_profile") ||
                window.location.pathname.includes(
                  "/profile_change_password"
                )) &&
              context?.user?.avatar &&
              context?.user?.avatar !== null &&
              context?.user?.avatar !== undefined ? (
                <img
                  src={context?.user?.avatar}
                  alt="logo"
                  width={50}
                  className=" "
                  onClick={() => {
                    setImageShow(true);
                    setCurrentImage(context?.user?.avatar);
                  }}
                />
              ) : (
                <CommonAvatar
                  name={
                    window.location.pathname.includes("/personal_profile") ||
                    window.location.pathname.includes(
                      "/profile_change_password"
                    )
                      ? context?.user?.name
                        ? context?.user?.name?.toUpperCase()
                        : context?.user?.email?.toUpperCase()
                      : window.location.pathname.includes("/company_profile")
                      ? context?.user?.role
                        ? context?.user?.role?.toUpperCase()
                        : context?.user?.department?.toUpperCase()
                      : window.location.pathname.includes(
                          "/vehicle_information"
                        )
                      ? context?.userVehicle?.type
                        ? context?.userVehicle?.type?.toUpperCase()
                        : context?.userVehicle?.model?.toUpperCase()
                      : "-"
                  }
                  size={"50"}
                  style={{
                    backgroundColor: "#6b5ca4",
                  }}
                />
              )}
            </div>
          )}
          <span className={i18n.language === "en" ? "px-2 " : "d-none"}>
            <MdOutlineArrowForwardIos />
          </span>
          <span className={i18n.language === "en" ? "d-none" : "px-2"}>
            <MdArrowBackIos />
          </span>

          {!isProfile && (
            <h5
              className={`${isMobile ? "fs-6 text-capitalize m-0 " : " m-0"}`}
            >
              {context?.member?.name ? context?.member?.name : "UnAuthorized"}
            </h5>
          )}
          {isProfile && (
            <div className="">
              <h5 className="m-0 fw-semibold text-capitalize border-0">
                {window.location.pathname.includes("/personal_profile") ||
                window.location.pathname.includes("/profile_change_password")
                  ? context?.user?.name
                    ? context?.user?.name
                    : "Personal profile"
                  : window.location.pathname.includes("/company_profile")
                  ? context?.user?.role
                    ? context?.user?.role
                    : "Company profile"
                  : window.location.pathname.includes("/vehicle_information")
                  ? context?.userVehicle?.type
                    ? context?.userVehicle?.type
                    : "Vehicle Information"
                  : ""}
              </h5>
              <h6 className="m-0 emailprofile ff-questrial">
                {window.location.pathname.includes("/personal_profile") ||
                window.location.pathname.includes(
                  "/profile_change_password"
                ) ? (
                  context?.user?.email
                ) : window.location.pathname.includes("/company_profile") ? (
                  <DepartmentDisplay departments={context?.user?.department} />
                ) : context?.user?.department ? (
                  window.location.pathname.includes("/vehicle_information")
                ) : (
                  context?.userVehicle?.model
                )}
              </h6>
            </div>
          )}
        </div>
        <div
          style={!isMobile ? { background: "#fefefe" } : {}}
          className={`${isDesktop ? "p-5 mt-4 rounded-1 " : " p-0"}`}
        >
          <div
            className={`${isMobile ? "container-fluid p-0 " : "container p-0"}`}
          >
            <div
              className={`${
                isMobile ? " overflow-scroll" : " overflow-scroll"
              }`}
            >
              <ul className="d-flex justify-content-between nestedul">
                <li className=" m-0" style={{ minWidth: "120px" }}>
                  <NavLink
                    to={
                      isProfile
                        ? routes.adminPersonalProfile
                        : routes.getPersonalProfileUrl(
                            memberId,
                            context?.queryParamsDetails()
                          )
                    }
                    // to={routes.personalProfile}
                    className="text-decoration-none  "
                  >
                    <span
                      className={`edit_link_routes ${
                        (window.location.pathname.includes(
                          "/personal_profile"
                        ) ||
                          window.location.pathname.includes(
                            "/edit_personal_profile"
                          )) &&
                        "active"
                      }`}
                    >
                      {t("editpage.personalProfile")}
                    </span>
                  </NavLink>
                </li>
                <li className="mx-4" style={{ minWidth: "124px" }}>
                  <NavLink
                    to={
                      isProfile
                        ? routes.admincompanyProfile
                        : routes.getCompanyProfileUrl(
                            memberId,
                            context?.queryParamsDetails()
                          )
                    }
                    className="text-decoration-none "
                  >
                    <span
                      className={`edit_link_routes  ${
                        (window.location.pathname.includes(
                          "/company_profile"
                        ) ||
                          window.location.pathname.includes(
                            "/edit_company_profile"
                          )) &&
                        "active"
                      }`}
                    >
                      {t("editpage.companyProfile")}
                    </span>
                  </NavLink>
                </li>
                <li style={{ minWidth: "149px" }}>
                  <NavLink
                    to={
                      isProfile
                        ? routes.adminvehicleInformation
                        : routes.getVehicleInformationUrl(
                            memberId,
                            context?.queryParamsDetails()
                          )
                    }
                    className="text-decoration-none "
                  >
                    <span
                      className={`edit_link_routes ${
                        (window.location.pathname.includes(
                          "/vehicle_information"
                        ) ||
                          window.location.pathname.includes(
                            "/edit_vehicle_information"
                          )) &&
                        "active"
                      }`}
                    >
                      {t("editpage.vehicleInformation")}
                    </span>
                  </NavLink>
                </li>
                <li className="mx-3" style={{ minWidth: "160px" }}>
                  <NavLink
                    to={
                      isProfile
                        ? routes.adminChangePassword
                        : routes.getMemberPasswordResetUrl(
                            memberId,
                            context?.queryParamsDetails()
                          )
                    }
                    className="text-decoration-none "
                  >
                    {userContext?.user?.role === "ADMIN" && (
                      <span
                        className={`edit_link_routes ${
                          (isProfile
                            ? window.location.pathname.includes(
                                "/profile_change_password"
                              )
                            : window.location.pathname.includes(
                                "/edit_reset_password"
                              )) && "active"
                        }`}
                      >
                        {t(
                          isProfile
                            ? "editpage.changepassword"
                            : "editpage.resetPassword"
                        )}
                      </span>
                    )}
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          {props.children}
        </div>
      </div>
    </AppLayout>
  );
};

export default EditPage;
