import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import { Button, Form, FormGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { HiOutlineArrowRight } from "react-icons/hi";
import * as Yup from "yup";
import LoginLayout from "../../layouts/LoginLayout";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useState } from "react";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
// import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
// import { useState } from "react";

const ChangePasswordPage = () => {
  const { t, i18n } = useTranslation();
  const [newPasswordEye, setNewPasswordEye] = useState(false);
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(false);
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("Password is requried")
        .min(8, "Password is too short- should be 8 characters  minimum")
        .matches(/^(?=.{6,})/, "Must Contain 6 Characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])/,
          "Must Contain One Uppercase, One Lowercase"
        )
        .matches(
          /^(?=.*?[!"#$%&'()*+,-./:;<=>?@_`{}~])/,
          "Must Contain One Special Case Character"
        )
        .matches(/^(?=.{6,20}$)\D*\d/, "Must Contain One Number"),
      confirmPassword: Yup.string()
        .required("Confirm Password is requried")
        .oneOf([Yup.ref("newPassword")], "Password doesn't match"),
    }),

    onSubmit: async (values: any) => {
      // setUpdatePasswordDetail(values);
      // setShowPopUp(true);
      // setTextMessage("update the password");
    },
  });

  const { errors, handleSubmit, isSubmitting, isValid, dirty } = formik;
  return (
    <>
      <LoginLayout
        title={t("changepassword.title")}
        subTitle={t("changepassword.subtitle")}
      >
        <FormikProvider value={formik}>
          <Form validated={!!errors} onSubmit={handleSubmit}>
            <div className="floating">
              <FormGroup className="my-4 position-relative">
                <label>{t("changepassword.newpassword")}</label>

                <Field
                  name="newPassword"
                  type={newPasswordEye ? "text" : "password"}
                  className="form-control"
                />
                {formik?.values.newPassword.length > 0 && (
                  <div
                    className={
                      i18n.language === "en" ? "eyeicon" : "eyeicon-right"
                    }
                    onClick={() => setNewPasswordEye(true)}
                  >
                    <AiOutlineEye color="##2a2b2f80" />
                  </div>
                )}
                {formik?.values.newPassword.length > 0 && newPasswordEye && (
                  <div
                    className=" eyeicon"
                    onClick={() => setNewPasswordEye(false)}
                  >
                    <AiOutlineEyeInvisible color="##2a2b2f80" />
                  </div>
                )}
                <Form.Control.Feedback
                  style={{ display: !!errors.newPassword ? "block" : "none" }}
                  type={!!errors.newPassword ? "invalid" : "valid"}
                >
                  <ErrorMessage name="newPassword" />
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup className="my-4 position-relative">
                <label>{t("changepassword.confirmpassword")}</label>

                <Field
                  name="confirmPassword"
                  type={confirmPasswordEye ? "text" : "password"}
                  className="form-control"
                />
                {formik?.values.confirmPassword.length > 0 && (
                  <div
                    className={
                      i18n.language === "en" ? "eyeicon" : "eyeicon-right"
                    }
                    onClick={() => setConfirmPasswordEye(true)}
                  >
                    <AiOutlineEye color="##2a2b2f80" />
                  </div>
                )}
                {formik?.values.confirmPassword.length > 0 &&
                  confirmPasswordEye && (
                    <div
                      className=" eyeicon"
                      onClick={() => setConfirmPasswordEye(false)}
                    >
                      <AiOutlineEyeInvisible color="##2a2b2f80" />
                    </div>
                  )}
                <Form.Control.Feedback
                  style={{
                    display: !!errors.confirmPassword ? "block" : "none",
                  }}
                  type={!!errors.confirmPassword ? "invalid" : "valid"}
                >
                  <ErrorMessage name="confirmPassword" />
                </Form.Control.Feedback>
              </FormGroup>
            </div>

            <div>
              <Button
                type="submit"
                variant="primary"
                className="my-5 px-5 py-2 rounded-1 d-flex justify-content-center align-items-center pointer"
                disabled={isSubmitting || !isValid || !dirty}
              >
                <span
                  className={`me-2 ${
                    isSubmitting || !isValid || !dirty ? "" : "fw-semibold"
                  }`}
                >
                  {t("changepassword.reset")}
                </span>
                <span className={i18n.language === "en" ? "ms-2" : "d-none"}>
                  <FaLongArrowAltRight />
                </span>
                <span className={i18n.language === "en" ? "d-none" : "me-2"}>
                  <FaLongArrowAltLeft />
                </span>
              </Button>
            </div>
          </Form>
        </FormikProvider>
      </LoginLayout>
    </>
  );
};

export default ChangePasswordPage;
