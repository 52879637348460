import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";

export default function MaterialRequestPDF({ data }: any) {
  return (
    <Document>
      <Page size="A4">
        <View
          style={{
            margin: 30,
          }}
        >
          <View style={{ justifyContent: "flex-end", alignItems: "flex-end" }}>
            <Image
              style={{ height: 100 }}
              src={require("../assets/pdfLogo.jpeg")}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <Text
              style={{
                borderBottomWidth: 1,
                borderBottomColor: "black",
              }}
            >
              Material Request
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontSize: 10 }}>Requested by : </Text>
              <Text
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: "black",
                  fontSize: 10,
                }}
              >
                {data?.requested_by?.name}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontSize: 10 }}>Date : </Text>
              <Text
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: "black",
                  fontSize: 10,
                }}
              >
                {moment(data?.requested_on).format("DD-MM-YYYY")}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontSize: 10 }}>Project : </Text>
              <Text
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: "black",
                  fontSize: 10,
                }}
              >
                {data?.project_name || "                "}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontSize: 10 }}>Branch : </Text>
              <Text
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: "black",
                  fontSize: 10,
                }}
              >
                {data?.city}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontSize: 10 }}>Board no : </Text>
              <Text
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: "black",
                  fontSize: 10,
                }}
              >
                {data?.billboard_id || "                "}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontSize: 10 }}>Material Request Id: </Text>
              <Text
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: "black",
                  fontSize: 10,
                }}
              >
                {data?.material_id}
              </Text>
            </View>
          </View>

          <View style={{ flexDirection: "row", marginTop: 20 }}>
            <View style={{ flex: 1, border: 1, fontSize: 10 }}>
              <Text>Stock Code</Text>
            </View>
            <View style={{ flex: 1, border: 1, fontSize: 10 }}>
              <Text>Part No</Text>
            </View>
            <View style={{ flex: 1, border: 1, fontSize: 10 }}>
              <Text>Part Des.</Text>
            </View>
            <View style={{ flex: 1, border: 1, fontSize: 10 }}>
              <Text>Requested Qty</Text>
            </View>
            <View style={{ flex: 1, border: 1, fontSize: 10 }}>
              <Text>Delivered Qty</Text>
            </View>
            <View style={{ flex: 1, border: 1, fontSize: 10 }}>
              <Text>Remark</Text>
            </View>
          </View>
          {data?.stocks?.map((item: any) => (
            <View style={{ flexDirection: "row" }}>
              <View
                style={{ flex: 1, border: 1, flexWrap: "wrap", fontSize: 10 }}
              >
                <Text>{item?.stock_code?.stock_code}</Text>
              </View>
              <View
                style={{ flex: 1, border: 1, flexWrap: "wrap", fontSize: 10 }}
              >
                <Text>{item?.stock_code?.supplier_part_no}</Text>
              </View>
              <View
                style={{ flex: 1, border: 1, flexWrap: "wrap", fontSize: 10 }}
              >
                <Text>{item?.stock_code?.part_description}</Text>
              </View>
              <View
                style={{ flex: 1, border: 1, flexWrap: "wrap", fontSize: 10 }}
              >
                <Text>{item?.requested_quantity}</Text>
              </View>
              <View
                style={{ flex: 1, border: 1, flexWrap: "wrap", fontSize: 10 }}
              >
                <Text>{item?.delivered_quantity}</Text>
              </View>
              <View
                style={{ flex: 1, border: 1, flexWrap: "wrap", fontSize: 10 }}
              >
                <Text></Text>
              </View>
            </View>
          ))}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontSize: 10 }}>Created by : </Text>
              <Text
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: "black",
                  fontSize: 10,
                }}
              >
                {data?.created_by?.name}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontSize: 10 }}>Date and Time : </Text>
              <Text
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: "black",
                  fontSize: 10,
                }}
              >
                {moment(data?.createdAt).format("DD-MM-YYYY : HH:MM")}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontSize: 10 }}>Received by : </Text>
              <Text
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: "black",
                  fontSize: 10,
                }}
              >
                {data?.issued_to?.name}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontSize: 10 }}>Store Incharge : </Text>
              <Text
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: "black",
                  fontSize: 10,
                }}
              >
                {data?.updated_by
                  ? data?.updated_by?.name
                  : data?.created_by?.name}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontSize: 10 }}>Date : </Text>
              <Text
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: "black",
                  fontSize: 10,
                }}
              >
                {moment(data?.updatedAt).format("DD-MM-YYYY")}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontSize: 10 }}>Date : </Text>
              <Text
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: "black",
                  fontSize: 10,
                }}
              >
                {moment(data?.delivered_on).format("DD-MM-YYYY")}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
