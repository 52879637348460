import { Form, FormGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CommonButton from "../CommonButton/CommonButton";

export default function ReasonPopUp({
  setReasonValue,
  reasonValue,
  updateStatus,
}: any) {
  const { t } = useTranslation();
  return (
    <Form validated={true} className="floating">
      <FormGroup className="my-4 position-relative">
        <label> {t("cleaning.Reason")}</label>
        <div>
          <input
            type="text"
            autoFocus
            className={"form-control"}
            value={reasonValue}
            onChange={(e: any) => setReasonValue(e?.target?.value)}
          />
        </div>
      </FormGroup>

      <div className=" d-flex justify-content-end mt-5">
        <CommonButton
          text={t("cleaning.Reason")}
          type="submit"
          variant="success"
          className="btn-i18n text-white"
          isDisabled={reasonValue?.length === 0}
          onClick={() => updateStatus()}
        />
      </div>
    </Form>
  );
}
