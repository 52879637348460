import { useEffect, useState } from "react";
import AppLayout from "../../layouts/AppLayout";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { isDesktop, isMobile, isMobileOnly } from "react-device-detect";
import { routes } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import { IoQrCodeSharp } from "react-icons/io5";

import { Button } from "react-bootstrap";
import { HiDotsVertical } from "react-icons/hi";
import ToastQr from "../../components/PopupToast/ToastQr";
import ToastQrImageAndDowload from "../../components/PopupToast/ToastQrImageAndDowload";

const CreateNewBillBoard = (props: any) => {
  const { t, i18n } = useTranslation();
  const [buttondropdown, setButtondropdown] = useState(false);
  const [qrPopup, setQrPopup] = useState(false);

  const {
    userContext,
    context,
    setTextMessage,
    setShowPopUp,
    setHeaderMessage,
  } = props;
  const urlParams = useParams();

  const getBillBoardDetail = async () => {
    if (urlParams?.billboardId) {
      await context?.getBillBoardDetail(urlParams?.billboardId);
    }
  };

  useEffect(() => {
    if (context) {
      getBillBoardDetail();
    }
  }, []);

  const navigate = useNavigate();

  return (
    <AppLayout>
      <div
        className="edit_members p-3 "
        style={!isDesktop ? {} : { background: "#FAFAFA", height: "100%" }}
      >
        <div
          className={
            isMobileOnly
              ? ""
              : " d-flex justify-content-between align-items-center"
          }
        >
          <div className="members py-4 mt-2 d-flex align-items-center justify-content-between">
            <div>
              <h5
                className={`${
                  isMobile ? "fs-6  mb-0 membertitle" : "  mb-0 membertitle"
                }`}
                onClick={() =>
                  navigate(
                    routes.listBillBoardUrl(
                      context?.billBoardQueryParamsDetails()
                    )
                  )
                }
              >
                {t("CreateNewBillBoard.BillBoard")}
              </h5>
              <>
                <span className={i18n.language === "en" ? "px-2 " : "d-none"}>
                  <MdOutlineArrowForwardIos />
                </span>
                <span className={i18n.language === "en" ? "d-none" : "px-2"}>
                  <MdArrowBackIos />
                </span>

                <h5
                  className={`${
                    isMobile ? "fs-6 text-capitalize m-0  " : " m-0 "
                  }`}
                >
                  {urlParams?.billboardId
                    ? context?.billBoard?.board_no
                    : window.location.pathname.includes("/new")
                    ? `${t("CreateNewBillBoard.New BillBoard")}`
                    : `${t("CreateNewBillBoard.uploadbills")}`}
                </h5>
              </>
            </div>
            {urlParams?.billboardId && (
              <div
                onClick={() => {
                  setButtondropdown(!buttondropdown);
                }}
                className={
                  isMobileOnly ? "position-relative pointer" : "d-none"
                }
              >
                <span className=" pointer">
                  <HiDotsVertical color="6b5ca4" fontSize={24} />
                </span>
                <div
                  className={
                    i18n.language === "en"
                      ? `dropdownMark ${buttondropdown ? "active" : "inactive"}`
                      : `dropdownMarkArabic ${
                          buttondropdown ? "active" : "inactive"
                        }`
                  }
                  id="sortDropDown"
                >
                  <div>
                    <h6
                      className=" d-flex justify-content-start qrcode"
                      id="canvas"
                      onClick={() => {
                        setQrPopup(true);
                      }}
                    >
                      {t("CreateNewBillBoard.downloadqr")}
                    </h6>
                    {urlParams?.billboardId &&
                      ((context?.billBoard?.board_status === "ACTIVE" &&
                        userContext?.roleAccess["BOARD"].includes(
                          "NON_OPERATIONAL"
                        )) ||
                        (context?.billBoard?.board_status !== "ACTIVE" &&
                          userContext?.roleAccess["BOARD"].includes(
                            "OPERATIONAL"
                          ))) && (
                        <div>
                          <h6
                            className={`  ${
                              context?.billBoard?.board_status === "ACTIVE"
                                ? ` in-active`
                                : ` active`
                            }`}
                            onClick={() => {
                              setHeaderMessage(
                                context?.billBoard?.board_status === "ACTIVE"
                                  ? `${t(
                                      "successError.mark the billboard non-operational"
                                    )}`
                                  : `${t(
                                      "successError.mark the billboard operational"
                                    )}`
                              );
                              setTextMessage(`${t("successError.mark it")}`);
                              setShowPopUp(true);
                            }}
                          >
                            <span className="markline">
                              {context?.billBoard?.board_status === "ACTIVE"
                                ? t(
                                    "successError.Mark the billboard non-operational"
                                  )
                                : t(
                                    "successError.Mark the billboard operational"
                                  )}
                            </span>
                          </h6>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className={isMobileOnly ? " d-none" : "d-flex align-items-center"}
          >
            {urlParams?.billboardId &&
              ((context?.billBoard?.board_status === "ACTIVE" &&
                userContext?.roleAccess["BOARD"].includes("NON_OPERATIONAL")) ||
                (context?.billBoard?.board_status !== "ACTIVE" &&
                  userContext?.roleAccess["BOARD"].includes(
                    "OPERATIONAL"
                  ))) && (
                <>
                  <span
                    className="mx-3 pointer"
                    onClick={() => {
                      setQrPopup(true);
                    }}
                  >
                    <IoQrCodeSharp fontSize={29} color="#2a2b2f" />
                  </span>
                  {(context?.billBoard?.board_status === "NON_OPERATIONAL" ||
                    context?.billBoard?.board_status === "ACTIVE") && (
                    <div className={isMobileOnly ? " d-none" : ""}>
                      <Button
                        className={`rounded-1 d-flex justify-content-center align-items-center ${
                          context?.billBoard?.board_status === "ACTIVE"
                            ? `btn_mark in-active`
                            : `btn_mark active`
                        }`}
                        onClick={() => {
                          setHeaderMessage(
                            context?.billBoard?.board_status === "ACTIVE"
                              ? `${t(
                                  "successError.mark the billboard non-operational"
                                )}`
                              : `${t(
                                  "successError.mark the billboard operational"
                                )}`
                          );
                          setTextMessage(`${t("successError.mark it")}`);
                          setShowPopUp(true);
                        }}
                      >
                        <span>
                          {context?.billBoard?.board_status === "ACTIVE"
                            ? t("successError.marknon")
                            : t("successError.markoperation")}
                        </span>
                      </Button>
                    </div>
                  )}
                </>
              )}
          </div>
        </div>

        <div
          style={!isMobile ? { background: "#fefefe" } : {}}
          className={`${isDesktop ? "p-5 mt-4 rounded-1 " : " p-0"}`}
        >
          <div
            className={`${isMobile ? "container-fluid p-0 " : "container p-0"}`}
          >
            <div className=" overflow-scroll">
              <ul className="d-flex nestedul ">
                {!urlParams?.billboardId && (
                  <>
                    <li style={{ minWidth: "130px" }}>
                      <NavLink
                        to={routes.getNewBillBoardUrl(
                          context?.billBoardQueryParamsDetails()
                        )}
                        className="text-decoration-none"
                      >
                        <span
                          className={`edit_link_routes ${
                            window.location.pathname.includes("/new") &&
                            "active"
                          }`}
                        >
                          {t("CreateNewBillBoard.New BillBoard")}
                        </span>
                      </NavLink>
                    </li>
                    <li style={{ minWidth: "146px" }}>
                      <NavLink
                        to={routes.getUploadBillBoardUrl(
                          context?.billBoardQueryParamsDetails()
                        )}
                        className="text-decoration-none "
                      >
                        <span
                          className={`edit_link_routes ${
                            window.location.pathname.includes("/upload") &&
                            "active"
                          }`}
                        >
                          {t("CreateNewBillBoard.Upload BillBoard")}
                        </span>
                      </NavLink>
                    </li>
                  </>
                )}
                {urlParams?.billboardId && (
                  <>
                    <li style={{ minWidth: "130px" }}>
                      <NavLink
                        to={routes.getEditBillBoardUrl(
                          urlParams?.billboardId,
                          context?.billBoardQueryParamsDetails()
                        )}
                        className="text-decoration-none "
                      >
                        <span
                          className={`edit_link_routes ${
                            window.location.pathname.includes("/edit") &&
                            "active"
                          }`}
                        >
                          {t("CreateNewBillBoard.Edit Billborad")}
                        </span>
                      </NavLink>
                    </li>
                    <li className=" me-4 ">
                      <NavLink
                        to={routes.getBillOfMaterialsUrl(
                          urlParams?.billboardId,
                          context?.billBoardQueryParamsDetails()
                        )}
                        className="text-decoration-none "
                      >
                        <span
                          className={`edit_link_routes ${
                            window.location.pathname.includes("/bom") &&
                            "active"
                          }`}
                        >
                          {t("CreateNewBillBoard.BOM")}
                        </span>
                      </NavLink>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>

          {props.children}
        </div>
        <div>
          {context?.billBoard?.qrcode && (
            <ToastQr trigger={qrPopup} setTrigger={setQrPopup}>
              <ToastQrImageAndDowload
                setTrigger={setQrPopup}
                url={context?.billBoard?.qrcode}
              />
            </ToastQr>
          )}
        </div>
      </div>
    </AppLayout>
  );
};

export default CreateNewBillBoard;
