import React, { ReactElement, useEffect, useState } from "react";
import { SOCKET_URL, api } from "../utils/api";
import { io } from "socket.io-client";
const socket = io(SOCKET_URL || "");

interface BillBoardProviderProps {
  children: ReactElement;
}

export interface BillBoardContextProp {
  isLoading: boolean;
  setIsLoading: Function;
  billBoards: any;
  billBoard: any;
  meta: any;
  tickets: any;
  removeTicket: Function;
  removeBillBoard: Function;
  loadBillBoards: Function;
  getBillBoardDetail: Function;
  updateBillBoardDetail: Function;
  loadTicketBillBoards: Function;
  loadMembers: Function;
  members: any;
  updateTicketDetail: Function;
  createTicketDetail: Function;
  ticket: any;
  getTicketDetail: Function;
  ticketActivityFeed: any;
  getTicketActivityFeed: Function;
  createBillBoardDetail: Function;
  UploadBillBoardDetail: Function;
  markAsNonOperational: Function;
  approveBillBoard: Function;
  rejectBillBoard: Function;
  markAsOperational: Function;
  generatorQrCode: Function;
  getTicketTitleDetail: Function;
  socketData: any;
  disConnectSocket: Function;
  billboard_id: any;
  setBillboard_id: Function;
  autoCompleteApi: Function;
  createGroupConversation: Function;
  approveByStaffTicket: Function;
  approveBySupervisorTicket: Function;
  rejectTicket: Function;
  reassignTicket: Function;
  downloadExcel: Function;
  downloadQrCodePdf: Function;
  downloadPdf: Function;
  cityList: Function;
  billBoardQueryParamsDetails: Function;
  deleteBillboard: Function;
  billOfMaterial: any;
  setBillOfMaterial: Function;
  getBOM: Function;
  downloadBOMPdf: Function;
  downloadBOMExcel: Function;
  getBomId: Function;
  bom: any;
  currentBomId: any;
  setCurrentBomId: Function;
  billBoardLocation: Function;
  billBoardBoundaryBox: Function;
}

const BillBoardContext = React.createContext<BillBoardContextProp | null>(null);

const BillBoardProvider = ({ children }: BillBoardProviderProps) => {
  const queryParams = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading]: [boolean, Function] = useState(false);
  const [billBoards, setBillBoards] = useState<any>([]);
  const [members, setMembers] = useState<any>([]);
  const [billBoard, setBillBoard] = useState<any>({});
  const [tickets, setTickets] = useState<any>([]);
  const [ticket, setTicket] = useState<any>({});
  const [ticketActivityFeed, setTicketActivityFeed] = useState<any>([]);
  const [meta, setMeta] = useState<any>({});
  const [socketData, setSocketData] = useState<any>({});
  const [billboard_id, setBillboard_id] = useState("");
  const [billOfMaterial, setBillOfMaterial] = useState<any>([]);
  const [bom, setBom] = useState<any>({});
  const [currentBomId, setCurrentBomId] = useState<any>("");

  const loadBillBoards = async (params: any) => {
    setIsLoading(true);
    const { billboards, meta, success } = await api.billBoards(params);
    setBillBoards(billboards);
    setMeta(meta);
    setIsLoading(false);
    return { success, billboards };
  };

  const billBoardLocation = async (params: any) => {
    setIsLoading(true);
    const { data, count, success } = await api.billBoardLocation(params);
    setIsLoading(false);
    return { success, data, count };
  };

  const billBoardBoundaryBox = async (params: any) => {
    setIsLoading(true);
    const { data, count, success } = await api.billBoardBoundaryBox(params);
    setIsLoading(false);
    return { success, data, count };
  };

  const getBillBoardDetail = async (billBoardId: any) => {
    if (billBoardId) {
      setIsLoading(true);
      const { success, user, error } = await api.billBoard(billBoardId);
      setBillBoard(user);
      setBillboard_id(user?.id);
      setIsLoading(false);
      return { success, user, error };
    }
  };

  const updateBillBoardDetail = async (billBoardId: any, params: any) => {
    if (billBoardId) {
      setIsLoading(true);
      const { data } = await api.updateBillBoard(billBoardId, params);
      const { success, billboard, error } = data || {};
      if (success) {
        setBillBoard(billboard);
      }
      setIsLoading(false);
      return { success, billboard, error };
    }
  };
  const UploadBillBoardDetail = async (params: any) => {
    setIsLoading(true);
    const { data } = await api.uploadBillBoard(params);
    const { success, billboard, error, counts } = data || {};
    setIsLoading(false);
    return { success, billboard, error, counts };
  };

  const createBillBoardDetail = async (params: any) => {
    setIsLoading(true);
    const { data } = await api.createBillBoard(params);
    const { success, user, error } = data || {};
    setBillBoard(user);
    setIsLoading(false);
    return { success, user, error };
  };

  const loadTicketBillBoards = async (id: any) => {
    setIsLoading(true);
    const { tickets, success, error } = await api.getTicketBasedOnBillBoard(id);
    if (success) {
      setTickets(tickets);
    }
    setIsLoading(false);
    return { tickets, success, error };
  };

  const loadMembers = async (params: any) => {
    setIsLoading(true);
    const { success, user, meta } = await api.getMembers(params);
    if (success) {
      setMembers(user);
      setMeta(meta);
    }
    setIsLoading(false);
    return { success, user, meta };
  };

  const updateTicketDetail = async (ticketId: any, params: any) => {
    if (ticketId) {
      setIsLoading(true);
      const { data } = await api.updateTicket(ticketId, params);
      const { success, ticket, error } = data || {};
      if (success) {
        setTicket(ticket);
      }
      setIsLoading(false);
      return { success, ticket, error };
    }
  };

  const createTicketDetail = async (params: any) => {
    setIsLoading(true);
    const { data } = await api.createTicket(params);
    const { success, ticket, error } = data || {};
    if (success) {
      setTicket(ticket);
    }
    setIsLoading(false);
    return { success, ticket, error };
  };

  const getTicketDetail = async (TicketId: any) => {
    if (TicketId !== "0") {
      setIsLoading(true);
      const { success, ticket, error } = await api.ticket(TicketId);
      if (success) {
        setTicket(ticket);
      }
      setIsLoading(false);
      return { success, ticket, error };
    }
  };

  const getTicketActivityFeed = async (ticketId: any) => {
    if (ticketId !== "0") {
      setIsLoading(true);
      const { success, ticket_activities, error } =
        await api.getTicketActivityFeed(ticketId);
      if (success) {
        setTicketActivityFeed(ticket_activities);
      }
      setIsLoading(false);
      return { success, ticket_activities, error };
    }
  };

  const removeTicket = (value: any) => {
    setTicket(value);
    setTicketActivityFeed(value);
  };

  const removeBillBoard = (value: any) => {
    setBillBoard(value);
  };

  const markAsNonOperational = async (billBoardId: any, reason: any) => {
    if (billBoardId) {
      setIsLoading(true);
      const { data } = await api.nonOperationalBillBoard(billBoardId, reason);
      const { success, bill_board, error } = data || {};
      setBillBoard(bill_board);
      setIsLoading(false);
      return { success, bill_board, error };
    }
  };

  const markAsOperational = async (billBoardId: any) => {
    if (billBoardId) {
      setIsLoading(true);
      const { data } = await api.markOperationalBillBoard(billBoardId);
      const { success, bill_board, error } = data || {};
      setBillBoard(bill_board);
      setIsLoading(false);
      return { success, bill_board, error };
    }
  };

  const approveBillBoard = async (billBoardId: any) => {
    if (billBoardId) {
      setIsLoading(true);
      const { data } = await api.approvenNonOperationalBillBoard(billBoardId);
      const { success, bill_board, error } = data || {};
      setBillBoard(bill_board);
      setIsLoading(false);
      return { success, bill_board, error };
    }
  };

  const rejectBillBoard = async (billBoardId: any) => {
    if (billBoardId) {
      setIsLoading(true);
      const { data } = await api.rejectNonOperationalBillBoard(billBoardId, {
        status: "REJECTED",
      });
      const { success, bill_board, error } = data || {};
      setBillBoard(bill_board);
      setIsLoading(false);
      return { success, bill_board, error };
    }
  };

  const generatorQrCode = async (billBoardNo: any) => {
    if (billBoardNo) {
      setIsLoading(true);
      const { data } = await api.generateQrCode(billBoardNo);
      const { success, url, error } = data || {};
      setIsLoading(false);
      return { success, url, error };
    }
  };

  const getTicketTitleDetail = async (value: any) => {
    setIsLoading(true);
    const { success, title, error } = await api.getTicketTitle(value);
    setIsLoading(false);
    return { success, title, error };
  };

  socket.on(billboard_id, (data: any) => {
    if (data?.success) {
      setSocketData(data?.billboard);
    }
  });

  const autoCompleteApi = async (params: any) => {
    setIsLoading(true);
    const { success, users, meta } = await api.autoCompleteApi(params);
    setIsLoading(false);
    return { success, users, meta };
  };

  useEffect(() => {
    return () => {
      disConnectSocket(billboard_id);
    };
  }, []);

  const disConnectSocket = (value: any) => {
    socket.off(value);
  };

  const createGroupConversation = async (id: any) => {
    setIsLoading(true);
    const data = await api.createConversation({ ticket_id: id });
    setIsLoading(false);
    return data?.data;
  };

  const approveByStaffTicket = async (id: any) => {
    setIsLoading(true);
    const { data } = await api.approveByStaffTicket(id);
    const { success, error } = data || {};
    setIsLoading(false);
    return { success, error };
  };

  const approveBySupervisorTicket = async (id: string, reason: string) => {
    setIsLoading(true);
    const { data } = await api.approveBySupervisorTicket(
      id,
      reason?.length > 0
        ? {
            submitted_reason: reason,
          }
        : {}
    );
    const { success, error } = data || {};
    setIsLoading(false);
    return { success, error };
  };

  const rejectTicket = async (id: any) => {
    setIsLoading(true);
    const { data } = await api.rejectTicket(id);
    const { success, error } = data || {};
    setIsLoading(false);
    return { success, error };
  };

  const reassignTicket = async (id: any, params: any) => {
    setIsLoading(true);
    const { data } = await api.reassignTicket(id, params);
    const { success, error } = data || {};
    setIsLoading(false);
    return { success, error };
  };

  const downloadExcel = async (params: any) => {
    setIsLoading(true);
    const res = await api.billboardDownload(params);
    setIsLoading(false);
    return res;
  };

  const downloadPdf = async (params: any) => {
    setIsLoading(true);
    const res = await api.billboardPdfDownload(params);
    setIsLoading(false);
    return res;
  };

  const downloadQrCodePdf = async (params: any) => {
    setIsLoading(true);
    const { data } = await api.qrCodeDownload(params);
    setIsLoading(false);
    return data;
  };

  const cityList = async (params: any) => {
    setIsLoading(true);
    const data = await api.cityAutoComplete(params);
    setIsLoading(false);
    return data;
  };

  const getBOM = async (id: string, params: any) => {
    setIsLoading(true);
    const { success, data, meta } = await api.getBilllofMaterial(id, params);
    if (success) {
      setBillOfMaterial(data);
    }
    setIsLoading(false);
    return { success, data, meta };
  };

  const downloadBOMExcel = async (id: string, params: any) => {
    setIsLoading(true);
    const res = await api.bomExcelDownload(id, params);
    setIsLoading(false);
    return res;
  };

  const downloadBOMPdf = async (id: string, params: any) => {
    setIsLoading(true);
    const res = await api.bomPdfDownload(id, params);
    setIsLoading(false);
    return res;
  };

  const billBoardQueryParamsDetails = () => {
    const isInActiveBillBoards = queryParams.get("isInActiveBillBoards")
      ? queryParams.get("isInActiveBillBoards")
      : "";
    const isActiveBillBoards = queryParams.get("isActiveBillBoards")
      ? queryParams.get("isActiveBillBoards")
      : "";
    let params = {
      search: queryParams.get("search") ? queryParams.get("search") : "",
      sort: queryParams.get("sort") ? queryParams.get("sort") : "-createdAt",
      limit: 10,
      board_status: queryParams.get("board_status")
        ? queryParams.get("board_status")
        : "",
      page: queryParams.get("page") ? queryParams.get("page") : 1,
      board_location: queryParams.get("board_location")
        ? queryParams.get("board_location")
        : "",
      board_type: queryParams.get("board_type")
        ? queryParams.get("board_type")
        : "",
      teamviewer_status:
        isActiveBillBoards === "Team Viewer_online"
          ? "online"
          : isInActiveBillBoards === "Team Viewer_offline"
          ? "offline"
          : "",
      novastar_status:
        isActiveBillBoards === "Novastar_online"
          ? "online"
          : isInActiveBillBoards === "Novastar_offline"
          ? "offline"
          : "",
      ivms_status:
        isActiveBillBoards === "IVMS 4200_online"
          ? "online"
          : isInActiveBillBoards === "IVMS 4200_offline"
          ? "offline"
          : "",
      screen_resolution: queryParams.get("screen_size")
        ? queryParams.get("screen_size")
        : "",
      pending: queryParams.get("ticketCount")
        ? queryParams.get("ticketCount")
        : "",
    };
    return { ...params, isInActiveBillBoards, isActiveBillBoards };
  };

  const deleteBillboard = async (id: string) => {
    setIsLoading(true);
    const { success, error } = await api?.deleteBillboard(id);
    setIsLoading(false);
    return { success, error };
  };

  const getBomId = async (id: string) => {
    setIsLoading(true);
    const { success, bom } = await api.getBom(id);
    if (success) {
      setBom(bom);
    }
    setIsLoading(false);
    return { success, bom };
  };

  const value: BillBoardContextProp = {
    isLoading,
    setIsLoading,
    billBoards,
    meta,
    tickets,
    billBoard,
    removeTicket,
    removeBillBoard,
    loadBillBoards,
    getBillBoardDetail,
    updateBillBoardDetail,
    loadTicketBillBoards,
    loadMembers,
    members,
    updateTicketDetail,
    createTicketDetail,
    ticket,
    getTicketDetail,
    ticketActivityFeed,
    getTicketActivityFeed,
    createBillBoardDetail,
    UploadBillBoardDetail,
    markAsNonOperational,
    approveBillBoard,
    rejectBillBoard,
    markAsOperational,
    generatorQrCode,
    getTicketTitleDetail,
    socketData,
    disConnectSocket,
    billboard_id,
    setBillboard_id,
    autoCompleteApi,
    createGroupConversation,
    approveByStaffTicket,
    approveBySupervisorTicket,
    rejectTicket,
    reassignTicket,
    downloadExcel,
    downloadQrCodePdf,
    downloadPdf,
    cityList,
    billBoardQueryParamsDetails,
    deleteBillboard,
    getBOM,
    billOfMaterial,
    setBillOfMaterial,
    downloadBOMPdf,
    downloadBOMExcel,
    getBomId,
    bom,
    currentBomId,
    setCurrentBomId,
    billBoardLocation,
    billBoardBoundaryBox,
  };

  return (
    <BillBoardContext.Provider value={value}>
      {children}
    </BillBoardContext.Provider>
  );
};

const useBillBoard = () => React.useContext(BillBoardContext);

export { BillBoardProvider, useBillBoard };
