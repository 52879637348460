import { isMobile } from "react-device-detect";
import { NavLink } from "react-router-dom";
import { routes } from "../../constants/routes";
import AppLayout from "../../layouts/AppLayout";
import { useTranslation } from "react-i18next";

const InventoryNestedTab = (props: any) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <AppLayout>
        <div
          className={`${isMobile ? "list_members position-relative  " : "list_members p-3 "
            }`}
        >
          <div
            className={`${isMobile ? "container-fluid p-0 " : "container p-3 "
              }`}
          >
            <div
              className={`${isMobile ? " overflow-scroll" : " overflow-scroll px-2"
                }`}
            >
              <ul
                className="d-flex justify-content-between nestedulInventory pt-3"
                style={isMobile ? { paddingInlineStart: "1.5rem" } : {}}
              >
                <li
                  className="me-3"
                  style={
                    i18n.language === "en"
                      ? { minWidth: "220px" }
                      : { minWidth: "185px" }
                  }
                >
                  <NavLink
                    to={routes.inventoryPurchase}
                    className="text-decoration-none "
                  >
                    <span
                      className={`edit_link_routes ${window.location.pathname.includes(
                        "/inventory/purchases"
                      ) && "active"
                        }`}
                    >
                      {t("inventory.Material Rec. (From Supplier)")}
                    </span>
                  </NavLink>
                </li>
                <li
                  className="mx-3"
                  style={
                    i18n.language === "en"
                      ? { minWidth: "165px" }
                      : { minWidth: "165px" }
                  }
                >
                  <NavLink
                    to={routes.inventoryMaterialRequest}
                    className="text-decoration-none "
                  >
                    <span
                      className={`edit_link_routes ${window.location.pathname.includes(
                        "/inventory/materialRequest"
                      ) && "active"
                        }`}
                    >
                      {t("inventory.Material Request")}
                    </span>
                  </NavLink>
                </li>
                <li
                  className="mx-3"
                  style={
                    i18n.language === "en"
                      ? { minWidth: "240px" }
                      : { minWidth: "165px" }
                  }
                >
                  <NavLink
                    to={routes.inventoryMaterialToTechnician}
                    className="text-decoration-none "
                  >
                    <span
                      className={`edit_link_routes ${window.location.pathname.includes(
                        "/inventory/materialToTechnician"
                      ) && "active"
                        }`}
                    >
                      {t("inventory.Material Issued (To Technician)")}
                    </span>
                  </NavLink>
                </li>
                <li
                  className="mx-3"
                  style={
                    i18n.language === "en"
                      ? { minWidth: "240px" }
                      : { minWidth: "170px" }
                  }
                >
                  <NavLink
                    to={routes.inventoryReturnMaterialFromTechnician}
                    className="text-decoration-none "
                  >
                    <span
                      className={`edit_link_routes ${window.location.pathname.includes(
                        "inventory/returnMaterialFromTechnician"
                      ) && "active"
                        }`}
                    >
                      {t("inventory.Damage Rec. (From Technician)")}
                    </span>
                  </NavLink>
                </li>
                <li
                  className="mx-3"
                  style={
                    i18n.language === "en"
                      ? { minWidth: "200px" }
                      : { minWidth: "170px" }
                  }
                >
                  <NavLink
                    to={routes.inventoryReturnMaterialToSupplier}
                    className="text-decoration-none "
                  >
                    <span
                      className={`edit_link_routes ${window.location.pathname.includes(
                        "/inventory/returnMaterialToSupplier"
                      ) && "active"
                        }`}
                    >
                      {t("inventory.Damage Ret. (To Supplier)")}
                    </span>
                  </NavLink>
                </li>
                <li
                  className="mx-3"
                  style={
                    i18n.language === "en"
                      ? { minWidth: "230px" }
                      : { minWidth: "190px" }
                  }
                >
                  <NavLink
                    to={routes.inventoryreplaceMaterialFromSupplier}
                    className="text-decoration-none "
                  >
                    <span
                      className={`edit_link_routes ${window.location.pathname.includes(
                        "/inventory/replaceMaterialFromSupplier"
                      ) && "active"
                        }`}
                    >
                      {t("inventory.Damage Rec. (From Supplier)")}
                    </span>
                  </NavLink>
                </li>
                <li
                  className="mx-3"
                  style={
                    i18n.language === "en"
                      ? { minWidth: "60px" }
                      : { minWidth: "60px" }
                  }
                >
                  <NavLink to={routes.report} className="text-decoration-none ">
                    <span
                      className={`edit_link_routes ${window.location.pathname.includes(
                        "/inventory/reports"
                      ) && "active"
                        }`}
                    >
                      {t("inventory.Report")}
                    </span>
                  </NavLink>
                </li>
                <li
                  className="mx-3"
                  style={
                    i18n.language === "en"
                      ? { minWidth: "165px" }
                      : { minWidth: "165px" }
                  }
                >
                  <NavLink
                    to={routes.stockAdjustment}
                    className="text-decoration-none "
                  >
                    <span
                      className={`edit_link_routes ${window.location.pathname.includes(
                        "/inventory/stockAdjustment"
                      ) && "active"
                        }`}
                    >
                      {t("inventory.StockAdjustment")}
                    </span>
                  </NavLink>
                </li>
              </ul>
            </div>

            {props.children}
          </div>
        </div>
      </AppLayout>
    </>
  );
};

export default InventoryNestedTab;
