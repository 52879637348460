import React, { useEffect, useState } from "react";
import { useAuthentication } from "../../context/AuthenticationProvider";
import AppLayout from "../../layouts/AppLayout";
import { Table } from "react-bootstrap";
import { Formik, Field, Form } from "formik";

const RolesAndAccess = () => {
  const userContext = useAuthentication();
  const [features, setFeatures] = useState<any>([]);
  const [modules, setModules] = useState<string[]>([]);
  const [roles, setRoles] = useState<string[]>([]);
  const [access, setAccess] = useState<any>({});

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    const { success, role, modules, features } =
      await userContext?.getConfigRoles();
    if (success) {
      setFeatures(features);
      setModules(modules);
      setRoles(role);
      const res = await userContext?.getRolesDetails();
      if (res?.success) {
        setAccess(res?.roles);
      }
    }
  };

  const getValue = async (
    e: any,
    role: any,
    module: any,
    feature: any,
    value: any
  ) => {
    if (!e) {
      let currentIndex = feature.findIndex((data: any) => data === value);
      feature.splice(currentIndex, 1);
    } else {
      feature.push(value);
    }
    let params = {
      role: role,
      module: module,
      features: feature,
    };
    await userContext?.updateRoles(params);
    getUserDetails();
  };

  return (
    <AppLayout>
      <div className="table-responsive iq-pricing p-3 list_members">
        <Table responsive="sm table_class">
          <thead>
            <tr className="table_white_head ">
              <th className="text-center ">
                <span className="type">#</span>
              </th>
              {roles.map((role: string) => (
                <th className="text-center py-4">
                  <span className="type" key={role}>
                    {role}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {modules.map((module: string) => (
              <tr className="  table_white ">
                <th className="text-center " key={module}>
                  {module}
                </th>
                {roles?.map((role: string) => (
                  <td className="text-center  " key={role}>
                    {features[module].map((feature: string) => (
                      <ul className="rolelist " key={feature}>
                        <li>
                          <Formik
                            initialValues={{
                              toggle: false,
                              checked: [],
                            }}
                            onSubmit={() => {}}
                          >
                            <Field
                              type="checkbox"
                              className="checkboxroles checkboc form-check-input"
                              onChange={(e: any) => {
                                getValue(
                                  e.target.checked,
                                  role,
                                  module,
                                  access[role][module],
                                  feature
                                );
                              }}
                              checked={
                                access &&
                                access[role] &&
                                access[role][module] &&
                                access[role][module]?.includes(feature)
                              }
                            />
                          </Formik>
                          <p className=" ff-questrial fw-light m-0">
                            {feature?.replace("_", " ")}
                          </p>
                        </li>
                      </ul>
                    ))}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </AppLayout>
  );
};

export default RolesAndAccess;
