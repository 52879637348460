import { FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FormikField from "../../components/FormikField/FormikField";
import CommonButton from "../../components/CommonButton/CommonButton";

interface NewMemberFormProps {
  context: any;
  onClose: Function;
  popup: Function;
  setSuccess: Function;
  setBodyMsg: Function;
  timeOutPopUp: Function;
  configType: any;
  type: any;
  loadData: Function;
}

export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color?: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

export default function NewConfigModalForm({
  context,
  onClose,
  popup,
  setSuccess,
  setBodyMsg,
  timeOutPopUp,
  configType,
  type,
  loadData,
}: NewMemberFormProps) {
  const { t } = useTranslation();
  const [errorMessages, setErrorMessages] = useState([]);

  const initialValues = {
    title: context?.config?.title || "",
  };
  const validationSchema = Yup.object({
    title: Yup.string().required(`${type} is required`),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setErrorMessages([]);
      const { success, error } = context?.config?.id
        ? await context.updateConfig(context?.config?.id, {
            ...values,
            config_type: context?.config?.config_type,
          })
        : await context.createConfig({
            ...values,
            config_type: configType,
          });
      setSuccess(success);
      if (success) {
        formik.resetForm();
        popup(true);
        setBodyMsg(
          `${
            context?.config?.id
              ? t("successError.Edited")
              : t("successError.Added")
          } ${t(`masters.${type}`)} ${t("successError.successfully")}`
        );
        timeOutPopUp(true);
        onClose(false);
        loadData();
      } else {
        popup(true);
        timeOutPopUp(false);
        setBodyMsg(error);
        setErrorMessages(error);
      }
    },
  });

  const { errors } = formik;

  return (
    <FormikProvider value={formik}>
      <Form
        validated={true}
        onSubmit={formik.handleSubmit}
        className="floating"
      >
        {errorMessages.length > 0 && (
          <ul className="text-danger">
            {errorMessages.map((message: string) => (
              <li>{message}</li>
            ))}
          </ul>
        )}

        <div className=" mb-4">
          <FormikField
            name="title"
            type="text"
            validationSchema={validationSchema}
            // label={`${type} ${t("masters.Title")}`}
            label={`${type}`}
            errors={errors as Record<string, string>}
          />
        </div>

        <div className=" d-flex justify-content-end mt-5">
          <CommonButton
            text={
              context?.config?.id
                ? `${t("masters.Edit")} ${type}`
                : `${t("masters.Add")} ${type}`
            }
            type="submit"
            variant="success"
            className="btn-i18n text-white"
            isLoading={formik.isSubmitting}
            isDisabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          />
        </div>
      </Form>
    </FormikProvider>
  );
}
