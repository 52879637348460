import moment, { Moment } from "moment";
import { IGender, IRole, IUser } from "../interfaces/IUser";

export default class User implements IUser {
  id: string;
  name: string;
  email: string;
  gender: IGender;
  role: IRole;
  biography: string;
  confirmedAt: Moment | null;
  contactNumber: string;
  dateOfJoining: Moment | null;
  iqamaNo: string;
  location: string;
  responsibleArea: string;
  roleAccess: [];
  whatappNumber: string;
  status: string;
  department: string;
  createdAt: Moment;
  updatedAt: Moment;
  board_no: string;
  board_location: string;
  board_type: string;
  screen_size: string;
  screen_resolution: string;
  avatar:string;

  constructor(params: any) {
    this.id = params._id || params.id;
    this.name = params.name;
    this.email = params.email;
    this.gender = params.gender;
    this.role = params.role;
    this.avatar = params.avatar;
    this.biography = params.biography;
    this.confirmedAt = params.confirmed_at ? moment(params.confirmed_at) : null;
    this.contactNumber = params.contact_number;
    this.dateOfJoining = params.date_of_joining
      ? moment(params.date_of_joining)
      : null;
    this.iqamaNo = params.iqama_no;
    this.location = params.location;
    this.responsibleArea = params.responsible_area;
    this.roleAccess = params.role_access;
    this.whatappNumber = params.whatsapp_number;
    this.status = params.status;
    this.department = params.status;
    this.createdAt = moment(params.createdAt);
    this.updatedAt = moment(params.updatedAt);
    this.board_no = params.board_no;
    this.board_type = params.board_type;
    this.board_location = params.board_location;
    this.screen_size = params.screen_size;
    this.screen_resolution = params.screen_resolution;
  }
}
