import Button from "react-bootstrap/esm/Button";
import "./CommonButton.scss";
import { Spinner } from "react-bootstrap";

export interface ButtonProps {
  text: string;
  className?: string;
  type?: "submit" | "reset" | "button" | undefined;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDoubleClick?: (() => void) | undefined;
  isDisabled?: boolean;
  isLoading?: boolean;
  variant?: string
}

const CommonButton = ({
  text,
  type,
  onClick,
  onDoubleClick,
  className = "",
  isDisabled,
  isLoading,
  variant,
}: ButtonProps) => {

  return (
    <div data-testid={text}>
      <Button
        type={type}
        onDoubleClick={onDoubleClick && onDoubleClick}
        onClick={onClick && onClick}
        disabled={isDisabled || isLoading}
        id={text}
        className={className}
        variant={variant}
      >
        <h6 className="m-0 fw-normal">

          {isLoading ? (
            <span>
              <Spinner size="sm" /> <span className="ms-2">{text}</span>
            </span>
          ) : (
            text
          )}
        </h6>
      </Button>
    </div>
  );
};

export default CommonButton;
