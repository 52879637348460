import TicketList from "../TicketList";
import ViewTickets from "./ViewTickets";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ActivityFeed from "../../../components/ActivityFeed";
import { useEffect } from "react";
import { routes } from "../../../constants/routes";

const ViewAndActivityFeed = ({ context, userContext }: any) => {
  const urlParmas = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    loadTicketData();
  }, []);

  const loadTicketData = async () => {
    const { tickets, success } = await context?.loadTicketBillBoards(
      urlParmas?.billboardId
    );
    if (success && tickets.length) {
      if (
        window.location.pathname.includes("/edit") &&
        urlParmas?.ticketId !== "0"
      ) {
        navigate(
          routes.getQueryBillBoardTicketsIdUrl(
            urlParmas.billboardId,
            urlParmas?.ticketId,
            context?.billBoardQueryParamsDetails()
          )
        );
      } else if (window.location.pathname.includes("/edit")) {
        navigate(
          routes.getQueryBillBoardTicketsIdUrl(
            urlParmas.billboardId,
            tickets[0].id,
            context?.billBoardQueryParamsDetails()
          )
        );
      }
    }
  };

  return (
    <div>
      {context?.tickets?.length === 0 && (
        <div className=" d-flex justify-content-center">
          <div>No Tickets Found</div>
        </div>
      )}
      {context?.tickets.length > 0 && (
        <div className=" row  ">
          <div className=" col-xl-4 col-12 p-0">
            <div>
              <TicketList context={context} userContext={userContext} />
            </div>
          </div>
          <div className=" col-lg-8 col-12 actfeedheight">
            {location.pathname.includes("/edit") && (
              <ViewTickets context={context} userContext={userContext} />
            )}
            {location.pathname.includes("/activities") && (
              <ActivityFeed context={context} userContext={userContext} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewAndActivityFeed;
