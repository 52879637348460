import { Navigate, Route, Routes } from "react-router-dom";
import { routes } from "./constants/routes";
import {
  AuthenticationContextProp,
  useAuthentication,
} from "./context/AuthenticationProvider";
import ChangePasswordPage from "./pages/AuthenticationPages/ChangePasswordPage";
import ForgetPasswordPage from "./pages/AuthenticationPages/ForgetPasswordPage";
import InvitationPage from "./pages/AuthenticationPages/InvitationPage";
import LoginPage from "./pages/AuthenticationPages/LoginPage";
import MembersPages from "./pages/members";
import ResetPassword from "./pages/AuthenticationPages/ResetPasswordPage";
import BillBoardPages from "./pages/BillBoards";
import ToastPopup from "./components/PopupToast/ToastPopup";
import SuccessErrorToast from "./components/PopupToast/SuccessErrorToast";
import TicketsPages from "./pages/Tickets";
import CircuitsPages from "./pages/Circuits";
import File404 from "./components/404/File404";
import RolesAndAccess from "./pages/Rolesdetails/RolesAndAccess";
import { useEffect, useState } from "react";
import File502 from "./components/404/File502";
import i18next from "i18next";
import DashboardPages from "./pages/Dashboard";
import InventeryPages from "./pages/Inventory";
import * as Sentry from "@sentry/react";
import ChatPages from "./pages/Chat";
import CleaningPages from "./pages/Cleaning";
import MastersPages from "./pages/masters";
import TimeSheetPage from "./pages/TimeSheet";
import HelpCenter from "./pages/HelpCenter";

export const RequireAuth: React.FC<any> = ({ module, feature, children }) => {
  const isLoggedIn = sessionStorage.getItem("auth") ? true : false;
  const userContext: AuthenticationContextProp | null = useAuthentication();
  let hasAccess: boolean = true;
  if (module && feature) {
    hasAccess =
      userContext &&
      userContext?.role &&
      userContext?.role[module] &&
      userContext?.role[module]
        ?.map((obj: string) => obj.toLocaleLowerCase())
        ?.includes(feature.toLocaleLowerCase());
  }

  return isLoggedIn && hasAccess ? (
    children
  ) : (
    <Navigate to={routes.login} replace />
  );
};

function App() {
  const [onlineStatus, setOnlineStatus] = useState<boolean>(navigator.onLine);

  const setOnline = () => {
    setOnlineStatus(true);
  };
  const setOffline = () => {
    setOnlineStatus(false);
  };

  useEffect(() => {
    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);

    return () => {
      window.removeEventListener("offline", setOffline);
      window.removeEventListener("online", setOnline);
    };
  }, []);

  const userContext = useAuthentication();

  useEffect(() => {
    const getUserDetails = async () => {
      await userContext?.getUser();
    };
    if (userContext) {
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    document.dir = i18next.dir();
  }, []);

  const token = sessionStorage.getItem("auth");

  return (
    <>
      <Routes>
        <Route
          path={routes.root}
          element={
            <Navigate to={token ? routes.dashboard : routes.login} replace />
          }
        />

        <Route path={routes.login} element={<LoginPage />} />
        <Route path={routes.forgetPassword} element={<ForgetPasswordPage />} />
        <Route path={routes.changePassword} element={<ChangePasswordPage />} />
        <Route path={routes.resetPassword} element={<ResetPassword />} />
        <Route path={routes.invitation} element={<InvitationPage />} />
        <Route path={routes.file404} element={<File404 />} />
        <Route path={routes.file502} element={<File502 />} />
        <Route path={routes.rolesandaccess} element={<RolesAndAccess />} />
        <Route path={routes.helpCenter} element={<HelpCenter />} />
      </Routes>
      <MembersPages userContext={userContext} />
      <BillBoardPages userContext={userContext} />
      <CircuitsPages userContext={userContext} />
      <TicketsPages userContext={userContext} />
      <DashboardPages userContext={userContext} />
      <InventeryPages userContext={userContext} />
      <CleaningPages userContext={userContext} />
      <ChatPages userContext={userContext} />
      <MastersPages userContext={userContext} />
      <TimeSheetPage userContext={userContext} />
      {!onlineStatus && (
        <ToastPopup trigger={true}>
          <SuccessErrorToast
            bodyMessage={"You're currently offline"}
            isSuccess={false}
            // okButton={() => isSuccess ? loginNow() : closeErrorPopUp()}
            // buttonName='Login Now!'
            footButton={false}
          />
        </ToastPopup>
      )}
    </>
  );
}

export default Sentry.withProfiler(App);
