import { isMobile } from "react-device-detect";
import { NavLink } from "react-router-dom";
import { routes } from "../../constants/routes";
import AppLayout from "../../layouts/AppLayout";
import { useTranslation } from "react-i18next";
export default function (props: any) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <AppLayout>
        <div
          className={`${
            isMobile ? "list_members position-relative  " : "list_members p-3 "
          }`}
        >
          <div
            className={`${
              isMobile ? "container-fluid p-0 " : "container p-3 "
            }`}
          >
            {props?.userContext?.user?.role === "ADMIN" && (
              <div
                className={`${
                  isMobile ? " overflow-scroll" : " overflow-scroll px-2"
                }`}
              >
                <ul
                  className="d-flex nestedulInventory pt-3"
                  style={isMobile ? { paddingInlineStart: "1.5rem" } : {}}
                >
                  <li className="me-3">
                    <NavLink
                      to={routes.timeSheet}
                      className="text-decoration-none "
                    >
                      <span
                        className={`edit_link_routes ${
                          window.location.pathname.includes("/timesheet") &&
                          !window.location.pathname.includes("/upload") &&
                          "active"
                        }`}
                      >
                        Time Sheet
                      </span>
                    </NavLink>
                  </li>
                  <li className="mx-3">
                    <NavLink
                      to={routes.timeSheetUpload}
                      className="text-decoration-none "
                    >
                      <span
                        className={`edit_link_routes ${
                          window.location.pathname.includes("/upload") &&
                          "active"
                        }`}
                      >
                        Upload Time Sheet
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            )}
            {props.children}
          </div>
        </div>
      </AppLayout>
    </>
  );
}
