import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "../../App";
import { routes } from "../../constants/routes";
import ListTimeSheet from "./ListTimeSheet";

const TimeSheetPage = ({ userContext }: any) => {
  return (
    userContext?.roleAccess && (
      <Routes>
        <Route
          path={routes.timeSheet}
          element={
            <RequireAuth>
              <ListTimeSheet userContext={userContext} />
            </RequireAuth>
          }
        />
        <Route
          path={routes.timeSheetUpload}
          element={
            <RequireAuth>
              <ListTimeSheet userContext={userContext} />
            </RequireAuth>
          }
        />
      </Routes>
    )
  );
};

export default TimeSheetPage;
