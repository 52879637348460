import AsyncSelect from "react-select/async";
import { customStyles } from "../../interfaces/IUser";
import { components } from "react-select";

const CommonAsyncSelect = ({
  updateField,
  values,
  options,
  loadOptionValue,
  isMulti = false,
  isFieldDisabled = false,
  className = "",
  searchDisabled = true,
  cacheOptions = true,
  name = "",
  isClearable = false,
  isShow = false,
}: any) => {
  const Option = (props: any) => {
    return (
      <components.Option {...props}>
        <div className="border-0">
          <div className="p-0">
            <div className=" d-flex align-items-center justify-content-between  ">
              <div className=" d-flex align-items-center">
                <div style={{ paddingInlineStart: ".7rem" }}>
                  <div>
                    <h6 className="fw-semibold m-0 ">
                      {props?.data?.label}
                      {props?.data?.name && (
                        <span className="ps-2 fw-lighter m-0 ">
                          ({props?.data?.name})
                        </span>
                      )}
                      {props?.data?.material_name && (
                        <span className="ps-2 fw-lighter m-0 ">
                          ({props?.data?.material_name})
                        </span>
                      )}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </components.Option>
    );
  };
  return (
    <AsyncSelect
      name={name}
      isClearable={isClearable}
      styles={customStyles}
      classNamePrefix="select"
      className={`${
        isFieldDisabled && "disabled-field"
      } basic-multi-select ${className}`}
      isDisabled={isFieldDisabled}
      isMulti={isMulti}
      value={values}
      defaultOptions={options}
      cacheOptions={cacheOptions}
      loadOptions={loadOptionValue}
      onChange={(option: any) => {
        updateField(option);
      }}
      isSearchable={searchDisabled}
      components={isShow ? { Option } : {}}
      placeholder="-select-"
    />
  );
};
export default CommonAsyncSelect;
