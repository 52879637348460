import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Button, Form, FormGroup, Spinner } from "react-bootstrap";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import CommonButton from "../../../components/CommonButton/CommonButton";
import CommonAsyncSelect from "../../../components/CommonComponents/CommonAsyncSelect";
import CommonToggleSwitch from "../../../components/CommonToggleSwitch/CommonToggleSwitch";
import SuccessErrorToast from "../../../components/PopupToast/SuccessErrorToast";
import ToastPopup from "../../../components/PopupToast/ToastPopup";
import { routes } from "../../../constants/routes";
import {
  InventeryContextProp,
  useInventery,
} from "../../../context/InventeryProvider";
import AppLayout from "../../../layouts/AppLayout";
import { convertExcel } from "../../../utils/api";

export default function Reports() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const context = useInventery();
  const [isStock, setIsStock] = useState<any>(
    t("inventory.Stock Based Report")
  );

  return (
    <AppLayout>
      <div
        className="edit_members p-3 "
        style={
          isMobile
            ? { background: "#FAFAFA", height: "auto" }
            : { background: "#FAFAFA", minHeight: "80vh" }
        }
      >
        <div className="members py-4 mt-2 ">
          <h5
            className={`${isMobile ? "fs-6  mb-0 membertitle" : "  mb-0 membertitle"
              }`}
            onClick={() => navigate(routes.inventoryPurchase)}
          >
            {t("inventory.Report")}
          </h5>
          <>
            <span className={i18n.language === "en" ? "px-2 " : "d-none"}>
              <MdOutlineArrowForwardIos />
            </span>
            <span className={i18n.language === "en" ? "d-none" : "px-2"}>
              <MdArrowBackIos />
            </span>
            <h5
              className={`${isMobile ? "fs-6 text-capitalize m-0  " : " m-0 "}`}
            >
              {t("inventory.Generate Report")}
            </h5>
          </>
        </div>
        <CommonToggleSwitch
          textOff={t("inventory.Technician Based Report")}
          textOn={t("inventory.Stock Based Report")}
          checked={isStock === t("inventory.Stock Based Report")}
          onChange={(e) => {
            setIsStock(
              e?.target?.checked
                ? t("inventory.Stock Based Report")
                : t("inventory.Technician Based Report")
            );
          }}
        />
        {context &&
          (isStock === t("inventory.Stock Based Report") ? (
            <ReportForm context={context} />
          ) : (
            <TechnicianReportForm context={context} />
          ))}
      </div>
    </AppLayout>
  );
}

export const ReportForm = ({ context }: { context: InventeryContextProp }) => {
  const { t } = useTranslation();
  const [openPopup, setOpenPopup] = useState(false);
  const [bodyMessage, setBodyMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [sockList, setStockList] = useState<any>([]);

  const initialValue = {
    stock_code: "",
    stock_code_name: "",
    material_name: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: Yup.object({
      stock_code: Yup.string().required("Stock Code is required"),
    }),
    onSubmit: async (values, { validateForm }) => {
      const res = await context.generateReports({
        stock_code: values?.stock_code,
      });
      if (res) {
        convertExcel(res, "Report");
        setIsSuccess(true);
        timeOutPopUp(true);
        setBodyMessage(`${t("inventory.Report generated successfully")}`);
      }
    },
  });

  const timeOutPopUp = (value: any) => {
    let timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        setOpenPopup(false);
        setBodyMessage("");
      } else {
        setOpenPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  const getStocks = useCallback(async () => {
    const { success, stocks } = await context.loadInventoryMaterials({
      status: "ACTIVE",
    });

    if (success) {
      setStockList(
        stocks.map((d: any) => ({
          label: d?.stock_code,
          value: d?.id,
          material_name: d?.material_name,
        }))
      );
    } else {
      setStockList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getStocks();
  }, [getStocks]);

  const getSupplierOptions: any = async (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    const { stocks } = await context.loadInventoryMaterials({
      status: "ACTIVE",
      search: inputValue,
    });
    callback(
      stocks.map((d: any) => ({
        label: d?.stock_code,
        value: d?.id,
        material_name: d?.material_name,
      }))
    );
  };

  const { errors, handleSubmit, isSubmitting, resetForm } = formik;
  return (
    <div className="px-4 pb-5">
      <FormikProvider value={formik}>
        <Form noValidate={true} onSubmit={handleSubmit}>
          <div className="">
            <div className="p-3 my-4" style={{ background: "#fefefe" }}>
              <div className="row ">
                <div className="col-md-6 col-12  pe-md-4 formprofile">
                  <FormGroup className="mt-4 roleForm position-relative">
                    <label>
                      {t("inventory.Stock Code")}
                      <span className="">*</span>
                    </label>
                    <CommonAsyncSelect
                      className={"select-wrapper"}
                      isMulti={false}
                      values={{
                        label: formik?.values?.stock_code,
                        value: formik?.values?.stock_code_name,
                      }}
                      options={sockList}
                      cacheOptions
                      loadOptionValue={getSupplierOptions}
                      updateField={(option: any) => {
                        formik?.setFieldValue("stock_code", option.label);
                        formik?.setFieldValue("stock_code_name", option.value);
                        formik?.setFieldValue(
                          "material_name",
                          option.material_name
                        );
                      }}
                    />
                    <Form.Control.Feedback
                      style={{
                        display: !!errors.stock_code ? "block" : "none",
                      }}
                      type={!!errors.stock_code ? "invalid" : "valid"}
                    >
                      <ErrorMessage name="stock_code" />
                    </Form.Control.Feedback>
                  </FormGroup>
                </div>
                <div className="col-md-6 col-12  pe-md-4 formprofile">
                  <FormGroup className="mt-4 roleForm position-relative">
                    <label>
                      {t("inventory.Material Name")}
                      <span className="">*</span>
                    </label>
                    <Field
                      name="material_name"
                      disabled
                      className="form-control"
                    />
                    <Form.Control.Feedback
                      style={{
                        display: !!errors.material_name ? "block" : "none",
                      }}
                      type={!!errors.material_name ? "invalid" : "valid"}
                    >
                      <ErrorMessage name="material_name" />
                    </Form.Control.Feedback>
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end py-5">
              <CommonButton
                text={t("inventory.Cancel")}
                isLoading={isSubmitting}
                variant="outline-danger"
                className="bg-transparent text-danger"
                isDisabled={isSubmitting}
                onClick={() => resetForm()}
              />

              <CommonButton
                text={t("inventory.Generate Report")}
                isLoading={isSubmitting}
                type="submit"
                variant="success"
                className="btn-i18n text-white"
                isDisabled={isSubmitting}
              />
            </div>
          </div>
        </Form>
      </FormikProvider>

      <ToastPopup
        trigger={context?.isLoading}
        setTrigger={context?.setIsLoading}
      >
        <SuccessErrorToast isLoading={true} />
      </ToastPopup>
      <ToastPopup trigger={openPopup} setTrigger={setOpenPopup}>
        <SuccessErrorToast
          bodyMessage={bodyMessage}
          isSuccess={isSuccess}
          footButton={false}
        />
      </ToastPopup>
    </div>
  );
};

export const TechnicianReportForm = ({
  context,
}: {
  context: InventeryContextProp;
}) => {
  const { t } = useTranslation();
  const [openPopup, setOpenPopup] = useState(false);
  const [bodyMessage, setBodyMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [memberList, setMemberList] = useState<any>([]);

  const initialValue = {
    user_id: "",
    user_id_name: "",
    work_space_id: "",
    location: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: Yup.object({
      user_id: Yup.string().required("Stock Code is required"),
    }),
    onSubmit: async (values, { validateForm }) => {
      const res = await context.generateTechnicianReport({
        user_id: values?.user_id,
      });
      if (res) {
        convertExcel(res, "Report");
        setIsSuccess(true);
        timeOutPopUp(true);
        setBodyMessage(`${t("inventory.Report generated successfully")}`);
      }
    },
  });

  const timeOutPopUp = (value: any) => {
    let timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        setOpenPopup(false);
        setBodyMessage("");
      } else {
        setOpenPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  const getMember = useCallback(async () => {
    const { success, user } = await context.loadMembers({
      status: "ACTIVE",
      role: "TECHNICIAN",
    });

    if (success) {
      setMemberList(
        user.map((d: any) => ({
          label: d?.name,
          value: d?.id,
          work_space_id: d?.work_space_id,
          location: d?.branch_location,
        }))
      );
    } else {
      setMemberList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getMember();
  }, [getMember]);

  const getMemeberOptions: any = async (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    const { user } = await context.loadMembers({
      status: "ACTIVE",
      search: inputValue,
      role: "TECHNICIAN",
    });
    callback(
      user.map((d: any) => ({
        label: d?.name,
        value: d?.id,
        work_space_id: d?.work_space_id,
        location: d?.branch_location,
      }))
    );
  };

  const { errors, handleSubmit, isSubmitting, isValid, resetForm } = formik;
  return (
    <div className="px-4 pb-5">
      <FormikProvider value={formik}>
        <Form noValidate={true} onSubmit={handleSubmit}>
          <div className="">
            <div className="p-3 my-4" style={{ background: "#fefefe" }}>
              <div className="row ">
                <div className="col-md-6 col-12  pe-md-4 formprofile">
                  <FormGroup className="mt-4 roleForm position-relative">
                    <label>
                      {t("TECHNICIAN")}
                      <span className="">*</span>
                    </label>
                    <CommonAsyncSelect
                      className={"select-wrapper"}
                      isMulti={false}
                      values={{
                        label: formik?.values?.user_id_name,
                        value: formik?.values?.user_id,
                      }}
                      options={memberList}
                      cacheOptions
                      loadOptionValue={getMemeberOptions}
                      updateField={(option: any) => {
                        formik?.setFieldValue("user_id_name", option.label);
                        formik?.setFieldValue("user_id", option.value);
                        formik?.setFieldValue(
                          "work_space_id",
                          option.work_space_id
                        );
                        formik?.setFieldValue("location", option.location);
                      }}
                    />
                    <Form.Control.Feedback
                      style={{
                        display: !!errors.user_id ? "block" : "none",
                      }}
                      type={!!errors.user_id ? "invalid" : "valid"}
                    >
                      <ErrorMessage name="user_id" />
                    </Form.Control.Feedback>
                  </FormGroup>
                </div>
                <div className="col-md-6 col-12  pe-md-4 formprofile">
                  <FormGroup className="mt-4 roleForm position-relative">
                    <label>
                      {t("companyprofile.name")}
                      <span className="">*</span>
                    </label>
                    <Field
                      name="work_space_id"
                      disabled
                      className="form-control"
                    />
                    <Form.Control.Feedback
                      style={{
                        display: !!errors.work_space_id ? "block" : "none",
                      }}
                      type={!!errors.work_space_id ? "invalid" : "valid"}
                    >
                      <ErrorMessage name="stock_code" />
                    </Form.Control.Feedback>
                  </FormGroup>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-6 col-12  pe-md-4 formprofile">
                  <FormGroup className="mt-4 roleForm position-relative">
                    <label>
                      {t("companyprofile.branchLocation")}
                      <span className="">*</span>
                    </label>
                    <Field name="location" disabled className="form-control" />
                    <Form.Control.Feedback
                      style={{
                        display: !!errors.location ? "block" : "none",
                      }}
                      type={!!errors.location ? "invalid" : "valid"}
                    >
                      <ErrorMessage name="stock_code" />
                    </Form.Control.Feedback>
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end py-5">
              <Button
                className={`${isMobileOnly
                  ? "  btn_cancel mx-3 rounded-1 d-flex  justify-content-center   align-items-center"
                  : " mx-3  btn_cancel rounded-1 d-flex  justify-content-center   align-items-center"
                  }`}
                disabled={isSubmitting}
                onClick={() => resetForm()}
              >
                <span className={` ${!isValid ? "" : "fw-semibold"}`}>
                  {t("inventory.Cancel")}
                </span>
              </Button>
              <Button
                type="submit"
                className=" btn_update rounded-1 d-flex justify-content-center align-items-center"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <span className={` ${!isValid ? "" : "fw-semibold"}`}>
                    {t("inventory.Generate Report")}
                  </span>
                )}
              </Button>
            </div>
          </div>
        </Form>
      </FormikProvider>

      <ToastPopup
        trigger={context?.isLoading}
        setTrigger={context?.setIsLoading}
      >
        <SuccessErrorToast isLoading={true} />
      </ToastPopup>
      <ToastPopup trigger={openPopup} setTrigger={setOpenPopup}>
        <SuccessErrorToast
          bodyMessage={bodyMessage}
          isSuccess={isSuccess}
          footButton={false}
        />
      </ToastPopup>
    </div>
  );
};
