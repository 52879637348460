import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isMobile, isMobileOnly } from "react-device-detect";
import add from "../../assets/add.png";
import arrowup from "../../assets/arrowup.png";
import arrowdown from "../../assets/arrowdown.png";
import edit from "../../assets/edit.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import moment from "moment";
import deleteButton from "../../assets/delete.png";
const BillBoardTable = ({
  billBoard,
  userContext,
  context,
  openDeletePopUp,
}: any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (billBoard?.id) {
      context?.setBillboard_id(billBoard?.id);
    }
  }, [billBoard]);
  return (
    <>
      <td className={`${isMobileOnly ? " p-3" : "d-none "}`}>
        <div className=" d-flex justify-content-between align-items-center">
          <div>
            <h6 className=" fw-semibold billnumber m-0 pb-2 ">
              {billBoard.board_no}
            </h6>

            <h6 className="m-0 ">
              {billBoard?.ticket_stats?.pending || 0} {t("header.ticket")}
            </h6>
          </div>
          <div>
            <h6
              className="mb-2 fw-semibold textlocation text-capitalize membertitle"
              onClick={() =>
                window.open(
                  `https://www.google.com/maps/place/${billBoard?.location?.coordinates[1]},${billBoard?.location?.coordinates[0]}`
                )
              }
            >
              {billBoard.board_location?.length > 25
                ? billBoard.board_location?.slice(0, 25) + "..."
                : billBoard.board_location || "-"}
            </h6>
            <div className=" d-flex align-items-center justify-content-end">
              <div
                onClick={() => {
                  navigate(
                    routes.getEditBillBoardUrl(
                      billBoard.id,
                      context?.billBoardQueryParamsDetails()
                    )
                  );
                }}
              >
                <img src={edit} alt="" width={24} className="pointer" />
              </div>
              <div
                className="foradd"
                onClick={() => {
                  context?.removeTicket(null);
                  navigate(
                    userContext?.roleAccess["BOARD"].includes("WRITE")
                      ? routes.getQueryBillBoardTicketsAddUrl(
                          billBoard.id,
                          context?.billBoardQueryParamsDetails()
                        )
                      : routes.getQueryBillBoardTicketsIdUrl(
                          billBoard.id,
                          0,
                          context?.billBoardQueryParamsDetails()
                        )
                  );
                }}
              >
                <img src={add} alt="" width={24} className="pointer" />
              </div>
              {userContext?.roleAccess["BOARD"].includes("DELETE") &&
                billBoard?.ticket_stats?.pending === 0 && (
                  <div
                    className=""
                    onClick={() =>
                      openDeletePopUp(
                        billBoard?.id,
                        `${t("successError.delete this billBoard")}`,
                        `${t("successError.delete it")}`
                      )
                    }
                  >
                    <img
                      src={deleteButton}
                      alt=""
                      width={24}
                      className="pointer"
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className=" d-flex justify-content-between align-items-center py-3">
          <div className=" d-flex flex-column  align-items-center">
            {billBoard?.board_status === "NON_OPERATIONAL" ? (
              <div className="arrow_round_red ">
                <img src={arrowdown} alt="" width={15} />
              </div>
            ) : (
                context?.socketData?.id === billBoard?.id
                  ? context?.socketData?.teamviewer === "online"
                  : billBoard?.teamviewer_status === "online"
              ) ? (
              <div className="arrow_round text-center ">
                <img src={arrowup} alt="" width={15} />
              </div>
            ) : (
              <div className="arrow_round_red ">
                <img src={arrowdown} alt="" width={15} />
              </div>
            )}
            <div className="pt-2">
              <h6 className=" ff-questrial bill_tittle m-0">
                {t("billboard.team")}
              </h6>
            </div>
          </div>
          <div
            className={`d-flex flex-column align-items-center ${
              !billBoard.is_novastar ? " justify-content-center" : ""
            }`}
          >
            {billBoard?.is_novastar ? (
              <React.Fragment>
                {billBoard?.board_status === "NON_OPERATIONAL" ? (
                  <div className="arrow_round_red ">
                    <img src={arrowdown} alt="" width={15} />
                  </div>
                ) : (
                    context?.socketData?.id === billBoard?.id &&
                    context?.socketData?.novastar === "online"
                      ? context?.socketData?.novastar === "online"
                      : billBoard?.novastar_status === "online"
                  ) ? (
                  <div className="arrow_round text-center ">
                    <img src={arrowup} alt="" width={15} />
                  </div>
                ) : (
                  <div className="arrow_round_red ">
                    <img src={arrowdown} alt="" width={15} />
                  </div>
                )}
                <div className="pt-2">
                  <h6 className=" ff-questrial bill_tittle m-0">
                    {t("billboard.novastar")}
                  </h6>
                </div>
              </React.Fragment>
            ) : (
              <h6 className="m-0 fw-normal p-0">NA</h6>
            )}
          </div>

          <div
            className={`d-flex flex-column align-items-center ${
              !billBoard.is_ivms ? " justify-content-center" : ""
            }`}
          >
            {billBoard.is_ivms ? (
              <React.Fragment>
                {billBoard?.board_status === "NON_OPERATIONAL" ? (
                  <div className="arrow_round_red ">
                    <img src={arrowdown} alt="" width={15} />
                  </div>
                ) : (
                    context?.socketData?.id === billBoard?.id &&
                    context?.socketData?.ivms === "online"
                      ? context?.socketData?.ivms === "online"
                      : billBoard?.ivms_status === "online"
                  ) ? (
                  <div className="arrow_round text-center ">
                    <img src={arrowup} alt="" width={15} />
                  </div>
                ) : (
                  <div className="arrow_round_red ">
                    <img src={arrowdown} alt="" width={15} />
                  </div>
                )}
                <div className="pt-2">
                  <h6 className=" ff-questrial bill_tittle m-0">IVMS 4200</h6>
                </div>
              </React.Fragment>
            ) : (
              <h6 className="m-0 fw-normal p-0">NA</h6>
            )}
          </div>
        </div>
        <div className="  ">
          <div className="billformobile">
            <span>
              {t("billboard.billtype")} - {billBoard.board_type}
            </span>
            <span className="screensizeborder px-2 mx-2">
              {t("billboard.screensize")} - {billBoard.screen_size}
            </span>
            <span>
              {t("billboard.screenresolution")} - {billBoard.screen_resolution}
            </span>
          </div>
        </div>
      </td>
      <td className={`${isMobileOnly ? "d-none " : "ps-3 py-5"}`}>
        <h6 className=" fw-semibold billnumber m-0 pb-2 ">
          {billBoard.board_no}
        </h6>

        <h6 className="m-0 ">
          {billBoard?.ticket_stats?.pending || 0} {t("header.ticket")}
        </h6>
      </td>
      <td className={`${isMobileOnly ? "d-none " : " "}`}>
        <div className="d-flex align-items-center">
          {(
            context?.socketData?.id === billBoard?.id &&
            context?.socketData?.teamviewer === "online"
              ? context?.socketData?.teamviewer === "online"
              : billBoard?.teamviewer_status === "online"
          ) ? (
            <div className="arrow_round text-center ">
              <img src={arrowup} alt="" width={15} />
            </div>
          ) : (
            <div className="arrow_round_red ">
              <img src={arrowdown} alt="" width={15} />
            </div>
          )}
          <div className="teambox">
            <h6 className=" ff-questrial bill_tittle m-0">
              {t("billboard.team")}
            </h6>
            <div
              className={
                i18n.language === "en" ? "dateandtime" : "dateandtimeArabic"
              }
            >
              <h6 className="m-0 fw-normal p-0">
                {context?.socketData?.id === billBoard?.id &&
                context?.socketData?.teamviewer_last_online
                  ? moment(context?.socketData?.teamviewer_last_online).format(
                      "DD MMM YYYY"
                    )
                  : moment(billBoard?.teamviewer_status_on).format(
                      "DD MMM YYYY"
                    )}
                <span className={`${isMobile ? "d-none " : " billtime"}`}>
                  {context?.socketData?.id === billBoard?.id &&
                  context?.socketData?.teamviewer_last_online
                    ? moment(
                        context?.socketData?.teamviewer_last_online
                      ).format(" hh:mm A")
                    : moment(billBoard?.teamviewer_status_on).format(
                        " hh:mm A"
                      )}
                </span>
              </h6>
            </div>
          </div>
        </div>
      </td>
      <td className={`${isMobileOnly ? "d-none " : " "}`}>
        <div
          className={`d-flex align-items-center ${
            !billBoard.is_novastar ? " justify-content-center" : ""
          }`}
        >
          {billBoard.is_novastar ? (
            <React.Fragment>
              {(
                context?.socketData?.id === billBoard?.id &&
                context?.socketData?.novastar === "online"
                  ? context?.socketData?.novastar === "online" ||
                    context?.socketData?.novastar === "alaram" ||
                    context?.socketData?.novastar === "fault"
                  : billBoard?.novastar_status === "online" ||
                    billBoard?.novastar_status === "alaram" ||
                    billBoard?.novastar_status === "fault"
              ) ? (
                <div className="arrow_round text-center ">
                  <img src={arrowup} alt="" width={15} />
                </div>
              ) : (
                <div className="arrow_round_red ">
                  <img src={arrowdown} alt="" width={15} />
                </div>
              )}
              <div className="teambox">
                <h6 className=" ff-questrial bill_tittle m-0">
                  {t("billboard.novastar")}
                </h6>
                <div
                  className={
                    i18n.language === "en" ? "dateandtime" : "dateandtimeArabic"
                  }
                >
                  <h6 className="m-0 fw-normal p-0">
                    {context?.socketData?.id === billBoard?.id &&
                    context?.socketData?.novastar_last_online
                      ? moment(
                          context?.socketData?.novastar_last_online
                        ).format("DD MMM YYYY")
                      : moment(billBoard?.novastar_status_on).format(
                          "DD MMM YYYY"
                        )}
                    <span className={`${isMobile ? "d-none " : " billtime"}`}>
                      {context?.socketData?.id === billBoard?.id &&
                      context?.socketData?.novastar_last_online
                        ? moment(
                            context?.socketData?.novastar_last_online
                          ).format(" hh:mm A")
                        : moment(billBoard?.novastar_status_on).format(
                            " hh:mm A"
                          )}
                    </span>
                  </h6>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <h6 className="m-0 fw-normal p-0">NA</h6>
          )}
        </div>
      </td>
      <td className={`${isMobileOnly ? "d-none " : " "}`}>
        <div
          className={`d-flex align-items-center ${
            !billBoard.is_ivms ? " justify-content-center" : ""
          }`}
        >
          {billBoard.is_ivms ? (
            <React.Fragment>
              {(
                context?.socketData?.id === billBoard?.id &&
                context?.socketData?.ivms === "online"
                  ? context?.socketData?.ivms === "online"
                  : billBoard?.ivms_status === "online"
              ) ? (
                <div className="arrow_round text-center ">
                  <img src={arrowup} alt="" width={15} />
                </div>
              ) : (
                <div className="arrow_round_red ">
                  <img src={arrowdown} alt="" width={15} />
                </div>
              )}
              <div className="teambox">
                <h6 className=" ff-questrial bill_tittle m-0">IVMS 4200</h6>
                <div
                  className={
                    i18n.language === "en" ? "dateandtime" : "dateandtimeArabic"
                  }
                >
                  <h6 className="m-0 fw-normal p-0">
                    {context?.socketData?.id === billBoard?.id &&
                    context?.socketData?.ivms_last_online
                      ? moment(context?.socketData?.ivms_last_online).format(
                          "DD MMM YYYY"
                        )
                      : moment(billBoard?.ivms_status_on).format("DD MMM YYYY")}
                    <span className={`${isMobile ? "d-none " : " billtime"}`}>
                      {context?.socketData?.id === billBoard?.id &&
                      context?.socketData?.ivms_last_online
                        ? moment(context?.socketData?.ivms_last_online).format(
                            " hh:mm A"
                          )
                        : moment(billBoard?.ivms_status_on).format(" hh:mm A")}
                    </span>
                  </h6>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <h6 className="m-0 fw-normal p-0">NA</h6>
          )}
        </div>
      </td>

      <td className={`${isMobileOnly ? "d-none " : "pe-3 "}`}>
        <div className=" d-flex justify-content-end">
          <h6
            className="mb-2 fw-semibold textlocation text-capitalize membertitle"
            onClick={() =>
              window.open(
                // `https://www.google.com/maps/dir//${billBoard.board_location}`
                `https://www.google.com/maps/place/${billBoard?.location?.coordinates[1]},${billBoard?.location?.coordinates[0]}`
              )
            }
          >
            {billBoard.board_location?.length > 25
              ? billBoard.board_location.slice(0, 25) + "..."
              : billBoard.board_location || "-"}
          </h6>
        </div>

        {
          <div className=" d-flex align-items-center justify-content-end">
            <div
              onClick={() => {
                navigate(
                  routes.getEditBillBoardUrl(
                    billBoard.id,
                    context?.billBoardQueryParamsDetails()
                  )
                );
              }}
            >
              <img src={edit} alt="" width={24} className="pointer" />
            </div>
            <div
              className="foradd"
              onClick={() => {
                context?.removeTicket(null);
                navigate(
                  userContext?.roleAccess["BOARD"].includes("WRITE")
                    ? routes.getQueryBillBoardTicketsAddUrl(
                        billBoard.id,
                        context?.billBoardQueryParamsDetails()
                      )
                    : routes.getQueryBillBoardTicketsIdUrl(
                        billBoard.id,
                        0,
                        context?.billBoardQueryParamsDetails()
                      )
                );
              }}
            >
              <img src={add} alt="" width={24} className="pointer" />
            </div>
            {userContext?.roleAccess["BOARD"].includes("DELETE") &&
              billBoard?.ticket_stats?.pending === 0 && (
                <div
                  className="foradd"
                  onClick={() =>
                    openDeletePopUp(
                      billBoard?.id,
                      `${t("successError.delete this billBoard")}`,
                      `${t("successError.delete it")}`
                    )
                  }
                >
                  <img
                    src={deleteButton}
                    alt=""
                    width={24}
                    className="pointer"
                  />
                </div>
              )}
          </div>
        }
      </td>
      <div
        className={`${
          isMobileOnly ? "d-none " : " board_details px-0 pt-3 me-3  "
        }`}
      >
        <div className={`${isMobileOnly ? "billtypesmobile " : " billtypes"}`}>
          <span>
            {t("billboard.billtype")} - {billBoard.board_type}
          </span>
          <span className=" screensizeborder px-2 mx-2">
            {t("billboard.screensize")} - {billBoard.screen_size}
          </span>
          <span>
            {t("billboard.screenresolution")} - {billBoard.screen_resolution}
          </span>
        </div>
      </div>
    </>
  );
};

export default BillBoardTable;
