import { useEffect, useState } from "react";
import { Button, Dropdown, Table } from "react-bootstrap";
import {
  isDesktop,
  isMobile,
  isMobileOnly,
  isTablet,
} from "react-device-detect";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import edit from "../../assets/edit.png";
import error from "../../assets/error.png";
import errorg from "../../assets/errorgray.png";
import filterby from "../../assets/filter.png";
import reset from "../../assets/reset.png";
import sorting from "../../assets/sorting.png";
import success from "../../assets/tick.png";
import successg from "../../assets/tickgray.png";
import CommonSearch from "../../components/CommonSearch/CommonSearch";
import SuccessErrorToast from "../../components/PopupToast/SuccessErrorToast";
import ToastPopup from "../../components/PopupToast/ToastPopup";
import ToastPopupSecond from "../../components/PopupToast/ToastPopupSecond";
import { routes } from "../../constants/routes";
import AppLayout from "../../layouts/AppLayout";

let timedPopupSetTimeOut: any = undefined;

const ListCircuits = () => {
  const { t, i18n } = useTranslation();
  const [timedPopup, setTimedPopup] = useState(false);
  const [isBlockOrUnBlockPopUp, setIsBlockOrUnBlockPopUp] = useState(false);
  const [sort, setSort] = useState("-created");
  const [role, setRole] = useState("");
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState("");
  const [department, setDepartment] = useState("");
  const [, setCurrentTicketId] = useState("");
  const [, setBlockOrUnBlockText] = useState<any>("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [isSuccess] = useState(false);
  const [openfilter, setOpenfilter] = useState(false);
  const [opensort, setOpenSort] = useState(false);

  useEffect(() => {
    loadData();
  }, [search, sort]);

  const loadData = async () => {
    // context?.loadTickets(params);
    // navigate(routes.listTicketsUrl(params));
  };

  const sortByOpen = () => {
    setOpenfilter(false);
    setOpenSort(!opensort);
  };

  const applyFilter = () => {
    loadData();
    filterByOpen();
  };

  const resetFilter = () => {
    setRole("");
    setStatus("");
    setDepartment("");
    loadData();
    filterByOpen();
  };

  const filterByOpen = () => {
    setOpenSort(false);
    setOpenfilter(!openfilter);
  };

  const handleEnterKey = (e: any) => {
    if (e.target.value.length === 0) {
      setSearch(e.target.value);
    }
    if (e.key === "Enter") {
      setSearch(e.target.value);
    }
  };

  const openBlockPopUp = (id: any, value: any) => {
    setCurrentTicketId(id);
    setIsBlockOrUnBlockPopUp(true);
    setBlockOrUnBlockText(value);
  };

  const timeOutPopUp = (value: any) => {
    timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        setTimedPopup(false);
        setBodyMessage("");
      } else {
        setTimedPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  return (
    <AppLayout>
      <div
        className={`${
          isMobile ? "list_members position-relative  " : "list_members p-3 "
        }`}
      >
        <div
          className={`${
            isMobile ? "container-fluid position-relative " : "container "
          }`}
        >
          <div
            className={`${
              isMobile
                ? "d-none "
                : "pt-4 d-flex align-items-center justify-content-between "
            }`}
          >
            <div className="members ">
              <h5 className=" mb-0 ">
                {t("ListCircuit.All_circuit")}
                {/* {context?.meta ? context?.meta?.total_count : 0}) */}
              </h5>
            </div>
            <div
              className={`${
                isMobile ? "d-none " : "container-fluid p-0 forinput"
              }`}
            >
              <CommonSearch
                placeholder={t("ListCircuit.search") || ""}
                onKeyDown={(e: any) => handleEnterKey(e)}
                onChange={(e: any) =>
                  e.target.value.length === 0 && setSearch(e.target.value)
                }
              />

              {/* <button
                    id="doc_searchQuerySubmit"
                    type="submit"
                    name="doc_searchQuerySubmit"
                    // className=" position-absolute "
                  >
                    <svg width={24} height={24} viewBox="0 0 24 24">
                      <path
                        fill="#bcbcbc"
                        d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                      />
                    </svg>
                  </button> */}
            </div>

            <div className="">
              <div className="d-flex  align-items-center justify-content-end">
                <div className=" sort-z">
                  <div className=" position-relative">
                    <div
                      className=" d-flex sortbox "
                      onClick={() => {
                        sortByOpen();
                      }}
                    >
                      <h6 className="m-0 by fw-normal">
                        {sort === "-name"
                          ? `${t("listmember.ZtoA")}`
                          : sort === "name"
                          ? `${t("listmember.AtoZ")}`
                          : sort === "-createdAt"
                          ? `${t("listmember.Recent")}`
                          : sort === "createdAt"
                          ? `${t("listmember.Datedecen")}`
                          : `${t("listmember.sortBy")}`}
                      </h6>
                      <div className="mx-2">
                        <img src={sorting} alt="" width={16} />
                      </div>
                    </div>
                    <div
                      className={`dropdownsort ${
                        opensort ? "active" : "inactive"
                      }`}
                    >
                      <DropdownSort setSort={setSort} sortByOpen={sortByOpen} />
                    </div>
                  </div>
                </div>

                <div className=" filter-z">
                  <div className=" position-relative">
                    <div
                      className=" d-flex filterbox   "
                      onClick={() => filterByOpen()}
                    >
                      <h6 className="m-0 by fw-normal">{t("filter.filter")}</h6>
                      <div className="mx-2">
                        <img src={filterby} alt="" width={16} />
                      </div>
                    </div>
                    <div
                      className={`dropdownfilter ${
                        openfilter ? "active" : "inactive"
                      }`}
                    >
                      <Dropdownfilter
                        filterByOpen={filterByOpen}
                        setRole={setRole}
                        role={role}
                        status={status}
                        applyFilter={applyFilter}
                        resetFilter={resetFilter}
                        setStatus={setStatus}
                        setDepartment={setDepartment}
                        department={department}
                      />
                    </div>
                  </div>
                </div>

                <NavLink to={routes.newTicket} className="">
                  <Button className=" btn_invite_member ">
                    {t("ListCircuit.new")}
                    <span>
                      {/* <FiPlusCircle color="#fafafa" fontSize={22} /> */}
                    </span>
                  </Button>
                </NavLink>
              </div>
            </div>
          </div>
          <div className={`${isDesktop ? "d-none " : " pt-4"}`}>
            <div
              className={`${
                isDesktop
                  ? "d-none "
                  : " d-flex align-items-center justify-content-between "
              }`}
            >
              <div className="members ">
                <h5 className="m-0">
                  {t("ListCircuit.All_circuit")}
                  {/* (
                  {context?.meta ? context?.meta?.total_count : 0}) */}
                </h5>
              </div>
              <div className="d-flex">
                <div className={`${isTablet ? " d-flex" : "d-none  "}`}>
                  <div className=" sort-z">
                    <div className=" position-relative">
                      <div
                        className=" d-flex sortbox ms-4"
                        onClick={() => {
                          sortByOpen();
                        }}
                      >
                        <h6 className="m-0 by fw-normal">
                          {sort === "-name"
                            ? `${t("listmember.ZtoA")}`
                            : sort === "name"
                            ? `${t("listmember.AtoZ")}`
                            : sort === "-createdAt"
                            ? `${t("listmember.Recent")}`
                            : sort === "createdAt"
                            ? `${t("listmember.Datedecen")}`
                            : `${t("listmember.sortBy")}`}
                        </h6>
                        <div className="mx-2">
                          <img src={sorting} alt="" width={16} />
                        </div>
                      </div>
                      <div
                        className={`dropdownsort ${
                          opensort ? "active" : "inactive"
                        }`}
                      >
                        <DropdownSort
                          setSort={setSort}
                          sortByOpen={sortByOpen}
                        />
                      </div>
                    </div>
                  </div>

                  <div className=" filter-z">
                    <div className=" position-relative">
                      <div
                        className=" d-flex filterbox mx-4"
                        onClick={() => filterByOpen()}
                      >
                        <h6 className="m-0 by fw-normal">
                          {t("filter.filter")}
                        </h6>

                        <div className="mx-2">
                          <img src={filterby} alt="" width={16} />
                        </div>
                      </div>
                      <div
                        className={`dropdownfilter ${
                          openfilter ? "active" : "inactive"
                        }`}
                      >
                        <Dropdownfilter
                          filterByOpen={filterByOpen}
                          setRole={setRole}
                          role={role}
                          status={status}
                          applyFilter={applyFilter}
                          resetFilter={resetFilter}
                          setStatus={setStatus}
                          setDepartment={setDepartment}
                          department={department}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center ">
                  <NavLink to={routes.newTicket} className="">
                    <Button
                      className={`${
                        isDesktop ? " btn_invite_member" : " btn_in_member"
                      }`}
                    >
                      {t("ListCircuit.new")}
                      <span>
                        {/* <FiPlusCircle color="#fafafa" fontSize={22} /> */}
                      </span>
                    </Button>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="pt-5">
              <CommonSearch
                placeholder={t("ListCircuit.search") || ""}
                onKeyDown={(e: any) => handleEnterKey(e)}
                onChange={(e: any) =>
                  e.target.value.length === 0 && setSearch(e.target.value)
                }
              />
            </div>
          </div>

          <div className=" pt-4 table-responsive">
            <Table responsive="sm table_class">
              <thead>
                <tr className="table_white_head">
                  <th className={`${isMobileOnly ? " py-4" : "py-5 "}`}>
                    Circuit Name
                  </th>
                  <th className={`${isMobile ? "d-none " : " "}`}>
                    Circuit InCharge
                  </th>
                  <th className={`${isMobile ? "d-none " : " "}`}>
                    No. of BillBoards
                  </th>
                  <th className={`${isMobileOnly ? "  d-none" : " "}`}>
                    No. of Tickets
                  </th>
                  <th className={`${isMobileOnly ? "  d-none" : " "}`}>
                    Action
                  </th>
                </tr>
              </thead>
              {/* {context?.tickets && context?.tickets?.length > 0 ? ( */}
              <tbody className="table_white">
                {/* {context?.tickets.map((ticket: any, index: number) => {
                    return ( */}
                <tr
                  // key={ticket.id}
                  className="table_white"
                >
                  <td className={`${isMobile ? "" : "py-5 "}`}>
                    Circuit One - BB1-08122202208DEC2022
                  </td>
                  <td
                    className={`${
                      isMobile
                        ? " py-4 d-flex justify-content-between"
                        : "d-none "
                    }`}
                  >
                    <div>
                      <h6 className=" pt-"></h6>
                    </div>

                    <td className={`${isMobileOnly ? "   " : " d-none  "}`}>
                      <div className="text-decoration-none ">
                        <div
                          // onClick={() =>
                          //   navigate(routes.editTicketUrl(ticket?.id))
                          // }
                          className="pb-2"
                        >
                          <img
                            src={edit}
                            alt=""
                            width={22}
                            className="pointer"
                          />
                        </div>

                        <div
                          // onClick={() =>
                          //   openBlockPopUp(
                          //     ticket?.id,
                          //     "approve the ticket"
                          //   )
                          // }
                          className="pb-2"
                        >
                          <img
                            src={success}
                            alt=""
                            width={27}
                            className="pointer"
                          />
                          <img
                            src={successg}
                            alt=""
                            width={27}
                            className="pointer"
                          />
                        </div>
                        <div
                          // onClick={() =>
                          //   openBlockPopUp(
                          //     ticket?.id,
                          //     "reject the ticket"
                          //   )
                          // }
                          className="pb-2"
                        >
                          <img
                            src={errorg}
                            alt=""
                            width={27}
                            className="pointer"
                          />
                          <img
                            src={error}
                            alt=""
                            width={27}
                            className="pointer"
                          />
                        </div>
                        <div
                          // onClick={() =>
                          //   openBlockPopUp(
                          //     ticket?.id,
                          //     "delete the ticket"
                          //   )
                          // }
                          className="pb-2"
                        ></div>
                      </div>
                    </td>
                  </td>

                  <td className={`${isMobile ? "d-none " : " "}`}>
                    <div
                      className={
                        i18n.language === "en"
                          ? "dateandtime"
                          : "dateandtimeArabic"
                      }
                    ></div>
                    Sangeeth
                  </td>
                  <td className={`${isMobileOnly ? "d-none " : " "}`}>100</td>
                  <td className={`${isMobileOnly ? "d-none " : " "}`}>10</td>
                  <td
                    className={`${isMobileOnly ? "  py-5  d-none" : " py-5  "}`}
                  ></td>
                </tr>
                {/* );
                  })} */}
              </tbody>
              {/* ) : ( */}
              {/* <div className=" d-flex justify-content-center align-items-center">
                No Memeber Found
              </div> */}
              {/* )} */}
            </Table>
          </div>
          {/* {context?.meta &&
            context?.Circuits &&
            context?.tickets?.length > 0 && ( */}
          {/* <Pagination
                meta={context?.meta}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                currentPageCount={context?.length}
              /> */}
          {/* )} */}
        </div>
        <ToastPopup trigger={timedPopup} setTrigger={setTimedPopup}>
          <SuccessErrorToast
            bodyMessage={bodyMessage}
            isSuccess={isSuccess}
            // okButton={() => isSuccess ? loginNow() : closeErrorPopUp()}
            // buttonName='Login Now!'
            footButton={false}
          />
        </ToastPopup>
        <ToastPopupSecond
          trigger={isBlockOrUnBlockPopUp}
          setTrigger={setIsBlockOrUnBlockPopUp}
        >
          {/* <DeleteBlockToast
            setTrigger={(status: boolean) => {
              loadData();
              setIsBlockOrUnBlockPopUp(status);
            }}
            closePopUp={(data: any) => setIsBlockOrUnBlockPopUp(data)}
            submit={() =>
              blockOrUnBlockText === "approve by staff the ticket"
                ? approveTicket(true)
                : blockOrUnBlockText === "approve by supervisor the ticket"
                ? approveTicket(false)
                : blockOrUnBlockText === "reject by staff the ticket"
                ? rejectTicket(true)
                : blockOrUnBlockText === "reject by supervisor the ticket"
                ? rejectTicket(false)
                : blockOrUnBlockText === "delete the ticket"
                ? deleteTicket()
                : reassignTicket()
            }
            textMessage={blockOrUnBlockText}
          /> */}
        </ToastPopupSecond>
        <div className={`${isMobileOnly ? " position-relative" : "d-none"}`}>
          <SortAndFilterForMobile
            setSort={setSort}
            sortByOpen={sortByOpen}
            opensort={opensort}
            sort={sort}
            openfilter={openfilter}
            filterByOpen={filterByOpen}
            setRole={setRole}
            status={status}
            role={role}
            applyFilter={applyFilter}
            resetFilter={resetFilter}
            setStatus={setStatus}
            setDepartment={setDepartment}
            department={department}
          />
        </div>
      </div>
    </AppLayout>
  );
};

export default ListCircuits;

const DropdownSort = ({ setSort, sortByOpen }: any) => {
  const { t } = useTranslation();
  const updateSortFilter = (value: any) => {
    setSort(value);
    sortByOpen();
  };

  return (
    <div>
      <div className=" pt-2 d-flex justify-content-between align-items-center sortingline">
        <h6 className=" fw-bold m-0 "> {t("listmember.sortBy")} </h6>
      </div>
      <div className="pt-3 sortingh6">
        <h6 onClick={() => updateSortFilter("-createdAt")}>
          {t("listmember.Recent")}
        </h6>
        <h6 onClick={() => updateSortFilter("name")}>{t("listmember.AtoZ")}</h6>
        <h6 onClick={() => updateSortFilter("-name")}>
          {t("listmember.ZtoA")}
        </h6>
        <h6 onClick={() => updateSortFilter("createdAt")}>
          {t("listmember.Datedecen")}
        </h6>
      </div>
    </div>
  );
};

const SortAndFilterForMobile = ({
  setSort,
  opensort,
  sort,
  filterByOpen,
  openfilter,
  setRole,
  role,
  status,
  applyFilter,
  resetFilter,
  setStatus,
  setDepartment,
  department,
  setResponsibleArea,
  sortByOpen,
}: any) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="sortfilterMobile row m-0">
      <div
        className={
          i18n.language === "en"
            ? "col p-0 d-flex justify-content-evenly align-items-center border-end border-2 "
            : "col p-0 d-flex justify-content-evenly align-items-center border-start border-2 "
        }
        onClick={() => sortByOpen()}
      >
        <div className=" text-center  ">
          <h6 className="m-0 by fw-normal">
            {sort === "-name"
              ? `${t("listmember.ZtoA")}`
              : sort === "name"
              ? `${t("listmember.AtoZ")}`
              : sort === "-createdAt"
              ? `${t("listmember.Recent")}`
              : sort === "createdAt"
              ? `${t("listmember.Datedecen")}`
              : `${t("listmember.sortBy")}`}
          </h6>
        </div>
        <div>
          <img src={sorting} alt="" width={16} />
        </div>

        <div
          className={`dropdownsortMobile ${opensort ? "active" : "inactive"}`}
        >
          <DropdownSort setSort={setSort} sortByOpen={sortByOpen} />
        </div>
      </div>
      <div
        className="col p-0 d-flex justify-content-evenly align-items-center "
        onClick={() => filterByOpen()}
      >
        <h6 className="m-0 by fw-normal">{t("filter.filter")}</h6>

        <div>
          <img src={filterby} alt="" width={16} />
        </div>
        <div
          className={`dropdownfilterMobile ${
            openfilter ? "active" : "inactive"
          }`}
        >
          <Dropdownfilter
            role={role}
            filterByOpen={filterByOpen}
            setRole={setRole}
            status={status}
            applyFilter={applyFilter}
            resetFilter={resetFilter}
            setStatus={setStatus}
            setDepartment={setDepartment}
            department={department}
            setResponsibleArea={setResponsibleArea}
          />
        </div>
      </div>
    </div>
  );
};

const Dropdownfilter = ({
  setRole,
  role,
  status,
  applyFilter,
  filterByOpen,
  resetFilter,
  setStatus,
  setDepartment,
  department,
  setResponsibleArea,
}: any) => {
  const { t } = useTranslation();
  return (
    <div className="">
      <div className=" d-flex justify-content-between align-items-center filterline pt-2">
        <h6 className=" fw-bold m-0"> {t("filter.fil")}</h6>
        <div
        // className={`${isMobileOnly ? "" : "d-none"}`}
        >
          <img
            src={reset}
            alt=""
            width={16}
            onClick={() => {
              resetFilter();
            }}
            className="pointer"
          />
        </div>
      </div>

      <div className="py-2">
        <h6> {t("filter.department")}</h6>
        <div className="fordesign">
          <Dropdown className="">
            <Dropdown.Toggle
              variant="success"
              id={`${isMobileOnly ? "dropdownbasic" : "dropdown-basic"}`}
            >
              {department === "CLEANING"
                ? `${t("filter.cleaning")}`
                : department === "ELECTRICAL"
                ? `${t("filter.electrical")}`
                : department === "MONITORING"
                ? `${t("filter.monitoring")}`
                : department === "STAFF_MANAGEMENT"
                ? `${t("filter.staffm")}`
                : department === "STORE_MANAGEMENT"
                ? `${t("filter.Storem")}`
                : `${t("filter.department")}`}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setDepartment("")}>
                {t("filter.all")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setDepartment("CLEANING")}>
                {t("filter.cleaning")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setDepartment("ELECTRICAL")}>
                {t("filter.electrical")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setDepartment("MONITORING")}>
                {t("filter.monitoring")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setDepartment("STAFF_MANAGEMENT")}>
                {t("filter.staffm")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setDepartment("STORE_MANAGEMENT")}>
                {t("filter.Storem")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="py-2 ">
        <h6>{t("filter.status")}</h6>
        <div className="Status ">
          <h6
            className={
              status === "CREATED"
                ? "act_btn mb-3 filter-btn-active btn"
                : "act_btn mb-3 btn"
            }
            onClick={() => setStatus("CREATED")}
          >
            {t("ListTickets.CREATED")}
          </h6>
          <h6
            className={
              status === "VIEWED"
                ? "pen_btn mb-3 filter-btn-active btn"
                : "pen_btn mb-3 btn"
            }
            onClick={() => setStatus("VIEWED")}
          >
            {t("ListTickets.VIEWED")}
          </h6>
          <h6
            className={
              status === "SUBMITTED"
                ? "act_btn mb-3 filter-btn-active btn"
                : "act_btn mb-3 btn"
            }
            onClick={() => setStatus("SUBMITTED")}
          >
            {t("ListTickets.SUBMITTED")}
          </h6>
          <h6
            className={
              status === "APPROVED"
                ? "pen_btn mb-3 filter-btn-active btn"
                : "pen_btn mb-3 btn"
            }
            onClick={() => setStatus("APPROVED")}
          >
            {t("ListTickets.APPROVED")}
          </h6>
          <h6
            className={
              status === "CLOSED"
                ? "act_btn mb-3 filter-btn-active btn"
                : "act_btn mb-3 btn"
            }
            onClick={() => setStatus("CLOSED")}
          >
            {t("ListTickets.CLOSED")}
          </h6>
          <h6
            className={
              status === "REASSIGNED"
                ? "pen_btn mb-3 filter-btn-active btn"
                : "pen_btn mb-3 btn"
            }
            onClick={() => setStatus("REASSIGNED")}
          >
            {t("ListTickets.REASSIGNED")}
          </h6>
          <h6
            className={
              status === "REJECTED"
                ? "act_btn mb-3 filter-btn-active btn"
                : "act_btn mb-3 btn"
            }
            onClick={() => setStatus("REJECTED")}
          >
            {t("ListTickets.REJECTED")}
          </h6>
        </div>
      </div>
      <div className=" d-flex justify-content-start pt-3">
        <Button
          type="submit"
          className="  btn_cancel rounded-1 d-flex  justify-content-center   align-items-center"
          onClick={() => filterByOpen()}
          // disabled={
          //   role.length > 0 ||
          //   status.length > 0 ||
          //   department.length > 0 ||
          //   responsibleArea.length > 0
          // }
        >
          {t("filter.close")}
        </Button>
        <Button
          type="submit"
          className=" mx-3 btn_update rounded-1 d-flex justify-content-center align-items-center"
          onClick={() => applyFilter()}
          // disabled={
          //   role.length > 0 ||
          //   status.length > 0 ||
          //   department.length > 0 ||
          //   responsibleArea.length > 0
          // }
        >
          {t("filter.apply")}
        </Button>
      </div>
    </div>
  );
};
