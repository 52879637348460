import { useState } from "react";
import PersonalProfileForm from "./PersonalProfileForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EditPage from "./EditPage";
import { routes } from "../../constants/routes";
import ChangePasswordForm from "./ChangeAndResetPasswordForm";
import CompanyProfileForm from "./CompanyProfileForm";
import VehicleInformationForm from "./VehicleInformationForm";
import ToastPopup from "../../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../../components/PopupToast/SuccessErrorToast";
import { useMembers } from "../../context/MembersProvider";
import ToastPopupSecond from "../../components/PopupToast/ToastPopupSecond";
import DeleteBlockToast from "../../components/PopupToast/DeleteBlockToast";
import { t } from "i18next";
import { useAuthentication } from "../../context/AuthenticationProvider";
import ImagePopUp from "../../components/PopupToast/ImagePopUp";

let timedPopupSetTimeOut: any = undefined;

const UpdateProfileOrMember = ({
  userContext,
  roleOptions,
  departmentOptions,
  vehicleOptions,
}: any) => {
  const authContext: any = useAuthentication();
  const context: any = useMembers();
  const { memberId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [bodyMessage, setBodyMessage] = useState("");
  const [timedPopup, setTimedPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [imageShow, setImageShow] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [updateProfileDetail, setUpdateProfileDetail] = useState<any>({});
  const [formikFunction, setFormikFunction] = useState<any>({});
  const [textMessage, setTextMessage] = useState<any>("");
  const [showPopUp, setShowPopUp] = useState<any>(false);
  const [headerMessage, setHeaderMessage] = useState<any>("");

  const timeOutPopUp = (value: any) => {
    timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        setBodyMessage("");
        setTimedPopup(false);
        if (headerMessage === `${t("successError.cancel the changes")}`) {
          setTimedPopup(false);
        }
        if (
          headerMessage !== `${t("successError.cancel the changes")}` &&
          location.pathname === routes.adminChangePassword
        ) {
          navigate(routes.login);
        }
      } else {
        setBodyMessage("");
        setTimedPopup(false);
      }
    }, 2000);
  };

  const loginNow = () => {
    clearTimeout(timedPopupSetTimeOut);
    if (timedPopupSetTimeOut) {
      clearTimeout(timedPopupSetTimeOut);
    }
    if (
      location.pathname === routes.adminChangePassword ||
      location.pathname === routes.memberPasswordResetUrl(memberId)
    ) {
      navigate(routes.login);
    }
  };

  const handleUpdateData = async () => {
    setShowPopUp(false);
    if (context && !memberId) {
      const { success, error } =
        location.pathname === routes.adminvehicleInformation
          ? await context?.updateUserVehicleDetail(updateProfileDetail)
          : location.pathname === routes.adminChangePassword
          ? await context.updatePassword(updateProfileDetail)
          : await context.updateUserDetail(updateProfileDetail);
      setIsSuccess(success);
      if (success) {
        formikFunction.resetForm();
        timeOutPopUp(true);
        setTimedPopup(true);
      } else {
        setTimedPopup(true);
        timeOutPopUp(false);
        setBodyMessage(error);
      }
    } else {
      const { success, error } =
        location.pathname === routes.vehicleInformationUrl(memberId)
          ? await context?.updateMemberVehicleDetail(
              memberId,
              updateProfileDetail
            )
          : location.pathname === routes.memberPasswordResetUrl(memberId)
          ? await context.updateMemberPassword(memberId, updateProfileDetail)
          : await context.updateMemberDetail(memberId, updateProfileDetail);
      setIsSuccess(success);
      if (success) {
        formikFunction.resetForm();
        timeOutPopUp(true);
        setTimedPopup(true);
      } else {
        setTimedPopup(true);
        timeOutPopUp(false);
        setBodyMessage(error);
      }
    }
    if (window?.location?.pathname === routes?.adminPersonalProfile) {
      authContext?.getUser();
    }
  };

  const cancelForm = () => {
    formikFunction?.resetForm();
    if (window.location.pathname.includes("company_profile")) {
      formikFunction.setFieldValue("role", formikFunction?.initialValues?.role);
      formikFunction.setFieldValue(
        "department",
        formikFunction?.initialValues?.department
      );
      formikFunction.setFieldValue(
        "depart",
        formikFunction?.initialValues?.depart
      );
    }
    setShowPopUp(false);
  };

  return (
    <div>
      <EditPage
        memberId={memberId}
        isProfile={!memberId}
        userContext={userContext}
        setBodyMessage={setBodyMessage}
        setTimedPopup={setTimedPopup}
        setIsSuccess={setIsSuccess}
        timeOutPopUp={timeOutPopUp}
        setImageShow={setImageShow}
        setCurrentImage={setCurrentImage}
      >
        {(location.pathname === routes.adminPersonalProfile ||
          location.pathname?.includes("/edit_personal_profile")) && (
          <PersonalProfileForm
            memberId={memberId}
            isProfile={!memberId}
            setBodyMessage={setBodyMessage}
            userContext={userContext}
            setUpdateProfileDetail={setUpdateProfileDetail}
            context={context}
            setTextMessage={setTextMessage}
            setFormikFunction={setFormikFunction}
            setShowPopUp={setShowPopUp}
            setHeaderMessage={setHeaderMessage}
            roleOptions={roleOptions}
            departmentOptions={departmentOptions}
          />
        )}
        {(location.pathname === routes.admincompanyProfile ||
          location.pathname?.includes("/edit_company_profile")) && (
          <CompanyProfileForm
            memberId={memberId}
            isProfile={!memberId}
            setBodyMessage={setBodyMessage}
            userContext={userContext}
            setUpdateProfileDetail={setUpdateProfileDetail}
            context={context}
            setTextMessage={setTextMessage}
            setFormikFunction={setFormikFunction}
            setShowPopUp={setShowPopUp}
            setHeaderMessage={setHeaderMessage}
            roleOptions={roleOptions}
            departmentOptions={departmentOptions}
          />
        )}
        {(location.pathname === routes.adminvehicleInformation ||
          location.pathname?.includes("/edit_vehicle_information")) && (
          <VehicleInformationForm
            memberId={memberId}
            isProfile={!memberId}
            setBodyMessage={setBodyMessage}
            userContext={userContext}
            setUpdateProfileDetail={setUpdateProfileDetail}
            context={context}
            setTextMessage={setTextMessage}
            setFormikFunction={setFormikFunction}
            setShowPopUp={setShowPopUp}
            setHeaderMessage={setHeaderMessage}
            roleOptions={roleOptions}
            departmentOptions={departmentOptions}
            vehicleOptions={vehicleOptions}
          />
        )}
        {(location.pathname === routes.adminChangePassword ||
          location.pathname?.includes("/edit_reset_password")) && (
          <ChangePasswordForm
            memberId={memberId}
            isProfile={!memberId}
            setBodyMessage={setBodyMessage}
            loginNow={loginNow}
            userContext={userContext}
            setUpdateProfileDetail={setUpdateProfileDetail}
            context={context}
            setTextMessage={setTextMessage}
            setFormikFunction={setFormikFunction}
            setShowPopUp={setShowPopUp}
            setHeaderMessage={setHeaderMessage}
            roleOptions={roleOptions}
            departmentOptions={departmentOptions}
          />
        )}
      </EditPage>
      <ToastPopup trigger={timedPopup} setTrigger={setTimedPopup}>
        <SuccessErrorToast
          bodyMessage={bodyMessage}
          isSuccess={isSuccess}
          okButton={() => setTimedPopup(false)}
          buttonName="Close"
        />
      </ToastPopup>
      <ImagePopUp
        imageShow={imageShow}
        image={currentImage}
        setImageShow={(data: any) => setImageShow(data)}
      />
      <ToastPopupSecond trigger={showPopUp} setTrigger={setShowPopUp}>
        <DeleteBlockToast
          closePopUp={(data: any) => {
            setShowPopUp(data);
          }}
          submit={() =>
            headerMessage === `${t("successError.cancel the changes")}`
              ? cancelForm()
              : handleUpdateData()
          }
          headerMessage={headerMessage}
          textMessage={textMessage}
        />
      </ToastPopupSecond>
      <ToastPopup
        trigger={context?.isLoading}
        setTrigger={context?.setIsLoading}
      >
        <SuccessErrorToast isLoading={true} />
      </ToastPopup>
    </div>
  );
};

export default UpdateProfileOrMember;
