import { isMobileOnly } from "react-device-detect";
import { Chart } from "react-chartjs-2";

const DashboardBarAndLineChart = ({ data, options, title, info }: any) => {
  return (
    <div className="chartCard chartCardheight rounded-3 d-flex flex-column justify-content-between">
      <div>
        <h6 className=" fw-bold px-3 m-0 pt-3">{title}</h6>
      </div>
      <div className=" px-1">
        <div
          className={
            isMobileOnly
              ? " d-flex justify-content-center py-3 bar"
              : " bar py-3"
          }
        >
          <Chart
            data={{
              labels: info?.label,
              datasets: info?.datasets || [],
            }}
            options={{
              ...options,
            }}
            type={"bar"}
            className="forbarChat"
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardBarAndLineChart;
