import { ReactElement } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import bgimage from "../assets/bg3.jpg";
import logo from "../assets/logo_transparent.png";
import SwitchLanguage from "../components/utils/SwitchLanguage";
import { useBootStrapLayout } from "../context/BootstrapLayoutProvider";

interface LoginLayoutProps {
  title: string;
  subTitle: string;
  children: ReactElement;
}

const LeftPanel = ({ title, subTitle, children }: LoginLayoutProps) => {
  const { isDesktop } = useBootStrapLayout();
  return (
    <Col className="px-4 px-md-0">
      <Stack
        className="px-md-5 h-100"
        style={!isMobile ? { background: "#FAFAFA" } : {}}
      >
        <Stack className="align-items-flex-start">
          <Stack
            direction="horizontal"
            className={`${
              isMobile ? "align-items-center justify-content-between py-0" : "align-items-center justify-content-between py-5"
            }`}
          >
            <img src={logo} alt="logo" width={120} className="logo" />
            <SwitchLanguage />
          </Stack>
          <Stack className="mt-5">
            <h1
              className={`${isDesktop ? "display-6 head_title" : " head_title display-3"} fw-semibold head_title`}
            >
              {title}
            </h1>
            <div className="head_description  ff-questrial">{subTitle}</div>
            <div className="">{children}</div>
          </Stack>
        </Stack>
      </Stack>
    </Col>
  );
};
const RightPanel = () => {
  const { t } = useTranslation();
  return (
    <>
      {!isMobile && (
        <Col className="d-none d-sm-none d-md-block p-0">
          <div
            className="bgimage"
            style={{
              background: `url(${bgimage})`,
              backgroundSize: "cover",
            }}
          >
            <div className="overlay">
              <Stack className="align-items-center justify-content-end text-white h-100 p-5  mx-5 text-center">
                <p className="h1 head_content">{t("office team")}</p>
                <div className="line"></div>
                <p className="h6 mt-2 pt-3 ff-questrial head_content">{t("platfrom")}</p>
              </Stack>
            </div>
          </div>
        </Col>
      )}
    </>
  );
};

const LoginLayout = ({ children, title, subTitle }: LoginLayoutProps) => {

  return (
    <Container>
      <Row style={{ height: isMobile ? "85vh" : "100vh" }} className="py-5">
        <>
          <LeftPanel title={title} subTitle={subTitle}>
            {children}
          </LeftPanel>
          <RightPanel />
        </>
      </Row>
    </Container>
  );
};

export default LoginLayout;
