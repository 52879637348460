import React from "react";
import { isMobile } from "react-device-detect";

// interface modalProps{
//     props : react;

// }

const ToastPopup = (props: any) => {
  return props.trigger ? (
    <>
      <div className="popup">
        <div
          className={`${isMobile ? "toastinner p-4 m-3" : " toastinner p-4"}`}
        >
          {props.children}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default ToastPopup;
