import { useState } from "react";
import LoadingBar from "react-top-loading-bar";


const CommonLoadingBar = () => {
    const [progress, setProgress] = useState(0);

    return (
        <div>
            <LoadingBar
                color="#db2c59"
                height={2}
                progress={100}
                loaderSpeed={1300}
                onLoaderFinished={() => setProgress(100)}
            />
        </div>
    )
}

export default CommonLoadingBar