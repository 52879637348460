import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NewBomForm from "../pages/masters/BOM/bomForm";
import { BASE_URL } from "../utils/api";
import { RxCross2 } from "react-icons/rx";

export default function DownloadPopUp({
  show,
  setShow,
  download,
  isBillboard = false,
  isBom = false,
  userContext,
  billboardContext,
  pathName,
}: any) {
  const { t } = useTranslation();
  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      centered={true}
      size={isBom ? "xl" : "sm"}
    >
      <Modal.Header>
        {/* {t("avatar.Profile picture update")} */}
        <div className=" d-flex justify-content-between align-items-center  w-100">
          <div>{isBom ? t("masters.BOM") : t("Download")}</div>
          <span
            onClick={() => {
              setShow(false);
            }}
            className="cursor-pointer"
          >
            <RxCross2 size={24} color="#db2c59" />
          </span>
        </div>
      </Modal.Header>
      <Modal.Body>
        {isBom ? (
          <NewBomForm
            userContext={userContext}
            billboardContext={billboardContext}
            pathName={pathName}
          />
        ) : (
          <div className="row py-3">
            <div className="justify-content-center">
              <div className=" d-flex align-items-center justify-content-center">
                <div
                  className=" mb-0 mx-3  pointer ExcelPdf "
                  onClick={() => {
                    setShow(false);
                    download("excel");
                  }}
                >
                  {t("As Excel")}
                </div>
                <div
                  className=" mb-0 mx-3  pointer ExcelPdf"
                  onClick={() => {
                    setShow(false);
                    download("pdf");
                  }}
                >
                  {t("As PDF")}
                </div>
                {isBillboard && (
                  <div
                    className=" mb-0 mx-3  pointer ExcelPdf"
                    onClick={() =>
                      window.open(`${BASE_URL}/billboards/qrCodeDownload`)
                    }
                  >
                    {t("As QR Code")}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
