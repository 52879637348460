import React, { ReactElement, useState } from "react";
import { api } from "../utils/api";

interface MastersProviderProps {
  children: ReactElement;
}

export interface MastersContextProp {
  isLoading: boolean;
  setIsLoading: Function;
  config: any;
  configs: any;
  meta: any;
  suppliers: any;
  supplier: any;
  stocks: any;
  stock: any;
  bom: any;
  boms: any;
  cleaningConfigs: any;
  getAllConfig: Function;
  getConfigById: Function;
  createConfig: Function;
  updateConfig: Function;
  deleteConfig: Function;
  loadSupplier: Function;
  addSupplier: Function;
  getSupplierDetail: Function;
  updateSupplierDetail: Function;
  deleteSupplierAccount: Function;
  loadMaterials: Function;
  getMaterialDetail: Function;
  addMaterials: Function;
  updateStockDetail: Function;
  downloadSupplierExcel: Function;
  downloadSupplierPdf: Function;
  getAllCleangingConfig: Function;
  getCleaningConfigById: Function;
  createCleaningConfig: Function;
  updateCleaningConfig: Function;
  deleteCleaningConfig: Function;
  setConfig: Function;
  downloadMaterialExcel: Function;
  downloadMaterialPdf: Function;
  stockAutoComplete: Function;
  getAllBom: Function;
  getBomId: Function;
  createBom: Function;
  updateBom: Function;
  deleteBom: Function;
  setBom: Function;
  addInventorySupplier: Function;
  setStock: Function;
  setSupplier: Function;
}

const MastersContext = React.createContext<MastersContextProp | null>(null);

const MastersProvider = ({ children }: MastersProviderProps) => {
  const [isLoading, setIsLoading]: [boolean, Function] = useState(false);
  const [meta, setMeta] = useState<any>({});
  const [configs, setConfigs] = useState<any>([]);
  const [cleaningConfigs, setCleaningConfigs] = useState<any>([]);
  const [config, setConfig] = useState<any>({});
  const [suppliers, setSuppliers] = useState<any>([]);
  const [supplier, setSupplier] = useState<any>([]);
  const [stocks, setStocks] = useState<any>([]);
  const [stock, setStock] = useState<any>([]);
  const [bom, setBom] = useState<any>({});
  const [boms, setBoms] = useState<any>([]);

  const getAllConfig = async (params: any) => {
    setIsLoading(true);
    const { success, config, meta } = await api.getAllConfig(params);
    if (success) {
      setConfigs(config);
      setMeta(meta);
    }
    setIsLoading(false);
  };

  const getConfigById = async (id: string) => {
    setIsLoading(true);
    const { success, config } = await api.getConfigById(id);
    if (success) {
      setConfig(config);
    }
    setIsLoading(false);
    return { success, config };
  };

  const createConfig = async (params: any) => {
    setIsLoading(true);
    const data = await api.createConfig({
      ...params,
      title: params?.title?.toUpperCase(),
    });
    const { success, error } = data?.data;
    setIsLoading(false);
    return { success, error };
  };

  const updateConfig = async (id: string, params: any) => {
    setIsLoading(true);
    const data = await api.updateConfig(id, {
      ...params,
      title: params?.title?.toUpperCase(),
    });
    const { success, error } = data?.data;
    setIsLoading(false);
    return { success, error };
  };

  const deleteConfig = async (id: string) => {
    setIsLoading(true);
    const { success, error } = await api.deleteConfig(id);
    setIsLoading(false);
    return { success, error };
  };

  const loadSupplier = async (params: any) => {
    setIsLoading(true);
    const { suppliers, success, meta } = await api.inventorySupplier(params);
    if (success) {
      setSuppliers(suppliers);
    }
    setIsLoading(false);
    setMeta(meta);
    return { suppliers, success, meta };
  };

  const addSupplier = async (params: any) => {
    setIsLoading(true);
    const res = await api.addSupplier(params);
    const { success, message, error } = res.data;
    setIsLoading(false);
    if (success) {
      return { success, message };
    } else {
      return {
        success,
        errors: error,
      };
    }
  };

  const getSupplierDetail = async (supplierId: any) => {
    if (supplierId) {
      setIsLoading(true);
      const { success, supplier, error } = await api.supplier(supplierId);
      if (success) {
        setSupplier(supplier);
      }
      setIsLoading(false);
      return { success, supplier, error };
    }
  };

  const updateSupplierDetail = async (supplierId: string, params: any) => {
    if (supplierId) {
      setIsLoading(true);
      const data = await api.updateSupplier(supplierId, params);
      const { success, supplier, error } = data?.data;
      if (success) {
        setSupplier(supplier);
      }
      setIsLoading(false);
      return { success, supplier, error };
    }
  };
  const deleteSupplierAccount = async (supplierId: string) => {
    setIsLoading(true);
    const { success, error } = await api.deleteSupplier(supplierId);
    setIsLoading(false);
    return { success, error };
  };

  const loadMaterials = async (params: any) => {
    setIsLoading(true);
    const { stocks, success, meta } = await api.inventoryMaterial(params);
    if (success) {
      setStocks(stocks);
    }
    setIsLoading(false);
    setMeta(meta);
    return { stocks, success, meta };
  };

  const addMaterials = async (params: any) => {
    setIsLoading(true);
    const res = await api.addMaterial(params);
    const { success, message, error } = res.data;
    setIsLoading(false);
    return { success, message, error };
  };

  const getMaterialDetail = async (id: any) => {
    if (id) {
      setIsLoading(true);
      const { success, stock, error } = await api.material(id);
      if (success) {
        setStock(stock);
      }
      setIsLoading(false);
      return { success, stock, error };
    }
  };

  const updateStockDetail = async (materialId: string, params: any) => {
    if (materialId) {
      setIsLoading(true);
      const data = await api.updateMaterial(materialId, params);
      const { success, stock, error } = data?.data;
      if (success) {
        setStock(stock);
      }
      setIsLoading(false);
      return { success, stock, error };
    }
  };

  const downloadSupplierExcel = async (params: any) => {
    setIsLoading(true);
    const res = await api.supplierDownload(params);
    setIsLoading(false);
    return res;
  };

  const downloadSupplierPdf = async (params: any) => {
    setIsLoading(true);
    const res = await api.supplierPdfDownload(params);
    setIsLoading(false);
    return res;
  };

  const downloadMaterialExcel = async (params: any) => {
    setIsLoading(true);
    const res = await api.materialDownload(params);
    setIsLoading(false);
    return res;
  };

  const downloadMaterialPdf = async (params: any) => {
    setIsLoading(true);
    const res = await api.materialPdfDownload(params);
    setIsLoading(false);
    return res;
  };

  const getAllCleangingConfig = async (params: any) => {
    setIsLoading(true);
    const { success, data, meta } = await api.getAllCleangingConfig(params);
    if (success) {
      setCleaningConfigs(data);
      setMeta(meta);
    }
    setIsLoading(false);
  };

  const getCleaningConfigById = async (id: string) => {
    setIsLoading(true);
    const { success, master } = await api.getCleaningConfigById(id);
    if (success) {
      setConfig(master);
    }
    setIsLoading(false);
    return { success, config };
  };

  const createCleaningConfig = async (params: any) => {
    setIsLoading(true);
    const data = await api.createCleaningConfig(params);
    const { success, error } = data?.data;
    setIsLoading(false);
    return { success, error };
  };

  const updateCleaningConfig = async (id: string, params: any) => {
    setIsLoading(true);
    const data = await api.updateCleaningConfig(id, params);
    const { success, error } = data?.data;
    setIsLoading(false);
    return { success, error };
  };

  const deleteCleaningConfig = async (id: string) => {
    setIsLoading(true);
    const { success, error } = await api.deleteCleaningConfig(id);
    setIsLoading(false);
    return { success, error };
  };

  const getAllBom = async (params: any) => {
    setIsLoading(true);
    const { success, bom, meta } = await api.getBomList(params);
    if (success) {
      setBoms(bom);
      setMeta(meta);
    }
    setIsLoading(false);
  };

  const getBomId = async (id: string) => {
    setIsLoading(true);
    const { success, bom } = await api.getBom(id);
    if (success) {
      setBom(bom);
    }
    setIsLoading(false);
    return { success, bom };
  };

  const createBom = async (params: any) => {
    setIsLoading(true);
    const data = await api.createBom(params);
    const { success, error } = data?.data;
    setIsLoading(false);
    return { success, error };
  };

  const updateBom = async (id: string, params: any) => {
    setIsLoading(true);
    const data = await api.updateBom(id, params);
    const { success, error } = data?.data;
    setIsLoading(false);
    return { success, error };
  };

  const deleteBom = async (id: string) => {
    setIsLoading(true);
    const { success, error } = await api.deleteBom(id);
    setIsLoading(false);
    return { success, error };
  };

  const stockAutoComplete = async (params: any) => {
    setIsLoading(true);
    const res = await api.stockAutoComplete(params);
    setIsLoading(false);
    return res;
  };

  const addInventorySupplier = async (params: any) => {
    setIsLoading(true);
    const res = await api.addSupplier(params);
    const { success, message, error } = res.data;
    setIsLoading(false);
    if (success) {
      return { success, message };
    } else {
      return {
        success,
        errors: error,
      };
    }
  };

  const value: MastersContextProp = {
    isLoading,
    setIsLoading,
    config,
    configs,
    meta,
    supplier,
    stocks,
    stock,
    bom,
    boms,
    cleaningConfigs,
    getAllConfig,
    getConfigById,
    createConfig,
    updateConfig,
    deleteConfig,
    suppliers,
    loadSupplier,
    addSupplier,
    getSupplierDetail,
    updateSupplierDetail,
    deleteSupplierAccount,
    loadMaterials,
    getMaterialDetail,
    addMaterials,
    updateStockDetail,
    downloadSupplierExcel,
    downloadSupplierPdf,
    getAllCleangingConfig,
    getCleaningConfigById,
    createCleaningConfig,
    updateCleaningConfig,
    deleteCleaningConfig,
    setConfig,
    downloadMaterialExcel,
    downloadMaterialPdf,
    stockAutoComplete,
    getAllBom,
    getBomId,
    createBom,
    updateBom,
    deleteBom,
    setBom,
    addInventorySupplier,
    setStock,
    setSupplier,
  };

  return (
    <MastersContext.Provider value={value}>{children}</MastersContext.Provider>
  );
};

const useMasters = () => React.useContext(MastersContext);

export { MastersProvider, useMasters };
