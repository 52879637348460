import { useTranslation } from "react-i18next";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import { useEffect } from "react";
import ReactCustomSelect from "../../components/CommonComponents/ReactCustomSelect";
import CommonButton from "../../components/CommonButton/CommonButton";
import FormikField from "../../components/FormikField/FormikField";
import CustomInputField from "../../components/InputField/CustomInputField";
import FormikDateField from "../../components/FormikDateField/FormikDateField";

const VehicleInformationForm = ({
  memberId,
  isProfile,
  setBodyMessage,
  userContext,
  setUpdateProfileDetail,
  context,
  setTextMessage,
  setFormikFunction,
  setShowPopUp,
  setHeaderMessage,
  vehicleOptions,
}: any) => {
  const { t } = useTranslation();

  useEffect(() => {
    getMemberDetail();
  }, []);

  const getMemberDetail = async () => {
    if (context && isProfile) {
      await context.getUserVehicleDetail();
    } else if (context && !isProfile) {
      await context.getMemberVehicleDetail(memberId);
    }
  };

  const initialValues = {
    type: isProfile ? context?.userVehicle?.type : context?.memberVehicle?.type,
    number: isProfile
      ? context?.userVehicle?.number
      : context?.memberVehicle?.number,
    model: isProfile
      ? context?.userVehicle?.model
      : context?.memberVehicle?.model,
  };
  const validationSchema = Yup.object({
    type: Yup.string()
      .min(1, "Please enter id more than 1 character")
      .required("Vehicle Type is required"),
    number: Yup.string().required("Licence Plate Number is required"),
    model: Yup.string()
      .min(1, "Please enter id correct year")
      .max(4, "Please Enter 4 character model"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setTextMessage(`${t("successError.update")}`);
      setBodyMessage(`${t("successError.Vehicle information updated")}`);
      setHeaderMessage(
        `${t("successError.Updated vehicle information details")}`
      );
      setUpdateProfileDetail(values);
      setShowPopUp(true);
      setFormikFunction(formik);
    },
  });

  const {
    errors,
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    values,
    setFieldValue,
  } = formik;
  return (
    <FormikProvider value={formik}>
      <Form validated={true} onSubmit={handleSubmit}>
        <Row>
          <Col md={6} className="formprofile">
            <Field name={"type"}>
              {({ field: formikField }: FieldProps<string>) => (
                <CustomInputField
                  validationSchema={validationSchema}
                  label={t("Vehicleinfo.vehicleType")}
                  field={formikField}
                  inputId={"type"}
                  error={formik?.errors["type"] as string}
                >
                  <ReactCustomSelect
                    isFieldDisabled={
                      !isProfile &&
                      !userContext?.roleAccess["USER"].includes("UPDATE")
                    }
                    className={"select-wrapper"}
                    feildName={"type"}
                    defaultOptions={{
                      label: `${t(formik?.values?.type)}`,
                      value: formik?.values?.type,
                    }}
                    isMulti={false}
                    updateFieldValue={(data: any) =>
                      formik?.setFieldValue("type", data.value)
                    }
                    dataOptions={vehicleOptions}
                    searchDisabled={false}
                  />
                </CustomInputField>
              )}
            </Field>
          </Col>
          <Col md={6} className=" mb-4">
            <FormikField
              name="number"
              type="text"
              autoFocus
              validationSchema={validationSchema}
              label={t("Vehicleinfo.licnumber")}
              errors={errors as Record<string, string>}
              disabled={
                !isProfile &&
                !userContext?.roleAccess["USER"].includes("UPDATE")
              }
            />
          </Col>

          <Col className=" mb-4 formprofile">
            <FormikDateField
              name={"model"}
              label={t("Vehicleinfo.model")}
              isDisabled={
                !isProfile &&
                !userContext?.roleAccess["USER"].includes("UPDATE")
              }
              onlyYearPicker
              maxDate={new Date()}
              // onChange={(e: any) => {
              //   if (/^[0-9\b]+$/.test(e.target.value) || e.target.value === '') {
              //     setFieldValue('model', e.target.value);
              //   }
              // }}
            />
          </Col>
        </Row>

        {(isProfile
          ? true
          : userContext?.roleAccess["USER"].includes("UPDATE")) && (
          <div className="d-flex justify-content-end my-5">
            <CommonButton
              text={t("Vehicleinfo.cancel")}
              type="button"
              isLoading={isSubmitting}
              variant="outline-danger"
              className="bg-transparent text-danger"
              isDisabled={isSubmitting || !dirty}
              onClick={() => {
                setHeaderMessage(`${t("successError.cancel the changes")}`);
                setTextMessage(`${t("successError.cancel it")}`);
                setBodyMessage(`${t("successError.cancel it")}`);
                setFormikFunction(formik);
                setShowPopUp(true);
              }}
            />
            <CommonButton
              text={t("Vehicleinfo.Update")}
              isLoading={isSubmitting}
              type="submit"
              variant="success"
              className="btn-i18n text-white"
              isDisabled={
                isSubmitting ||
                !isValid ||
                !dirty ||
                !(values?.model?.length > 0
                  ? values?.model?.length === 4
                  : true)
              }
            />
          </div>
        )}
      </Form>
    </FormikProvider>
  );
};
export default VehicleInformationForm;
