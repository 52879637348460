import error from "../../assets/error.png";
import success from "../../assets/tick.png";
import { useTranslation } from "react-i18next";
import logo from "../../assets/logo.jpeg";

const SuccessErrorToast = (props: any) => {
  const { t, i18n } = useTranslation();
  const {
    isLoading = false,
    isSuccess = false,
    bodyMessage = "",
    buttonName = "",
    okButton = "",
    footButton = false,
  } = props || {};

  return (
    <div className="toastpop position-relative">
      {isLoading ? (
        <>
          <div className="d-flex align-items-center justify-content-center">
            <div className="position-relative">
              <div className="loader"></div>
              <div className=" position-absolute img-div">
                <img src={logo} className="embetimg loader-img" />
              </div>
            </div>
          </div>
          <h4 className="d-flex align-items-center justify-content-center pt-5 loading-text">
            {t("LOADING")}...
          </h4>
        </>
      ) : (
        <>
          <div className=" d-flex align-items-center justify-content-center">
            {isSuccess !== undefined && (
              <div>
                <img src={isSuccess ? success : error} alt="" width={70} />
              </div>
            )}
          </div>
          <div className=" d-flex justify-content-center py-3">
            <h4
              className={
                i18n.language === "en"
                  ? "m-0 fw-semibold"
                  : " m-0 fw-semibold errorArabic"
              }
            >
              {isSuccess
                ? `${t("successError.success")}`
                : `${t("successError.error")}`}
            </h4>
          </div>
          <div className="pb-3">
            <h6 className=" text-center ff-questrial ">{bodyMessage}</h6>
          </div>
        </>
      )}
      {footButton && (
        <div className=" logcenter pointer">
          <h6 className="logbtn" onClick={() => okButton()}>
            {buttonName}
          </h6>
        </div>
      )}
    </div>
  );
};

export default SuccessErrorToast;
