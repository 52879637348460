import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "../../App";
import File404 from "../../components/404/File404";
import { routes } from "../../constants/routes";
import { TicketProvider } from "../../context/TicketProvider";
import CreateOrViewTicket from "./CreateOrViewTicket";
import ListTickets from "./ListTickets";

const TicketsPages = ({ userContext }: any) => {
  return (
    userContext?.roleAccess && (
      <TicketProvider>
        <Routes>
          <Route
            path={routes.listTickets}
            element={
              <RequireAuth>
                {userContext?.roleAccess["TICKETS"].includes("READ") ? (
                  <ListTickets userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.newTicket}
            element={
              <RequireAuth>
                {userContext?.roleAccess["TICKETS"].includes("READ") ? (
                  <CreateOrViewTicket userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.editTicket}
            element={
              <RequireAuth>
                {userContext?.roleAccess["TICKETS"].includes("READ") ? (
                  <CreateOrViewTicket userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.activityTicket}
            element={
              <RequireAuth>
                {userContext?.roleAccess["TICKETS"].includes("READ") ? (
                  <CreateOrViewTicket userContext={userContext} />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
        </Routes>
      </TicketProvider>
    )
  );
};

export default TicketsPages;
