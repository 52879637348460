import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { Alert, Button, Form, FormGroup, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { HiOutlineArrowRight } from "react-icons/hi";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import SuccessErrorToast from "../../components/PopupToast/SuccessErrorToast";
import ToastPopup from "../../components/PopupToast/ToastPopup";
import { routes } from "../../constants/routes";
import LoginLayout from "../../layouts/LoginLayout";
import { api } from "../../utils/api";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";

let timedPopupSetTimeOut: any = undefined;

const ResetPasswordPage = () => {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const [bodyMessage, setBodyMessage] = useState("");
  const [buttonPopup, setButtonPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [newPasswordEye, setNewPasswordEye] = useState(false);
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(false);
  const navigate = useNavigate();
  const token = searchParams.get("token");

  const formik = useFormik({
    initialValues: {
      token,
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password is too short- should be 8 characters  minimum")
        .matches(/^(?=.{6,})/, "Must Contain 6 Characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])/,
          "Must Contain One Uppercase, One Lowercase"
        )
        .matches(
          /^(?=.*?[!"#$%&'()*+,-./:;<=>?@_`{}~])/,
          "Must Contain One Special Case Character"
        )
        .matches(/^(?=.{6,20}$)\D*\d/, "Must Contain One Number"),
      passwordConfirmation: Yup.string()
        .required("Confirmation Password is required")
        .oneOf([Yup.ref("password")], "Password doesn't match"),
    }),
    onSubmit: async (values) => {
      if (!token) {
        return;
      }
      const res = await api.resetPassword(
        token,
        values.password,
        values.passwordConfirmation
      );
      const { success, error } = res.data;
      setIsSuccess(success);
      formik.resetForm();
      if (success) {
        setButtonPopup(true);
        setBodyMessage(`${t("successError.update the password")}`);
        timeOutPopUp(true);
      } else {
        setButtonPopup(true);
        setBodyMessage(error);
        timeOutPopUp(false);
      }
    },
  });

  const timeOutPopUp = (value: any) => {
    timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        navigate(routes.login);
        setBodyMessage("");
      } else {
        setButtonPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  const validateConfirmPassword = (pass: string, value: string) => {
    let error = "";
    if (pass && value) {
      if (pass !== value) {
        error = "Password not matched with password confirmation";
      }
    }
    return error;
  };

  const { errors, handleSubmit, values, isSubmitting, isValid, dirty } = formik;
  return (
    <>
      <LoginLayout
        title={t("resetPassword.title")}
        subTitle={t("resetPassword.subtitle")}
      >
        <FormikProvider value={formik}>
          <Form validated={!!errors} onSubmit={handleSubmit}>
            {!!errors.token && (
              <Alert variant="danger" className="mt-4">
                <small className="text-danger">{errors.token}</small>
              </Alert>
            )}
            <div className="my-4">
              <FormGroup className=" position-relative">
                <label>{t("resetPassword.password")}</label>
                <Field
                  name="password"
                  type={newPasswordEye ? "text" : "password"}
                  className={`form-control ${
                    errors.password ? "error-field" : ""
                  }`}
                  maxLength={50}
                />
                {formik?.values.password.length > 0 && (
                  <div
                    className={
                      i18n.language === "en" ? "eyeicon" : "eyeicon-right"
                    }
                    onClick={() => setNewPasswordEye(true)}
                  >
                    <AiOutlineEye color="##2a2b2f80" />
                  </div>
                )}
                {formik?.values.password.length > 0 && newPasswordEye && (
                  <div
                    className={
                      i18n.language === "en" ? "eyeicon" : "eyeicon-right"
                    }
                    onClick={() => setNewPasswordEye(false)}
                  >
                    <AiOutlineEyeInvisible color="##2a2b2f80" />
                  </div>
                )}
                <small
                  className="text-danger"
                  style={{ display: !!errors.password ? "block" : "none" }}
                >
                  <ErrorMessage name="password" />
                </small>
              </FormGroup>
            </div>
            <div className="my-4 ">
              <FormGroup className=" position-relative">
                <label>{t("resetPassword.passwordConfirmation")}</label>
                <Field
                  name="passwordConfirmation"
                  type={confirmPasswordEye ? "text" : "password"}
                  maxLength={50}
                  className={`form-control ${
                    errors.password ? "error-field" : ""
                  }`}
                  validate={(value: any) =>
                    validateConfirmPassword(values.password, value)
                  }
                />
                {formik?.values.passwordConfirmation.length > 0 && (
                  <div
                    className={
                      i18n.language === "en" ? "eyeicon" : "eyeicon-right"
                    }
                    onClick={() => setConfirmPasswordEye(true)}
                  >
                    <AiOutlineEye color="##2a2b2f80" />
                  </div>
                )}
                {formik?.values.passwordConfirmation.length > 0 &&
                  confirmPasswordEye && (
                    <div
                      className={
                        i18n.language === "en" ? "eyeicon" : "eyeicon-right"
                      }
                      onClick={() => setConfirmPasswordEye(false)}
                    >
                      <AiOutlineEyeInvisible color="##2a2b2f80" />
                    </div>
                  )}
                <small
                  className="text-danger"
                  style={{
                    display: !!errors.passwordConfirmation ? "block" : "none",
                  }}
                >
                  <ErrorMessage name="passwordConfirmation" />
                </small>
              </FormGroup>
            </div>
            <Button
              type="submit"
              variant="primary"
              className="my-5 px-5 py-2 rounded-0 d-flex justify-content-center align-items-center pointer"
              disabled={isSubmitting || !isValid || !dirty}
            >
              {isSubmitting ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <>
                  <span className={`me-2 ${!isValid ? "" : "fw-semibold"}`}>
                    {t("resetPassword.button")}
                  </span>
                  <span className={i18n.language === "en" ? "ms-2" : "d-none"}>
                    <FaLongArrowAltRight />
                  </span>
                  <span className={i18n.language === "en" ? "d-none" : "me-2"}>
                    <FaLongArrowAltLeft />
                  </span>
                </>
              )}
            </Button>
          </Form>
        </FormikProvider>
      </LoginLayout>
      <ToastPopup trigger={buttonPopup} setTrigger={setButtonPopup}>
        <SuccessErrorToast
          bodyMessage={bodyMessage}
          isSuccess={isSuccess}
          // okButton={() => isSuccess ? loginNow() : closeErrorPopUp()}
          // buttonName='Login Now!'
          footButton={false}
        />
      </ToastPopup>
    </>
  );
};

export default ResetPasswordPage;
