import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RiArrowDownSLine } from "react-icons/ri";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ChatOptions = ({
  item,
  context,
  attachment = false,
  attachmentFile = "",
  chatContext,
}: any) => {
  const { t } = useTranslation();
  return (
    <div className="chatdropdown">
      <Dropdown className="btn-resize">
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          <RiArrowDownSLine color="#2a2b2f" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {attachment && (
            <a href={attachmentFile} download className="dropdown-item">
              {t("chat.Download")}
            </a>
          )}
          <Dropdown.Item onClick={() => chatContext?.setAction("Forward")}>
            {t("chat.Forward")}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              context?.setReplyObject(item);
              chatContext?.setAction("Reply");
            }}
          >
            {t("chat.Reply")}
          </Dropdown.Item>
          {!attachment && (
            <Dropdown.Item
            >
              {/* <CopyToClipboard text={item?.message}>
                {t("chat.Copy")}
              </CopyToClipboard> */}
              <CopyToClipboard text={item && item?.message}>
                <button className="copy-btn">{t("chat.Copy")}</button>
              </CopyToClipboard>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
export default ChatOptions;
