import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { Col, Container, Form } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import x from "../../assets/x.png";
import { MembersContextProp, useMembers } from "../../context/MembersProvider";
import { updateText } from "../../utils/api";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import FormikField from "../../components/FormikField/FormikField";
import CustomInputField from "../../components/InputField/CustomInputField";
import CommonAsyncSelect from "../../components/CommonComponents/CommonAsyncSelect";
import CommonButton from "../../components/CommonButton/CommonButton";

const NewMemberModal = (props: any) => {
  const context = useMembers();
  const { t } = useTranslation();
  const {
    setTimedPopup,
    setIsSuccess,
    setBodyMessage,
    setTrigger,
    timeOutPopUp,
    resendInvitation,
    pendingInvitation,
    currentMemberId,
    memberDetail,
    roleOptions,
    userContext,
    trigger = false,
  } = props || {};

  return (
    <Container>
      <Col>
        <div className="d-flex align-items-center justify-content-between">
          <h4 className={`${isMobile ? " inmember" : " inmember"}`}>
            {pendingInvitation
              ? t("inviteMemberForm.buttonPendingInvitation")
              : resendInvitation
              ? t("inviteMemberForm.buttonresend")
              : t("inviteMemberForm.inviteMember")}
          </h4>
          <div onClick={() => setTrigger(false)} className=" py-3">
            <img src={x} alt="" width={24} className="pointer" />
          </div>
        </div>

        {context && (
          <NewMemberModalForm
            context={context}
            userContext={userContext}
            onClose={setTrigger}
            popup={setTimedPopup}
            setSuccess={setIsSuccess}
            setBodyMsg={setBodyMessage}
            timeOutPopUp={timeOutPopUp}
            resendInvitation={resendInvitation}
            pendingInvitation={pendingInvitation}
            currentMemberId={currentMemberId}
            memberDetail={memberDetail}
            roleOptions={roleOptions}
            trigger={trigger}
          />
        )}
      </Col>
    </Container>
  );
};

interface NewMemberFormProps {
  context: MembersContextProp;
  userContext: any;
  onClose: Function;
  popup: Function;
  setSuccess: Function;
  setBodyMsg: Function;
  timeOutPopUp: Function;
  resendInvitation: any;
  pendingInvitation: any;
  currentMemberId: any;
  memberDetail: any;
  roleOptions: any;
  trigger: Boolean;
}
const NewMemberModalForm = ({
  context,
  userContext,
  onClose,
  popup,
  setSuccess,
  setBodyMsg,
  timeOutPopUp,
  resendInvitation,
  pendingInvitation,
  currentMemberId,
  memberDetail,
  roleOptions,
  trigger,
}: NewMemberFormProps) => {
  const { t } = useTranslation();
  const [errorMessages, setErrorMessages] = useState([]);
  const [departMentList, setDepartMentList] = useState<any>([]);
  const getDepartmentDetail = useCallback(async () => {
    const { success, config } = await userContext?.getAllConfigDetails({
      config_type: "DEPARTMENT",
    });
    if (success) {
      let res = config?.map((d: any) => {
        return {
          label: updateText(d?.title?.replace("_", " ")),
          value: d?.title,
        };
      });
      setDepartMentList(res);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (trigger) {
      getDepartmentDetail();
    }
  }, [getDepartmentDetail, trigger]);

  interface billboardAutocompleteProps {
    readonly label: string;
    readonly value: string;
  }

  const getDepartmentOptions: any = async (
    inputValue: string,
    callback: (options: billboardAutocompleteProps) => void
  ) => {
    const { config } = await userContext?.getAllConfigDetails({
      search: inputValue,
      config_type: "DEPARTMENT",
    });
    callback(
      config?.map((d: any) => ({
        label: updateText(d?.title?.replace("_", " ")),
        value: d?.title,
      }))
    );
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(
        /^([_A-Za-z0-9+]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5}))|(\d+$)$/,
        "Invalid email address or phone number"
      )
      .min(13, "Invalid email address or phone number")
      .required("E-mail Address or phone number is required"),
    department: Yup.mixed()
      .when("role", {
        is: (role: string) => role === "SUPERVISOR",
        then: () => Yup.string().required("Department is required"),
        otherwise: () =>
          Yup.array()
            .min(1, "Department is required")
            .required("Department is required"),
      })
      .required("Department is required"),
    role: Yup.string().required("Role is required"),
  });

  const formik = useFormik({
    initialValues: {
      email:
        (resendInvitation || pendingInvitation) && memberDetail
          ? memberDetail?.email || memberDetail?.contact_number
          : "",
      department:
        memberDetail.role === "SUPERVISOR"
          ? memberDetail?.department[0]
          : memberDetail?.department || [],
      role:
        (resendInvitation || pendingInvitation) && memberDetail
          ? memberDetail?.role
          : "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setErrorMessages([]);
      const { success, errors } =
        resendInvitation || pendingInvitation
          ? await context.reSendInviteMember(
              {
                ...values,
                department:
                  values.role !== "SUPERVISOR"
                    ? values?.department?.map((value: any) => value)
                    : [values?.department],
              },
              currentMemberId
            )
          : await context.inviteMember({
              ...values,
              department:
                values.role !== "SUPERVISOR"
                  ? values?.department?.map((value: any) => value)
                  : [values?.department],
            });
      setSuccess(success);
      if (success) {
        formik.resetForm();
        popup(true);
        setBodyMsg(`${t("successError.Successfully send the invitation")}`);
        timeOutPopUp(true);
        onClose(false);
        formik.setFieldValue("department", []);
      } else {
        popup(true);
        timeOutPopUp(false);
        setBodyMsg(errors);
      }
    },
  });
  return (
    <FormikProvider value={formik}>
      <Form
        validated={true}
        onSubmit={formik.handleSubmit}
        className="floating"
      >
        {errorMessages.length > 0 && (
          <ul className="text-danger">
            {errorMessages.map((message: string) => (
              <li>{message}</li>
            ))}
          </ul>
        )}
        <FormikField
          name={"email"}
          errors={formik.errors as any}
          validationSchema={validationSchema}
          label={t("inviteMemberForm.email")}
          type={"text"}
          maxLength={50}
          disabled={resendInvitation}
        />
        <div className=" mt-4 formprofile">
          <Field name={"role"}>
            {({ field: formikField }: FieldProps<string>) => (
              <CustomInputField
                validationSchema={validationSchema}
                label={t("inviteMemberForm.role")}
                field={formikField}
                inputId={"role"}
                error={formik?.errors["role"] as string}
              >
                <CommonAsyncSelect
                  className={"select-wrapper"}
                  isMulti={false}
                  values={{
                    label: updateText(formik?.values?.role),
                    value: formik?.values?.role,
                  }}
                  options={roleOptions}
                  cacheOptions
                  updateField={(e: any) => {
                    if (e.value === "SUPERVISOR") {
                      formik.setFieldValue("department", "");
                    } else {
                      formik.setFieldValue("department", []);
                    }
                    formik.setFieldValue("role", e.value);
                  }}
                />
              </CustomInputField>
            )}
          </Field>
        </div>

        <div className=" mt-4 formprofile">
          <Field name={"department"}>
            {({ field: formikField }: FieldProps<string>) => (
              <CustomInputField
                validationSchema={validationSchema}
                label={`${t("inviteMemberForm.department")}*`}
                field={formikField}
                inputId={"department"}
                error={formik?.errors["department"] as string}
              >
                <Field
                  name={"department"}
                  component={CustomSelect}
                  isClearable={false}
                  options={departMentList}
                  className={"select-wrapper"}
                  cacheOptions
                  loadOptionValue={getDepartmentOptions}
                  isMulti={formik?.values?.role !== "SUPERVISOR"}
                />
              </CustomInputField>
            )}
          </Field>
        </div>
        <div className=" d-flex justify-content-end mt-5">
          <CommonButton
            text={
              pendingInvitation
                ? t("inviteMemberForm.button3")
                : resendInvitation
                ? t("inviteMemberForm.button2")
                : t("inviteMemberForm.button")
            }
            type="submit"
            variant="success"
            className="btn-i18n text-white"
            isLoading={formik.isSubmitting}
            isDisabled={
              pendingInvitation
                ? formik.isSubmitting ||
                  !formik.isValid ||
                  !formik.values.department.length
                : resendInvitation
                ? false
                : formik.isSubmitting ||
                  !formik.isValid ||
                  !formik.dirty ||
                  !formik.values.department.length
            }
          />
        </div>
      </Form>
    </FormikProvider>
  );
};

export default NewMemberModal;

export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color?: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}
