import moment from "moment";
import { RiShareForwardFill } from "react-icons/ri";
import ChatOptions from "./ChatOptions";
import { isMobileOnly } from "react-device-detect";
import VideoPlayer from "react-video-player-extended";
import { useTranslation } from "react-i18next";

const ChatReceived = ({
  item,
  index,
  userContext,
  getCheckBoxValues,
  context,
  setCurrentImage,
  setTimedPopup,
  setImageShow,
  chatContext,
  currentAttachmentVideo,
  setCurrentAttachmentVideo,
}: any) => {
  // const [base64Value, setBase64Value] = useState("");
  // useEffect(() => {
  //   if (item?.attachment_type === "AUDIO") {
  //     convertUrltoBase64();
  //   } else if (item?.reply_message_id?.attachment_type === "AUDIO") {
  //     convertUrltoBase64();
  //   }
  // }, []);

  // const convertUrltoBase64 = async () => {
  //   const data = await userContext?.urlToBase64(
  //     item?.attachment_type === "AUDIO"
  //       ? item?.attachment
  //       : item?.reply_message_id?.attachment
  //   );
  //   if (data?.success) {
  //     setBase64Value("data:audio/mp3;base64," + data.base64);
  //   }
  // };
  const { i18n } = useTranslation();

  return (
    <li
      className={
        chatContext?.checkBoxValue?.includes(item?.id)
          ? "chat__bubble--you-bg"
          : ""
      }
      id={item?.id}
    >
      <div className="d-flex  align-items-center">
        <div className="forwardcheckbox me-3">
          {chatContext?.action === "Forward" && (
            <input
              type="checkbox"
              className="custom-control-input"
              id={item?.id}
              value={item?.id}
              onChange={getCheckBoxValues}
            />
          )}
        </div>
        <div className="d-flex align-items-center">
          {!item?.attachment_type && !item?.is_forwarded && !item?.is_reply && (
            <div className="chat__bubble">
              <div
                className={
                  i18n.language === "en"
                    ? "chat__bubble--you position-relative"
                    : "chat__bubble_you_Arabic position-relative"
                }
              >
                {item?.group_id !== null && (
                  <div>
                    <h6 className="m-0 fw-semibold">{item?.from?.name}</h6>
                  </div>
                )}
                <div className="chatArrow">
                  <ChatOptions
                    chatContext={chatContext}
                    context={context}
                    item={item}
                    attachment={item?.attachment_type?.length > 0}
                  />
                </div>
                <div className="doubletick">
                  <div className="chat__time d-flex justify-content-end">
                    {moment(item?.createdAt).format("hh:mm a")}
                  </div>
                </div>
                <div className="  d-flex align-items-center ">
                  <div>
                    <h6 className="py-3 px-2 m-0"> {item?.message}</h6>
                  </div>
                </div>
              </div>
              <div
                className={
                  i18n.language === "en"
                    ? "chat__bubble--you_triangle"
                    : "chat__bubble_you_triangle_Arabic"
                }
              ></div>
            </div>
          )}
          {item?.attachment_type === "IMAGE" &&
            !item?.is_forwarded &&
            !item?.is_reply && (
              <div
                className={
                  i18n.language === "en"
                    ? "chat__bubble chat__bubble--you chat__bubble-image position-relative"
                    : "chat__bubble chat__bubble_you_Arabic chat__bubble-image position-relative  "
                }
              >
                <div
                  className="py-3"
                  onClick={() => {
                    setCurrentImage(item?.attachment);
                    setImageShow(true);
                  }}
                >
                  <img
                    src={item?.attachment}
                    alt="img"
                    style={
                      isMobileOnly ? { width: "150px" } : { width: "300px" }
                    }
                  />
                </div>

                <div className="chatArrow">
                  <ChatOptions
                    chatContext={chatContext}
                    context={context}
                    item={item}
                    attachment={item?.attachment_type?.length > 0}
                    attachmentFile={item?.attachment}
                  />
                </div>
                <div className="doubletick">
                  <div className="chat__time d-flex justify-content-end">
                    {moment(item?.createdAt).format("hh:mm a")}
                  </div>
                </div>
              </div>
            )}
          {item?.attachment_type === "VIDEO" &&
            !item?.is_forwarded &&
            !item?.is_reply && (
              <div
                className={
                  i18n.language === "en"
                    ? "chat__bubble chat__bubble--you chat__bubble-image position-relative"
                    : "chat__bubble chat__bubble_you_Arabic chat__bubble-image position-relative  "
                }
              >
                <div className="py-3">
                  <VideoPlayer
                    volume={currentAttachmentVideo?.volume}
                    url={item?.attachment}
                    isPlaying={currentAttachmentVideo?.index === index}
                    onPlay={() =>
                      setCurrentAttachmentVideo({
                        index: index,
                      })
                    }
                    onPause={() =>
                      setCurrentAttachmentVideo({
                        index: null,
                      })
                    }
                    onVolume={(value: any) =>
                      setCurrentAttachmentVideo({
                        index: index,
                        volume: value,
                      })
                    }
                    height="360px"
                    width="435px"
                  />
                  {/* <video width="100%" height="300" controls>
                    <source src={item?.attachment} />
                    Your browser does not support HTML5 video.
                  </video> */}
                </div>
                <div className="chatArrow">
                  <ChatOptions
                    chatContext={chatContext}
                    context={context}
                    item={item}
                    attachment={item?.attachment_type?.length > 0}
                    attachmentFile={item?.attachment}
                  />
                </div>
                <div className="doubletick">
                  <div className="chat__time d-flex justify-content-end">
                    {moment(item?.createdAt).format("hh:mm a")}
                  </div>
                </div>
              </div>
            )}
          {item?.attachment_type === "AUDIO" &&
            !item?.is_forwarded &&
            !item?.is_reply && (
              <div
                className={
                  i18n.language === "en"
                    ? "chat__bubble chat__bubble--you chat__bubble-image position-relative"
                    : "chat__bubble chat__bubble_you_Arabic chat__bubble-image position-relative  "
                }
              >
                <div className="py-3">
                  <div className="sound sound-wave" style={{ width: "280px" }}>
                    {/* <Waveform audio={item?.attachment} /> */}
                    <audio src={item?.attachment} controls></audio>
                  </div>
                </div>
                <div className="chatArrow">
                  <ChatOptions
                    chatContext={chatContext}
                    context={context}
                    item={item}
                    attachment={item?.attachment_type?.length > 0}
                    attachmentFile={item?.attachment}
                  />
                </div>
                <div className="doubletick">
                  <div className="chat__time d-flex justify-content-end">
                    {moment(item?.createdAt).format("hh:mm a")}
                  </div>
                </div>
              </div>
            )}
          <div style={{ maxWidth: "420px" }}>
            {(item?.is_reply || item?.is_forwarded) && (
              <div
                className="ctext-wrap-content position-relative"
                style={
                  isMobileOnly
                    ? { maxWidth: "180px" }
                    : { maxWidth: "420px", minWidth: "140px" }
                }
              >
                <div className="chatArrowForward">
                  <ChatOptions
                    chatContext={chatContext}
                    context={context}
                    item={item}
                    attachment={item?.attachment_type?.length > 0}
                    attachmentFile={item?.attachment}
                  />
                </div>
                {item?.is_forwarded && (
                  <span className="px-2">
                    <RiShareForwardFill /> forwarded
                  </span>
                )}
                {item?.is_reply && (
                  <a href={`#${item?.reply_message_id?.id}`}>
                    <div className="replymessage-block">
                      <h5 className="conversation-name">
                        {item?.reply_message_id?.to === userContext?.user?.id
                          ? item?.from?.name
                          : "You"}
                      </h5>
                      <div className="m-0 replywd">
                        <h6 className="forwardwd">
                          {!item?.reply_message_id?.attachment_type && (
                            <>{item?.reply_message_id?.message}</>
                          )}
                        </h6>

                        <div>
                          {item?.reply_message_id?.attachment_type ===
                            "IMAGE" && (
                            <img
                              src={item?.reply_message_id?.attachment}
                              alt="img"
                              style={
                                isMobileOnly
                                  ? { width: "150px" }
                                  : { width: "300px" }
                              }
                            />
                          )}
                        </div>
                        <div>
                          {item?.reply_message_id?.attachment_type ===
                            "VIDEO" && (
                            <VideoPlayer
                              volume={currentAttachmentVideo?.volume}
                              url={item?.reply_message_id?.attachment}
                              isPlaying={
                                currentAttachmentVideo?.index === index
                              }
                              onPlay={() =>
                                setCurrentAttachmentVideo({
                                  index: index,
                                })
                              }
                              onPause={() =>
                                setCurrentAttachmentVideo({
                                  index: null,
                                })
                              }
                              onVolume={(value: any) =>
                                setCurrentAttachmentVideo({
                                  index: index,
                                  volume: value,
                                })
                              }
                              height="360px"
                              width="405px"
                            />
                            // <video width="50%" height="100" controls>
                            //   <source
                            //     src={item?.reply_message_id?.attachment}
                            //   />
                            //   Your browser does not support HTML5 video.
                            // </video>
                          )}
                        </div>
                        <div>
                          {item?.reply_message_id?.attachment_type ===
                            "AUDIO" && (
                            <div
                              className="sound sound-wave"
                              style={{ width: "280px" }}
                            >
                              {/* <Waveform audio={item?.attachment} /> */}
                              <audio
                                src={item?.reply_message_id?.attachment}
                                controls
                              ></audio>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </a>
                )}
                <div className="mb-0 ps-3">
                  <div className="">
                    <div
                      style={{ height: "auto" }}
                      className=" d-flex align-items-center py-3"
                    >
                      <h6 className="px-2 forwardwd">
                        {!item?.attachment_type && <>{item?.message}</>}
                      </h6>
                    </div>
                    {item?.attachment_type === "IMAGE" && (
                      <div
                        onClick={() => {
                          setCurrentImage(item?.attachment);
                          setImageShow(true);
                        }}
                      >
                        <img src={item?.attachment} alt="img" width={300} />
                      </div>
                    )}
                    {item?.attachment_type === "VIDEO" && (
                      <VideoPlayer
                        volume={currentAttachmentVideo?.volume}
                        url={item?.attachment}
                        isPlaying={currentAttachmentVideo?.index === index}
                        onPlay={() =>
                          setCurrentAttachmentVideo({
                            index: index,
                          })
                        }
                        onPause={() =>
                          setCurrentAttachmentVideo({
                            index: null,
                          })
                        }
                        onVolume={(value: any) =>
                          setCurrentAttachmentVideo({
                            index: index,
                            volume: value,
                          })
                        }
                        height="360px"
                        width="435px"
                      />
                      // <video width="100%" height="300" controls>
                      //   <source src={item?.attachment} />
                      //   Your browser does not support HTML5 video.
                      // </video>
                    )}
                    {item?.attachment_type === "AUDIO" && (
                      <div
                        className="sound sound-wave"
                        style={{ width: "280px" }}
                      >
                        {/* <Waveform audio={item?.attachment} /> */}
                        <audio src={item?.attachment} controls></audio>
                      </div>
                    )}
                  </div>
                </div>
                <div className="doubletick">
                  <div className="chat__time d-flex justify-content-end">
                    {moment(item?.createdAt).format("hh:mm a")}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default ChatReceived;
