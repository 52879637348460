import { useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { AiOutlineSearch } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { IoExitOutline } from "react-icons/io5";
import { RiArrowDownSLine } from "react-icons/ri";
import CommonAvatar from "../../../components/Avatar/CommonAvatar";
const GroupExit = ({
  userContext,
  context,
  setShow,
  setSearchMember,
  removeMemberGroup,
  createNewConversation,
  setShowGroupInfo,
  setCurrentGroupId,
  setShowConversation,
}: any) => {
  const { t, i18n } = useTranslation();
  const [viewMore, setViewMore] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const viewMoreDetails = () => {
    setViewMore(viewMore + 10);
  };

  return (
    <div
      className={
        i18n.language === "en"
          ? " chat chatline px-0 pl-md-1"
          : "chatline_Arabic px-0 pl-md-1"
      }
    >
      <div className="container-fluid p-0">
        <div className="chat__wrapper py-2 pt-mb-2 pb-md-3 position-relative">
          <div className="container">
            <div
              className={
                isMobile
                  ? i18n.language === "en"
                    ? " pointer"
                    : "arrowForword pointer m-4"
                  : " d-none"
              }
            >
              <BiArrowBack fontSize={24} color="#6b5ca4" />
            </div>
            <div>
              <div className=" d-flex justify-content-center mt-3">
                <div className="group-icon">
                  <CommonAvatar
                    name={context?.groupInfo?.group_name?.toUpperCase()}
                    size={(isMobileOnly ? 100 : 100).toString()}
                    style={{
                      backgroundColor: "#6b5ca4",
                      lineHeight: "100px",
                    }}
                  />
                  {/* <img
                    src={GroupIcon}
                    alt="grp-icon"
                    width={100}
                    height={100}
                  /> */}

                  {/* <div className="cameraIcon">
                    <div className=" d-flex justify-content-center">
                      <FaCamera />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center p-3">
                <div>{context?.groupInfo?.group_name}</div>
              </div>
            </div>
            {context?.chatType === "GROUPS" &&
              context?.groupInfo?.group_member?.find(
                (item: any) => item?.id === userContext?.user?.id
              ) && (
                <div className=" d-flex justify-content-end align-items-center py-4">
                  <Button
                    onClick={() => {
                      setShow(true);
                      setSearchMember("");
                    }}
                  >
                    {t("chat.Add Members")}
                    {/* <span>
                  <MdOutlineAddCircleOutline />
                </span> */}
                  </Button>
                </div>
              )}
            <div className="exit-bg px-5">
              <div className="card-body d-flex justify-content-between align-items-center  my-3">
                <div className="no-of-members">
                  <span className="">
                    {context?.groupInfo?.group_member?.length}
                    {""} {t("chat.Members")}
                  </span>
                </div>
                <div className="rightsidepanel-search">
                  <div
                    className={`${
                      isMobile ? "d-none " : "container-fluid p-0 forinput"
                    }`}
                  >
                    <div className="position-relative">
                      <div className="msg-lens position-absolute">
                        <AiOutlineSearch />
                      </div>
                      <div className="">
                        <input
                          id="doc_searchQueryChat"
                          type="text"
                          name="doc_searchQueryChat"
                          placeholder={t("chat.Search Members") || ""}
                          maxLength={50}
                          onChange={(e: any) => {
                            setSearchKey(e?.target?.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grpInfoHeight">
                <div>
                  {context?.groupInfo?.group_member?.length > 0 &&
                    context?.groupInfo?.group_member
                      ?.slice(0, viewMore)
                      ?.filter((item: any) =>
                        item?.name
                          ?.toLowerCase()
                          ?.includes(searchKey.toLowerCase())
                      )
                      .map((info: any) => {
                        return (
                          <>
                            <div className="card-body d-flex justify-content-between  my-3">
                              <div className="cardsec d-flex justify-content-center align-items-center">
                                <CommonAvatar
                                  name={
                                    info?.name
                                      ? info?.name?.toUpperCase()
                                      : info?.email?.toUpperCase()
                                  }
                                  imageSrc={
                                    info?.avatar !== null ||
                                    info?.avatar !== undefined
                                      ? info?.avatar
                                      : ""
                                  }
                                  size={"35"}
                                  style={{
                                    backgroundColor: "white",
                                    color: "#6b5ca4",
                                  }}
                                  className="pointer"
                                />
                                <span
                                  className="admin-acc "
                                  style={{ marginInlineStart: ".7rem" }}
                                >
                                  {info?.name}
                                </span>
                              </div>
                              <div className="rightsidepanel">
                                <div className="chatdropdown">
                                  <Dropdown className="btn-resize">
                                    <Dropdown.Toggle
                                      variant="success"
                                      id="dropdown-basic"
                                    >
                                      <RiArrowDownSLine color="#6b5ca4" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {context?.chatType === "GROUPS" &&
                                        context?.groupInfo?.group_member?.find(
                                          (item: any) =>
                                            item?.id === userContext?.user?.id
                                        ) && (
                                          <Dropdown.Item
                                            onClick={() => {
                                              removeMemberGroup(info);
                                            }}
                                          >
                                            {t("chat.Remove")}
                                          </Dropdown.Item>
                                        )}
                                      <Dropdown.Item
                                        onClick={() => {
                                          createNewConversation(info);
                                          context?.setChatType("CHATS");
                                          setShowGroupInfo(false);
                                          setShowGroupInfo(false);
                                          setCurrentGroupId("");
                                        }}
                                      >
                                        {t("chat.Message Personally")}
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                </div>

                {context?.groupInfo?.group_member?.length > 10 &&
                  context?.groupInfo?.group_member?.length >= viewMore && (
                    <div className="view-more-members d-flex justify-content-center pt-3">
                      <div
                        className="cardsec"
                        onClick={() => {
                          viewMoreDetails();
                        }}
                      >
                        <span className="admin-acc ms-2">
                          {t("chat.View more")}
                        </span>
                      </div>
                    </div>
                  )}
              </div>

              {/* <div className="card-body d-flex justify-content-between mx-5 my-3">
                <div className="cardsec">
                  <img src={GroupIcon} width="5%"></img>
                  <span className=" admin-acc ms-2">Admin 2</span>
                </div>
                <div className="rightsidepanel">Admin</div>
              </div>
              <div className="card-body d-flex justify-content-between mx-5 my-3">
                <div className="cardsec">
                  <img src={GroupIcon} width="5%"></img>
                  <span className=" admin-acc ms-2">Admin 3</span>
                </div>
              </div> */}
            </div>
            {context?.chatType === "GROUPS" &&
              context?.groupInfo?.group_member?.find(
                (item: any) => item?.id === userContext?.user?.id
              ) && (
                <div className="exit-group-btn d-flex justify-content-center p-3">
                  <div
                    className="exit-group ps-2"
                    onClick={() => {
                      removeMemberGroup(userContext?.user);
                      context?.setChatType("GROUPS");
                      setShowConversation("");
                      context?.setCurrentConversationId("");
                    }}
                  >
                    <span className="mx-2">
                      <IoExitOutline color="#db2c59" fontSize={24} />
                    </span>
                    {t("chat.Exit Group")}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default GroupExit;
