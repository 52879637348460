import { useTranslation } from "react-i18next";
import { isMobileOnly } from "react-device-detect";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import VideoPlayer from "react-video-player-extended";

const ActivityFeed = ({ context }: any) => {
  const { t, i18n } = useTranslation();
  const urlParams = useParams();

  useEffect(() => {
    getActivityDetails();
  }, [urlParams?.ticketId]);

  const getActivityDetails = async () => {
    if (
      urlParams?.ticketId !== "0" &&
      (context?.ticketActivityFeed?.length === 0 ||
        context?.ticketActivityFeed === null)
    ) {
      await context?.getTicketActivityFeed(urlParams?.ticketId);
    }
  };

  return (
    <>
      <div>
        {context?.ticketActivityFeed?.length === 0 && (
          <div className=" d-flex justify-content-center align-items-center">
            <div> {t("Activityfeed.No Activity Feed Found")}</div>
          </div>
        )}
        {context?.ticketActivityFeed?.length > 0 && (
          <div className=" pt-3">
            <div
              className={
                i18n.language === "en"
                  ? "activityfeed mt-5"
                  : "activityfeedarabic mt-5"
              }
            >
              {context?.ticketActivityFeed?.length > 0 &&
                context?.ticketActivityFeed
                  ?.sort((first: any, last: any) =>
                    first.createdAt > last.createdAt ? -1 : 1
                  )
                  ?.map((activity: any) => {
                    return (
                      <div className="daydate px-4 position-relative">
                        <div
                          className={
                            i18n.language === "en"
                              ? "sidebox position-absolute"
                              : "sideboxarabic position-absolute"
                          }
                        ></div>
                        <h6 className="datefeed">
                          {moment(activity?.createdAt).format(
                            "dddd, MMMM D, YYYY hh:mm A"
                          )}
                        </h6>
                        {activity?.activity_type === "CREATED" ? (
                          <div className=" fw-semibold pb-4">
                            {activity?.created_by?.name}
                            <span className=" fw-light">
                              {t("Activityfeed.has created a new ticket")} -{" "}
                            </span>
                            {activity?.ticket_id?.ticket_no}
                            <div>
                              {Object.keys(activity?.meta).map(
                                (item: any, index: any) => {
                                  if (
                                    item === "pictures" &&
                                    activity?.meta?.pictures?.length
                                  ) {
                                    return (
                                      <>
                                        {item} -{" "}
                                        {activity?.meta?.pictures && (
                                          <>
                                            <div
                                              className={`${
                                                isMobileOnly
                                                  ? "d-flex justify-content-center align-items-center  flex-wrap "
                                                  : "d-flex justify-content-start align-items-center flex-wrap  "
                                              }`}
                                            >
                                              {activity?.meta?.pictures.map(
                                                (image: any) => {
                                                  return (
                                                    <div className="feed p-3">
                                                      <img
                                                        src={image}
                                                        alt=""
                                                        className="embet"
                                                      />
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </>
                                    );
                                  }
                                  if (
                                    item === "videos" &&
                                    activity?.meta?.videos?.length
                                  ) {
                                    return (
                                      <>
                                        {item} -{" "}
                                        {activity?.meta?.videos && (
                                          <>
                                            <div className="d-flex justify-content-start align-items-center  overflow-hidden flex-wrap">
                                              {activity?.meta?.videos.map(
                                                (video: any) => {
                                                  return (
                                                    <div className="overflow-hidden d-flex justify-content-center p-2">
                                                      {/* <video src={video} className="embet"></video> */}
                                                      <VideoPlayer
                                                        url={video}
                                                        height="360px"
                                                        width="340px"
                                                        isPlaying={false}
                                                        volume={0}
                                                      />
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </>
                                    );
                                  }
                                  if (
                                    item === "audios" &&
                                    activity?.meta?.audios?.length
                                  ) {
                                    return (
                                      <>
                                        {item} -{" "}
                                        {activity?.meta?.audios && (
                                          <>
                                            <div className="d-flex justify-content-start align-items-center  overflow-hidden flex-wrap">
                                              {activity?.meta?.audios.map(
                                                (audio: any) => {
                                                  return (
                                                    <div className="overflow-hidden d-flex justify-content-center p-2">
                                                      <audio
                                                        src={audio}
                                                        controls
                                                      ></audio>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </>
                                    );
                                  }
                                }
                              )}
                            </div>
                            <span className=" fw-light">
                              {t("Activityfeed.for the billboard")} -{" "}
                            </span>
                            {activity?.ticket_id?.bill_board_id?.board_no}
                            <span className=" fw-light">
                              {t("Activityfeed.and assigned to")}
                            </span>
                            {activity?.ticket_id?.assignee?.name}
                          </div>
                        ) : activity?.activity_type === "VIEWED" ? (
                          <div className=" fw-semibold pb-4">
                            {activity?.created_by.name}
                            <span className=" fw-light">
                              {t("Activityfeed.has viewed the ticket")} -{" "}
                            </span>
                            {activity?.ticket_id?.ticket_no}
                          </div>
                        ) : activity?.activity_type === "UPDATED" ? (
                          <div className=" fw-semibold pb-4">
                            {activity?.created_by.name}
                            <span className=" fw-light">
                              {t("Activityfeed.has updated the ticket")} -{" "}
                            </span>
                            {activity?.ticket_id?.ticket_no}
                            {Object.keys(activity?.meta).map(
                              (item: any, index: any) => {
                                if (
                                  item !== "_id" &&
                                  item !== "pictures" &&
                                  item !== "videos" &&
                                  item !== "audios"
                                ) {
                                  return (
                                    <>
                                      <span className=" fw-light">
                                        {Object.keys(activity?.meta).length -
                                          2 ===
                                        index
                                          ? " and "
                                          : ", "}
                                        the {item} -{" "}
                                      </span>
                                      {item === "start_date" ||
                                      item === "end_date"
                                        ? moment(activity?.meta[item]).format(
                                            "YYYY-MM-DD"
                                          )
                                        : activity?.meta[item]}
                                    </>
                                  );
                                }
                                if (
                                  item === "pictures" &&
                                  activity?.meta?.pictures?.length
                                ) {
                                  return (
                                    <>
                                      {item} -
                                      {activity?.meta?.pictures && (
                                        <>
                                          <div
                                            className={`${
                                              isMobileOnly
                                                ? "d-flex justify-content-center align-items-center  flex-wrap "
                                                : "d-flex justify-content-start align-items-center flex-wrap  "
                                            }`}
                                          >
                                            {activity?.meta?.pictures.map(
                                              (image: any) => {
                                                return (
                                                  <div className="feed p-3">
                                                    <img
                                                      src={image}
                                                      alt=""
                                                      className="embet"
                                                    />
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </>
                                  );
                                }
                                if (
                                  item === "videos" &&
                                  activity?.meta?.videos?.length
                                ) {
                                  return (
                                    <>
                                      {item} -
                                      {activity?.meta?.videos && (
                                        <>
                                          <div className="d-flex justify-content-start align-items-center  overflow-hidden flex-wrap">
                                            {activity?.meta?.videos.map(
                                              (video: any) => {
                                                return (
                                                  <div className="overflow-hidden d-flex justify-content-center p-2">
                                                    {/* <video src={video} className="embet"></video> */}
                                                    {/* <video
                                                      width="450"
                                                      height="300"
                                                      controls
                                                      className=""
                                                    >
                                                      <source src={video} />
                                                      Your browser does not
                                                      support HTML5 video.
                                                    </video> */}
                                                    <VideoPlayer
                                                      url={video}
                                                      height="360px"
                                                      width="340px"
                                                      isPlaying={false}
                                                      volume={0}
                                                    />
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </>
                                  );
                                }
                                if (
                                  item === "audios" &&
                                  activity?.meta?.audios?.length
                                ) {
                                  return (
                                    <>
                                      {item} -
                                      {activity?.meta?.audios && (
                                        <>
                                          <div className="d-flex justify-content-start align-items-center  overflow-hidden flex-wrap">
                                            {activity?.meta?.audios.map(
                                              (audio: any) => {
                                                return (
                                                  <div className="overflow-hidden d-flex justify-content-center p-2">
                                                    <audio
                                                      src={audio}
                                                      controls
                                                    ></audio>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </>
                                  );
                                }
                              }
                            )}
                          </div>
                        ) : activity?.activity_type === "SUBMITTED" ? (
                          <div className=" fw-semibold pb-4">
                            {activity?.ticket_id?.submitted_by.name}
                            <span className=" fw-light">
                              {t("Activityfeed.has submitted the")}
                            </span>
                            {activity?.ticket_id?.ticket_no}{" "}
                            <div>
                              {Object.keys(activity?.meta).map(
                                (item: any, index: any) => {
                                  if (item === "submitted_reason") {
                                    return (
                                      <>
                                        <span className=" fw-light">
                                          {Object.keys(activity?.meta).length -
                                            2 ===
                                          index
                                            ? " and "
                                            : ", "}
                                          the {item.replace("_", " ")} -{" "}
                                        </span>
                                      </>
                                    );
                                  }
                                  if (
                                    item === "submitted_pictures" &&
                                    activity?.meta?.submitted_pictures?.length
                                  ) {
                                    return (
                                      <>
                                        {item.replace("_", " ")} -
                                        {activity?.meta?.submitted_pictures && (
                                          <>
                                            <div
                                              className={`${
                                                isMobileOnly
                                                  ? "d-flex justify-content-center align-items-center  flex-wrap "
                                                  : "d-flex justify-content-start align-items-center flex-wrap  "
                                              }`}
                                            >
                                              {activity?.meta?.submitted_pictures.map(
                                                (image: any) => {
                                                  return (
                                                    <div className="feed p-3">
                                                      <img
                                                        src={image}
                                                        alt=""
                                                        className="embet"
                                                      />
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </>
                                    );
                                  }
                                  if (
                                    item === "submitted_videos" &&
                                    activity?.meta?.submitted_videos?.length
                                  ) {
                                    return (
                                      <>
                                        {item.replace("_", " ")}-
                                        {activity?.meta?.submitted_videos && (
                                          <>
                                            <div className="d-flex justify-content-start align-items-center  overflow-hidden flex-wrap">
                                              {activity?.meta?.submitted_videos.map(
                                                (video: any) => {
                                                  return (
                                                    <div className="overflow-hidden d-flex justify-content-center p-2">
                                                      {/* <video src={video} className="embet"></video> */}
                                                      <VideoPlayer
                                                        url={video}
                                                        height="360px"
                                                        width="340px"
                                                        isPlaying={false}
                                                        volume={0}
                                                      />
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </>
                                    );
                                  }
                                  if (
                                    item === "submitted_audios" &&
                                    activity?.meta?.submitted_audios?.length
                                  ) {
                                    return (
                                      <>
                                        {item.replace("_", " ")}-
                                        {activity?.meta?.submitted_audios && (
                                          <>
                                            <div className="d-flex justify-content-start align-items-center  overflow-hidden flex-wrap">
                                              {activity?.meta?.submitted_audios.map(
                                                (audio: any) => {
                                                  return (
                                                    <div className="overflow-hidden d-flex justify-content-center p-2">
                                                      <audio
                                                        src={audio}
                                                        controls
                                                      ></audio>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </div>
                        ) : activity?.activity_type === "VERIFIED" ? (
                          <div className=" fw-semibold pb-4">
                            {activity?.ticket_id?.approved_by_supervisor?.name}
                            <span className=" fw-light">
                              {t("Activityfeed.has verified the ticket")} -
                            </span>
                            {activity?.ticket_id?.ticket_no}
                          </div>
                        ) : activity?.activity_type === "APPROVED" ? (
                          <div className=" fw-semibold pb-4">
                            {activity?.ticket_id?.approved_by_manager?.name
                              ? activity?.ticket_id?.approved_by_manager?.name
                              : "Auto Approve"}
                            <span className=" fw-light">
                              {t("Activityfeed.has approved the ticket")} -
                            </span>
                            {activity?.ticket_id?.ticket_no}
                          </div>
                        ) : activity?.activity_type === "REJECTED" ? (
                          <div className=" fw-semibold pb-4">
                            {activity?.created_by?.name}
                            <span className=" fw-light">
                              {t("Activityfeed.has rejected the ticket")} -
                            </span>
                            {activity?.ticket_id?.ticket_no}
                          </div>
                        ) : activity?.activity_type === "REASSIGN" ? (
                          <div className=" fw-semibold pb-4">
                            {activity?.created_by?.name}
                            <span className=" fw-light">
                              {t("Activityfeed.has reassigned the ticket")} -
                            </span>
                            {activity?.ticket_id?.ticket_no}
                            <span className=" fw-light">
                              {t("Activityfeed.to the")}
                            </span>
                            {activity?.ticket_id?.assignee?.name}
                          </div>
                        ) : activity?.activity_type === "CLOSED" ? (
                          <div className=" fw-semibold pb-4">
                            {activity?.created_by?.name}
                            <span className=" fw-light">
                              {t("Activityfeed.has closed the ticket")} -
                            </span>
                            {activity?.ticket_id?.ticket_no}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}

              {/* <div className=" pt-3">
            <div
              className={
                i18n.language === "en"
                  ? "activityfeed mt-5"
                  : "activityfeedarabic mt-5"
              }
            >
              <div className="daydate px-4 position-relative">
                <div
                  className={
                    i18n.language === "en"
                      ? "sidebox position-absolute"
                      : "sideboxarabic position-absolute"
                  }
                ></div>
                <h6 className="datefeed">Monday, December 12,2022</h6>
                <div className=" fw-semibold pb-4">
                  Elakiya
                  <span className=" fw-light">has created new ticket for</span>
                  BB1-0812202208DEC2022
                </div>
                <div className="row pb-4">
                  <div className="col-md-6 col-12  pe-md-4 ">
                    <div className="feed">
                      <img src={man2} alt="" className="embet" />
                    </div>
                  </div>
                  <div
                    className={`${
                      isMobileOnly
                        ? " pt-4 col-md-6 col-12 ps-md-5 "
                        : "col-md-6 col-12 ps-md-5 "
                    }`}
                  >
                    <div className="feed">
                      <img src={man1} alt="" className="embet" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="daydate px-4 position-relative">
                <div
                  className={
                    i18n.language === "en"
                      ? "sidebox position-absolute"
                      : "sideboxarabic position-absolute"
                  }
                ></div>
                <h6 className="datefeed">Monday, December 12,2022</h6>
                <div className=" fw-semibold pb-4">
                  <span className="ticketreminder">[REMINDER]</span>
                  <span className=" fw-light">Due date of</span>
                  <span className="ticketreminder">BB1-0812202208DEC2022</span>
                  <span className=" fw-light">ticket will be coming</span>
                </div>
              </div>
              <div className="daydate px-4 position-relative">
                <div
                  className={
                    i18n.language === "en"
                      ? "sidebox position-absolute"
                      : "sideboxarabic position-absolute"
                  }
                ></div>
                <h6 className="datefeed">Monday, December 12,2022</h6>
                <div className=" fw-semibold pb-4">
                  Elakiya <span className=" fw-light">commented at </span>
                  <span className="feedpending">BB1-0812202208DEC2022</span>
                </div>
              </div>
              <div className="daydate px-4 position-relative">
                <div
                  className={
                    i18n.language === "en"
                      ? "sidebox position-absolute"
                      : "sideboxarabic position-absolute"
                  }
                ></div>
                <h6 className="datefeed">Monday, December 12,2022</h6>
                <div className=" fw-semibold pb-4">
                  Elakiya
                  <span className=" fw-light">has created new ticket for</span>
                  BB1-0812202208DEC2022
                </div>
                <div className="row pb-4">
                  <div className="col-md-4 col-12  pe-md-4 ">
                    <div className="feed">
                      <img src={man2} alt="" className="embet" />
                    </div>
                  </div>
                  <div
                    className={`${
                      isMobileOnly
                        ? " pt-4 col-md-6 col-12 ps-md-5 "
                        : "col-md-4 col-12 ps-md-4 "
                    }`}
                  >
                    <div className="feed">
                      <img src={man2} alt="" className="embet" />
                    </div>
                  </div>
                  <div
                    className={`${
                      isMobileOnly
                        ? " pt-4 col-md-6 col-12 ps-md-5 "
                        : "col-md-4 col-12 ps-md-4 "
                    }`}
                  >
                    <div className="feed">
                      <img src={man1} alt="" className="embet" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="daydate px-4 position-relative">
                <div
                  className={
                    i18n.language === "en"
                      ? "sidebox position-absolute"
                      : "sideboxarabic position-absolute"
                  }
                ></div>
                <h6 className="datefeed">Monday, December 12,2022</h6>
                <div className=" fw-semibold pb-4">
                  You <span className=" fw-light">has moved</span>
                  <span className=" feeddone"> BB1-0812202208DEC2022 </span>
                  <span className=" fw-light">ticket to</span> Done
                </div>
              </div>
            </div>
          </div> */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ActivityFeed;
