import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { Button, Form, FormGroup, Spinner } from "react-bootstrap";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import CommonAsyncSelect from "../../../components/CommonComponents/CommonAsyncSelect";
import SuccessErrorToast from "../../../components/PopupToast/SuccessErrorToast";
import ToastPopup from "../../../components/PopupToast/ToastPopup";
import { routes } from "../../../constants/routes";
import {
  InventeryContextProp,
  useInventery,
} from "../../../context/InventeryProvider";
import AppLayout from "../../../layouts/AppLayout";

export const AddStockAdjustment = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const context = useInventery();

  return (
    <AppLayout>
      <div
        className="edit_members p-3 "
        style={
          isMobile
            ? { background: "#FAFAFA", height: "auto" }
            : { background: "#FAFAFA", minHeight: "80vh" }
        }
      >
        <div className="members py-4 mt-2 ">
          <h5
            className={`${
              isMobile ? "fs-6  mb-0 membertitle" : "mb-0 membertitle"
            }`}
            onClick={() => navigate(routes.stockAdjustment)}
          >
            {t("inventory.AllStockAdjustment")}
          </h5>
          <>
            <span className={i18n.language === "en" ? "px-2 " : "d-none"}>
              <MdOutlineArrowForwardIos />
            </span>
            <span className={i18n.language === "en" ? "d-none" : "px-2"}>
              <MdArrowBackIos />
            </span>
            <h5
              className={`${isMobile ? "fs-6 text-capitalize m-0  " : " m-0 "}`}
            >
              {t("inventory.StockAdjustment")}
            </h5>
          </>
        </div>
        {context && <StockAdjustmentForm context={context} />}
      </div>
    </AppLayout>
  );
};

export const StockAdjustmentForm = ({
  context,
}: {
  context: InventeryContextProp;
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [bodyMessage, setBodyMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [sockList, setStockList] = useState<any>([]);

  const initialValue = {
    stock_code: "",
    stock_code_name: "",
    stock_code_qty: "",
    quantity: "0",
    description: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: Yup.object({
      stock_code: Yup.string().required("Stock Code is required"),
      quantity: Yup.string().required("Quantity is required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: async (values, { validateForm }) => {
      validateForm(values);
      const { data } = await context.addStockAdjustment({
        ...values,
        quantity: parseInt(values.quantity),
      });
      setIsSuccess(data?.success);
      if (data?.success) {
        formik.resetForm();
        timeOutPopUp(true);
        setBodyMessage(
          `${t("successError.create the stock Adjustment successfully")}`
        );
      } else {
        timeOutPopUp(false);
        setBodyMessage(data?.error || "Error");
      }
    },
  });

  const timeOutPopUp = (value: any) => {
    let timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        setOpenPopup(false);
        setBodyMessage("");
        navigate(routes.stockAdjustment);
      } else {
        setOpenPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  const getStocks = useCallback(async () => {
    const { success, stocks } = await context.loadInventoryMaterials({
      status: "ACTIVE",
    });
    if (success) {
      setStockList(
        stocks.map((d: any) => ({
          label: d?.stock_code,
          value: d?.id,
          qty: d?.available_qty_in_store,
        }))
      );
    } else {
      setStockList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getStocks();
  }, [getStocks]);

  const getSupplierOptions: any = async (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    const { stocks } = await context.loadInventoryMaterials({
      status: "ACTIVE",
      search: inputValue,
    });
    callback(
      stocks.map((d: any) => ({
        label: d?.stock_code,
        value: d?.id,
        qty: d?.available_qty_in_store,
      }))
    );
  };

  const { errors, handleSubmit, isSubmitting, isValid, resetForm } = formik;
  return (
    <div className="px-4 pb-5">
      <FormikProvider value={formik}>
        <Form noValidate={true} onSubmit={handleSubmit}>
          <div className="">
            <div className="p-3 my-4" style={{ background: "#fefefe" }}>
              <div className="row ">
                <div className="col-md-6 col-12  pe-md-4 formprofile">
                  <FormGroup className="mt-4 roleForm position-relative">
                    <label>
                      {t("inventory.Stock Code")}
                      <span className="">*</span>
                    </label>
                    <CommonAsyncSelect
                      className={"select-wrapper"}
                      isMulti={false}
                      values={{
                        label: formik?.values?.stock_code_name,
                        value: formik?.values?.stock_code,
                      }}
                      options={sockList}
                      cacheOptions
                      loadOptionValue={getSupplierOptions}
                      updateField={(option: any) => {
                        formik?.setFieldValue("stock_code_name", option.label);
                        formik?.setFieldValue("stock_code", option.value);
                        formik?.setFieldValue("stock_code_qty", option.qty);
                      }}
                    />
                    <Form.Control.Feedback
                      style={{
                        display: !!errors.stock_code ? "block" : "none",
                      }}
                      type={!!errors.stock_code ? "invalid" : "valid"}
                    >
                      <ErrorMessage name="stock_code" />
                    </Form.Control.Feedback>
                  </FormGroup>
                </div>

                <div className="col-md-6 col-12  pe-md-4 formprofile">
                  <FormGroup className="mt-4 roleForm position-relative">
                    <label>{t("inventory.Available quantity in store")}</label>
                    <Field
                      name="stock_code_qty"
                      type="string"
                      maxLength={20}
                      className={`form-control ${
                        errors.stock_code_qty ? "form-control-invalid" : ""
                      }`}
                      onChange={(e: any) => {
                        if (
                          /^[0-9\b]+$/.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          formik?.setFieldValue(
                            "stock_code_qty",
                            e?.target?.value
                          );
                        }
                      }}
                      disabled={true}
                    />
                    <Form.Control.Feedback
                      style={{
                        display: !!errors.stock_code_qty ? "block" : "none",
                      }}
                      type={!!errors.stock_code_qty ? "invalid" : "valid"}
                    >
                      <ErrorMessage name="stock_code_qty" />
                    </Form.Control.Feedback>
                  </FormGroup>
                </div>
              </div>
            </div>
            <div
              className="row px-3 pb-4 mt-4"
              style={{ background: "#fefefe" }}
            >
              <div className="row">
                <div className={"col-12 formprofile"}>
                  <FormGroup className="mt-4 roleForm position-relative">
                    <label>{t("inventory.Quantity")}</label>
                    <span className="">*</span>

                    <Field
                      name="quantity"
                      type="text"
                      autoFocus
                      className={`form-control`}
                      maxLength={15}
                      onChange={(e: any) =>
                        formik.setFieldValue(
                          "quantity",
                          e?.target?.value?.replace(/[^0-9]/gi, "")
                        )
                      }
                    />
                    <Form.Control.Feedback
                      style={{
                        display: !!errors.quantity ? "block" : "none",
                      }}
                      type={!!errors.quantity ? "invalid" : "valid"}
                    >
                      <ErrorMessage name="quantity" />
                    </Form.Control.Feedback>
                  </FormGroup>
                </div>
              </div>
            </div>
            <div
              className="row px-3 pb-4 mt-4"
              style={{ background: "#fefefe" }}
            >
              <div className="row">
                <div className={"col-12 formprofile"}>
                  <FormGroup className="mt-4">
                    <label>
                      {t("cleaning.Description")}
                      <span className="">*</span>
                    </label>
                    <Field
                      name="description"
                      type="text"
                      className={`form-control`}
                      as="textarea"
                      maxLength={250}
                    />
                    <Form.Control.Feedback
                      style={{
                        display: !!errors.description ? "block" : "none",
                      }}
                      type={!!errors.description ? "invalid" : "valid"}
                    >
                      <ErrorMessage name="description" />
                    </Form.Control.Feedback>
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end py-5">
              <Button
                className={`${
                  isMobileOnly
                    ? "  btn_cancel mx-3 rounded-1 d-flex  justify-content-center   align-items-center"
                    : " mx-3  btn_cancel rounded-1 d-flex  justify-content-center   align-items-center"
                }`}
                disabled={isSubmitting}
                onClick={() => resetForm()}
              >
                <span className={` ${!isValid ? "" : "fw-semibold"}`}>
                  {t("inventory.Cancel")}
                </span>
              </Button>
              <Button
                type="submit"
                className=" btn_update rounded-1 d-flex justify-content-center align-items-center"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <span className={` ${!isValid ? "" : "fw-semibold"}`}>
                    {t("inventory.Add StockAdjustment")}
                  </span>
                )}
              </Button>
            </div>
          </div>
        </Form>
      </FormikProvider>
      <ToastPopup
        trigger={context?.isLoading}
        setTrigger={context?.setIsLoading}
      >
        <SuccessErrorToast isLoading={true} />
      </ToastPopup>
      <ToastPopup trigger={openPopup} setTrigger={setOpenPopup}>
        <SuccessErrorToast
          bodyMessage={bodyMessage}
          isSuccess={isSuccess}
          footButton={false}
        />
      </ToastPopup>
    </div>
  );
};
