import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "../../App";
import Dashboard from "./Dashboard";
import { routes } from "../../constants/routes";
import { DashboardProvider } from "../../context/DashboardProvider";
import OverView from "./OverView";

const DashboardPages = ({ userContext }: any) => {
  return (
    <DashboardProvider>
      <Routes>
        <Route
          path={routes.dashboard}
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path={routes.overView}
          element={
            <RequireAuth>
              <OverView />
            </RequireAuth>
          }
        />
      </Routes>
    </DashboardProvider>
  );
};

export default DashboardPages;
