import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { TiTick } from "react-icons/ti";
import { IoMdCloseCircleOutline } from "react-icons/io";

const ProgressBar = ({ context }: any) => {
  const { t, i18n } = useTranslation();
  let currentStepStatus =
    context?.ticket.status === "CREATED" ||
    context?.ticket.status === "REASSIGNED"
      ? 1
      : context?.ticket.status === "VIEWED"
      ? 2
      : context?.ticket.status === "SUBMITTED"
      ? 3
      : context?.ticket.status === "VERIFIED"
      ? 4
      : context?.ticket.status === "APPROVED" ||
        context?.ticket.status === "REJECTED"
      ? 5
      : (context?.ticket?.approved_by_supervisor ||
          context?.ticket?.rejected_by_supervisor) &&
        context?.ticket.status === "CLOSED"
      ? 5
      : 6;
  let currentStepDetails =
    context?.ticket.status === "CREATED" ||
    context?.ticket.status === "VIEWED" ||
    context?.ticket.status === "SUBMITTED" ||
    context?.ticket.status === "VERIFIED"
      ? [
          t("CREATED"),
          t("VIEWED"),
          t("SUBMITTED"),
          t("VERIFIED"),
          t("REASSIGNED"),
          t("APPROVED"),
          t("CLOSED"),
        ]
      : context?.ticket.status === "OVERDUE" && !context?.ticket?.reassigned_on
      ? [t("CREATED"), t("VIEWED"), t("Overdue")]
      : context?.ticket.status === "REASSIGNED" ||
        (context?.ticket?.reassigned_on && context?.ticket.status != "OVERDUE")
      ? [
          t("REASSIGNED"),
          t("VIEWED"),
          t("SUBMITTED"),
          t("VERIFIED"),
          t("APPROVED"),
          t("CLOSED"),
        ]
      : context?.ticket?.approved_by_supervisor &&
        context?.ticket.status === "APPROVED"
      ? [
          t("CREATED"),
          t("VIEWED"),
          t("SUBMITTED"),
          t("VERIFIED"),
          t("APPROVED"),
          t("CLOSED"),
        ]
      : context?.ticket?.rejected_by_supervisor &&
        context?.ticket.status === "REJECTED"
      ? [
          t("CREATED"),
          t("VIEWED"),
          t("SUBMITTED"),
          t("VERIFIED"),
          t("REJECTED"),
          t("CLOSED"),
        ]
      : context?.ticket.status === "OVERDUE" && context?.ticket?.reassigned_on
      ? [t("REASSIGNED"), t("VIEWED"), t("Overdue")]
      : [];

  const steps = currentStepDetails;
  return (
    <>
      <div className="d-flex justify-content-between ">
        {steps?.length > 0 &&
          steps?.map((step, i) => {
            return (
              <div
                key={i}
                className={
                  i18n.language === "en"
                    ? `${
                        isMobileOnly
                          ? `step-item2 ${
                              currentStepStatus === i + 1 && "progressactive"
                            } ${i + 1 < currentStepStatus && "complete"} `
                          : `step-item ${
                              currentStepStatus === i + 1 && "progressactive"
                            } ${i + 1 < currentStepStatus && "complete"} `
                      }`
                    : `stepArabic ${
                        currentStepStatus === i + 1 && "progressactive"
                      } ${i + 1 < currentStepStatus && "complete"} `
                }
              >
                <div className={`${isMobileOnly ? "step2 " : "step "}`}>
                  {i + 1 < currentStepStatus ? <TiTick size={24} /> : i + 1}
                </div>
                <h6
                  className={`${
                    isMobileOnly ? "d-none " : " m-0 fs-6 pt-2 fw-light"
                  }`}
                >
                  {step}
                </h6>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default ProgressBar;
