export interface SearchProps {
    placeholder?: string;
    className?: string;
    onChange?: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
    onKeyDown?: ((e: React.KeyboardEvent<HTMLInputElement>) => void) | undefined;
    isDisabled?: boolean;
}

const CommonSearch = ({
    placeholder,
    onChange,
    onKeyDown,
    className = "",
    isDisabled,
}: SearchProps) => {
    return (
        <input
            id="doc_searchQueryInput"
            type="text"
            name="doc_searchQueryInput"
            maxLength={50}
            className={className}
            placeholder={placeholder}
            onKeyDown={onKeyDown}
            onChange={onChange}
            disabled={isDisabled}
        />
    );
};

export default CommonSearch;
