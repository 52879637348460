import { getIn } from "formik";
import { FormGroup, FormLabel } from "react-bootstrap";
import { ChangeEvent, FocusEvent, ReactNode } from "react";
import { AnySchema } from "yup";

export interface CustomInputFieldProps {
  validationSchema: AnySchema<any> | undefined;
  label: string;
  field: {
    name: string;
    value: Date | string | string[] | number | undefined | unknown;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onBlur: (e?: FocusEvent<HTMLInputElement, Element> | undefined) => void;
  };
  error: string | undefined;
  children: ReactNode;
  inputId: string;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isRequiredField = (validationSchema: any, name: string) => {
  return !!getIn(validationSchema?.describe().fields, name)?.tests?.find(
    (obj: { name: string }) => obj.name === "required" || obj.name === "min"
  );
};

export default function CustomInputField({
  validationSchema,
  label,
  field,
  error,
  children,
  inputId,
}: CustomInputFieldProps) {
  return (
    <FormGroup controlId={inputId} className="">
      <FormLabel className="form_label">
        {label || ""} {isRequiredField(validationSchema, field.name) && "*"}
      </FormLabel>
      <div>{children}</div>

      {error && (
        <small className={`form-text text-danger form_error`}>{error}</small>
      )}
    </FormGroup>
  );
}
