import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { Button, Form, FormGroup, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import SuccessErrorToast from "../../components/PopupToast/SuccessErrorToast";
import ToastPopup from "../../components/PopupToast/ToastPopup";
import { routes } from "../../constants/routes";
import { useAuthentication } from "../../context/AuthenticationProvider";
import LoginLayout from "../../layouts/LoginLayout";
import { BiErrorCircle } from "react-icons/bi";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";

const ForgetPasswordPage = () => {
  const context = useAuthentication();
  const [bodyMessage, setBodyMessage] = useState("");
  const [buttonPopup, setButtonPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  let timedPopupSetTimeOut: any = undefined;
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(
          /^([_A-Za-z0-9+]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5}))|(\d+$)$/,
          "Invalid email address or phone number"
        )
        .min(13, "Invalid email address or phone number")
        .required("E-mail Address or phone number is required"),
    }),
    onSubmit: async (values) => {
      const res = await context?.forgotpassword(values.email);
      const { success, error } = res.data;
      setIsSuccess(success);
      if (success) {
        formik.resetForm();
        setButtonPopup(true);
        setBodyMessage(`${t("successError.forgot")} ${values.email}`);
        timeOutPopUp(true);
      } else {
        setButtonPopup(true);
        setBodyMessage(error);
        timeOutPopUp(false);
      }
    },
  });

  const timeOutPopUp = (value: any) => {
    timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        navigate(routes.login);
        setBodyMessage("");
      } else {
        setButtonPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  const { errors, handleSubmit, isSubmitting, isValid, dirty } = formik;

  return (
    <>
      <LoginLayout
        title={t("forgotpassword.title")}
        subTitle={t("forgotpassword.subtitle")}
      >
        <FormikProvider value={formik}>
          <Form validated={!!errors} onSubmit={handleSubmit}>
            <div className="floating">
              <FormGroup className="my-4 position-relative">
                <label>{t("forgotpassword.label")} *</label>

                <Field
                  name="email"
                  type="text"
                  className="form-control"
                  maxLength={50}
                />
                {formik?.errors?.email && (
                  <div
                    className={
                      i18n.language === "en" ? "eyeicon" : "eyeicon-right"
                    }
                  >
                    <BiErrorCircle color="#db2c59" />
                  </div>
                )}
                <small className="text-danger">
                  <ErrorMessage name="email" />
                </small>
              </FormGroup>
            </div>

            <div>
              <Button
                type="submit"
                variant="primary"
                className="my-5 px-5 py-2 rounded-1 d-flex justify-content-center align-items-center pointer"
                disabled={isSubmitting || !isValid || !dirty}
              >
                {isSubmitting ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <>
                    <span className={` ${!isValid ? "" : "fw-semibold"}`}>
                      {t("forgotpassword.reset")}
                    </span>
                    <span
                      className={i18n.language === "en" ? "ms-2" : "d-none"}
                    >
                      <FaLongArrowAltRight />
                    </span>
                    <span
                      className={i18n.language === "en" ? "d-none" : "me-2"}
                    >
                      <FaLongArrowAltLeft />
                    </span>
                  </>
                )}
              </Button>
            </div>
            <div className="mt-4">
              <NavLink
                to={routes.login}
                className="text-primary text-decoration-none"
              >
                {t("forgotpassword.i remember")}
              </NavLink>
            </div>
          </Form>
        </FormikProvider>
      </LoginLayout>
      <ToastPopup trigger={buttonPopup} setTrigger={setButtonPopup}>
        <SuccessErrorToast
          bodyMessage={bodyMessage}
          isSuccess={isSuccess}
          // okButton={() => isSuccess ? loginNow() : closeErrorPopUp()}
          // buttonName='Login Now!'
          footButton={false}
        />
      </ToastPopup>
    </>
  );
};

export default ForgetPasswordPage;
