import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppLayout from "../../layouts/AppLayout";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useBillBoard } from "../../context/BillBoardProvider";
import NestedForm from "../../components/CommonComponents/NestedForm";
import { routes } from "../../constants/routes";
import { MdArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import ivms from "../../assets/7250232.png";
import nova from "../../assets/Logo.png";
import teamv from "../../assets/team-viewer.png";
import moment from "moment";
import { FiHardDrive } from "react-icons/fi";
import { RiCpuLine } from "react-icons/ri";
import { BiMicrochip } from "react-icons/bi";
import PercentageBar from "../../components/CommonComponents/PercentageBar";
import ToastPopup from "../../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../../components/PopupToast/SuccessErrorToast";

const BillBoardTicketPage = (props: any) => {
  const { t, i18n } = useTranslation();
  const context = useBillBoard();
  const navigate = useNavigate();
  const { billboardId } = useParams();

  useEffect(() => {
    const loadData = async (billboardId: string) => {
      await context?.getBillBoardDetail(billboardId);
    };
    if (billboardId) {
      loadData(billboardId);
    }
  }, [billboardId]);

  return (
    <AppLayout>
      <div className={`${isMobile ? "billboard   " : "billboard p-3 "}`}>
        <div className={`${isMobile ? "container-fluid p-0" : "container "}`}>
          <div className=" d-flex justify-content-between py-4 mt-2 flex-wrap px-2">
            <div className="members  d-flex align-items-center ">
              <h5
                className={`${
                  isMobile ? "fs-6  mb-0 membertitle" : "  mb-0 membertitle"
                }`}
                onClick={() => navigate(routes.listBillBoard)}
              >
                {t("BillboardTicketPage.BillBoard_Number")}
              </h5>

              <span className={i18n.language === "en" ? "px-2 " : "d-none"}>
                <MdOutlineArrowForwardIos />
              </span>
              <span className={i18n.language === "en" ? "d-none" : "px-2"}>
                <MdArrowBackIos />
              </span>
              <h5 className={`${isMobile ? "  m-0 " : " m-0 "}`}>
                {context?.billBoard?.board_no}
              </h5>
            </div>

            <h6
              className={` fw-semibold pe-4 ${isMobile ? "mb-0 mt-3" : "m-0"}`}
              onClick={() =>
                window.open(
                  // `https://www.google.com/maps/dir//${context?.billBoard?.board_location}`
                  `https://www.google.com/maps/place/${context?.billBoard?.location?.coordinates[1]},${context?.billBoard?.location?.coordinates[0]}`
                )
              }
            >
              {context?.billBoard?.board_location}
            </h6>
          </div>
          <div className="whitebox py-5 px-4 rounded-1">
            <div
              className="blackbox p-4 rounded-1 d-flex flex-column justify-content-between"
              // style={{ height: isMobileOnly ? "35vh" : "40vh" }}
            >
              <div className="d-flex justify-content-between align-items-center">
                {context?.billBoard?.is_novastar ? (
                  (
                    context?.socketData?.id === billboardId &&
                    context?.socketData?.novastar === "online"
                      ? context?.socketData?.novastar === "online"
                      : context?.billBoard?.novastar_status === "online"
                  ) ? (
                    <h6 className="Active m-0 fw-semibold">
                      {t("BillboardTicketPage.Online")}
                    </h6>
                  ) : (
                    <h6 className="inactive_btn m-0 fw-semibold">
                      {t("BillboardTicketPage.Offline")}
                    </h6>
                  )
                ) : (
                  <h6 className="inactive_btn m-0 fw-semibold">NA</h6>
                )}

                <div>
                  <h5 className=" text-white fw-semibold m-0">
                    {t("BillboardTicketPage.NovaStar")}
                  </h5>
                  {context?.billBoard?.is_novastar && (
                    <div
                      className={
                        i18n.language === "en"
                          ? "dateandtime"
                          : "dateandtimeArabic"
                      }
                    >
                      <h6 className=" ff-questrial text-white">
                        {context?.socketData?.id === context?.billBoard?.id &&
                        context?.socketData?.novastar_last_online
                          ? moment(
                              context?.socketData?.novastar_last_online
                            ).format("DD MMM YYYY hh:mm A")
                          : moment(
                              context?.billBoard?.novastar_status_on
                            ).format("DD MMM YYYY hh:mm A")}
                      </h6>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-center py-5 my-3">
                <div
                  className={`${
                    isMobileOnly ? "  logobillboardmobile" : "  logobillboard"
                  }`}
                >
                  <img src={nova} alt="" />
                </div>
              </div>

              {context?.billBoard?.is_novastar && (
                <React.Fragment>
                  <div>
                    {(context?.socketData?.meta
                      ? context?.socketData?.meta
                      : context?.billBoard?.meta) && (
                      <div className="row">
                        <div className={`${isMobile ? "col-12" : "col-4 "}`}>
                          <div className=" d-flex  align-items-center">
                            <h5 className="text-white fw-semibold pb-3">
                              <RiCpuLine color="#fefefe" fontSize={24} />
                            </h5>
                            <div style={{ width: "100%" }}>
                              <PercentageBar
                                value={parseFloat(
                                  context?.socketData?.meta?.cpu
                                    ? context?.socketData?.meta?.cpu
                                    : context?.billBoard?.meta?.cpu
                                )}
                              />
                              <div className="ms-2 mt-1">
                                <h6 className=" m-0 text-white fornova">
                                  CPU -
                                  {`${
                                    context?.socketData?.meta?.cpu
                                      ? context?.socketData?.meta?.cpu
                                      : context?.billBoard?.meta?.cpu
                                  }%`}
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className=" d-flex align-items-center">
                            <h5 className="text-white fw-semibold   pb-3">
                              <BiMicrochip color="#fefefe" fontSize={24} />
                            </h5>
                            <div style={{ width: `100%` }}>
                              <PercentageBar
                                value={
                                  (parseFloat(
                                    context?.socketData?.meta?.mem_used
                                      ? context?.socketData?.meta?.mem_used
                                      : context?.billBoard?.meta?.mem_used
                                  ) /
                                    parseFloat(
                                      context?.socketData?.meta?.mem_total
                                        ? context?.socketData?.meta?.mem_total
                                        : context?.billBoard?.meta?.mem_total
                                    )) *
                                  100
                                }
                              />
                              <div className="ms-2 mt-1">
                                <h6 className=" m-0 text-white fornova">
                                  RAM -
                                  {`${
                                    context?.socketData?.meta?.mem_used
                                      ? context?.socketData?.meta?.mem_used
                                      : context?.billBoard?.meta?.mem_used
                                  }GB`}
                                  /
                                  {context?.socketData?.meta?.mem_total
                                    ? context?.socketData?.meta?.mem_total
                                    : context?.billBoard?.meta?.mem_total}
                                  GB
                                </h6>
                              </div>
                            </div>
                          </div>
                          {(context?.socketData?.meta?.computer
                            ? context?.socketData?.meta?.computer
                            : context?.billBoard?.meta?.computer
                          )?.map((computer: any, index: any) => {
                            return (
                              <div className=" d-flex align-items-center">
                                <h5 className="text-white fw-semibold pb-3">
                                  <FiHardDrive color="#fefefe" fontSize={24} />
                                </h5>

                                <div style={{ width: "100%" }}>
                                  <PercentageBar
                                    value={
                                      (parseFloat(computer.used) /
                                        parseFloat(computer.total)) *
                                      100
                                    }
                                  />
                                  <div className="ms-2 mt-1">
                                    <h6 className=" m-0 text-white fornova">
                                      {computer.name} {`${computer.used}GB`}/
                                      {computer.total}GB
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="d-flex justify-content-end pb-3 ">
                    <h6
                      className="d-inline-block connect rounded-1 btn m-0"
                      onClick={() =>
                        window.open("https://monitor-eu.vnnox.com/new#/home")
                      }
                    >
                      {t("BillboardTicketPage.Connect")}
                    </h6>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className=" row justify-content-between pt-4">
              <div className={`${isMobile ? " col-12 pb-4" : " col"}`}>
                <div
                  className="blackbox p-4 rounded-1 d-flex flex-column justify-content-between"
                  // style={{ height: isMobileOnly ? "35vh" : "40vh" }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    {context?.billBoard?.is_ivms ? (
                      (
                        context?.socketData?.id === context?.billBoard?.id &&
                        context?.socketData?.ivms === "online"
                          ? context?.socketData?.ivms === "online"
                          : context?.billBoard?.ivms_status === "online"
                      ) ? (
                        <h6 className="Active m-0 fw-semibold">
                          {t("BillboardTicketPage.Online")}
                        </h6>
                      ) : (
                        <h6 className="inactive_btn m-0 fw-semibold">
                          {t("BillboardTicketPage.Offline")}
                        </h6>
                      )
                    ) : (
                      <h6 className="inactive_btn m-0 fw-semibold">NA</h6>
                    )}

                    <div>
                      <h5 className=" text-white fw-semibold m-0">IVMS 4200</h5>
                      {context?.billBoard?.is_ivms && (
                        <div
                          className={
                            i18n.language === "en"
                              ? "dateandtime"
                              : "dateandtimeArabic"
                          }
                        >
                          <h6 className=" ff-questrial text-white">
                            {context?.socketData?.id ===
                              context?.billBoard?.id &&
                            context?.socketData?.ivms_last_online
                              ? moment(
                                  context?.socketData?.ivms_last_online
                                ).format("DD MMM YYYY hh:mm A")
                              : moment(
                                  context?.billBoard?.ivms_status_on
                                ).format("DD MMM YYYY hh:mm A")}
                          </h6>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center py-5 my-3">
                    <div
                      className={`${
                        isMobileOnly
                          ? "  logobillboardmobile"
                          : "  logobillboard "
                      }`}
                    >
                      <img src={ivms} alt="" />
                    </div>
                  </div>

                  {
                    <div className=" d-flex   justify-content-end  flex-wrap">
                      {/* <div>
                      <h5 className=" text-white fw-semibold m-0">
                        08122202208DEC2022
                      </h5>
                      <div
                        className={
                          i18n.language === "en"
                            ? "dateandtime"
                            : "dateandtimeArabic"
                        }
                      >
                        <h6 className=" ff-questrial text-white m-0">
                          Password@123
                        </h6>
                      </div>
                    </div> */}
                      <div className=" d-flex align-items-center justify-content-end">
                        {context?.billBoard?.is_ivms && (
                          <h6
                            className="d-inline-block connect rounded-1 btn m-0"
                            onClick={() =>
                              window.open("https://www.hik-connect.com/")
                            }
                          >
                            {t("BillboardTicketPage.Connect")}
                          </h6>
                        )}
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className={`${isMobile ? " col-12 pb-4" : " col"}`}>
                <div
                  className="blackbox p-4 rounded-1 d-flex flex-column justify-content-between"
                  // style={{ height: isMobileOnly ? "35vh" : "40vh" }}
                >
                  <div className=" d-flex justify-content-between align-items-center">
                    {(
                      context?.socketData?.id === context?.billBoard?.id &&
                      context?.socketData?.teamviewer === "online"
                        ? context?.socketData?.teamviewer === "online"
                        : context?.billBoard?.teamviewer_status === "online"
                    ) ? (
                      <h6 className="Active m-0 fw-semibold">
                        {t("BillboardTicketPage.Online")}
                      </h6>
                    ) : (
                      <h6 className="inactive_btn m-0 fw-semibold">
                        {t("BillboardTicketPage.Offline")}
                      </h6>
                    )}
                    <div>
                      <h5 className=" text-white fw-semibold m-0">
                        {t("BillboardTicketPage.Team_Viewer")}
                      </h5>
                      <div
                        className={
                          i18n.language === "en"
                            ? "dateandtime"
                            : "dateandtimeArabic"
                        }
                      >
                        <h6 className=" ff-questrial text-white">
                          {context?.socketData?.id === context?.billBoard?.id &&
                          context?.socketData?.teamviewer_last_online
                            ? moment(
                                context?.socketData?.teamviewer_last_online
                              ).format("DD MMM YYYY hh:mm A")
                            : moment(
                                context?.billBoard?.teamviewer_status_on
                              ).format("DD MMM YYYY hh:mm A")}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center py-5 my-3">
                    <div
                      className={`${
                        isMobileOnly
                          ? "  logobillboardmobile"
                          : "  logobillboard"
                      }`}
                    >
                      <img src={teamv} alt="" />
                    </div>
                  </div>
                  <div className=" d-flex   justify-content-between flex-wrap">
                    <div>
                      <h5 className=" text-white fw-semibold m-0">
                        {context?.billBoard?.team_viewer_id}
                      </h5>
                      {/* <div
                        className={
                          i18n.language === "en"
                            ? "dateandtime"
                            : "dateandtimeArabic"
                        }
                      >
                        <h6 className=" ff-questrial text-white m-0">
                          {context?.billBoard?.team_viewer_password}
                        </h6>
                      </div> */}
                    </div>
                    <div className=" d-flex align-items-center justify-content-between">
                      {/* <div className="zoomin ">
                        <img src={zin} alt="" width={24} />
                      </div>
                      <div className="zoomout mx-4">
                        <img src={zout} alt="" width={24} />
                      </div> */}

                      <h6
                        className="d-inline-block connect rounded-1 btn m-0"
                        onClick={() =>
                          window.open("https://login.teamviewer.com/Connect")
                        }
                      >
                        {t("BillboardTicketPage.Connect")}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <NestedForm context={context} userContext={props.userContext} />
          </div>
        </div>
        <ToastPopup
          trigger={context?.isLoading}
          setTrigger={context?.setIsLoading}
        >
          <SuccessErrorToast isLoading={true} />
        </ToastPopup>
      </div>
    </AppLayout>
  );
};

export default BillBoardTicketPage;
