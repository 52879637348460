import React from "react";
import x from "../../assets/x.png";
import { isMobile, isMobileOnly } from "react-device-detect";

// interface modalProps{
//     props : react;

// }

const Modal = (props: any) => {
  return props.trigger ? (
    <>
      <div className="popup">
        <div
          className={`${isMobile ? "popup_inner p-4 m-3" : " popup_inner p-5"}`}
          style={isMobileOnly ? { maxHeight: "81vh", overflowY: "scroll" } : {}}
        >
          {props.children}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default Modal;
