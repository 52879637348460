import React, { useRef, useState } from "react";
import upload from "../../assets/upload.png";
import { usePdf } from "@mikecousins/react-pdf";
import { Button, Stack } from "react-bootstrap";
import { routes } from "../../constants/routes";
import { useLocation, useNavigate } from "react-router-dom";
import ToastPopup from "../../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../../components/PopupToast/SuccessErrorToast";

export default function TimeSheet({ userContext }: any) {
  let timedPopupSetTimeOut: any = undefined;
  const [buttonPopup, setButtonPopup] = useState(false);
  const [bodyMessage, setBodyMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const canvasRef: any = useRef(null);
  const [page, setPage] = useState(1);
  const { pdfDocument } = usePdf({
    scale: 1,
    file: userContext?.user?.time_schedule,
    page,
    canvasRef,
  });

  const handleUploadTimeSheet = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file[0]);
    const data = await userContext?.uploadFiles(formData, "file");
    if (data?.success) {
      const { success, error } = await userContext?.uploadTimeSheet({
        url: data?.url,
      });
      setIsSuccess(success);
      if (success) {
        timeOutPopUp(true);
        setButtonPopup(true);
        setBodyMessage("Update time sheet");
      } else {
        setButtonPopup(true);
        timeOutPopUp(false);
        setBodyMessage(error);
      }
    }
  };

  const timeOutPopUp = (value: any) => {
    timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        setBodyMessage("");
        setButtonPopup(false);
        navigate(routes.timeSheet);
      } else {
        setBodyMessage("");
        setButtonPopup(false);
      }
    }, 2000);
  };

  return (
    <div>
      {location.pathname === routes.timeSheetUpload ? (
        <>
          <div>
            <div className="row pt-4">
              <div className="col-12  ">
                <div className=" d-flex d-block uploads align-items-center justify-content-center position-relative fileupload ">
                  <div>
                    <img src={upload} alt="" width={29} />
                  </div>
                  <input
                    type={"file"}
                    name="myfile"
                    accept=".pdf"
                    onChange={(e: any) => handleUploadTimeSheet(e.target.files)}
                  />

                  <h6 className=" mb-0 mx-3 fw-semibold ">
                    Uploadload Time Sheet (.pdf)
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <ToastPopup trigger={buttonPopup} setTrigger={setButtonPopup}>
            <SuccessErrorToast
              bodyMessage={bodyMessage}
              isSuccess={isSuccess}
              // okButton={() => isSuccess ? loginNow() : closeErrorPopUp()}
              // buttonName='Login Now!'
              footButton={false}
            />
          </ToastPopup>
        </>
      ) : (
        <Stack className="w-md-50" style={{ margin: "0px auto" }}>
          {!pdfDocument && <span>Loading...</span>}
          <div className=" position-relative">
            <canvas ref={canvasRef} />
          </div>
          {Boolean(pdfDocument && pdfDocument.numPages) && (
            <Stack
              direction="horizontal"
              className="justify-content-center my-2"
            >
              <Button
                variant="outline-dark"
                size="sm"
                disabled={page === 1}
                onClick={() => {
                  setPage(page - 1);
                }}
                className="mx-2"
              >
                Previous
              </Button>
              {page}
              <Button
                variant="outline-dark"
                size="sm"
                className="mx-2"
                disabled={page === pdfDocument?.numPages}
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                Next
              </Button>
            </Stack>
          )}
        </Stack>
      )}
    </div>
  );
}
