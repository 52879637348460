import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "../../App";
import { routes } from "../../constants/routes";
import { MastersProvider } from "../../context/MasterProider";
import CreateNewMaterial from "./Material/CreateNewMaterial";
import CreateNewSupplier from "./Supplier/CreateNewSupplier";
import ListMasters from "./ListMasters";
import CreateNewBom from "./BOM/CreateNewBom";

const MastersPages = ({ userContext }: any) => {
  return (
    userContext?.roleAccess && (
      <MastersProvider>
        <Routes>
          <Route
            path={routes.mastersSupplier}
            element={
              <RequireAuth>
                <ListMasters userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.createMastersSupplier}
            element={
              <RequireAuth>
                <CreateNewSupplier userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.mastersEditSupplier}
            element={
              <RequireAuth>
                <CreateNewSupplier userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.mastersViewSupplier}
            element={
              <RequireAuth>
                <CreateNewSupplier userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.mastersMaterial}
            element={
              <RequireAuth>
                <ListMasters userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.createMastersMaterial}
            element={
              <RequireAuth>
                <CreateNewMaterial userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.mastersViewMaterial}
            element={
              <RequireAuth>
                <CreateNewMaterial userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.mastersEditMaterial}
            element={
              <RequireAuth>
                <CreateNewMaterial userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.masters}
            element={
              <RequireAuth>
                <ListMasters userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.listMasterBillBoardType}
            element={
              <RequireAuth>
                <ListMasters userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.bomList}
            element={
              <RequireAuth>
                <ListMasters userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.AddBomList}
            element={
              <RequireAuth>
                <CreateNewBom userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.editBomList}
            element={
              <RequireAuth>
                <CreateNewBom userContext={userContext} />
              </RequireAuth>
            }
          />
          <Route
            path={routes.viewBomList}
            element={
              <RequireAuth>
                <CreateNewBom userContext={userContext} />
              </RequireAuth>
            }
          />
        </Routes>
      </MastersProvider>
    )
  );
};

export default MastersPages;
