import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "../../App";
import File404 from "../../components/404/File404";
import { routes } from "../../constants/routes";
import { BillBoardProvider } from "../../context/BillBoardProvider";
import BillBoardTicketPage from "./BillBoardTicketPage";
import ListBillBoards from "./ListBillBoards";
import NewBillBoardNested from "./NewBillBoardNested";
import { useTranslation } from "react-i18next";
import BillboardMapPage from "./BillboardMaps/BillboardMapPage";

const BillBoardPages = ({ userContext }: any) => {
  const { t } = useTranslation();

  let billBoardType = [
    { label: `${t("BRIDGE_SCREEN")}`, value: "BRIDGE_SCREEN" },
    { label: `${t("M1MUPI")}`, value: "M1MUPI" },
    { label: `${t("M2MUPI")}`, value: "M2MUPI" },
    { label: `${t("MEZA")}`, value: "MEZA" },
    { label: `${t("MUPI")}`, value: "MUPI" },
    { label: `${t("UNIPOLE")}`, value: "UNIPOLE" },
    { label: `${t("BUILDING_SCREEN")}`, value: "BUILDING_SCREEN" },
  ];

  const screenUnits = [
    { value: "CM", label: `${t("CM")}` },
    { value: "INCH", label: `${t("INCH")}` },
    { value: "METER", label: `${t("METER")}` },
  ];
  return (
    userContext?.roleAccess && (
      <BillBoardProvider>
        <Routes>
          <Route
            path={routes.listBillBoard}
            element={
              <RequireAuth>
                {userContext?.roleAccess["BOARD"].includes("READ") ? (
                  <ListBillBoards
                    userContext={userContext}
                    billBoardType={billBoardType}
                    screenUnits={screenUnits}
                  />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          {/* <Route
            path={routes.billBoardMap}
            element={
              <RequireAuth>
                {userContext?.roleAccess["BOARD"].includes("READ") ? (
                  <BillboardMapPage
                    userContext={userContext}
                    billBoardType={billBoardType}
                    screenUnits={screenUnits}
                  />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          /> */}

          <Route
            path={routes.getBillBoardTicketsAdd}
            element={
              <RequireAuth>
                {userContext?.roleAccess["BOARD"].includes("READ") ? (
                  <BillBoardTicketPage
                    userContext={userContext}
                    billBoardType={billBoardType}
                    screenUnits={screenUnits}
                  />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.getBillBoardTicketsId}
            element={
              <RequireAuth>
                {userContext?.roleAccess["BOARD"].includes("READ") ? (
                  <BillBoardTicketPage
                    userContext={userContext}
                    billBoardType={billBoardType}
                    screenUnits={screenUnits}
                  />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.getBillBoardTicketsActivityFeed}
            element={
              <RequireAuth>
                {userContext?.roleAccess["BOARD"].includes("READ") ? (
                  <BillBoardTicketPage
                    userContext={userContext}
                    billBoardType={billBoardType}
                    screenUnits={screenUnits}
                  />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.newBillBoard}
            element={
              <RequireAuth>
                {userContext?.roleAccess["BOARD"].includes("READ") ? (
                  <NewBillBoardNested
                    userContext={userContext}
                    billBoardType={billBoardType}
                    screenUnits={screenUnits}
                  />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.editBillBoard}
            element={
              <RequireAuth>
                {userContext?.roleAccess["BOARD"].includes("READ") ? (
                  <NewBillBoardNested
                    userContext={userContext}
                    billBoardType={billBoardType}
                    screenUnits={screenUnits}
                  />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.uploadBillBoard}
            element={
              <RequireAuth>
                {userContext?.roleAccess["BOARD"].includes("READ") ? (
                  <NewBillBoardNested
                    userContext={userContext}
                    billBoardType={billBoardType}
                    screenUnits={screenUnits}
                  />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
          <Route
            path={routes.billOfMaterials}
            element={
              <RequireAuth>
                {userContext?.roleAccess["BOARD"].includes("READ") ? (
                  <NewBillBoardNested
                    userContext={userContext}
                    billBoardType={billBoardType}
                    screenUnits={screenUnits}
                  />
                ) : (
                  <File404 />
                )}
              </RequireAuth>
            }
          />
        </Routes>
      </BillBoardProvider>
    )
  );
};

export default BillBoardPages;
