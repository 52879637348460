/* eslint-disable react-hooks/exhaustive-deps */
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import "chartjs-plugin-doughnut-innertext";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import SuccessErrorToast from "../../components/PopupToast/SuccessErrorToast";
import ToastPopup from "../../components/PopupToast/ToastPopup";
import { useDashboard } from "../../context/DashboardProvider";
import {
  stackedBaroptionsHorizontal,
  stackedBaroptionsVertical,
} from "../../interfaces/Chart/BarChart";
import {
  datamap,
  datamapticket,
  optionsmap,
  optionsmapticket,
} from "../../interfaces/Chart/GeoChart";
import DashBoardDountChart from "./DashBoardDountChart";
import DashboardBarAndLineChart from "./DashboardBarAndLineChart";
import DashboardBarChart from "./DashboardBarChart";
import DashboardMapChart from "./DashboardMapChart";
import DashboardOverview from "./DashboardOverviewInside";
import MapWithAMarker from "./MapWithAMarker";

ChartJS.register(
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const OverView = ({ billBoard, dateValue }: any) => {
  const context = useDashboard();
  const { t, i18n } = useTranslation();
  const [overViewData] = useState<any>("year");
  const [ticketStatus, setTicketStatus] = useState<any>({});
  const [ticketPriority, setTicketPriority] = useState<any>({});
  const [departmentPriority, setDepartmentPriority] = useState<any>({});

  const convertDate = useCallback(() => {
    return moment(new Date()).format("YYYY-MM-DD");
  }, []);

  const getDashBoardOverview = useCallback(async () => {
    await context?.getStatsOverview(convertDate(), overViewData);
  }, [context, convertDate, overViewData]);

  const getAreaWiseBillboard = useCallback(async () => {
    await context?.getAreaWiseBillboard(convertDate(), overViewData);
  }, [context, convertDate, overViewData]);

  const getDashBoardTicketStatus = useCallback(async () => {
    const result = await context?.getStatusTicket(convertDate(), overViewData);
    setTicketStatus(result);
  }, [context, convertDate, overViewData]);

  const getDashBoardTicketPriority = useCallback(async () => {
    const resultPriority = await context?.getPriorityTicket(
      convertDate(),
      overViewData
    );
    setTicketPriority(resultPriority);
  }, [context, convertDate, overViewData]);

  const getDepartmenTicket = useCallback(async () => {
    const resultPriority = await context?.getDepartmenTicket(
      convertDate(),
      overViewData
    );
    setDepartmentPriority(resultPriority);
  }, [context, convertDate, overViewData]);

  const getLocationBasedTicket = useCallback(async () => {
    await context?.getLocationBasedTicket(convertDate(), overViewData);
  }, [context, convertDate, overViewData]);

  const hitApiCall = useCallback(async () => {
    getDashBoardOverview();
    getDashBoardTicketStatus();
    getDashBoardTicketPriority();
    // getDashBoardStatusPriorityTicket();
    getDepartmenTicket();
    getLocationBasedTicket();
    getAreaWiseBillboard();
  }, [
    getAreaWiseBillboard,
    getDashBoardOverview,
    getDashBoardTicketPriority,
    getDashBoardTicketStatus,
    getDepartmenTicket,
    getLocationBasedTicket,
  ]);

  useEffect(() => {
    hitApiCall();
    const intervalId = setInterval(hitApiCall, 240000);
    return () => {
      clearInterval(intervalId);
    };
  }, [overViewData]);

  return (
    <div className="p-5">
      <div className="overviewCard p-2">
        <div className=" d-flex justify-content-between align-items-center">
          <h6 className=" fw-semibold"> {t("dashboard.stats")}</h6>
        </div>
        <div>
          <DashboardOverview context={context} />
        </div>
      </div>
      <div className="py-3 row">
        <div
          className={
            i18n.language === "en"
              ? "col-lg-4 col-6 pe-md-4"
              : "col-lg-4 col-6 pe-md-4"
          }
        >
          <div>
            <DashboardBarChart
              options={stackedBaroptionsVertical}
              title={t("dashboard.Tickets Status")}
              info={ticketStatus}
            />
          </div>
        </div>
        <div
          className={
            isMobile ? "col-lg-4 col-6 pe-md-4" : "col-lg-4 col-6 pe-md-4"
          }
        >
          <div>
            <DashboardBarChart
              options={stackedBaroptionsHorizontal}
              title={t("dashboard.Tickets Based On Priority")}
              info={ticketPriority}
            />
          </div>
        </div>
        <div
          className={
            i18n.language === "en"
              ? "col-lg-4 col-6 pe-md-4"
              : "col-lg-4 col-6 pe-md-4"
          }
        >
          <div>
            <DashboardBarAndLineChart
              options={stackedBaroptionsVertical}
              title={t("dashboard.Department")}
              info={departmentPriority}
            />
          </div>
        </div>
        {/* <div
            className={
              i18n.language === "en"
                ? "col-lg-3 col-6 pe-md-4"
                : "col-lg-3 col-6 pe-md-4"
            }
          >
            <div>
              {billBoardData?.datasets && (
                <DashboardAreaWisePieChart
                  title={"Area Wise Ticket"}
                  data={billBoardData}
                />
              )}
            </div>
          </div> */}
      </div>
      <div className="py-3 row">
        <div
          className={
            i18n.language === "en"
              ? "col-lg-3 col-6 pe-md-4"
              : "col-lg-3 col-6 pe-md-4"
          }
        >
          <div>
            <DashBoardDountChart
              title={`${t("dashboard.Team Viewer")}`}
              socketData={{
                active: context?.socketData?.active_teamviewer,
                inActive: context?.socketData?.inactive_teamviewer,
                non_operational: context?.socketData?.non_operational,
                pending: context?.socketData?.pending,
                activeColor: "#2AD2D4",
                InactiveColor: "#6289F1",
                non_operationalColor: "red",
                pendingColor: "orange",
              }}
            />
          </div>
        </div>
        <div
          className={
            isMobile ? "col-lg-3 col-6 pe-md-4" : "col-lg-3 col-6 pe-md-4"
          }
        >
          <div>
            <DashBoardDountChart
              title={`${t("dashboard.Novastar")}`}
              socketData={{
                active: context?.socketData?.active_novastar,
                inActive: context?.socketData?.inactive_novastar,
                alarm: context?.socketData?.alarm_novastar,
                fault: context?.socketData?.fault_novastar,
                non_operational: context?.socketData?.non_operational,
                pending: context?.socketData?.pending,
                novastar_na: context?.socketData?.novastar_na,
                activeColor: "green",
                InactiveColor: "grey",
                alarmColor: "#ffc65d",
                faultColor: "#fd5454",
                novastar_naColor: "black",
                non_operationalColor: "red",
                pendingColor: "orange",
              }}
            />
          </div>
        </div>
        <div
          className={
            i18n.language === "en"
              ? "col-lg-3 col-6 pe-md-4"
              : "col-lg-3 col-6 pe-md-4"
          }
        >
          <div>
            <DashBoardDountChart
              title={`${t("dashboard.IVMS 4200")}`}
              socketData={{
                active: context?.socketData?.active_ivms,
                inActive: context?.socketData?.inactive_ivms,
                non_operational: context?.socketData?.non_operational,
                pending: context?.socketData?.pending,
                ivsm_na: context?.socketData?.ivsm_na,
                activeColor: "#2ae1aa",
                InactiveColor: "#ff9e3f",
                ivsm_naColor: "black",
                non_operationalColor: "red",
                pendingColor: "orange",
              }}
            />
          </div>
        </div>
        <div
          className={
            isMobile ? "col-lg-3 col-6 pe-md-4" : "col-lg-3 col-6 pe-md-4"
          }
        >
          <div>
            <DashBoardDountChart
              title={`${t("dashboard.Active & InActive BillBoards")}`}
              socketData={{
                active: context?.socketData?.overall_active,
                inActive: context?.socketData?.overall_inactive,
                non_operational: context?.socketData?.non_operational,
                pending: context?.socketData?.pending,
                activeColor: "#5B4CF5",
                InactiveColor: "#F750A6",
                non_operationalColor: "red",
                pendingColor: "orange",
              }}
            />
          </div>
        </div>
      </div>
      <div className="pt-1">
        <div className="row d-none">
          <div
            className={
              i18n.language === "en"
                ? "col-lg-6 col-12 pe-md-4"
                : "col-lg-6 col-12 pe-md-4"
            }
          >
            <div>
              <DashboardMapChart
                data={datamap}
                options={optionsmap}
                title={"BilBoards Map"}
              />
            </div>
          </div>
          <div
            className={
              isMobile
                ? " col-lg-6 col-12 ps-md-4 pt-5"
                : " col-lg-6 col-12 ps-md-4 "
            }
          >
            <div>
              <DashboardMapChart
                data={datamapticket}
                options={optionsmapticket}
                title={"Tickets Map"}
              />
            </div>
          </div>
        </div>
      </div>
      <MapWithAMarker context={context} />
      <ToastPopup
        trigger={context?.isLoading}
        setTrigger={context?.setIsLoading}
      >
        <SuccessErrorToast isLoading={true} />
      </ToastPopup>
    </div>
  );
};

export default OverView;
