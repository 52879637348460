import moment from "moment";
import { ReactElement, useEffect, useState } from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";
import { isMobile, isMobileOnly } from "react-device-detect";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { FiUserCheck } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import {
  RiCloseLine,
  RiLogoutBoxLine,
  RiLogoutBoxRLine,
  RiMenu3Line,
} from "react-icons/ri";
import { NavLink, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import bell from "../assets/bell.png";
import camera from "../assets/camera.png";
import car from "../assets/car.png";
import Companyprofile from "../assets/company.png";
import help from "../assets/help.png";
import logo from "../assets/logo2.png";
import Changepass from "../assets/password.png";
import profile from "../assets/profile.png";
import chat from "../assets/telegram.png";
import deleteImg from "../assets/xo.png";
import CommonAvatar from "../components/Avatar/CommonAvatar";
import Notification from "../components/Notification";
import DeleteBlockToast from "../components/PopupToast/DeleteBlockToast";
import ImagePopUp from "../components/PopupToast/ImagePopUp";
import SuccessErrorToast from "../components/PopupToast/SuccessErrorToast";
import ToastPopup from "../components/PopupToast/ToastPopup";
import ToastPopupSecond from "../components/PopupToast/ToastPopupSecond";
import SwitchLanguage from "../components/utils/SwitchLanguage";
import { routes } from "../constants/routes";
import { useAuthentication } from "../context/AuthenticationProvider";
import { SOCKET_URL } from "../utils/api";
import { RxCross2 } from "react-icons/rx";

const NotificationSound = require("../assets/notificationSound.wav");

interface AppLayoutProps {
  children: ReactElement;
}
let timedPopupSetTimeOut: any = undefined;

const AppLayout = ({ children }: AppLayoutProps) => {
  let logOutPopUp = false;
  const { t } = useTranslation();
  const [toggleMenu, setToggleMenu] = useState(false);
  const context: any = useAuthentication();
  const [open, setOpen] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [bodyMessage, setBodyMessage] = useState("");
  const [timedPopup, setTimedPopup] = useState(false);
  const [showPopUpLogout, setShowPopUpLogout] = useState(false);
  const [imageShow, setImageShow] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [notificationShowPopUp, setNotificationShowPopUp] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (context?.socket === null) {
      context?.setSocket(io(SOCKET_URL || ""));
    }
    if (context?.socket && context?.user?.id) {
      context?.socket?.once(
        context?.user?.id + "notification",
        (_data: any) => {
          if (_data?.success) {
            let updateData = [...context?.notifications];
            updateData.push(_data?.data);
            context?.setNotification(updateData);
            context?.setUser(_data?.notification_count);
            let value = {
              ..._data?.data,
              message: JSON.parse(_data?.data?.message),
            };
            notify(value);
            setTimeout(() => {
              toast.dismiss();
            }, 5000);
          }
        }
      );
    }
    return () => {
      context?.disConnectSocket("");
      context?.disConnectSocket("onlinestatus");
      context?.disConnectSocket(context?.user?.id + "notification");
    };
  }, [context?.socket]);

  const notificationSound = new Audio(NotificationSound);

  const notify = (value: any) => {
    notificationSound.play();
    toast.custom((tst) => (
      <Card
        className="notification-card mb-3"
        onClick={() => {
          toast.dismiss(tst.id);
          if (
            value?.title === "billboard_non_operation" ||
            value?.title === "billboard_approved" ||
            value?.title === "billboard_created"
          ) {
            navigate(routes?.editBillBoardUrl(value?.message?.id));
          } else if (
            value?.title === "ticket_created" ||
            value?.title === "ticket_viewed" ||
            value?.title === "ticket_submitted" ||
            value?.title === "ticket_verified" ||
            value?.title === "ticket_reassigned" ||
            value?.title === "ticket_approved" ||
            value?.title === "ticket_rejected"
          ) {
            navigate(routes?.editTicketUrl(value?.message?.id));
          } else if (value?.title === "billboard_created") {
            navigate(routes?.editBillBoardUrl(value?.message?.billboard?.id));
          } else if (value?.title === "ticket_updated") {
            navigate(routes?.editTicketUrl(value?.message?.ticket?.id));
          } else if (value?.title === "user_accept_object") {
            navigate(routes?.personalProfileUrl(value?.message?.id));
          } else if (value?.title === "excel_upload") {
            navigate(routes?.listBillBoard);
          } else if (value?.title === "member_invite") {
            navigate(routes?.listMembers);
          }
        }}
      >
        <div className="d-flex">
          <CommonAvatar
            name={context?.userName(value, value?.message)}
            imageSrc={context?.userAvatar(value, value?.message)}
            size={(isMobileOnly ? 50 : 50).toString()}
            style={{
              backgroundColor: "#6b5ca4",
            }}
          />
          {value?.title === "billboard_operational" && (
            <div className="px-2">
              {value?.message?.board_no}{" "}
              {t(
                "notification.has been requested to mark it as the operational billboard made by"
              )}{" "}
              - {value?.message?.requested_by?.name}
            </div>
          )}
          {value?.title === "billboard_non_operation" && (
            <div className="px-2">
              {value?.message?.board_no}{" "}
              {t(
                "notification.has been requested to mark it as the non - operational billboard requested by"
              )}{" "}
              - {value?.message?.requested_by?.name}
            </div>
          )}
          {value?.title === "billboard_approved" && (
            <div className="px-2">
              {value?.message?.board_no}{" "}
              {t(
                "notification.has been Approved as the non - operational billboard Approved by"
              )}{" "}
              - {value?.message?.approved_by?.name}
            </div>
          )}
          {value?.title === "billboard_created" && (
            <div className="px-2">
              {value?.message?.created_by?.name}{" "}
              {t("notification.has been created")} - {value?.message?.board_no}
            </div>
          )}
          {value?.title === "billboard_updated" && (
            <div className="px-2">
              {value?.message?.updated_info?.name}{" "}
              {t("notification.has been updated")} -{" "}
              {value?.message?.billboard?.board_no}{" "}
              {value?.message?.meta &&
                Object.keys(value?.message?.meta).map(
                  (item: any, index: any) => {
                    if (item !== "_id") {
                      return (
                        <>
                          {Object.keys(value?.message?.meta).length - 2 ===
                          index
                            ? " and "
                            : ", "}
                          the {item?.replace("_", " ")} -{" "}
                          {item === "operational_date" ||
                          item === "installation_date"
                            ? moment(value?.message?.meta[item]).format(
                                "YYYY-MM-DD"
                              )
                            : item === "screen_size"
                            ? "screen width" +
                              value?.message?.meta?.screen_size
                                .replace("X", " ")
                                ?.split(" ")[0] +
                              " ," +
                              "screen height" +
                              value?.message?.meta?.screen_size
                                .replace("X", " ")
                                ?.split(" ")[1] +
                              " ," +
                              "screen units" +
                              value?.message?.meta?.screen_size
                                .replace("X", " ")
                                ?.split(" ")[2]
                            : value?.message?.meta[item]}
                        </>
                      );
                    }
                  }
                )}
            </div>
          )}
          {value?.title === "ticket_created" && (
            <div className="px-2">
              {value?.message?.created_by?.name}{" "}
              {t("Activityfeed.has created a new ticket")} -{" "}
              {value?.message?.ticket_no} {t("Activityfeed.for the billboard")}{" "}
              - {value?.message?.bill_board_id?.board_no}{" "}
              {t("Activityfeed.and assigned to")} -{" "}
              {value?.message?.assignee?.name}
            </div>
          )}
          {value?.title === "ticket_updated" && (
            <div className="px-2">
              {value?.message?.updated_info?.name}{" "}
              {t("Activityfeed.has updated the ticket")} -{" "}
              {value?.message?.ticket?.ticket_no}
              {value?.message?.meta &&
                Object.keys(value?.message?.meta).map(
                  (item: any, index: any) => {
                    if (item !== "_id") {
                      return (
                        <>
                          {Object.keys(value?.message?.meta).length - 2 ===
                          index
                            ? " and "
                            : ", "}
                          the {item?.replace("_", " ")} -{" "}
                          {item === "start_date" || item === "end_date"
                            ? moment(value?.message?.meta[item]).format(
                                "YYYY-MM-DD"
                              )
                            : item === "pictures" &&
                              value?.message?.meta?.pictures?.length
                            ? value?.message?.meta?.pictures?.length
                            : item === "videos" &&
                              value?.message?.meta?.videos?.length
                            ? value?.message?.meta?.videos?.length
                            : item === "audios" &&
                              value?.message?.meta?.audios?.length
                            ? value?.message?.meta?.audios?.length
                            : value?.message?.meta[item]}
                        </>
                      );
                    }
                  }
                )}
            </div>
          )}
          {value?.title === "ticket_viewed" && (
            <div className="px-2">
              {value?.message?.assignee?.name}{" "}
              {t("Activityfeed.has viewed the ticket")} -{" "}
              {value?.message?.ticket_no}
            </div>
          )}
          {value?.title === "ticket_submitted" && (
            <div className="px-2">
              {value?.message?.assignee?.name}{" "}
              {t("Activityfeed.has submitted the")} -{" "}
              {value?.message?.ticket_no}
            </div>
          )}
          {value?.title === "ticket_verified" && (
            <div className="px-2">
              {value?.message?.approved_by_supervisor?.name}{" "}
              {t("Activityfeed.has verified the ticket")}
            </div>
          )}
          {value?.title === "ticket_reassigned" && (
            <div className="px-2">
              {value?.message?.created_by?.name}{" "}
              {t("Activityfeed.has reassigned the ticket")} -{" "}
              {value?.message?.ticket_no} {t("Activityfeed.to the")} -{" "}
              {value?.message?.assignee?.name}
            </div>
          )}
          {value?.title === "ticket_approved" && (
            <div className="px-2">
              {value?.message?.approved_by_manager?.name}{" "}
              {t("Activityfeed.has approved the ticket")}
            </div>
          )}
          {value?.title === "ticket_rejected" && (
            <div className="px-2">
              {value?.message?.rejected_by?.name}{" "}
              {t("Activityfeed.has rejected the ticket")}
            </div>
          )}
          {value?.title === "user_accept_object" && (
            <div className="px-2">
              {value?.message?.name}{" "}
              {t("notification.has accepted the intivation")}
            </div>
          )}
          {value?.title === "excel_upload" && (
            <div className="px-2">
              {value?.message?.updated_by?.name}{" "}
              {t(
                "notification.has uploaded the billbard excel which contains of "
              )}{" "}
              - {value?.message?.document_count} {t("header.bill")}
            </div>
          )}
          {value?.title === "member_invite" && (
            <div className="px-2">
              {value?.message?.email}{" "}
              {t("notification.has accepted invitation")}
            </div>
          )}
        </div>
      </Card>
    ));
  };

  useEffect(() => {
    const getRolesDetails = async () => {
      await context?.getUser();
    };
    if (context?.roleAccess === null) {
      getRolesDetails();
    }
    return () => {
      context?.disConnectSocket();
    };
  }, []);

  const logOut = async () => {
    await context?.logout();
  };

  const uploadProfileImage = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file[0]);
    const { success, url } = await context?.uploadFiles(formData, "image");
    if (success) {
      const { success, error } = await context?.updateUserDetail({
        avatar: url,
      });
      context?.setIsProfileUpdated(success);
      setImageShow(false);
      setIsSuccess(success);
      if (success) {
        timeOutPopUp(true);
        setTimedPopup(true);
        setBodyMessage(`${t("successError.Updated the profile picture")}`);
      } else {
        setTimedPopup(true);
        timeOutPopUp(false);
        setBodyMessage(error);
      }
    }
    setShow(false);
  };

  const deleteImage = async () => {
    const { success, error } = await context?.updateUserDetail({
      avatar: null,
    });
    context?.setIsProfileUpdated(success);
    setImageShow(false);
    setIsSuccess(success);
    if (success) {
      timeOutPopUp(true);
      setTimedPopup(true);
      setBodyMessage(`${t("successError.deleted the profile picture")}`);
    } else {
      setTimedPopup(true);
      timeOutPopUp(false);
      setBodyMessage(error);
    }
    setShow(false);
  };

  const logOutPop = () => {
    setShowPopUp(false);
    logOutPopUp = true;
    setImageShow(false);
    setIsSuccess(true);
    timeOutPopUp(true);
    setTimedPopup(true);
    setBodyMessage(`${t("successError.Logged out")}`);
  };

  const timeOutPopUp = (value: any) => {
    timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        if (logOutPopUp) {
          logOut();
        }
        setBodyMessage("");
        setTimedPopup(false);
      } else {
        setBodyMessage("");
        setTimedPopup(false);
      }
    }, 2000);
  };

  const navigateChat = async () => {
    const { success } = await context?.setUserOnline();
    if (success) {
      navigate(routes.chat);
    }
  };

  return (
    <div className=" position-relative">
      <div>
        <Toaster position="top-right" reverseOrder={false} />
      </div>
      <div
        className={`${
          isMobile
            ? "container-fluid p-0 position-relative"
            : "container  p-0 position-relative "
        }`}
      >
        <div className="position-relative ">
          <Row className="header align-items-center  m-0 ">
            <Col>
              <img src={logo} alt="logo" width={120} className="logo" />
            </Col>
            {isMobile ? (
              <Col>
                <div className="position-relative d-flex justify-content-end">
                  {toggleMenu ? (
                    <RiCloseLine
                      color="#fafafa"
                      fontSize={24}
                      onClick={() => setToggleMenu(false)}
                    />
                  ) : (
                    <RiMenu3Line
                      color="#fafafa"
                      fontSize={24}
                      onClick={() => setToggleMenu(true)}
                    />
                  )}
                  {toggleMenu && (
                    <>
                      <div className="navbar__mobile_menu_links motion">
                        <div className="navbar__mobile_menu_links_drop overflow-scroll  position-relative">
                          <div
                            className=" d-flex justify-content-between align-items-center px-3 position-sticky top-0 "
                            style={
                              isMobileOnly
                                ? { backgroundColor: "#6b5ca4", zIndex: "1" }
                                : {}
                            }
                          >
                            <div>
                              <img
                                src={logo}
                                alt="logo"
                                width={120}
                                className="logo"
                              />
                            </div>
                            <div>
                              <RiCloseLine
                                color="#fafafa"
                                fontSize={24}
                                onClick={() => setToggleMenu(false)}
                              />
                            </div>
                          </div>
                          <div className="MobileMenu">
                            <MobileMenu
                              context={context}
                              setToggleMenu={setToggleMenu}
                              setOpen={setOpen}
                              setShowPopUp={setShowPopUp}
                              setShowPopUpLogout={setShowPopUpLogout}
                              setNotificationShowPopUp={
                                setNotificationShowPopUp
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Col>
            ) : (
              ""
            )}
            {!isMobile ? (
              <>
                <Col>
                  <Menu context={context} />
                </Col>
                <Col>
                  <Language
                    context={context}
                    setOpen={setOpen}
                    open={open}
                    setTimedPopup={setTimedPopup}
                    setImageShow={setImageShow}
                    setCurrentImage={setCurrentImage}
                    uploadProfileImage={() => setShow(true)}
                    setShowPopUp={setShowPopUp}
                    setNotificationShowPopUp={setNotificationShowPopUp}
                  />
                </Col>
              </>
            ) : (
              ""
            )}
          </Row>
          {children}

          {!window?.location?.pathname?.includes("/chat") && (
            <span className=" chatFloatingIcon ">
              <span className=" position-relative">
                {context?.totalCount > 0 && (
                  <span className=" chat-count">{context?.totalCount}</span>
                )}
              </span>
              <span>
                <img
                  src={chat}
                  alt=""
                  width={40}
                  className="mx-3 pointer"
                  onClick={() => navigateChat()}
                />
              </span>
            </span>
          )}
        </div>
      </div>

      <ToastPopupSecond trigger={showPopUp} setTrigger={setShowPopUp}>
        <DeleteBlockToast
          closePopUp={(data: any) => setShowPopUp(data)}
          submit={() => logOutPop()}
          textMessage={`${t("successError.logout")}`}
          headerMessage={`${t("successError.logout")}`}
        />
      </ToastPopupSecond>
      <ToastPopup
        trigger={timedPopup}
        setTrigger={setTimedPopup}
        setShowPopUpLogout={setShowPopUpLogout}
        showPopUpLogout={showPopUpLogout}
      >
        <SuccessErrorToast
          bodyMessage={bodyMessage}
          closePopUp={(data: any) => setShowPopUp(data)}
          textMessage={`${t("successError.logout")}`}
          isSuccess={isSuccess}
          okButton={() => setTimedPopup(false)}
          buttonName="Close"
        />
      </ToastPopup>
      <ImagePopUp
        imageShow={imageShow}
        image={currentImage}
        setImageShow={(data: any) => setImageShow(data)}
      />
      {notificationShowPopUp && (
        <Notification
          setNotificationShowPopUp={setNotificationShowPopUp}
          notificationShowPopUp={notificationShowPopUp}
          context={context}
          t={t}
          navigate={navigate}
        />
      )}
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header>
          <div className=" d-flex justify-content-between align-items-center w-100">
            <div> {t("avatar.Profile picture update")}</div>
            <span
              onClick={() => {
                setShow(false);
              }}
              className="cursor-pointer"
            >
              <RxCross2 size={24} color="#db2c59" />
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <div className=" d-flex d-block uploads align-items-center justify-content-center position-relative fileupload">
                <div>
                  <img src={camera} alt="" width={24} />
                </div>
                <input
                  type={"file"}
                  name="myfile"
                  multiple
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e: any) => uploadProfileImage(e.target.files)}
                />
                <h6 className=" mb-0 mx-3 fw-semibold ">
                  {t("avatar.Update_Image")}
                </h6>
              </div>
            </div>
            {context?.user?.avatar && context?.user?.avatar !== null && (
              <div>
                <div
                  className=" d-flex d-block uploads align-items-center justify-content-center position-relative fileupload"
                  onClick={() => deleteImage()}
                >
                  <div>
                    <img src={deleteImg} alt="" width={24} />
                  </div>
                  <h6 className=" mb-0 mx-3 fw-semibold ">
                    {t("avatar.Delete_Image")}
                  </h6>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AppLayout;

const Language = ({
  context,
  setOpen,
  open,
  setTimedPopup,
  setImageShow,
  setCurrentImage,
  uploadProfileImage,
  setShowPopUp,
  setNotificationShowPopUp,
}: any) => {
  const { i18n } = useTranslation();
  return (
    <>
      <div
        className={`${
          isMobile
            ? "mobile_view "
            : "desktop_view align-items-center justify-content-end"
        }`}
      >
        <SwitchLanguage />
        <div className={`${isMobile ? "mobile_view " : "desktop_view"}`}>
          <span
            className="px-3"
            onClick={() => {
              context?.getNotificationDetails();
              setNotificationShowPopUp(true);
            }}
          >
            <img src={bell} alt="logo" width={24} className="Cursoricon" />
            {context?.user?.notification_count > 0 && (
              <span className="notification-count">
                ({context?.user?.notification_count})
              </span>
            )}
          </span>
          <div className="d-flex position-relative">
            <div
              className="profile_tirgger memberimageshapelayout position-relative"
              // onClick={() => {
              //   setOpen(!open);
              // }}
              onClick={() => {
                setOpen(!open);
              }}
              // onMouseLeave={() => {
              //   setOpen(!open);
              // }}
            >
              {context?.user?.avatar !== null &&
              context?.user?.avatar !== undefined ? (
                <img
                  src={context?.user?.avatar}
                  alt="logo"
                  width={50}
                  className=" "
                />
              ) : (
                <>
                  <CommonAvatar
                    name={
                      context?.user?.name
                        ? context?.user?.name?.toUpperCase()
                        : context?.user?.email?.toUpperCase()
                    }
                    size={(isMobileOnly ? 50 : 50).toString()}
                    style={{
                      backgroundColor: "white",
                      color: "#6b5ca4",
                    }}
                    className="pointer"
                  />
                </>
              )}
            </div>
            {/* <div className="angle"></div> */}
            <div
              className={
                i18n.language === "en"
                  ? `dropdown_menu ${open ? "active" : "inactive"}`
                  : `dropdown_menuArabic ${open ? "active" : "inactive"}`
              }
              onMouseLeave={() => {
                setOpen(!open);
              }}
            >
              <div className="d-flex justify-content- align-items-center">
                <div className="memberimageshape mb-2 position-relative">
                  {context?.user?.avatar !== null &&
                  context?.user?.avatar !== undefined ? (
                    <img
                      src={context?.user?.avatar}
                      alt="logo"
                      width={50}
                      className=" "
                      onClick={() => {
                        setImageShow(true);
                        setCurrentImage(context?.user?.avatar);
                      }}
                    />
                  ) : (
                    <CommonAvatar
                      name={
                        context?.user?.name
                          ? context?.user?.name?.toUpperCase()
                          : context?.user?.email?.toUpperCase()
                      }
                      size={(isMobileOnly ? 50 : 50).toString()}
                      style={{
                        backgroundColor: "#6b5ca4",
                      }}
                    />
                  )}
                  <form action="" method="post" id="form-image">
                    <input id="imageUpload" />
                    <label htmlFor="imageUpload">
                      <MdEdit
                        fontSize={13}
                        color="#6b5ca4"
                        onClick={() => uploadProfileImage()}
                      />
                    </label>
                  </form>
                </div>

                <h6 className="viewprofiledropdown fw-semibold">
                  {context?.user?.name}
                  <br />
                  <span className=" fs-6 text-center fw-normal ff-questrial ">
                    {context?.user?.department?.length > 0
                      ? context?.user?.department[0].toLowerCase() + ", ...."
                      : context?.user?.department}
                  </span>
                </h6>
              </div>

              <ul>
                <Dropdownitem
                  setOpen={setOpen}
                  setShowPopUp={setShowPopUp}
                  setNotificationShowPopUp={setNotificationShowPopUp}
                  context={context}
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Dropdownitem = ({
  setOpen,
  setShowPopUp,
  context,
  setNotificationShowPopUp,
}: any) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <li className="d-flex align-items-center">
        <div className="px-2">
          <img src={profile} alt="" width={24} />
        </div>
        <NavLink
          to={routes.adminPersonalProfile}
          onClick={() => setOpen(false)}
          className={`text-decoration-none  ff-questrial  dropdownlinks ${
            window.location.pathname.includes("/personal_profile") && "active"
          }`}
        >
          {t("avatar.viewProfile")}
        </NavLink>
      </li>
      <li className="d-flex align-items-center">
        <div className="px-2">
          <img src={Companyprofile} alt="" width={24} />
        </div>

        <NavLink
          to={routes.admincompanyProfile}
          onClick={() => setOpen(false)}
          className={`text-decoration-none  ff-questrial  dropdownlinks ${
            window.location.pathname.includes("/company_profile") && "active"
          }`}
        >
          {t("avatar.companyprofile")}
        </NavLink>
      </li>
      <li className="d-flex align-items-center">
        <div className="px-2">
          <img src={car} alt="" width={24} />
        </div>
        <NavLink
          to={routes.adminvehicleInformation}
          onClick={() => setOpen(false)}
          className={`text-decoration-none  ff-questrial  dropdownlinks ${
            window.location.pathname.includes("/vehicle_information") &&
            "active"
          }`}
        >
          {t("avatar.vehicle")}
        </NavLink>
      </li>
      <li className="d-flex align-items-center">
        <div className="px-2">
          <img src={Changepass} alt="" width={24} />
        </div>
        <NavLink
          to={routes.adminChangePassword}
          onClick={() => setOpen(false)}
          className={`text-decoration-none  ff-questrial  dropdownlinks ${
            window.location.pathname.includes("/profile_change_password") &&
            "active"
          }`}
        >
          {t("avatar.changepassword")}
        </NavLink>
      </li>
      {context?.user?.role === "ADMIN" && (
        <li className="d-flex align-items-center">
          <div className="px-2">
            {/* <img src={bell1} alt="" width={24} /> */}
            <FiUserCheck color="#6b5ca4" fontSize={22} />
          </div>
          <NavLink
            to={routes.rolesandaccess}
            className={`text-decoration-none  ff-questrial fw-lighter dropdownlinks ${
              window.location.pathname.includes("/roles") && "active"
            }`}
          >
            {t("avatar.roles")}
          </NavLink>
        </li>
      )}
      {/* <li className="d-flex align-items-center">
        <div className="px-2">
          <img src={bell1} alt="" width={24} />
        </div>
        <NavLink
          to=""
          className={`text-decoration-none  ff-questrial fw-lighter dropdownlinks`}

        >
          {t("avatar.noti")}
        </NavLink>
      </li> */}
      <li className="d-flex align-items-center">
        <div className="px-2">
          <img src={help} alt="" width={24} />
        </div>
        <NavLink
          to={routes.helpCenter}
          className={`text-decoration-none  ff-questrial fw-lighter dropdownlinks`}
        >
          {t("avatar.help")}
        </NavLink>
      </li>
      <li className="d-flex align-items-center">
        <div className="px-2">
          {/* <img src={logout} alt="" width={24} /> */}
          <span className={i18n.language === "en" ? "" : "d-none"}>
            <RiLogoutBoxRLine fontSize={24} color="#6b5ca4" />
          </span>
          <span className={i18n.language === "en" ? "d-none" : ""}>
            <RiLogoutBoxLine fontSize={24} color="#6b5ca4" />
          </span>
        </div>
        <NavLink
          to={routes?.timeSheet}
          className={`text-decoration-none  ff-questrial  fw-lighter dropdownlinks`}
        >
          {t("avatar.timeSheet")}
        </NavLink>
      </li>
      <li className="d-flex align-items-center">
        <div className="px-2">
          {/* <img src={logout} alt="" width={24} /> */}
          <span className={i18n.language === "en" ? "" : "d-none"}>
            <RiLogoutBoxRLine fontSize={24} color="#6b5ca4" />
          </span>
          <span className={i18n.language === "en" ? "d-none" : ""}>
            <RiLogoutBoxLine fontSize={24} color="#6b5ca4" />
          </span>
        </div>
        <NavLink
          to=""
          className={`text-decoration-none  ff-questrial  fw-lighter dropdownlinks`}
          onClick={() => {
            setShowPopUp(true);
          }}
        >
          {t("avatar.logout")}
        </NavLink>
      </li>
    </>
  );
};

const Menu = ({ context }: any) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={`${isMobile ? "mobile_view " : "desktop_view"} mb-3`}>
        <NavLink
          to={routes.dashboard}
          className="text-decoration-none  text-white  mx-2"
          style={{ minWidth: "70px" }}
        >
          <span
            className={`doc_link_routes ${
              window.location.pathname.includes("/dashboard") && "active"
            } text-nowrap text-truncate`}
          >
            {t("header.dashboard")}
          </span>
        </NavLink>
        {context &&
          context?.roleAccess &&
          context?.roleAccess["USER"]?.includes("READ") && (
            <NavLink
              to={routes.listMembers}
              className="text-decoration-none text-white mx-2"
            >
              <span
                className={`doc_link_routes ${
                  window.location.pathname.includes("/members") && "active"
                }`}
              >
                {t("header.member")}
              </span>
            </NavLink>
          )}
        {context &&
          context?.roleAccess &&
          context?.roleAccess["BOARD"]?.includes("READ") && (
            <NavLink
              to={routes.listBillBoard}
              className="text-decoration-none  text-white  mx-2"
            >
              <span
                className={`doc_link_routes ${
                  window.location.pathname.includes("/billboards") && "active"
                }`}
              >
                {t("header.bill")}
              </span>
            </NavLink>
          )}
        {context &&
          context?.roleAccess &&
          context?.roleAccess["TICKETS"]?.includes("READ") && (
            <NavLink
              to={routes.listTickets}
              className="text-decoration-none  text-white  mx-2"
            >
              <span
                className={`doc_link_routes ${
                  !window.location.pathname.includes("/billboards") &&
                  !window.location.pathname.includes("/cleaning") &&
                  window.location.pathname.includes("/tickets") &&
                  "active"
                }`}
              >
                {t("header.ticket")}
              </span>
            </NavLink>
          )}
        {context &&
          context?.roleAccess &&
          context?.roleAccess["INVENTORY"]?.includes("READ") && (
            <NavLink
              to={routes.inventoryPurchase}
              className="text-decoration-none  text-white  mx-2"
            >
              <span
                className={`doc_link_routes ${
                  window.location.pathname.includes("/inventory/") && "active"
                }`}
              >
                {t("header.inventery")}
              </span>
            </NavLink>
          )}
        <NavLink
          to={routes.getMasterUrl({ config_type: "DEPARTMENT" })}
          className="text-decoration-none  text-white  mx-2"
        >
          <span
            className={`doc_link_routes ${
              window.location.pathname.includes("/masters") && "active"
            }`}
          >
            {t("header.Master")}
          </span>
        </NavLink>
        {/* <NavLink
          to={routes.listCircuits}
          className="text-decoration-none  text-white  mx-2"
        >
          <span
            className={`doc_link_routes ${
              window.location.pathname.includes("/circuits") && "active"
            }`}
          >
            {t("header.cricut")}
          </span>
        </NavLink> */}

        {context &&
          context?.roleAccess &&
          context?.roleAccess["CLEANING"]?.includes("READ") && (
            <NavLink
              to={routes.listCleaning}
              className="text-decoration-none  text-white  mx-2"
            >
              <span
                className={`doc_link_routes ${
                  !window.location.pathname.includes("/billboards") &&
                  window.location.pathname.includes("/cleaning") &&
                  "active"
                }`}
              >
                {t("header.Cleaning")}
              </span>
            </NavLink>
          )}
      </div>
    </>
  );
};

const MobileMenu = ({
  context,
  setToggleMenu,
  setOpen,
  setShowPopUp,
  setShowPopUpLogout,
  setNotificationShowPopUp,
}: any) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={`${
          isMobileOnly ? "container px-4 pt-3" : "container-fluid"
        }`}
      >
        <div className=" d-flex flex-column justify-content-center  ">
          <div className="">
            <h3 className=" fw-semibold ">{context?.user?.name}</h3>
            <h6 className=" ">{context?.user?.department}</h6>
          </div>
          <div
            //  className={`${isMobile ? "mobile_view " : "desktop_view"}`}
            className=" d-flex flex-column"
          >
            <NavLink
              to={routes.dashboard}
              className="text-decoration-none  text-white  pt-2"
            >
              <span
                className={`doc_link_routes ${
                  window.location.pathname.includes("/dashboard") && "active"
                }`}
              >
                {t("header.dashboard")}
              </span>
            </NavLink>
            {context &&
              context?.roleAccess &&
              context?.roleAccess["USER"]?.includes("READ") && (
                <NavLink
                  to={routes.listMembers}
                  className="text-decoration-none text-white  pt-4"
                  onClick={() => {
                    setToggleMenu(false);
                  }}
                >
                  <span
                    className={`doc_link_routes ${
                      window.location.pathname.includes("/members") && "active"
                    }`}
                  >
                    {t("header.member")}
                  </span>
                </NavLink>
              )}
            {context &&
              context?.roleAccess &&
              context?.roleAccess["BOARD"]?.includes("READ") && (
                <NavLink
                  to={routes.listBillBoard}
                  className="text-decoration-none  text-white   pt-4"
                  onClick={() => {
                    setToggleMenu(false);
                  }}
                >
                  <span
                    className={`doc_link_routes ${
                      window.location.pathname.includes("/billboards") &&
                      "active"
                    }`}
                  >
                    {t("header.bill")}
                  </span>
                </NavLink>
              )}
            {context &&
              context?.roleAccess &&
              context?.roleAccess["TICKETS"]?.includes("READ") && (
                <NavLink
                  to={routes.listTickets}
                  className="text-decoration-none  text-white  pt-4"
                  onClick={() => {
                    setToggleMenu(false);
                  }}
                >
                  <span
                    className={`doc_link_routes ${
                      !window.location.pathname.includes("/cleaning") &&
                      window.location.pathname.includes("/tickets") &&
                      "active"
                    }`}
                  >
                    {t("header.ticket")}
                  </span>
                </NavLink>
              )}
            {context &&
              context?.roleAccess &&
              context?.roleAccess["INVENTORY"]?.includes("READ") && (
                <NavLink
                  to={routes.inventoryPurchase}
                  className="text-decoration-none  text-white  pt-4"
                  onClick={() => {
                    setToggleMenu(false);
                  }}
                >
                  <span
                    className={`doc_link_routes ${
                      window.location.pathname.includes("/inventory/") &&
                      "active"
                    }`}
                  >
                    {t("header.inventery")}
                  </span>
                </NavLink>
              )}
            <NavLink
              to={routes.getMasterUrl({ config_type: "DEPARTMENT" })}
              className="text-decoration-none  text-white  pt-4"
              onClick={() => {
                setToggleMenu(false);
              }}
            >
              <span
                className={`doc_link_routes ${
                  window.location.pathname.includes("/masters") && "active"
                }`}
              >
                {t("header.Master")}
              </span>
            </NavLink>
            {context &&
              context?.roleAccess &&
              context?.roleAccess["CLEANING"]?.includes("READ") && (
                <NavLink
                  to={routes.listCleaning}
                  className="text-decoration-none  text-white  pt-4"
                  onClick={() => {
                    setToggleMenu(false);
                  }}
                >
                  <span
                    className={`doc_link_routes ${
                      window.location.pathname.includes("/cleaning") && "active"
                    }`}
                  >
                    {t("header.Cleaning")}
                  </span>
                </NavLink>
              )}

            {/* <NavLink
              to={routes.listCircuits}
              className="text-decoration-none  text-white   py-4"
              onClick={() => {
                setToggleMenu(false);
              }}
            >
              <span
                className={`doc_link_routes ${
                  window.location.pathname.includes("/circuits") && "active"
                }`}
              >
                {t("header.cricut")}
              </span>
            </NavLink> */}
          </div>

          <span className="py-4 mobileBorder mt-4">
            <SwitchLanguage />
          </span>

          <ul className="ulforMobile ">
            <li className="d-flex align-items-center pt-4">
              <NavLink
                to={routes.adminPersonalProfile}
                onClick={() => {
                  setOpen(false);
                  setToggleMenu(false);
                }}
                className={`text-decoration-none  ff-questrial  dropdownlinks ${
                  window.location.pathname.includes("/personal_profile") &&
                  "active"
                }`}
                style={{ paddingInlineStart: "0rem" }}
              >
                {t("avatar.viewProfile")}
              </NavLink>
            </li>
            <li className="d-flex align-items-center pt-4">
              <NavLink
                to={routes.admincompanyProfile}
                onClick={() => {
                  setOpen(false);
                  setToggleMenu(false);
                }}
                className={`text-decoration-none  ff-questrial  dropdownlinks ${
                  window.location.pathname.includes("/company_profile") &&
                  "active"
                }`}
                style={{ paddingInlineStart: "0rem" }}
              >
                {t("avatar.companyprofile")}
              </NavLink>
            </li>
            <li className="d-flex align-items-center pt-4">
              <NavLink
                to={routes.adminvehicleInformation}
                onClick={() => {
                  setOpen(false);
                  setToggleMenu(false);
                }}
                className={`text-decoration-none  ff-questrial  dropdownlinks ${
                  window.location.pathname.includes("/vehicle_information") &&
                  "active"
                }`}
                style={{ paddingInlineStart: "0rem" }}
              >
                {t("avatar.vehicle")}
              </NavLink>
            </li>
            <li className="d-flex align-items-center pt-4">
              <NavLink
                to={routes.adminChangePassword}
                onClick={() => {
                  setOpen(false);
                  setToggleMenu(false);
                }}
                className={`text-decoration-none  ff-questrial  dropdownlinks ${
                  window.location.pathname.includes(
                    "/profile_change_password"
                  ) && "active"
                }`}
                style={{ paddingInlineStart: "0rem" }}
              >
                {t("avatar.changepassword")}
              </NavLink>
            </li>
            {context?.user?.role === "ADMIN" && (
              <li className="d-flex align-items-center pt-4">
                <NavLink
                  to={routes.rolesandaccess}
                  className={`text-decoration-none  ff-questrial fw-lighter dropdownlinks ${
                    window.location.pathname.includes("/roles") && "active"
                  }`}
                  style={{ paddingInlineStart: "0rem" }}
                >
                  {t("avatar.roles")}
                </NavLink>
              </li>
            )}
            <li className="d-flex align-items-center pt-4">
              <NavLink
                to=""
                className={`text-decoration-none  ff-questrial fw-lighter dropdownlinks`}
                style={{ paddingInlineStart: "0rem" }}
                onClick={() => {
                  setOpen(false);
                  setToggleMenu(false);
                  context?.getNotificationDetails();
                  setNotificationShowPopUp(true);
                }}
              >
                {t("avatar.noti")}
              </NavLink>
            </li>
            <li className="d-flex align-items-center pt-4">
              <NavLink
                to={routes.helpCenter}
                className={`text-decoration-none  ff-questrial fw-lighter dropdownlinks`}
                style={{ paddingInlineStart: "0rem" }}
              >
                {t("avatar.help")}
              </NavLink>
            </li>
            <li className="d-flex align-items-center pt-4">
              <NavLink
                to={routes.timeSheet}
                className={`text-decoration-none  ff-questrial fw-lighter dropdownlinks`}
                style={{ paddingInlineStart: "0rem" }}
              >
                {t("avatar.timeSheet")}
              </NavLink>
            </li>
            <li className="d-flex align-items-center pt-4">
              {/* <div className="px-2">
                <span className={i18n.language === "en" ? "" : "d-none"}>
                  <RiLogoutBoxRLine fontSize={24} color="#6b5ca4" />
                </span>
                <span className={i18n.language === "en" ? "d-none" : ""}>
                  <RiLogoutBoxLine fontSize={24} color="#6b5ca4" />
                </span>
              </div> */}
              <NavLink
                to=""
                className={`text-decoration-none  ff-questrial  fw-lighter dropdownlinks`}
                onClick={() => {
                  setShowPopUp(true);
                  setShowPopUpLogout(true);
                }}
                style={{ paddingInlineStart: "0rem" }}
              >
                {t("avatar.logout")}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
