import { Field, FieldProps } from "formik";
import InputField from "../InputField/InputField";
import { AnySchema } from "yup";
import { ChangeEvent } from "react";

export interface FormikFieldProps {
  name: string;
  errors: Record<string, string>;
  validationSchema: AnySchema<any>;
  label: string;
  type: string;
  autoFocus?: boolean;
  placeholder?: string;
  disabled?: boolean;
  as?: string;
  isPassword?: boolean;
  setPasswordIcon?: (data: boolean) => void;
  passwordIcon?: boolean;
  maxLength?: number;
  isAuth?: boolean;
  rightIcon?: boolean;
  icon?: React.ReactNode;
  onBlur?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

function FormikField({
  validationSchema,
  errors,
  label,
  type,
  name,
  autoFocus,
  placeholder,
  disabled,
  as,
  isPassword,
  setPasswordIcon,
  passwordIcon,
  maxLength,
  rightIcon,
  icon,
  onBlur,
  isAuth = false,
  onChange
}: FormikFieldProps) {
  return (
    <Field name={name} autoComplete="none">
      {({ field }: FieldProps) => (
        <InputField
          validationSchema={validationSchema}
          label={label}
          error={errors[name]}
          type={type}
          autoFocus={autoFocus}
          field={field}
          isValid={!errors[name]}
          placeholder={placeholder}
          disabled={disabled}
          as={as}
          isPassword={isPassword}
          setPasswordIcon={setPasswordIcon}
          passwordIcon={passwordIcon}
          maxLength={maxLength || 250}
          isAuth={isAuth}
          rightIcon={rightIcon}
          icon={icon}
          onBlur={onBlur}
          onChange={onChange}
        />
      )}
    </Field>
  );
}

export default FormikField;
