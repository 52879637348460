import { FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FormikField from "../../components/FormikField/FormikField";
import CommonButton from "../../components/CommonButton/CommonButton";

interface NewMemberFormProps {
  context: any;
  onClose: Function;
  popup: Function;
  setSuccess: Function;
  setBodyMsg: Function;
  timeOutPopUp: Function;
  loadData: Function;
}

export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color?: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

export default function NewConfigCleaningModalForm({
  context,
  onClose,
  popup,
  setSuccess,
  setBodyMsg,
  timeOutPopUp,
  loadData,
}: NewMemberFormProps) {
  const { t } = useTranslation();
  const [errorMessages, setErrorMessages] = useState([]);

  const initialValues = {
    billboard_type: context?.config?.billboard_type || "",
    dry_clean: context?.config?.dry_clean || "",
    wet_clean: context?.config?.wet_clean || "",
  };

  const validationSchema = Yup.object({
    billboard_type: Yup.string().required(`Billboard Type is required`),
    dry_clean: Yup.number().required(`Dry Clean is required`),
    wet_clean: Yup.number().required(`Wet Clean Type is required`),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setErrorMessages([]);
      const { success, errors } = context?.config?.id
        ? await context.updateCleaningConfig(context?.config?.id, {
            ...values,
          })
        : await context.createCleaningConfig({
            ...values,
          });
      setSuccess(success);
      if (success) {
        formik.resetForm();
        popup(true);
        setBodyMsg(
          `${
            context?.config?.id
              ? t("successError.Edited")
              : t("successError.Added")
          } ${t(`CreateBillBoardForm.BillBoard_Type`)} ${t(
            "successError.successfully"
          )}`
        );
        timeOutPopUp(true);
        onClose(false);
        loadData();
      } else {
        popup(true);
        timeOutPopUp(false);
        setBodyMsg(errors);
        setErrorMessages(errors);
      }
    },
  });

  const { errors } = formik;

  return (
    <FormikProvider value={formik}>
      <Form
        validated={true}
        onSubmit={formik?.handleSubmit}
        className="floating"
      >
        {errorMessages.length > 0 && (
          <ul className="text-danger">
            {errorMessages.map((message: string) => (
              <li>{message}</li>
            ))}
          </ul>
        )}

        <div className=" mb-4">
          <FormikField
            name="billboard_type"
            type="text"
            validationSchema={validationSchema}
            label={t("CreateBillBoardForm.BillBoard_Type")}
            errors={errors as Record<string, string>}
          />
        </div>

        <div className=" mb-4">
          <FormikField
            name="dry_clean"
            type="number"
            validationSchema={validationSchema}
            label={t("masters.Dry Clean")}
            errors={errors as Record<string, string>}
          />
        </div>

        <div className=" mb-4">
          <FormikField
            name="wet_clean"
            type="number"
            validationSchema={validationSchema}
            label={t("masters.Wet Clean")}
            errors={errors as Record<string, string>}
          />
        </div>

        <div className=" d-flex justify-content-end mt-5">
          <CommonButton
            text={
              context?.config?.id
                ? `${t("masters.Edit")} ${t(
                    "CreateBillBoardForm.BillBoard_Type"
                  )}`
                : `${t("masters.Add")} ${t(
                    "CreateBillBoardForm.BillBoard_Type"
                  )}`
            }
            type="submit"
            variant="success"
            className="btn-i18n text-white"
            isLoading={formik.isSubmitting}
            isDisabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          />
        </div>
      </Form>
    </FormikProvider>
  );
}
