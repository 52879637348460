import { useEffect, useState } from "react";
import ChatSearch from "./ChatSearch";
import AppLayout from "../../../layouts/AppLayout";
import { useChat } from "../context";
import RightChatBox from "./RightChatBox";
import LeftChatBox from "./LeftChatBox";
import ModelNewPopUp from "./ModelNewPopUp";
import GroupExit from "./GroupExit";
import { isDesktop, isMobile, isMobileOnly } from "react-device-detect";
import ToastPopup from "../../../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../../../components/PopupToast/SuccessErrorToast";
import { ChatMessageProvider } from "../messageContext";
import { useTranslation } from "react-i18next";
import moment from "moment";
import alarab from "../../../assets/alarab.gif";
import ImagePopUp from "../../../components/PopupToast/ImagePopUp";

const ChatPage = ({ userContext }: any) => {
  const { t } = useTranslation();
  const context = useChat();
  const [searchKey, setSearchKey] = useState("");
  const [showConversation, setShowConversation] = useState(
    userContext?.groupConversationInfo?.id ? true : false
  );
  const [showGroupInfo, setShowGroupInfo] = useState(false);
  const [show, setShow] = useState(false);
  const [currentGroupId, setCurrentGroupId] = useState(
    userContext?.groupConversationInfo?.id
      ? userContext?.groupConversationInfo?.id
      : ""
  );
  const [checkBoxMemberValue, setCheckBoxMemberValue] = useState<any>([]);
  const [searchMember, setSearchMember] = useState<any>("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [timedPopup, setTimedPopup] = useState(false);
  const [isFooterButton, setIsFooterButton] = useState(false);
  const [imageShow, setImageShow] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [showContent, setShowContent] = useState(false);
  const [createGroup, setCreateGroup] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [showName, setShowName] = useState(false);

  useEffect(() => {
    if (userContext?.groupConversationInfo?.id) {
      context?.setCurrentConversationId(
        userContext?.groupConversationInfo?.conversation
      );
      context?.setChatType("GROUPS");
      userContext?.setGroupConversationInfo({});
    }
    getAllConversationDetails();
  }, [context?.chatType, searchKey, context?.limit]);

  useEffect(() => {
    if (show && context?.action === "") {
      getMemberDetails();
    } else if (context?.action === "Forward" && show) {
      getForwardList();
    }
  }, [show, searchMember, context?.memberLimit]);

  const getMemberDetails = async () => {
    await context?.getMemberDetails({
      status: "ACTIVE",
      sort: "name",
      search: searchMember,
      limit: context?.memberLimit,
    });
  };

  const getForwardList = async () => {
    let params = {
      search: searchMember,
    };
    await context?.getFowardList(params);
  };

  useEffect(() => {
    if (context?.chatType === "CHATS" && currentGroupId) {
      context?.setChatType("GROUPS");
    }
  }, [currentGroupId]);

  const getAllConversationDetails = async () => {
    let params = {
      search: searchKey,
      group: context?.chatType === "GROUPS" ? "true" : "false",
      sort: "-updateAt",
      limit: context?.limit,
    };
    await context?.getAllConversation(params);
  };

  const createNewConversation = async (data: any) => {
    const { success } = await context?.createNewConversation(data?.id);
    if (success) {
      setShowConversation(true);
      setShow(false);
    }
  };

  const createGroupConversation = async () => {
    const params = {
      group_name: groupName,
      group_member: checkBoxMemberValue,
    };
    const res = await context?.createGroupConversation(params);
    if (res) {
      context?.setCurrentConversationId(res?.data?.conversation?.id);
      setCurrentGroupId(res?.data?.conversation?.group_id);
      context?.setChatType("GROUPS");
      setShowConversation(true);
      setCreateGroup(false);
      setGroupName("");
      setShow(false);
      setShowName(false);
      setCheckBoxMemberValue([]);
    }
  };

  const getUserToForword = (event: any) => {
    let newArray = [...checkBoxMemberValue, event.target.id];
    if (checkBoxMemberValue.includes(event.target.id)) {
      newArray = newArray.filter((day) => day !== event.target.id);
    }
    if (newArray.length && createGroup) {
      setCheckBoxMemberValue(newArray);
    } else if (newArray.length && context?.action !== "Forward") {
      setCheckBoxMemberValue(newArray);
    } else if (5 >= newArray.length && context?.action === "Forward") {
      setCheckBoxMemberValue(newArray);
    } else if (context?.action === "Forward") {
      setIsFooterButton(true);
      setBodyMessage("You can only share with up to 5 chats");
      setTimedPopup(true);
    } else if (!newArray.length) {
      setCheckBoxMemberValue([]);
    }
  };

  const forwordMessage = async () => {
    let groupArray = checkBoxMemberValue?.filter((item: any) =>
      item?.includes("_group")
    );
    let params: any = {
      message_id: context?.checkBoxValue,
      user_id: checkBoxMemberValue?.filter(
        (item: any) => !item?.includes("_group")
      ),
      group_id: groupArray?.map((item: any) => item?.replace("_group", "")),
    };
    const { success } = await context?.forwordMessage(params);
    if (success) {
      setShow(false);
      setCheckBoxMemberValue([]);
      context?.setCheckBoxValue([]);
      context?.setAction("");
    }
  };

  const addMemberGroup = async () => {
    await context?.addMemberGroup(currentGroupId, {
      group_member: checkBoxMemberValue,
    });
    setShow(false);
    getGroupDetails();
  };

  const removeMemberGroup = async (member: any) => {
    await context?.removeMemberGroup(currentGroupId, member?.id);
    getGroupDetails();
  };

  useEffect(() => {
    if (currentGroupId) {
      getGroupDetails();
    }
  }, [currentGroupId]);

  const getGroupDetails = async () => {
    await context?.getGroupMembers(currentGroupId);
  };

  const LeftChatPage = () => (
    <ul
      className="messages-page__list pb-5 px-1 px-md-3 mt-3 mb-0"
      style={{ height: "56vh" }}
    >
      {context?.conversationList?.length > 0 && (
        <>
          {context?.conversationList
            ?.sort((prev: any, curr: any) =>
              moment(curr?.updatedAt).format("MMMM Do YYYY, h:mm:ss a") >
              moment(prev?.updatedAt).format("MMMM Do YYYY, h:mm:ss a")
                ? 1
                : -1
            )
            ?.map((item: any) => {
              return (
                <div
                  onClick={() => {
                    context?.setCurrentConversationId(item?.id);
                    setCurrentGroupId(item?.group_id?.id);
                    setShowConversation(true);
                    context?.setAction("");
                    context?.setCheckBoxValue([]);
                    setCheckBoxMemberValue([]);
                    context?.setShowEmoji(false);
                    setHideChat(!hideChat);
                  }}
                  className=""
                >
                  <LeftChatBox
                    item={item}
                    userContext={userContext}
                    currentGroupId={currentGroupId}
                    context={context}
                    searchKey={searchKey}
                  />
                </div>
              );
            })}
          {context?.conversationList?.length > 0 &&
            !(context?.metaData?.total_count <= context?.limit) && (
              <div
                className="d-flex justify-content-center membertitle"
                onClick={() => context?.setLimit(context?.limit + 50)}
              >
                {t("chat.View more")}
              </div>
            )}
        </>
      )}
    </ul>
  );

  const [hideChat, setHideChat] = useState(true);
  const { i18n } = useTranslation();

  return (
    <AppLayout>
      <>
        <div
          className={`${
            isMobile
              ? "list_members position-relative  "
              : "list_members p-3 px-0 "
          }`}
        >
          <div
            className={`${
              isMobile ? "container-fluid position-relative " : "container p-0"
            }`}
            style={{ height: isMobile ? "88vh" : "85vh" }}
          >
            <div
              className={isMobile ? "row" : "row  mx-3"}
              style={{ height: "100%" }}
            >
              {(hideChat || isDesktop) && (
                <>
                  <div
                    className={
                      isMobile ? "col-lg-4 col-12 " : "col-lg-4 col-12 p-0"
                    }
                  >
                    <div className=" messages-page__list-scroll p-3">
                      <div>
                        <ChatSearch
                          setSearchKey={setSearchKey}
                          setShow={setShow}
                          show={show}
                          setSearchMember={setSearchMember}
                          searchKey={searchKey}
                          context={context}
                          userContext={userContext}
                          setCreateGroup={setCreateGroup}
                          setCheckBoxMemberValue={setCheckBoxMemberValue}
                          setShowName={setShowName}
                        />
                        <div className="d-flex mt-4 mb-4">
                          <div
                            onClick={() => {
                              context?.setChatType("CHATS");
                              setShowConversation(false);
                              context?.setCurrentConversationId("");
                              setCurrentGroupId("");
                              context?.setCheckBoxValue([]);
                              setCheckBoxMemberValue([]);
                              context?.setGroupInfo({});
                            }}
                            className="text-decoration-none text-secondary "
                          >
                            <span
                              className={`edit_link_routes ${
                                context?.chatType === "CHATS" ? "active" : ""
                              }`}
                            >
                              {t("chat.People")}
                            </span>
                          </div>
                          <div
                            onClick={() => {
                              context?.setChatType("GROUPS");
                              setShowConversation(false);
                              context?.setCurrentConversationId("");
                              setCurrentGroupId("");
                              context?.setCheckBoxValue([]);
                              setCheckBoxMemberValue([]);
                              context?.setGroupInfo({});
                            }}
                            className="text-decoration-none text-secondary "
                            style={{ paddingInlineStart: "1rem" }}
                          >
                            <span
                              className={`edit_link_routes ${
                                context?.chatType === "GROUPS" ? "active" : ""
                              }`}
                            >
                              {t("chat.Ticket")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <LeftChatPage />
                    </div>
                  </div>
                </>
              )}
              {(!hideChat || isDesktop) && (
                <>
                  <div
                    className="col-lg-8 col-12  p-0"
                    style={{ height: "100%" }}
                  >
                    {!showConversation && !showGroupInfo && (
                      <div
                        className={
                          i18n.language === "en"
                            ? " chatline d-flex justify-content-center align-items-center flex-column"
                            : " chatline_Arabic d-flex justify-content-center align-items-center flex-column"
                        }
                        style={{ height: "100%" }}
                      >
                        <img
                          src={alarab}
                          alt="loader"
                          style={
                            isMobileOnly
                              ? { width: "100px" }
                              : { width: "100px" }
                          }
                        />
                        <div>
                          <h3 className="  mt-3 text-center web fw-semibold">
                            Al-arabia Web
                          </h3>
                          <h6 className="   text-center web fw-semibold">
                            Send and receive messages
                          </h6>
                        </div>
                      </div>
                    )}
                    {showConversation && context?.currentConversationId && (
                      <ChatMessageProvider>
                        <RightChatBox
                          userContext={userContext}
                          chatContext={context}
                          setShow={setShow}
                          setShowGroupInfo={setShowGroupInfo}
                          setShowConversation={setShowConversation}
                          currentGroupId={currentGroupId}
                          setCurrentImage={setCurrentImage}
                          setTimedPopup={setTimedPopup}
                          setImageShow={setImageShow}
                          setIsFooterButton={setIsFooterButton}
                          setBodyMessage={setBodyMessage}
                          setShowContent={setShowContent}
                          showContent={showContent}
                          setHideChat={setHideChat}
                          hideChat={hideChat}
                        />
                      </ChatMessageProvider>
                    )}
                    {showGroupInfo &&
                      currentGroupId &&
                      !context?.currentConversationId &&
                      !showConversation && (
                        <GroupExit
                          userContext={userContext}
                          context={context}
                          setShow={setShow}
                          show={show}
                          setSearchMember={setSearchMember}
                          removeMemberGroup={removeMemberGroup}
                          createNewConversation={createNewConversation}
                          setShowGroupInfo={setShowGroupInfo}
                          setCurrentGroupId={setCurrentGroupId}
                          setShowConversation={setShowConversation}
                        />
                      )}
                  </div>
                </>
              )}
            </div>
          </div>
          <ModelNewPopUp
            show={show}
            setShow={setShow}
            setCheckBoxMemberValue={setCheckBoxMemberValue}
            searchMember={searchMember}
            setSearchMember={setSearchMember}
            context={context}
            createNewConversation={createNewConversation}
            forwordMessage={forwordMessage}
            getUserToForword={getUserToForword}
            currentGroupId={currentGroupId}
            addMemberGroup={addMemberGroup}
            checkBoxMemberValue={checkBoxMemberValue}
            userContext={userContext}
            setCurrentGroupId={setCurrentGroupId}
            createGroup={createGroup}
            setGroupName={setGroupName}
            groupName={groupName}
            setCreateGroup={setCreateGroup}
            createGroupConversation={createGroupConversation}
            showName={showName}
            setShowName={setShowName}
          />
        </div>
        <ToastPopup trigger={timedPopup} setTrigger={setTimedPopup}>
          <SuccessErrorToast
            bodyMessage={bodyMessage}
            okButton={() => {
              setTimedPopup(false);
              setImageShow(false);
              setCurrentImage("");
              setIsFooterButton(true);
              setBodyMessage("");
              setTimedPopup(false);
            }}
            buttonName={isFooterButton ? "Okay" : "Close"}
            footButton={isFooterButton}
          />
        </ToastPopup>
        <ImagePopUp
          imageShow={imageShow}
          image={currentImage}
          setImageShow={(data: any) => setImageShow(data)}
        />
      </>
    </AppLayout>
  );
};

export default ChatPage;
