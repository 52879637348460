import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { YOUR_GOOGLE_MAPS_API_KEY } from "../../interfaces/IUser";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import { FormGroup } from "react-bootstrap";
import CommonAsyncSelect from "../../components/CommonComponents/CommonAsyncSelect";
import greenPin from "../../assets/group-green.svg";
import redPin from "../../assets/group-red.svg";
import yellowPin from "../../assets/group-yellow.svg";
const customMarkerPath =
  "M10 0C4.477 0 0 4.477 0 10s10 26.667 10 26.667S20 15.523 20 10 15.523 0 10 0z";

const MapWithAMarker = ({ context }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [googleMap, setGoogleMap] = useState<google.maps.Map>();
  const [showInfo, setShowInfo] = useState(null);
  const [currentLatLog, setCurrentLatLog] = useState<any>({});
  const [searchedLatLog, setSearchedLatLog] = useState<any>({});
  const [billBoardList, setBillBoardList] = useState<any>([]);

  const containerStyle = {
    width: "auto",
    height: "400px",
  };

  useEffect(() => {
    if (googleMap) {
      googleMap.addListener("zoom_changed", () => {
        let center: any = googleMap.getCenter();
        setCurrentLatLog({ lat: center.lat(), lng: center.lng() });
      });
    }
    getBillBoardDetails();
  }, [googleMap]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: YOUR_GOOGLE_MAPS_API_KEY,
  });

  const getBillBoardDetails = async () => {
    const { success, billboards } = await context?.loadBillBoards({
      status: "ACTIVE",
      sort: "board_no",
    });
    if (success) {
      let res = billboards.map((d: any) => {
        return {
          label: d.board_no,
          value: d?.id,
          location: d?.location,
        };
      });
      setBillBoardList(res);
    }
    return { success, billboards };
  };

  const getBillboardOptions: any = async (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    const { billboards } = await context?.loadBillBoards({
      status: "ACTIVE",
      sort: "board_no",
      search: inputValue,
    });
    callback(
      billboards.map((d: any) => ({
        label: d?.board_no,
        value: d?.id,
        location: d?.location,
      }))
    );
  };

  const getMarkerIcon = (status: string) => {
    switch (status) {
      case "ACTIVE":
        return greenPin;
      case "NON_OPERATIONAL":
        return redPin;
      case "REQUESTED":
        return yellowPin;
      default:
        return greenPin;
    }
  };

  return isLoaded ? (
    <div>
      <div className="  mb-3">
        <FormGroup className="mt-4 roleForm position-relative">
          <label>{t("new_tickets.BillBoard_Number")}</label>
          <CommonAsyncSelect
            className={"select-wrapper"}
            isMulti={false}
            values={{
              label: searchedLatLog?.label || "",
              value: searchedLatLog?.value || "",
            }}
            options={billBoardList}
            cacheOptions
            loadOptionValue={getBillboardOptions}
            updateField={(option: any) => {
              setSearchedLatLog(option);
              if (option?.value) {
                const { coordinates } = option?.location;
                const [lng, lat] = coordinates;
                if (googleMap) {
                  const index = context?.mapData?.findIndex(
                    (item: any) => item?.board_no === option?.label
                  );
                  setShowInfo(index);
                  googleMap.setCenter({ lat, lng });
                  googleMap.setZoom(20);
                }
                // redirectToGoogleMaps(lat, lng); // Open Google Maps in a new window
              }
            }}
          />
        </FormGroup>
      </div>

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{
          lat:
            searchedLatLog?.lat ||
            currentLatLog?.lat ||
            context?.mapData[0]?.location?.coordinates[1],
          lng:
            searchedLatLog?.lng ||
            currentLatLog?.lng ||
            context?.mapData[0]?.location?.coordinates[0],
        }}
        zoom={10}
        onLoad={(map) => {
          setGoogleMap(map);
        }}
      >
        {context?.mapData?.map((item: any, index: any) => {
          const markerIcon = {
            url: getMarkerIcon(item?.board_status),
            scaledSize: new google.maps.Size(30, 30),
            fillOpacity: 0.7,
            scale: 1,
            strokeWeight: 2,
            strokeColor: "white",
          };
          return (
            <Marker
              title={`Billboard Number - ${item && item?.board_no}`}
              position={{
                lat: item?.location?.coordinates[1],
                lng: item?.location?.coordinates[0],
              }}
              options={{ map: googleMap, icon: markerIcon }}
              onClick={() => {
                setShowInfo(index);
              }}
            >
              {showInfo === index && (
                <InfoWindow onCloseClick={() => setShowInfo(null)}>
                  <div className="ps-3 pe-3">
                    <div>
                      {t("map.Billboard Number")}
                      {item && item?.board_no}
                    </div>
                    <div>
                      {t("map.Total Ticket Count")}
                      {item && item?.total_count}
                    </div>
                    <div>
                      {t("map.CLOSED")}
                      {item && item?.CLOSED}
                    </div>
                    <div>
                      {t("map.CREATED")}
                      {item && item?.CREATED}
                    </div>
                    <div>
                      {t("map.REASSIGNED")}
                      {item && item?.REASSIGNED}
                    </div>
                    <div>
                      {t("map.SUBMITTED")}
                      {item && item?.SUBMITTED}
                    </div>
                    <div>
                      {t("map.VERIFIED")}
                      {item && item?.VERIFIED}
                    </div>
                    <div>
                      {t("map.VIEWED")}
                      {item && item?.VIEWED}
                    </div>
                    <div>
                      {t("map.OVERDUE")}
                      {item && item?.OVERDUE}
                    </div>
                    <div>
                      {t("map.Board Status")}
                      {item && item?.board_status.replace("_", " ")}
                    </div>
                    <div
                      className="viewmap d-flex justify-content-end"
                      onClick={() =>
                        navigate(
                          routes.getBillBoardTicketsAddUrl(item?.billboard_id)
                        )
                      }
                    >
                      Redirect to Billboard
                    </div>
                    <div
                      className="viewmap d-flex justify-content-end"
                      onClick={() =>
                        window.open(
                          `https://www.google.com/maps/place/${item?.location?.coordinates[1]},${item?.location?.coordinates[0]}`
                        )
                      }
                    >
                      View on map
                    </div>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          );
        })}
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
};

export default MapWithAMarker;
