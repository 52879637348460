 export const datamap = [
    ["Country", "Popularity"],
    ["Germany", 200],
    ["United States", 300],
    ["Brazil", 400],
    ["Canada", 500],
    ["France", 600],
    ["India", 1600],
    ["RU", 700]

    // ["Andhra Pradesh",200],
    // ["Arunachal Pradesh",500],
    // ["Goa",200],
    // ["Assam",300],
    // ["Gujarat",200],
    // ["Tamil Nadu",1200]
  ];

  export const optionsmap = {
    colorAxis: { colors: ["#FF9E3F", "#EC4654", "#5EBEE4"] },
    // backgroundColor: "#fafafa",
    datalessRegionColor: "#FEC140",
    defaultColor: "#f5f5f5",
  };


  export const datamapticket = [
    ["Country", "Popularity"],
    ["Germany", 200],
    ["United States", 300],
    ["Brazil", 400],
    ["Canada", 500],
    ["France", 600],
    ["India", 1600],
    ["RU", 700]

    // ["Andhra Pradesh",200],
    // ["Arunachal Pradesh",500],
    // ["Goa",200],
    // ["Assam",300],
    // ["Gujarat",200],
    // ["Tamil Nadu",1200]
  ];

  export const optionsmapticket = {
    colorAxis: { colors: ["#FF9E3F", "#ff6c87", "#6289F1"] },
    // backgroundColor: "#fafafa",
    datalessRegionColor: "#2AD2D4",
    defaultColor: "#f5f5f5",
  };