import moment from "moment";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { BsMic } from "react-icons/bs";
import { FiCamera, FiVideo } from "react-icons/fi";
import CommonAvatar from "../../../components/Avatar/CommonAvatar";

const LeftChatBox = ({
  item,
  context,
  userContext,
  currentGroupId,
  searchKey,
}: any) => {
  const { i18n } = useTranslation();
  return (
    <>
      {item?.participants.map((person: any) => {
        if (person?.id !== userContext?.user?.id && item?.group_id === null) {
          return (
            <li className="messaging-member messaging-member--online messaging-member--active">
              <div
                className={
                  "messaging-member__wrapper mb-3" +
                  `${
                    context?.currentConversationId === item?.id
                      ? " messaging-member-active-message"
                      : ""
                  }`
                }
              >
                <div className=" position-relative memberimageshape">
                  {person?.avatar !== null && person?.avatar !== undefined ? (
                    <>
                      <img src={person?.avatar} alt="logo" width={50} />

                      {searchKey?.length === 0 && (
                        <div
                          className={
                            i18n.language === "en"
                              ? context?.onlineStatus.find(
                                  (item: any) => item?.id === person?.id
                                )?.online
                                ? "img_circle_active "
                                : "img_circle_inactive "
                              : context?.onlineStatus.find(
                                  (item: any) => item?.id === person?.id
                                )?.online
                              ? "img_circle_activeArabic "
                              : "img_circle_inactiveArabic "
                          }
                        ></div>
                      )}
                    </>
                  ) : (
                    <>
                      <CommonAvatar
                        name={
                          person?.name
                            ? person?.name?.toUpperCase()
                            : person?.email?.toUpperCase()
                        }
                        size={(isMobileOnly ? 50 : 50).toString()}
                        style={{
                          backgroundColor: "#6b5ca4",
                        }}
                      />
                      {searchKey?.length === 0 && (
                        <div
                          className={
                            i18n.language === "en"
                              ? context?.onlineStatus.find(
                                  (item: any) => item?.id === person?.id
                                )?.online
                                ? "img_circle_active "
                                : "img_circle_inactive "
                              : context?.onlineStatus.find(
                                  (item: any) => item?.id === person?.id
                                )?.online
                              ? "img_circle_activeArabic "
                              : "img_circle_inactiveArabic "
                          }
                        ></div>
                      )}
                    </>
                  )}
                </div>

                <div style={{ marginInlineStart: ".7rem" }}>
                  <span className="messaging-member__name">
                    <span className="messaging-member__fullname">
                      {person?.name?.charAt(0).toUpperCase() +
                        person?.name?.slice(1).toLowerCase() || "-"}
                    </span>
                    <span
                      style={{ paddingInlineStart: ".7rem" }}
                      className={
                        i18n.language === "en"
                          ? "dateandtime"
                          : "dateandtimeArabic"
                      }
                    >
                      {moment(new Date()).format("dddd, MMMM D, YYYY") ===
                      moment(item?.last_message_sent_at).format(
                        "dddd, MMMM D, YYYY"
                      )
                        ? moment(item?.last_message_sent_at).format("hh:mm a")
                        : moment(new Date())
                            .subtract(1, "day")
                            .format("dddd, MMMM D, YYYY") ===
                          moment(item?.last_message_sent_at).format(
                            "dddd, MMMM D, YYYY"
                          )
                        ? "Yesterday"
                        : moment(item?.last_message_sent_at).format(
                            "DD/MM/YYYY"
                          )}
                    </span>
                  </span>
                  {searchKey?.length === 0 && (
                    <span className=" messaging-member__name messaging-member__message">
                      <span className="messaging-member__last_message">
                        {context?.typingSocketResponse?.isTyping &&
                        context?.typingSocketResponse?.userId !==
                          userContext?.user?.id &&
                        context?.typingSocketResponse?.conversation_id ===
                          item?.id + "_typing" ? (
                          context?.typingSocketResponse?.isGroup ? (
                            context?.typingSocketResponse?.userName +
                            " typing..."
                          ) : (
                            "typing..."
                          )
                        ) : item?.last_message?.attachment_type === "IMAGE" ? (
                          <span>
                            <FiCamera color="#9b9b9b" className="mb-1" /> Photo
                          </span>
                        ) : item?.last_message?.attachment_type === "VIDEO" ? (
                          <span>
                            <FiVideo color="#9b9b9b" className="mb-1" /> Video
                          </span>
                        ) : item?.last_message?.attachment_type === "AUDIO" ? (
                          <span>
                            <BsMic color="#9b9b9b" className="mb-1" /> Audio
                          </span>
                        ) : (
                          item?.last_message?.message
                        )}
                      </span>
                      {item?.unread > 0 && (
                        <span style={{ paddingInlineStart: ".7rem" }}>
                          <button
                            type="button"
                            className="btn btn-outline-primary p-0 ps-2 pe-2"
                          >
                            {item?.unread}
                          </button>
                        </span>
                      )}
                    </span>
                  )}
                </div>
              </div>
            </li>
          );
        }
      })}
      {item?.group_id !== null && (
        <li className="messaging-member messaging-member--online messaging-member--active">
          <div
            className={
              "messaging-member__wrapper mb-3" +
              `${
                context?.currentConversationId === item?.id ||
                currentGroupId === item?.group_id?.id
                  ? " messaging-member-active-message"
                  : ""
              }`
            }
          >
            <div className=" position-relative memberimageshape">
              <CommonAvatar
                name={item?.group_id?.group_name?.toUpperCase()}
                size={(isMobileOnly ? 50 : 50).toString()}
                style={{
                  backgroundColor: "#6b5ca4",
                }}
              />
            </div>
            <div style={{ marginInlineStart: ".7rem" }}>
              <span className="messaging-member__name">
                <span className="messaging-member__fullname">
                  {item?.group_id?.group_name?.charAt(0).toUpperCase() +
                    item?.group_id?.group_name?.slice(1).toLowerCase() || "-"}
                </span>
                <span style={{ paddingInlineStart: ".7rem" }}>
                  {moment(new Date()).format("dddd, MMMM D, YYYY") ===
                  moment(item?.last_message_sent_at).format(
                    "dddd, MMMM D, YYYY"
                  )
                    ? moment(item?.last_message_sent_at).format("hh:mm a")
                    : moment(new Date())
                        .subtract(1, "day")
                        .format("dddd, MMMM D, YYYY") ===
                      moment(item?.last_message_sent_at).format(
                        "dddd, MMMM D, YYYY"
                      )
                    ? "Yesterday"
                    : moment(item?.last_message_sent_at).format("DD/MM/YYYY")}
                </span>
              </span>
              {searchKey?.length === 0 && (
                <span className=" messaging-member__name messaging-member__message">
                  <span className="messaging-member__last_message">
                    {context?.typingSocketResponse?.isTyping &&
                    context?.typingSocketResponse?.userId !==
                      userContext?.user?.id &&
                    context?.typingSocketResponse?.conversation_id ===
                      item?.id + "_typing" ? (
                      context?.typingSocketResponse?.isGroup ? (
                        context?.typingSocketResponse?.userName + " typing..."
                      ) : (
                        "typing..."
                      )
                    ) : item?.last_message?.attachment_type === "IMAGE" ? (
                      <span>
                        <FiCamera color="#9b9b9b" className="mb-1" /> Photo
                      </span>
                    ) : item?.last_message?.attachment_type === "VIDEO" ? (
                      <span>
                        <FiVideo color="#9b9b9b" className="mb-1" /> Video
                      </span>
                    ) : item?.last_message?.attachment_type === "AUDIO" ? (
                      <span>
                        <BsMic color="#9b9b9b" className="mb-1" /> Audio
                      </span>
                    ) : (
                      item?.last_message?.message
                    )}
                  </span>
                  {item?.unread > 0 && (
                    <span style={{ paddingInlineStart: ".7rem" }}>
                      <button
                        type="button"
                        className="btn btn-outline-primary p-0 ps-2 pe-2"
                      >
                        {item?.unread}
                      </button>
                    </span>
                  )}
                </span>
              )}
            </div>
          </div>
        </li>
      )}
    </>
  );
};

export default LeftChatBox;
