import { Container } from "react-bootstrap";
import CommonAvatar from "../../../components/Avatar/CommonAvatar";

const NewChatPopup = ({ member, createNewConversation }: any) => {
  return (
    <>
      <Container>
        <div
          className="user-img user-click group-user-selection d-flex align-items-center mb-2"
          onClick={() => createNewConversation(member)}
        >
          <>
            <div className="user-img">
              <div className=" position-relative memberimageshape">
                {member?.group_name ||
                member?.avatar === null ||
                member?.avatar === undefined ? (
                  <CommonAvatar
                    name={
                      member?.group_name
                        ? member?.group_name?.toUpperCase()
                        : member?.name?.toUpperCase() ||
                          member?.email?.toUpperCase()
                    }
                    size={"50"}
                    style={{
                      backgroundColor: "#6b5ca4",
                    }}
                  />
                ) : (
                  <img src={member?.avatar} alt="logo" width={50} />
                )}
              </div>
            </div>
            <div
              className="chat-wrapper d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <div className="bundle " style={{ marginInlineStart: ".7rem" }}>
                <div className="chat-user-list">
                  <div className="chat-username  fw-semibold">
                    {member?.group_name
                      ? member?.group_name?.charAt(0).toUpperCase() +
                        member?.group_name?.slice(1).toLowerCase()
                      : member?.name?.charAt(0).toUpperCase() +
                          member?.name?.slice(1).toLowerCase() || "-"}
                  </div>
                  <div className="chat__time ">
                    {member?.group_name
                      ? "-"
                      : member?.role?.charAt(0).toUpperCase() +
                          member?.role?.slice(1).toLowerCase() || "-"}
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </Container>
    </>
  );
};
export default NewChatPopup;
