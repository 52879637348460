import { useEffect, useState } from "react";
import ToastPopup from "../../../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../../../components/PopupToast/SuccessErrorToast";
import Pagination from "../../../components/Pagination";
import { Button, Dropdown, Table } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import moment from "moment";
import {
  isDesktop,
  isMobile,
  isMobileOnly,
  isTablet,
} from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMasters } from "../../../context/MasterProider";
import { routes } from "../../../constants/routes";
import NewConfigModal from "../NewConfigModal";
import Modal from "../../../components/PopupToast/Modal";
import { updateText } from "../../../utils/api";
import CommonButton from "../../../components/CommonButton/CommonButton";
import CommonSearch from "../../../components/CommonSearch/CommonSearch";
import CommonSort from "../../../components/CommonSort/CommonSort";
import CommonLoadingBar from "../../../components/LoadingBar/CommonLoadingBar";

export default function Config({ userContext }: any) {
  let timedPopupSetTimeOut: any;
  const queryParams = new URLSearchParams(window.location.search);
  const configType = queryParams.get("config_type");
  const context = useMasters();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [timedPopup, setTimedPopup] = useState(false);
  const [sort, setSort] = useState(
    queryParams.get("sort") ? queryParams.get("sort") : "-createdAt"
  );
  const [search, setSearch] = useState<any>(
    queryParams.get("search") ? queryParams.get("search") : ""
  );
  const [mobileSearch, setMobileSearch] = useState(
    queryParams.get("search") ? queryParams.get("search") : ""
  );
  const [bodyMessage, setBodyMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [openfilter, setOpenfilter] = useState(false);
  const [opensort, setOpenSort] = useState(false);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [buttonPopup, setButtonPopup] = useState(false);

  useEffect(() => {
    loadData();
  }, [search, sort, currentPage, configType]);

  const queryParamsDetails = () => {
    let params = {
      config_type: configType,
      search,
      sort: sort ? sort : "-updatedAt",
      limit: 10,
      page: currentPage,
    };
    return params;
  };

  const loadData = async () => {
    navigate(routes.getMasterUrl(queryParamsDetails()));
    context?.getAllConfig(queryParamsDetails());
  };

  const resetLoadMaterial = async () => {
    let params = {
      search,
      sort,
      limit: 10,
      page: currentPage,
    };
    navigate(routes.getMasterUrl(params));
    await context?.getAllConfig(params);
  };

  const sortByOpen = () => {
    setOpenfilter(false);
    setOpenSort(!opensort);
  };

  const applyFilter = () => {
    loadData();
    filterByOpen();
  };

  const resetFilter = () => {
    loadData();
    filterByOpen();
    resetLoadMaterial();
  };

  const filterByOpen = () => {
    setOpenSort(false);
    setOpenfilter(!openfilter);
  };

  const handleEnterKey = (e: any) => {
    if (e.target.value.length === 0) {
      setSearch(e.target.value);
    }
    if (e.key === "Enter") {
      setSearch(e.target.value);
      setCurrentPage(1);
    }
  };

  const timeOutPopUp = (value: any) => {
    timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        setTimedPopup(false);
        setBodyMessage("");
      } else {
        setTimedPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  const deleteConfig = async (id: any) => {
    const { success, error } = await context?.deleteConfig(id);
    setIsSuccess(success);
    if (success) {
      loadData();
      timeOutPopUp(true);
      setTimedPopup(true);
      setBodyMessage(
        `${t("successError.Deleted")} ${t("successError.successfully")}`
      );
    } else {
      // setDeletePopUp(false);
      setTimedPopup(true);
      timeOutPopUp(false);
      setBodyMessage(error);
    }
  };
  const getCurrentConfig = async (id: string) => {
    const { success } = await context?.getConfigById(id);
    if (success) {
      setButtonPopup(true);
    }
  };

  return (
    <div className={`${isMobile ? " position-relative  " : "  rounded-1 "}`}>
      {context?.isLoading && <CommonLoadingBar />}
      <div
        className={`${
          isMobile ? "container-fluid position-relative " : "container "
        }`}
      >
        {/* --------------------------------------------nested inventory------------------------------------------------- */}

        <div
          className={`${
            isMobile
              ? "d-none "
              : "pt-4 d-flex align-items-center justify-content-between "
          }`}
        >
          <div className="members ">
            <h5 className=" mb-0 ">
              {t(
                `masters.${updateText(
                  configType && configType?.replace("_", " ")
                )}`
              )}
              ({context?.meta ? context?.meta?.total_count : 0})
            </h5>
          </div>
          <div
            className={`${
              isMobile ? "d-none " : "container-fluid p-0 forinput"
            }`}
          >
            <CommonSearch
              placeholder={
                `Search ${t(
                  `masters.${updateText(
                    configType && configType?.replace("_", " ")
                  )}`
                )}` || ""
              }
              onKeyDown={(e: any) => handleEnterKey(e)}
              onChange={(e: any) =>
                e.target.value.length === 0 && setSearch(e.target.value)
              }
            />
          </div>

          <div className="d-flex  align-items-center justify-content-end">
            <CommonSort
              sort={sort}
              opensort={opensort}
              opensortClick={sortByOpen}
              setCurrentPage={setCurrentPage}
              setSort={setSort}
              sortAtoZ="title"
              sortZtoA="-title"
            />
            {/* <div>
                <CommonButton
                  text={t("listmember.Download")}
                  variant="outline-secondary"
                  className="ms-3 bg-transparent text-secondary btn-i18n"
                  onClick={() => setShow(true)}
                />
              </div> */}
            {userContext?.roleAccess["MASTER"].includes("WRITE") && (
              <CommonButton
                onClick={() => {
                  context?.setConfig({});
                  setButtonPopup(true);
                }}
                text={` ${t("masters.New")} ${t(
                  `masters.${updateText(
                    configType && configType?.replace("_", " ")
                  )}`
                )} `}
                variant="success"
                className=" btn-i18n text-white"
              />
            )}
          </div>
        </div>
        <div className={`${isDesktop ? "d-none " : " pt-4"}`}>
          <div
            className={`${
              isDesktop
                ? "d-none "
                : " d-flex align-items-center justify-content-between flex-wrap"
            }`}
          >
            <div className="members ">
              <h5 className="mb-3">
                {t(
                  `masters.${updateText(
                    configType && configType?.replace("_", " ")
                  )}`
                )}{" "}
                ({context?.meta ? context?.meta?.total_count : 0})
              </h5>
            </div>
            <div className="d-flex">
              <div className={`${isTablet ? " d-flex" : "d-none  "}`}></div>
              {userContext?.roleAccess["MASTER"].includes("WRITE") && (
                <div className="d-flex align-items-center ">
                  <CommonButton
                    onClick={() => {
                      context?.setConfig({});
                      setButtonPopup(true);
                    }}
                    text={` ${t("masters.New")} ${t(
                      `masters.${updateText(
                        configType && configType?.replace("_", " ")
                      )}`
                    )} `}
                    variant="success"
                    className=" btn-i18n text-white"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="pt-5 position-relative">
            <CommonSearch
              placeholder={
                `Search ${t(
                  `masters.${updateText(
                    configType && configType?.replace("_", " ")
                  )}`
                )}` || ""
              }
              onKeyDown={(e: any) => handleEnterKey(e)}
              onChange={(e: any) => {
                e.target.value.length === 0 && setSearch(e.target.value);
                if (isMobile) {
                  setMobileSearch(e.target.value);
                }
              }}
            />

            <Button
              className={
                i18n.language === "en"
                  ? "mobile-search-btn"
                  : "mobile-search-btn-right"
              }
              onClick={() =>
                handleEnterKey({
                  key: "Enter",
                  target: { value: mobileSearch },
                })
              }
            >
              {t("chat.Search")}
            </Button>
          </div>
        </div>

        <div className=" pt-4 table-responsive">
          <Table responsive="sm table_class">
            <thead>
              <tr className="table_white_head gapAtMobileView">
                <th
                  className={`${isMobileOnly ? "" : " "}`}
                  style={{ paddingInlineStart: "1.5rem" }}
                >
                  {t("masters.Title")}
                </th>
                <th className={`${isMobileOnly ? " " : " py-4 "}`}>
                  {t("masters.Updated On")}
                </th>
                {(userContext?.roleAccess["MASTER"].includes("UPDATE") ||
                  userContext?.roleAccess["MASTER"].includes("READ") ||
                  userContext?.roleAccess["MASTER"].includes("DELETE")) && (
                  <th className={`${isMobileOnly ? " " : " "}`}>
                    {t("memberTable.action")}
                  </th>
                )}
              </tr>
            </thead>
            {context?.configs && context?.configs?.length > 0 && (
              <tbody className="table_white">
                {context?.configs.map((config: any, index: number) => {
                  return (
                    <tr key={config.id} className="table_white">
                      <td
                        className={`${isMobileOnly ? " " : " "}`}
                        style={{ paddingInlineStart: "1.5rem" }}
                      >
                        {config?.title?.replace("_", " ")}
                      </td>
                      <td className={`${isMobileOnly ? " " : " py-4"}`}>
                        {moment(config?.updatedAt).format("DD/MM/YYYY")}
                      </td>
                      {(userContext?.roleAccess["MASTER"].includes("UPDATE") ||
                        userContext?.roleAccess["MASTER"].includes("READ") ||
                        userContext?.roleAccess["MASTER"].includes(
                          "DELETE"
                        )) && (
                        <td className={`${isMobileOnly ? " " : " "}`}>
                          <div className="pt-2 Actions">
                            <Dropdown className="Actions_title">
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basicActions"
                              >
                                <BsThreeDots fontSize={24} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="create-dropdown-css">
                                {userContext?.roleAccess["MASTER"].includes(
                                  "UPDATE"
                                ) && (
                                  <Dropdown.Item
                                    className="sort-by"
                                    onClick={() => {
                                      getCurrentConfig(config?.id);
                                    }}
                                  >
                                    {t("editdropdown.edit")}
                                  </Dropdown.Item>
                                )}
                                {userContext?.roleAccess["MASTER"].includes(
                                  "DELETE"
                                ) && (
                                  <Dropdown.Item
                                    className="sort-by"
                                    onClick={() => {
                                      deleteConfig(config?.id);
                                    }}
                                  >
                                    {t("editdropdown.delete")}
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </Table>
          {!context?.configs?.length && (
            <div className=" d-flex justify-content-center align-items-center">
              {t("masters.No")}{" "}
              {t(
                `masters.${updateText(
                  configType && configType?.replace("_", " ")
                )}`
              )}{" "}
              {t("masters.Found")}
            </div>
          )}
        </div>
        {context?.meta && context?.configs && context?.configs?.length > 0 && (
          <Pagination
            meta={context?.meta}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            currentPageCount={context?.configs?.length}
            component={t(
              `masters.${updateText(
                configType && configType?.replace("_", " ")
              )}`
            )}
            isConfig={true}
          />
        )}
      </div>
      <ToastPopup
        trigger={context?.isLoading}
        setTrigger={context?.setIsLoading}
      >
        <SuccessErrorToast isLoading={true} />
      </ToastPopup>
      <ToastPopup trigger={timedPopup} setTrigger={setTimedPopup}>
        <SuccessErrorToast
          bodyMessage={bodyMessage}
          isSuccess={isSuccess}
          footButton={false}
        />
      </ToastPopup>

      <div className={`${isMobileOnly ? " position-relative" : "d-none"}`}>
        <div className="sortfilterMobile row m-0">
          <CommonSort
            sort={sort}
            opensort={opensort}
            opensortClick={sortByOpen}
            setCurrentPage={setCurrentPage}
            setSort={setSort}
            sortAtoZ="title"
            sortZtoA="-title"
            isSortMobile
          />
        </div>
      </div>
      <Modal trigger={buttonPopup} setTrigger={setButtonPopup}>
        <NewConfigModal
          setTrigger={(status: boolean) => {
            setButtonPopup(status);
          }}
          setTimedPopup={setTimedPopup}
          setIsSuccess={setIsSuccess}
          setBodyMessage={setBodyMessage}
          timeOutPopUp={timeOutPopUp}
          configType={configType}
          type={t(
            `masters.${updateText(configType && configType?.replace("_", " "))}`
          )}
          loadData={loadData}
        />
      </Modal>
    </div>
  );
}
