import React from "react";
import AppLayout from "../../layouts/AppLayout";
import { useTranslation } from "react-i18next";

const File404 = () => {
  const { t, i18n } = useTranslation();

  return (
    <AppLayout>
      <div className="File404">
        <div>
          <h4 className="oops text-center text-uppercase fw-semibold m-0">
            {t("404.oops")}
          </h4>

          <h1
            className={
              i18n.language === "en"
                ? "font404 d-flex justify-content-center"
                : "font404eorr d-flex justify-content-center"
            }
          >
            <span>4</span>
            <span>0</span>
            <span>4</span>
          </h1>
          <h4 className="sorry text-center text-uppercase fw-semibold m-0">
            {t("404.sorry")}
          </h4>
        </div>
      </div>
    </AppLayout>
  );
};

export default File404;
