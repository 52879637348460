import { Field } from "formik";
import { useTranslation } from "react-i18next";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { AnySchema } from "yup";
import CustomInputField from "../InputField/CustomInputField";
import "./FormikPhoneNumber.css";

export default function FormikPhoneNumber({
  name,
  label,
  errors,
  validationSchema,
  isDisabled,
}: {
  name: string;
  label: string;
  errors?: string | undefined;
  isDisabled?: boolean;
  validationSchema?: AnySchema<any>;
}) {
  const { t } = useTranslation();

  return (
    <Field name={name}>
      {({ field, form }: any) => {
        return (
          <CustomInputField
            validationSchema={validationSchema}
            label={label}
            error={errors}
            field={field}
            inputId={"label"}
          >
            <PhoneInput
              disabled={isDisabled}
              defaultCountry="SA"
              placeholder={t("FormikPhoneNumber.Enter phone number")}
              name={field.name}
              className={`form-control autocomplete`}
              countryCallingCodeEditable={true}
              value={field?.value as any}
              onChange={(value: any | undefined) => {
                if (value?.length) {
                  if (!isValidPhoneNumber(value)) {
                    form.setFieldError(name, "Invalid Phone Number");
                  } else {
                    form.setFieldValue(name, value);
                  }
                } else {
                  form.setFieldError(name, "Phone number required");
                }
              }}
            />
          </CustomInputField>
        );
      }}
    </Field>
  );
}
