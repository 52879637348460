import InventoryNestedTab from "./InventoryNestedTab";
import { routes } from "../../constants/routes";
import { useLocation } from "react-router-dom";
import Purchase from "./Purchase/Purchase";
import ReturnTechnician from "./ReturnTechnician/ReturnTechnician";
import ReturnSupplier from "./ReturnSupplier/ReturnSupplier";
import ReplaceSupplier from "./ReplaceSupplier/ReplaceSupplier";
import MaterialToTechnician from "./MaterialToTechnician/MaterialToTechnician";
import ListStockAdjustment from "./StockAdjestment/ListStockAdjestment";

const ListInventory = ({ userContext }: any) => {
  const location = useLocation();
  return (
    <InventoryNestedTab>
      {location.pathname === routes.inventoryPurchase && (
        <Purchase userContext={userContext} />
      )}
      {location.pathname === routes.inventoryMaterialToTechnician && (
        <MaterialToTechnician userContext={userContext} />
      )}
      {location.pathname === routes.inventoryMaterialRequest && (
        <MaterialToTechnician userContext={userContext} />
      )}

      {location.pathname === routes.inventoryReturnMaterialFromTechnician && (
        <ReturnTechnician userContext={userContext} />
      )}
      {location.pathname === routes.inventoryReturnMaterialToSupplier && (
        <ReturnSupplier userContext={userContext} />
      )}
      {location.pathname === routes.inventoryreplaceMaterialFromSupplier && (
        <ReplaceSupplier userContext={userContext} />
      )}
      {location.pathname?.includes(routes.stockAdjustment) && (
        <ListStockAdjustment userContext={userContext} />
      )}
    </InventoryNestedTab>
  );
};

export default ListInventory;
