import React, { ReactElement, useState } from "react";
import { IRole } from "../interfaces/IUser";
import { api } from "../utils/api";

interface MembersProviderProps {
  children: ReactElement;
}

export interface MembersContextProp {
  isLoading: boolean;
  setIsLoading: Function;
  members: [];
  meta: any;
  member: [];
  user: any;
  memberVehicle: [];
  userVehicle: [];
  loadMembers: Function;
  inviteMember: Function;
  reSendInviteMember: Function;
  getMemberDetail: Function;
  getUserDetail: Function;
  updateMemberDetail: Function;
  updateUserDetail: Function;
  updatePassword: Function;
  getMemberVehicleDetail: Function;
  getUserVehicleDetail: Function;
  updateMemberVehicleDetail: Function;
  updateUserVehicleDetail: Function;
  updateMemberPassword: Function;
  blockMemberAccount: Function;
  unBlockMemberAccount: Function;
  deleteMemberAccount: Function;
  autoCompleteApi: Function;
  downloadExcel: Function;
  downloadPdf: Function;
  queryParamsDetails: Function;
}

export interface InviteMemberProps {
  email: string;
  department: string;
  role: IRole;
}

const MembersContext = React.createContext<MembersContextProp | null>(null);

const MembersProvider = ({ children }: MembersProviderProps) => {
  const queryParams = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading]: [boolean, Function] = useState(false);
  const [members, setMembers] = useState<any>([]);
  const [member, setMember] = useState<any>({});
  const [user, setUser] = useState<any>({});
  const [userVehicle, setUserVehicle] = useState<any>({});
  const [memberVehicle, setMemberVehicle] = useState<any>({});
  const [meta, setMeta] = useState<any>({});

  const loadMembers = async (params: any) => {
    setIsLoading(true);
    const { success, user, meta } = await api.members(params);
    if (success) {
      setMembers(user);
      setMeta(meta);
    }
    setIsLoading(false);
  };

  const inviteMember = async (params: InviteMemberProps) => {
    setIsLoading(true);
    const res = await api.inviteMember(params);
    const { success, message, error } = res.data;
    setIsLoading(false);
    if (success) {
      return { success, message };
    } else {
      return {
        success,
        errors: error,
      };
    }
  };

  const reSendInviteMember = async (params: InviteMemberProps, id: any) => {
    setIsLoading(true);
    const res = await api.reSendInviteMember(params, id);
    const { success, message, error } = res.data;
    setIsLoading(false);
    if (success) {
      return { success, message };
    } else {
      return {
        success,
        errors: error,
      };
    }
  };

  const getMemberDetail = async (memberId: any) => {
    if (memberId) {
      setIsLoading(true);
      const { success, user, error } = await api.member(memberId);
      if (success) {
        setMember(user);
      }
      setIsLoading(false);
      return { success, user, error };
    }
  };

  const updateMemberDetail = async (memberId: any, params: any) => {
    if (memberId) {
      setIsLoading(true);
      const { data } = await api.updateMember(memberId, params);
      const { success, user, error } = data || {};
      if (success) {
        setMember(user);
      }
      setIsLoading(false);
      return { success, user, error };
    }
  };

  const getMemberVehicleDetail = async (memberId: any) => {
    if (memberId) {
      setIsLoading(true);
      const { success, vehicle, error } = await api.memberVehicle(memberId);
      if (success) {
        setMemberVehicle(vehicle);
      }
      setIsLoading(false);
      return { success, vehicle, error };
    }
  };

  const updateMemberVehicleDetail = async (memberId: any, params: any) => {
    if (memberId) {
      setIsLoading(true);
      const { data } = await api.updateMemberVehicle(memberId, params);
      const { success, vehicle, error } = data || {};
      if (success) {
        setMemberVehicle(vehicle);
      }
      setIsLoading(false);
      return { success, vehicle, error };
    }
  };

  const autoCompleteApi = async (params: any) => {
    setIsLoading(true);
    const { success, users, meta } = await api.autoCompleteApi(params);
    setIsLoading(false);
    return { success, users, meta };
  };

  const getUserVehicleDetail = async () => {
    setIsLoading(true);
    const { success, vehicle, error } = await api.userVehicle();
    if (success) {
      setUserVehicle(vehicle);
    }
    setIsLoading(false);
    return { success, vehicle, error };
  };

  const handleLogout = async () => {
    sessionStorage.removeItem("auth");
  };

  const updateUserVehicleDetail = async (params: any) => {
    setIsLoading(true);
    const { data } = await api.updateUserVehicle(params);
    const { success, vehicle, error } = data || {};
    if (success) {
      setUserVehicle(vehicle);
    }
    setIsLoading(false);
    return { success, vehicle, error };
  };

  const getUserDetail = async () => {
    setIsLoading(true);
    const { user, success, error } = await api.user();
    if (success) {
      setUser(user);
    } else {
      handleLogout();
    }
    setIsLoading(false);
    return { user, success, error };
  };

  const updateUserDetail = async (params: any) => {
    setIsLoading(true);
    const res = await api.updateUser(params);
    const { data } = res || {};
    const { user, success, error } = data || {};
    if (success) {
      setUser(user);
    }
    setIsLoading(false);
    return { user, success, error };
  };

  const updatePassword = async (values: any) => {
    setIsLoading(true);
    const res = await api.updatePassword(values);
    const { data } = res || {};
    const { user, success, error, message } = data || {};
    setIsLoading(false);
    return { user, success, error, message };
  };

  const updateMemberPassword = async (id: any, values: any) => {
    setIsLoading(true);
    const res = await api.updateMemberPassword(id, values);
    const { data } = res || {};
    const { user, success, error, message } = data || {};
    setIsLoading(false);
    return { user, success, error, message };
  };

  const blockMemberAccount = async (memberId: any) => {
    setIsLoading(true);
    const res = await api.blockMember(memberId);
    const { data } = res || {};
    const { success, error } = data || {};
    setIsLoading(false);
    return { success, error };
  };

  const unBlockMemberAccount = async (memberId: any) => {
    setIsLoading(true);
    const res = await api.unBlockMember(memberId);
    const { data } = res || {};
    const { success, error } = data || {};
    setIsLoading(false);
    return { success, error };
  };

  const deleteMemberAccount = async (memberId: any) => {
    setIsLoading(true);
    const { success, error } = await api.deleteMember(memberId);
    setIsLoading(false);
    return { success, error };
  };

  const downloadExcel = async (params: any) => {
    setIsLoading(true);
    const res = await api.membersDownload(params);
    setIsLoading(false);
    return res;
  };

  const downloadPdf = async (params: any) => {
    setIsLoading(true);
    const res = await api.membersPdfDownload(params);
    setIsLoading(false);
    return res;
  };

  const queryParamsDetails = () => {
    let params = {
      search: queryParams.get("search") ? queryParams.get("search") : "",
      sort: queryParams.get("sort") ? queryParams.get("sort") : "-createdAt",
      role: queryParams.get("role") ? queryParams.get("role") : "",
      status: queryParams.get("status") ? queryParams.get("status") : "",
      department: queryParams.get("department")
        ? queryParams.get("department")?.replace(" ", "_")
        : "",
      responsible_area: queryParams.get("responsible_area")
        ? queryParams.get("responsible_area")
        : "",
      limit: 10,
      page: queryParams.get("page") ? queryParams.get("page") : 1,
    };
    return params;
  };

  const value: MembersContextProp = {
    isLoading,
    setIsLoading,
    members,
    meta,
    member,
    user,
    memberVehicle,
    userVehicle,
    loadMembers,
    inviteMember,
    reSendInviteMember,
    getMemberDetail,
    getUserDetail,
    updateMemberDetail,
    updateUserDetail,
    updatePassword,
    getMemberVehicleDetail,
    getUserVehicleDetail,
    updateMemberVehicleDetail,
    updateUserVehicleDetail,
    updateMemberPassword,
    blockMemberAccount,
    unBlockMemberAccount,
    deleteMemberAccount,
    autoCompleteApi,
    downloadExcel,
    downloadPdf,
    queryParamsDetails,
  };

  return (
    <MembersContext.Provider value={value}>{children}</MembersContext.Provider>
  );
};

const useMembers = () => React.useContext(MembersContext);

export { MembersProvider, useMembers };
