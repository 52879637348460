/* eslint-disable @typescript-eslint/no-explicit-any */
interface CustomStylesProps {
  isFocused: boolean;
  isSelected: boolean;
}

export const CustomStyles = {
  option: (styles: any, { isFocused, isSelected }: CustomStylesProps) => {
    return {
      ...styles,
      backgroundColor: isSelected
        ? "#6b5ca42e"
        : isFocused
        ? "#6b5ca42e"
        : undefined,
      color: isSelected ? "#fefefe" : isFocused ? "#6b5ca4" : undefined,
    };
  },
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "var(--bgColor)",
    minHeight: "48px",
    height: "54px",
  }),
  menuList: (styles: any) => ({
    ...styles,
    backgroundColor: "var(--bgColor)",
  }),
};
