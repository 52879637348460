import React, { ReactElement, useState } from "react";
import { SOCKET_URL, api } from "../../utils/api";
import { io } from "socket.io-client";
import { useAuthentication } from "../../context/AuthenticationProvider";
const socket = io(SOCKET_URL || "");

interface ChatProviderProps {
  children: ReactElement;
}

export interface ChatContextProp {
  isLoading: any;
  getAllConversation: Function;
  conversationList: any;
  getMemberDetails: Function;
  members: any;
  createNewConversation: Function;
  forwordMessage: Function;
  getGroupMembers: Function;
  groupInfo: any;
  setGroupInfo: Function;
  addMemberGroup: Function;
  removeMemberGroup: Function;
  conversationForwardList: any;
  getAllForwardConversation: Function;
  setConversationList: Function;
  setonlineStatus: Function;
  onlineStatus: any;
  getFowardList: any;
  forwardList: any;
  disConnectSocket: Function;
  setAction: Function;
  action: any;
  checkBoxValue: any;
  setCheckBoxValue: Function;
  currentConversationId: any;
  setCurrentConversationId: Function;
  limit: any;
  setLimit: Function;
  metaData: any;
  memberMeta: any;
  memberLimit: any;
  setMemberLimit: Function;
  typingSocketResponse: any;
  setShowEmoji: Function;
  showEmoji: any;
  chatType: any;
  setChatType: Function;
  createGroupConversation: Function;
}

const ChatContext = React.createContext<ChatContextProp | null>(null);

const ChatProvider = ({ children }: ChatProviderProps) => {
  const context = useAuthentication();
  const [isLoading, setIsLoading]: [boolean, Function] = useState(false);
  const [chatType, setChatType] = useState(
    context?.groupConversationInfo?.id ? "GROUPS" : "CHATS"
  );
  const [conversationList, setConversationList] = useState<any>(null);
  const [conversationForwardList, setConversationForwardList] = useState<any>(
    []
  );
  const [onlineStatus, setonlineStatus] = useState<any>([]);
  const [members, setMembers] = useState<any>([]);
  const [forwardList, setForwardList] = useState<any>([]);
  const [groupInfo, setGroupInfo] = useState<any>({});
  const [action, setAction] = useState("");
  const [checkBoxValue, setCheckBoxValue] = useState<any>([]);
  const [currentConversationId, setCurrentConversationId] = useState(
    context?.currentConversationId ? context?.currentConversationId : ""
  );
  const [metaData, setMetaData] = useState<any>({});
  const [memberMeta, setMemberMeta] = useState<any>({});
  const [limit, setLimit] = useState<any>(50);
  const [memberLimit, setMemberLimit] = useState<any>(10);
  const [typingSocketResponse, setTypingSocketResponse] = useState<any>({});
  const [showEmoji, setShowEmoji] = useState(false);

  socket.on(context?.user?.id, (data: any) => {
    if (data?.success) {
      const index = conversationList?.findIndex((item: any) => {
        return item?.id === data?.data?.id || item?._id === data?.data?._id;
      });
      if (
        (data?.data?.group_id === null && chatType === "CHATS") ||
        (data?.data?.group_id !== null && chatType === "GROUPS")
      ) {
        if (index > -1 && index !== undefined) {
          conversationList[index] = data?.data;
        } else {
          conversationList?.push(data?.data);
        }
      }
    }
  });

  socket.on(currentConversationId + "_typing", (data: any) => {
    if (data?.success) {
      setTypingSocketResponse(data?.data);
    }
  });

  socket.on("onlinestatus", (data: any) => {
    setonlineStatus(data);
  });

  const getAllConversation = async (params: any) => {
    setIsLoading(true);
    const { success, conversation, meta } = await api?.getAllConversation(
      params
    );
    if (success && params?.group) {
      setConversationList(conversation);
      setMetaData(meta);
    }
    setIsLoading(false);
    return { success, conversation };
  };

  const getAllForwardConversation = async (params: any) => {
    setIsLoading(true);
    const { success, conversation } = await api?.getAllConversation(params);
    if (success && params?.group) {
      setConversationForwardList(conversation);
    }
    setIsLoading(false);
    return { success, conversation };
  };

  const getMemberDetails = async (params: any) => {
    setIsLoading(true);
    const { success, user, meta } = await api.members(params);
    if (success) {
      setMembers(user);
      setMemberMeta(meta);
    }
    setIsLoading(false);
    return { success, user };
  };

  const createNewConversation = async (id: any) => {
    setIsLoading(true);
    const { data } = await api?.memberConversation(id);
    const { success, converstion } = data || {};
    setCurrentConversationId(converstion?.id);
    setIsLoading(false);
    return { success, converstion };
  };

  const forwordMessage = async (params: any) => {
    setIsLoading(true);
    const { data } = await api?.forwordMessage(params);
    const { success } = data || {};
    setIsLoading(false);
    return { success };
  };

  const getGroupMembers = async (params: any) => {
    setIsLoading(true);
    const { success, group } = await api?.getGroupMembers(params);
    if (success) {
      setGroupInfo(group);
    }
    setIsLoading(false);
    return { success };
  };

  const addMemberGroup = async (id: any, params: any) => {
    setIsLoading(true);
    const data = await api?.addMemberGroup(id, params);
    if (data?.success) {
      setGroupInfo(data?.data);
      setCheckBoxValue([]);
    }
    setIsLoading(false);
    return data;
  };

  const removeMemberGroup = async (grp_id: any, member_id: any) => {
    setIsLoading(true);
    const data = await api?.removeMemberGroup(grp_id, member_id);
    setIsLoading(false);
    return data;
  };

  const getFowardList = async (params: any) => {
    setIsLoading(true);
    const { data, success } = await api?.getFowardList(params);
    if (success) {
      setForwardList(data);
    }
    setIsLoading(false);
    return { data };
  };

  const disConnectSocket = (value: any) => {
    socket.off(value);
  };

  const createGroupConversation = async (params: any) => {
    setIsLoading(true);
    const data = await api.createConversation(params);
    setIsLoading(false);
    return data?.data;
  };

  const value: ChatContextProp = {
    isLoading,
    getAllConversation,
    conversationList,
    getMemberDetails,
    members,
    createNewConversation,
    forwordMessage,
    getGroupMembers,
    groupInfo,
    setGroupInfo,
    addMemberGroup,
    removeMemberGroup,
    conversationForwardList,
    getAllForwardConversation,
    setConversationList,
    setonlineStatus,
    onlineStatus,
    getFowardList,
    forwardList,
    disConnectSocket,
    action,
    setAction,
    checkBoxValue,
    setCheckBoxValue,
    currentConversationId,
    setCurrentConversationId,
    limit,
    setLimit,
    metaData,
    memberMeta,
    memberLimit,
    setMemberLimit,
    typingSocketResponse,
    showEmoji,
    setShowEmoji,
    chatType,
    setChatType,
    createGroupConversation,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

const useChat = () => React.useContext(ChatContext);

export { ChatProvider, useChat };
