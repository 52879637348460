import { isMobile, isMobileOnly } from "react-device-detect";
import { BsPeopleFill } from "react-icons/bs";
import { GiTicket } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import { HiDocumentCheck } from "react-icons/hi2";
import { BsFillCalendarRangeFill } from "react-icons/bs";

const CleaningStats = ({ context }: any) => {
  const { t } = useTranslation();
  return (
    <div
      className={
        isMobileOnly ? " pt-3" : "d-flex justify-content-around    pt-3"
      }
    >
      <div className="">
        <div
          className={
            isMobile ? "d-flex py-3 justify-content-start" : "d-flex pb-3"
          }
        >
          <div className=" d-flex justify-content-start dashbox">
            <div className={isMobileOnly ? "mt-3 " : "mt-3"}>
              <span className=" eyebox">
                <GiTicket color="#fefefe" fontSize={24} />
              </span>
            </div>
            <div style={{ paddingInlineStart: "1rem" }}>
              <h6 className="total fw-light">{t("cleaning.Created")}</h6>
              <h3 className=" fw-bold">
                {context?.statsData?.tickets
                  ? context.statsData?.tickets?.created
                  : "-"}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div
          className={
            isMobile ? "d-flex py-3 justify-content-start" : "d-flex pb-3"
          }
        >
          <div className=" d-flex justify-content-start dashbox ">
            <div className={isMobileOnly ? "mt-3 " : "mt-3 "}>
              <span className=" eyebox">
                <BsPeopleFill color="#fefefe" fontSize={24} />
              </span>
            </div>
            <div style={{ paddingInlineStart: "1rem" }}>
              <h6 className="total fw-light">{t("cleaning.Groups")}</h6>

              <h3 className="   fw-bold">{context.statsData?.group}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div
          className={
            isMobile ? "d-flex py-3 justify-content-start" : "d-flex pb-3"
          }
        >
          <div className=" d-flex justify-content-start dashbox ">
            <div className={isMobileOnly ? "mt-3 " : "mt-3 "}>
              <span className=" eyebox">
                <HiDocumentCheck color="#fefefe" fontSize={24} />
              </span>
            </div>
            <div style={{ paddingInlineStart: "1rem" }}>
              <h6 className="total fw-light">{t("cleaning.Closed")}</h6>
              <h3 className="   fw-bold">
                {context?.statsData?.tickets
                  ? context.statsData?.tickets?.closed
                  : " - "}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div
          className={
            isMobile ? "d-flex py-3 justify-content-start" : "d-flex pb-3"
          }
        >
          <div className=" d-flex justify-content-start dashbox">
            <div className={isMobileOnly ? "mt-3 " : "mt-3"}>
              <span className=" eyebox">
                <BsFillCalendarRangeFill color="#fefefe" fontSize={24} />
              </span>
            </div>
            <div style={{ paddingInlineStart: "1rem" }}>
              <h6 className="total fw-light">{t("cleaning.Overdue")}</h6>
              <h3 className=" fw-bold">
                {context?.statsData?.tickets
                  ? context.statsData?.tickets?.overdue
                  : " - "}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CleaningStats;
