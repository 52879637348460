import { isDesktop, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import { routes } from "../../../constants/routes";

const BillBoardNested = (props: any) => {
  const urlParams = useParams();
  const { t } = useTranslation();
  return (
    <div className="billboardnested">
      <div
        style={!isMobile ? { background: "#fefefe" } : {}}
        className={`${isDesktop ? "p-5 mt-4 rounded-1 " : " p-3"}`}
      >
        <div
          className={`${isMobile ? "container-fluid p-0 " : "container p-0"}`}
        >
          <div className=" overflow-scroll ">
            <ul className="d-flex nestedul ">
              {props?.userContext?.roleAccess["BOARD"].includes("WRITE") && (
                <li
                  className="  "
                  style={{ minWidth: "90px", marginInlineEnd: "1.5rem" }}
                >
                  <NavLink
                    to={routes.getQueryBillBoardTicketsAddUrl(
                      urlParams.billboardId,
                      props?.context?.billBoardQueryParamsDetails()
                    )}
                    className="text-decoration-none"
                  >
                    <span
                      className={`edit_link_routes ${
                        window.location.pathname?.includes("/tickets/new") &&
                        "active"
                      }`}
                    >
                      {t("new_tickets.New_ticket")}
                    </span>
                  </NavLink>
                </li>
              )}
              <li
                className="  "
                style={{ minWidth: "100px", marginInlineEnd: "1.5rem" }}
              >
                <NavLink
                  to={routes.getQueryBillBoardTicketsIdUrl(
                    urlParams.billboardId,
                    urlParams.ticketId || 0,
                    props?.context?.billBoardQueryParamsDetails()
                  )}
                  className="text-decoration-none "
                >
                  <span
                    className={`edit_link_routes ${
                      window.location.pathname.includes("/edit") && "active"
                    }`}
                  >
                    {t("new_tickets.View_ticket")}
                  </span>
                </NavLink>
              </li>
              <li className=" m-0" style={{ minWidth: "100px" }}>
                <NavLink
                  to={routes.getQueryBillBoardTicketsActivityFeedUrl(
                    urlParams.billboardId,
                    urlParams.ticketId || 0,
                    props?.context?.billBoardQueryParamsDetails()
                  )}
                  className="text-decoration-none "
                >
                  <span
                    className={`edit_link_routes ${
                      window.location.pathname.includes("/activities") &&
                      "active"
                    }`}
                  >
                    {t("new_tickets.Activity_feed")}
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default BillBoardNested;
