import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { updateText } from "../../../utils/api";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { routes } from "../../../constants/routes";
import { Col, Form, Row } from "react-bootstrap";
import CommonAsyncSelect from "../../../components/CommonComponents/CommonAsyncSelect";
import StockList from "../../Inventory/Purchase/StockList";
import ToastPopup from "../../../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../../../components/PopupToast/SuccessErrorToast";
import BOMPDF from "../../../components/BOMPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CommonButton from "../../../components/CommonButton/CommonButton";
import FormikField from "../../../components/FormikField/FormikField";
import CustomInputField from "../../../components/InputField/CustomInputField";

interface NewBomFormProps {
  context?: any;
  urlParams?: any;
  pathName: string;
  userContext: any;
  billboardContext: any;
}

const NewBomForm = ({
  context,
  urlParams,
  pathName,
  userContext,
  billboardContext,
}: NewBomFormProps) => {
  const initialStockValue = {
    supplier_part_no: "",
    stock_code: "",
    stock_code_name: "",
    quantity: 0,
    part_description: "",
    isEdit: true,
    isDelete: false,
    unit: "",
    order_no: "",
    country: "",
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [bodyMessage, setBodyMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [billBoardtypeList, setBillBoardtypeList] = useState<any>([]);
  const [stocks, setStocks] = useState([...[initialStockValue]]);

  useEffect(() => {
    if (
      urlParams?.bomId ||
      billboardContext?.billBoard?.bom?._id ||
      billboardContext?.currentBomId
    ) {
      editBom();
    }
  }, [
    urlParams?.bomId,
    billboardContext?.billBoard?.bom?._id || billboardContext?.currentBomId,
  ]);

  const editBom = async () => {
    const { success, bom } =
      billboardContext?.billBoard?.bom?._id || billboardContext?.currentBomId
        ? await billboardContext?.getBomId(
            billboardContext?.billBoard?.bom?._id ||
              billboardContext?.currentBomId
          )
        : await context?.getBomId(urlParams?.bomId);
    if (success) {
      const updatedStocks = bom?.stocks?.map((item: any) => ({
        supplier_part_no: item?.supplier_part_no,
        stock_code: item?.stock_code?.id,
        stock_code_name: item?.stock_code?.stock_code,
        quantity: item?.quantity,
        part_description: item?.part_description,
        isEdit: false,
        isDelete: false,
        unit: item?.unit,
        order_no: item?.order_no,
        country: item?.country,
      }));
      setStocks([...updatedStocks]);
    }
  };

  useEffect(() => {
    getbillBoardtypeDetail();
  }, []);

  const getbillBoardtypeDetail = async () => {
    const { success, data } = await userContext?.getAllCleangingConfigDetails(
      {}
    );
    if (success) {
      let res = data?.map((d: any) => {
        return {
          label: updateText(d?.billboard_type?.replace("_", " ")),
          value: d?.billboard_type,
        };
      });
      setBillBoardtypeList(res);
    }
  };

  const getbillBoardtypeList: any = async (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    const { data } = await userContext?.getAllCleangingConfigDetails({
      search: inputValue,
    });
    callback(
      data?.map((d: any) => ({
        label: updateText(d?.billboard_type?.replace("_", " ")),
        value: d?.billboard_type,
      }))
    );
  };

  const initialValues = {
    bom_name: billboardContext?.bom?.bom_name || context?.bom?.bom_name || "",
    billboard_type:
      billboardContext?.bom?.billboard_type ||
      context?.bom?.billboard_type ||
      "",
    board_size:
      billboardContext?.bom?.board_size || context?.bom?.board_size || "",
  };
  const validationSchema = Yup.object({
    bom_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("BOM Name is required"),
    board_size: Yup.string().min(2, "Too Short!").max(1000),
    billboard_type: Yup.string().required("Billboard Type is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const { success, error } = context?.bom?.id
        ? await context.updateBom(context?.bom?.id, {
            ...values,
            stocks: stocks,
          })
        : await context.createBom({ ...values, stocks: stocks });
      setIsSuccess(success);
      setOpenPopup(true);
      if (success) {
        setBodyMessage(
          context?.bom?.id
            ? `${t("inventory.Supplier updated sucessfully")}`
            : `${t("inventory.Supplier Added sucessfully")}`
        );
        formik.resetForm();
        timeOutPopUp(true);
      } else {
        setBodyMessage(error);
        timeOutPopUp(false);
      }
    },
  });

  const { errors, handleSubmit, isSubmitting, isValid, dirty, resetForm } =
    formik;

  const timeOutPopUp = (value: any) => {
    let timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        navigate(routes.bomList);
        setOpenPopup(false);
        setBodyMessage("");
      } else {
        setOpenPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  const buttonDisabled = () =>
    context?.bom?.id
      ? dirty ||
        (stocks?.filter((item) => !item?.isEdit)?.length &&
          stocks?.filter((item) => !item?.isEdit)?.length !==
            context?.bom?.stocks?.length)
      : dirty && stocks?.filter((item) => item?.isEdit)?.length
      ? false
      : true;

  return (
    <div className="px-4 pb-5">
      {billboardContext?.bom && (
        <div className="d-flex justify-content-end">
          <PDFDownloadLink
            document={<BOMPDF data={billboardContext?.bom} />}
            fileName={`${billboardContext?.bom?.bom_name}.pdf`}
          >
            {({ blob, url, loading, error }) =>
              loading
                ? `${t("masters.Loading document")}`
                : `${t("masters.Download PDF")}`
            }
          </PDFDownloadLink>
        </div>
      )}
      <FormikProvider value={formik}>
        <Form noValidate={true} onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className=" mb-4 formprofile">
              <FormikField
                name="bom_name"
                type="text"
                validationSchema={validationSchema}
                label={t("masters.BOM Name")}
                errors={errors as Record<string, string>}
                disabled={
                  pathName?.includes("/view") ||
                  billboardContext?.billBoard?.bom?._id ||
                  billboardContext?.currentBomId
                }
              />
            </Col>
            <Col md={6} className="formprofile mb-4">
              <Field name="billboard_type">
                {({ field }: FieldProps<string>) => {
                  return (
                    <CustomInputField
                      label={t("CreateBillBoardForm.BillBoard_Type")}
                      error={errors["billboard_type"] as string}
                      validationSchema={validationSchema}
                      inputId={""}
                      field={field}
                    >
                      <CommonAsyncSelect
                        className={"select-wrapper"}
                        isMulti={false}
                        values={{
                          label: updateText(formik?.values?.billboard_type),
                          value: formik?.values?.billboard_type,
                        }}
                        options={billBoardtypeList}
                        cacheOptions
                        loadOptionValue={getbillBoardtypeList}
                        updateField={(data: any) => {
                          formik?.setFieldValue("billboard_type", data?.value);
                        }}
                        isFieldDisabled={
                          pathName?.includes("/view") ||
                          billboardContext?.billBoard?.bom?._id ||
                          billboardContext?.currentBomId
                        }
                      />
                    </CustomInputField>
                  );
                }}
              </Field>
            </Col>
            <Col className=" mb-4 formprofile">
              <FormikField
                name="board_size"
                type="text"
                validationSchema={validationSchema}
                label={t("masters.Board Size")}
                errors={errors as Record<string, string>}
                disabled={
                  pathName?.includes("/view") ||
                  billboardContext?.billBoard?.bom?._id ||
                  billboardContext?.currentBomId
                }
              />
            </Col>
          </Row>

          <StockList
            type="BOM"
            context={context}
            setStocks={setStocks}
            stocks={stocks}
            initialStockValue={initialStockValue}
            userContext={userContext}
            billboardContext={billboardContext}
          />
          {pathName?.includes("/view") ||
          billboardContext?.billBoard?.bom?._id ||
          billboardContext?.currentBomId ? (
            <></>
          ) : (
            <div className="d-flex justify-content-end py-5">
              <CommonButton
                text={t("inventory.Cancel")}
                isLoading={isSubmitting}
                variant="outline-danger"
                className="bg-transparent text-danger"
                isDisabled={isSubmitting}
                onClick={() => {
                  setStocks([]);
                  resetForm();
                }}
              />

              <CommonButton
                text={
                  urlParams?.bomId && pathName?.includes("/edit")
                    ? `${t("masters.Edit")} ${t("masters.BOM")}`
                    : pathName?.includes("/view")
                    ? `${t("masters.View")} ${t("masters.BOM")}`
                    : `${t("masters.Add")} ${t("masters.BOM")}`
                }
                isLoading={isSubmitting}
                type="submit"
                variant="success"
                className="btn-i18n text-white"
                isDisabled={isSubmitting || !isValid || !buttonDisabled()}
              />
            </div>
          )}
        </Form>
      </FormikProvider>
      <ToastPopup
        trigger={context?.isLoading}
        setTrigger={context?.setIsLoading}
      >
        <SuccessErrorToast isLoading={true} />
      </ToastPopup>
      <ToastPopup trigger={openPopup} setTrigger={setOpenPopup}>
        <SuccessErrorToast
          bodyMessage={bodyMessage}
          isSuccess={isSuccess}
          footButton={false}
        />
      </ToastPopup>
    </div>
  );
};

export default NewBomForm;
