import download from "../../../src/assets/download.png";
import { useTranslation } from "react-i18next";
import x from "../../assets/x.png";

const ToastQrImageAndDowload = ({ setTrigger, url }: any) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        onClick={() => setTrigger(false)}
        className="ps-3  d-flex justify-content-end"
      >
        <img src={x} alt="" width={12} className="pointer" />
      </div>
      <div className="Qrcode d-flex justify-content-center  flex-column align-items-center">
        <div>
          <img src={url} alt="" width={150} height={150} />
        </div>
        <div className="d-flex align-items-center">
          <div>
            <img src={download} alt="" width={24} />
          </div>

          <h6 className=" mb-0 mx-2 fw-semibold ">
            <a href={url} download className=" text-decoration-none text-black">
              {t("CreateNewBillBoard.qr")}
            </a>
          </h6>
        </div>
      </div>
    </>
  );
};

export default ToastQrImageAndDowload;
