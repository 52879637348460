import { isMobileOnly } from "react-device-detect";
import DatePicker from "react-multi-date-picker";

const DashboardDatePicker = ({
  overViewData,
  setDateValues,
  dateValues,
}: any) => {
  return (
    <div
      className={
        isMobileOnly
          ? "position-relative dateRangeformobile"
          : "position-relative"
      }
    >
      <DatePicker
        editable={false}
        value={dateValues ? dateValues : new Date()}
        onChange={(data: any) => setDateValues(data)}
        range={overViewData === "quater"}
        weekPicker={overViewData === "week"}
        onlyMonthPicker={overViewData === "month" || overViewData === "quater"}
        onlyYearPicker={overViewData === "year"}
        multiple={overViewData === "week"}
        // format="DD-MM-YYYY"
        placeholder="DD-MM-YYYY"
        minDate="2023/01/01"
        maxDate={new Date()}
        // sort
      />
    </div>
  );
};

export default DashboardDatePicker;
