import CreateNewTicketForm from "../../Tickets/CreateNewTicketForm";
import ProgressBar from "../ProgressBar/ProgressBar";

const ViewTickets = ({ context, userContext }: any) => {
  return (
    <>
      {context?.ticket && context?.ticket?.ticket_no && (
        <div className=" d-flex justify-content-between pt-3">
          <h6 className=" m-0 fw-semibold ticket_no">
            {context?.ticket.ticket_no}
          </h6>
          <h6
            className={
              context?.ticket?.approved_by_manager
                ? "m-0 fw-semibold status"
                : "m-0 fw-semibold inActivestatus"
            }
          >
            {context?.ticket.status}
          </h6>
        </div>
      )}
      {context?.ticket?.status &&
        !(
          window?.location?.pathname?.includes("/billboards/") &&
          window?.location?.pathname?.includes(`/tickets/`) &&
          window?.location?.pathname?.includes(`/edit`) &&
          context?.ticket?.status === "CLOSED"
        ) && (
          <div className="pb-2 pt-5">
            <ProgressBar context={context} />
          </div>
        )}
      {<CreateNewTicketForm userContext={userContext} context={context} />}
    </>
  );
};

export default ViewTickets;
