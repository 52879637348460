import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { isMobile, isMobileOnly } from "react-device-detect";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const stackedBaroptionsVertical = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
      display: !isMobileOnly,
      labels: {
        display: !isMobileOnly,
      },
    },
    Tooltip: {
      callbacks: {
        title: function () {
          return "my tittle";
        },
      },
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

export const stackedBaroptionsHorizontal = {
  // indexAxis: "y" as const,
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
      display: !isMobileOnly,
      labels: {
        display: !isMobileOnly,
      },
    },
    Tooltip: {
      callbacks: {
        title: function () {
          return "my tittle";
        },
      },
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

// ------------------------------------------------------------------------------stackedBaroptions-------------------------------------------

export const Bardata = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      // axis: "y",
      type: "bar" as const,
      label: "Cleaning",
      backgroundColor: "#FF9E3F",
      borderColor: "#FF9E3F",
      // borderWidth: 2,
      data: [20, 30, 10, 50, 70, 50, 40],
    },
    {
      // axis: "y",
      type: "bar" as const,
      label: "Electrial",
      backgroundColor: "#6289F1",
      borderColor: "#6289F1",
      // borderWidth: 2,
      data: [20, 30, 10, 50, 70, 50, 40],
    },
    {
      // axis: "y",
      type: "bar" as const,
      label: "Monitoring",
      backgroundColor: "#5EBEE4",
      borderColor: "#5EBEE4",
      // borderColor: 'rgb(255, 99, 132)',
      data: [20, 30, 10, 50, 70, 50, 40],
    },
    {
      // axis: "y",
      label: "Installation",
      type: "bar" as const,
      backgroundColor: "#ff6c87",
      borderColor: "#ff6c87",
      data: [10, 20, 30, 40, 50, 60, 70],
    },
    {
      // axis: "y",
      label: "Spare parts",
      type: "bar" as const,
      backgroundColor: "#6289F1",
      borderColor: "#6289F1",
      data: [10, 20, 30, 40, 50, 60, 70],
    },
  ],
};

export const Baroptionshorizontal = {
  // indexAxis: "y" as const,
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    //   title: {
    //     display: true,
    //       text: "Chart.js Bar Chart",
    //   },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

export const Bardatahorizontal = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      axis: "y",
      label: "Status",
      //   barPercentage: 0.5,
      //   barThickness: 6,
      //   maxBarThickness: 8,
      //   minBarLength: 2,
      backgroundColor: "#2AD2D4",
      data: [10, 20, 30, 40, 50, 60, 70],
    },
    {
      axis: "y",
      label: "Priority",
      //   barPercentage: 0.5,
      //   barThickness: 6,
      //   maxBarThickness: 8,
      //   minBarLength: 2,
      backgroundColor: "#6289F1",
      data: [20, 30, 10, 50, 70, 50, 40],
    },
  ],
};

export const Baroptionsingle = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    //   title: {
    //     display: true,
    //       text: "Chart.js Bar Chart",
    //   },
  },
};

export const Bardatasingle = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],

  datasets: [
    {
      axis: "y",
      label: "Status",
      data: [65, 59, 80, 81, 56, 55, 40],
      fill: false,
      backgroundColor: ["#2AD2D4"],
      borderColor: ["#2AD2D4"],
      borderWidth: 1,
    },
    // {
    //   axis: "y",
    //   label: "My Second Dataset",
    //   data: [65, 59, 80, 81, 56, 55, 40],
    //   fill: false,
    //   backgroundColor: ["#6b5ca4"],
    //   borderColor: ["#6b5ca4"],
    //   borderWidth: 1,
    // type:'line',
    // hoverBackgroundColor: "rgba(255,99,132,0.4)",
    // hoverBorderColor: "rgba(255,99,132,1)",
    // },
  ],
};

export const Baroptionsinglehori = {
  indexAxis: "y" as const,
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    //   title: {
    //     display: true,
    //       text: "Chart.js Bar Chart",
    //   },
  },
};

export const Bardatasinglehori = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],

  datasets: [
    {
      axis: "y",
      label: "Priority",
      data: [65, 59, 80, 81, 56, 55, 40],
      fill: false,
      backgroundColor: [
        "#6289F1",

        // "rgba(255, 99, 132)",
        // "rgba(255, 159, 64)",
        // "rgba(255, 205, 86)",
        // "rgba(75, 192, 192)",
        // "rgba(54, 162, 235)",
        // "rgba(153, 102, 255)",
        // "rgba(201, 203, 207)",
      ],
      borderColor: [
        "#6289F1",

        // "rgb(255, 99, 132)",
        // "rgb(255, 159, 64)",
        // "rgb(255, 205, 86)",
        // "rgb(75, 192, 192)",
        // "rgb(54, 162, 235)",
        // "rgb(153, 102, 255)",
        // "rgb(201, 203, 207)",
      ],
      borderWidth: 1,
      // type: 'line',
    },
  ],
};
