import sorting from "../../assets/sorting.png";
import { useTranslation } from "react-i18next";

export interface SortProps {
    className?: string;
    sort?: any;
    opensort?: boolean;
    isSortMobile?: boolean;
    // opensortClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    opensortClick?: any;
    setCurrentPage?: any;
    setSort?: any;
    sortAtoZ?: string;
    sortZtoA?: string;

    // sortDisabled?: boolean;
}

const CommonSort = ({
    className = "",
    sort,
    opensort,
    opensortClick,
    isSortMobile,
    setCurrentPage,
    setSort,
    sortAtoZ,
    sortZtoA,
    // sortDisabled,
}:
    SortProps) => {
    const { t, i18n } = useTranslation();

    const updateSortFilter = (value: any) => {
        setSort(value);
        setCurrentPage(1);
        opensortClick();
    };

    return (
        <div className={` ${isSortMobile ? "col" : "sort-z"} ${className}`}>
            <div className={isSortMobile ? "row" : "position-relative"}>
                <div
                    className={
                        isSortMobile
                            ? `${` col p-0 d-flex justify-content-evenly align-items-center border-2 ${i18n.language === "en" ? " border-end " : " border-start"
                            }`}`
                            : `${`d-flex sortbox ${sort && sort.length > 0 && sort !== "-createdAt"
                                ? "foractivesort"
                                : ""
                            }`}`
                    }
                    onClick={opensortClick}
                >
                    <h6 className="m-0 by fw-normal">
                        {sort === sortZtoA
                            ? `${t("listmember.ZtoA")}`
                            : sort === sortAtoZ
                                ? `${t("listmember.AtoZ")}`
                                : sort === "-createdAt"
                                    ? `${t("listmember.Recent")}`
                                    : sort === "createdAt"
                                        ? `${t("listmember.Datedecen")}`
                                        : sort === "-updatedAt"
                                            ? `${t("listmember.Recentupdate")}`
                                            : `${t("listmember.Recent")}`}
                    </h6>
                    <div className="mx-2">
                        <img src={sorting} alt="" width={16} />
                    </div>
                </div>
                <div
                    className={` ${isSortMobile ? "dropdownsortMobile" : "dropdownsort"
                        }  ${opensort ? "active" : "inactive"}`}
                    id="sortDropDown"
                >
                    <div>
                        <div className=" pt-2 d-flex justify-content-between align-items-center sortingline">
                            <h6 className=" fw-bold m-0 "> {t("billboard.sortBy")} </h6>
                        </div>
                        <div className="pt-3 sortingh6">
                            <h6 onClick={() => updateSortFilter("-createdAt")}>
                                {t("billboard.Recent")}
                            </h6>
                            <h6 onClick={() => updateSortFilter("-updatedAt")}>
                                {t("billboard.Recentupdate")}
                            </h6>

                            <h6 onClick={() => updateSortFilter(sortAtoZ)}>
                                {t("billboard.AtoZ")}
                            </h6>
                            <h6 onClick={() => updateSortFilter(sortZtoA)}>
                                {t("billboard.ZtoA")}
                            </h6>
                            <h6 onClick={() => updateSortFilter("createdAt")}>
                                {t("billboard.Datedecen")}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommonSort;
