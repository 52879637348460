import { isMobile, isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { BiArrowBack } from "react-icons/bi";
import CommonAvatar from "../../../components/Avatar/CommonAvatar";

const ChatUsers = ({
  context,
  userContext,
  chatContext,
  setHideChat,
  hideChat,
}: any) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="chat-member__wrapper" data-online="true">
      <div
        onClick={() => {
          chatContext?.setCurrentConversationId("");
          chatContext?.setGroupInfo({});
          context?.setConversationId("");
          setHideChat(!hideChat);
        }}
        className={
          isMobile
            ? i18n.language === "en"
              ? " pointer"
              : "arrowForword pointer"
            : " d-none"
        }
      >
        <BiArrowBack fontSize={24} color="#6b5ca4" />
      </div>
      <div
        className=" position-relative memberimageshape"
        style={{ marginInlineStart: "7px" }}
      >
        {context?.metaData?.participants?.group_id ? (
          <CommonAvatar
            name={context?.metaData?.participants?.group_id?.group_name?.toUpperCase()}
            size={(isMobileOnly ? 50 : 50).toString()}
            style={{
              backgroundColor: "#6b5ca4",
            }}
          />
        ) : context?.metaData?.participants?.participants.find(
            (item: any) => item?.id !== userContext?.user?.id
          )?.avatar === null ||
          context?.metaData?.participants?.participants.find(
            (item: any) => item?.id !== userContext?.user?.id
          )?.avatar === undefined ? (
          <>
            <CommonAvatar
              name={
                context?.metaData?.participants?.participants
                  .find((item: any) => item?.id !== userContext?.user?.id)
                  ?.name?.toUpperCase() ||
                context?.metaData?.participants?.participants
                  .find((item: any) => item?.id !== userContext?.user?.id)
                  ?.email?.toUpperCase()
              }
              size={(isMobileOnly ? 50 : 50).toString()}
              style={{
                backgroundColor: "#6b5ca4",
              }}
            />
            <div
              // className={
              //   chatContext?.onlineStatus.find(
              //     (item: any) =>
              //       item?.id ===
              //       context?.metaData?.participants?.participants.find(
              //         (item: any) => item?.id !== userContext?.user?.id
              //       )?.id
              //   )?.online
              //     ? "img_circle_active "
              //     : "img_circle_inactive "
              // }
              className={
                i18n.language === "en"
                  ? chatContext?.onlineStatus.find(
                      (item: any) =>
                        item?.id ===
                        context?.metaData?.participants?.participants.find(
                          (item: any) => item?.id !== userContext?.user?.id
                        )?.id
                    )?.online
                    ? "img_circle_active "
                    : "img_circle_inactive "
                  : chatContext?.onlineStatus.find(
                      (item: any) =>
                        item?.id ===
                        context?.metaData?.participants?.participants.find(
                          (item: any) => item?.id !== userContext?.user?.id
                        )?.id
                    )?.online
                  ? "img_circle_activeArabic "
                  : "img_circle_inactiveArabic "
              }
            ></div>
          </>
        ) : (
          <>
            <img
              src={
                context?.metaData?.participants?.participants.find(
                  (item: any) => item?.id !== userContext?.user?.id
                )?.avatar
              }
              width={50}
            />
            <div
              className={
                chatContext?.onlineStatus.find(
                  (item: any) =>
                    item?.id ===
                    context?.metaData?.participants?.participants.find(
                      (item: any) => item?.id !== userContext?.user?.id
                    )?.id
                )?.online
                  ? "img_circle_active "
                  : "img_circle_inactive "
              }
            ></div>
          </>
        )}
      </div>

      <div
        className="chat-member__details"
        style={{ marginInlineStart: "10px" }}
      >
        <span className="chat-member__name">
          {context?.metaData?.participants?.group_id
            ? context?.metaData?.participants?.group_id?.group_name
                .charAt(0)
                .toUpperCase() +
              context?.metaData?.participants?.group_id?.group_name
                .slice(1)
                .toLowerCase()
            : context?.metaData?.participants?.participants
                .find((item: any) => item?.id !== userContext?.user?.id)
                ?.name?.charAt(0)
                .toUpperCase() +
                context?.metaData?.participants?.participants
                  .find((item: any) => item?.id !== userContext?.user?.id)
                  ?.name?.slice(1)
                  .toLowerCase() || "-"}
        </span>
        <div className="chat-member__status">
          <span className="user-status--large"></span>
          {context?.metaData?.participants?.group_id === null && (
            <span>
              {chatContext?.typingSocketResponse?.isTyping &&
              chatContext?.typingSocketResponse?.userId !==
                userContext?.user?.id &&
              chatContext?.typingSocketResponse?.conversation_id ===
                context?.conversationId + "_typing"
                ? chatContext?.typingSocketResponse?.isGroup
                  ? chatContext?.typingSocketResponse?.userName + " typing..."
                  : `${t("chat.Typing...")}`
                : chatContext?.onlineStatus.find(
                    (item: any) =>
                      item?.id ===
                      context?.metaData?.participants?.participants.find(
                        (item: any) => item?.id !== userContext?.user?.id
                      )?.id
                  )?.online
                ? `${t("chat.Online")}`
                : `${t("chat.Offline")}`}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatUsers;
