import { useEffect, useState } from "react";
import AppLayout from "../../../layouts/AppLayout";
import { isMobile, isMobileOnly } from "react-device-detect";
import { MdArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import { routes } from "../../../constants/routes";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import { Button, Form, FormGroup, Spinner } from "react-bootstrap";
import {
  InventeryContextProp,
  useInventery,
} from "../../../context/InventeryProvider";
import moment from "moment";
import DatePicker from "react-multi-date-picker";
import ToastPopup from "../../../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../../../components/PopupToast/SuccessErrorToast";
import CommonAsyncSelect from "../../../components/CommonComponents/CommonAsyncSelect";
import StockList from "./StockList";
import CommonButton from "../../../components/CommonButton/CommonButton";

const CreateNewPurchase = ({ userContext }: any) => {
  const context = useInventery();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const urlParams = useParams();
  const pathName = window?.location?.pathname;

  return (
    <>
      <AppLayout>
        <div
          className="edit_members p-3 "
          style={
            isMobile
              ? { background: "#FAFAFA", height: "auto" }
              : { background: "#FAFAFA", minHeight: "80vh" }
          }
        >
          <div className="members py-4 mt-2 ">
            <h5
              className={`${
                isMobile ? "fs-6  mb-0 membertitle" : "  mb-0 membertitle"
              }`}
              onClick={() => navigate(routes.inventoryPurchase)}
            >
              {t("inventory.All Purchases")}
            </h5>
            <>
              <span className={i18n.language === "en" ? "px-2 " : "d-none"}>
                <MdOutlineArrowForwardIos />
              </span>
              <span className={i18n.language === "en" ? "d-none" : "px-2"}>
                <MdArrowBackIos />
              </span>

              <h5
                className={`${
                  isMobile ? "fs-6 text-capitalize m-0  " : " m-0 "
                }`}
              >
                {urlParams?.purchaseId && pathName?.includes("/edit")
                  ? `${t("inventory.Edit Purchase")}`
                  : pathName?.includes("/view")
                  ? `${t("inventory.View Purchase")}`
                  : `${t("inventory.New Purchase")}`}
              </h5>
            </>
          </div>

          {context && (
            <NewPurchaseForm
              context={context}
              urlParams={urlParams}
              pathName={pathName}
            />
          )}
        </div>
      </AppLayout>
    </>
  );
};

interface NewPurchaseFormProps {
  context: InventeryContextProp;
  urlParams: any;
  pathName: string;
}

interface StockCodeAutocompleteProps {
  readonly label: string;
  readonly value: string;
}
interface SupplierAutocompleteProps {
  readonly label: string;
  readonly value: string;
}

const NewPurchaseForm = ({
  context,
  urlParams,
  pathName,
}: NewPurchaseFormProps) => {
  const initialStockValue = {
    supplier_part_no: "",
    stock_code: "",
    stock_code_name: "",
    quantity: 0,
    warranty_period: moment(new Date()).format("YYYY-MM-DD"),
    part_description: "",
    isEdit: true,
    isDelete: false,
  };

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [bodyMessage, setBodyMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [supplierList, setSupplierList] = useState<any>([]);
  const [stocks, setStocks] = useState([...[initialStockValue]]);

  useEffect(() => {
    editPurchase();
  }, [urlParams?.purchaseId]);

  const editPurchase = async () => {
    const { success, purchase } = await context?.getPurchaseDetail(
      urlParams?.purchaseId
    );
    if (success) {
      const updatedStocks = purchase?.stocks?.map((item: any) => ({
        supplier_part_no: item?.supplier_part_no,
        stock_code: item?.stock_code?.id,
        stock_code_name: item?.stock_code?.stock_code,
        quantity: item?.quantity,
        warranty_period: moment(item?.warranty_period).format("YYYY-MM-DD"),
        part_description: item?.part_description,
        isEdit: false,
        isDelete: false,
      }));
      setStocks([...updatedStocks]);
    }
  };

  useEffect(() => {
    getSupplierDetails();
  }, []);

  const getSupplierDetails = async () => {
    const { success, suppliers } = await context?.loadInventorySupplier({
      status: "ACTIVE",
    });
    if (success) {
      let res = suppliers.map((d: any) => {
        return {
          label: d?.name,
          value: d?.id,
          name: d?.supplier_id,
          contact_number: d?.contact_number,
        };
      });
      setSupplierList(res);
    }
  };

  const getSupplierOptions: any = async (
    inputValue: string,
    callback: (options: SupplierAutocompleteProps) => void
  ) => {
    const { suppliers } = await context?.loadInventorySupplier({
      status: "ACTIVE",
      search: inputValue,
    });
    callback(
      suppliers.map((d: any) => ({
        label: d?.name,
        value: d?.id,
        name: d?.supplier_id,
        contact_number: d?.contact_number,
      }))
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      supplier_name: context?.purchase?.supplier_info?.name || "",
      project_name: context?.purchase?.project_name || "",
      supplier_info: context?.purchase?.supplier_info?.id || "",
      date_recevied: context?.purchase?.date_recevied || "",
      invoice_no: context?.purchase?.invoice_no || "",
      invoice_date: context?.purchase?.invoice_date || "",
      po_no: context?.purchase?.po_no || "",
      po_date: context?.purchase?.po_date || "",
      consignment_batch_no: context?.purchase?.consignment_batch_no || "",
      remarks: context?.purchase?.remarks || "",
      supplier_id: context?.purchase?.supplier_info?.supplier_id || "",
    },
    validationSchema: Yup.object({
      project_name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Project name is required"),
      supplier_info: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
      date_recevied: Yup.string().required("Date recevied is required"),
      invoice_no: Yup.string().required("Invoice no is required"),
      invoice_date: Yup.string().required("Invoice date is required"),
      po_no: Yup.string().required("Purchase number is required"),
      po_date: Yup.string().required("Purchase date is required"),
      consignment_batch_no: Yup.string().required(
        "Consignment batch no is required"
      ),
      supplier_id: Yup.string(),
      supplier_name: Yup.string(),
      remarks: Yup.string(),
    }),
    onSubmit: async (values) => {
      const params = { ...values, stocks: stocks };
      const { success, errors } = context?.purchase?.id
        ? await context.updatePurchaseDetail(context?.purchase?.id, params)
        : await context.addInventoryPurchases(params);

      setIsSuccess(success);
      setOpenPopup(true);
      if (success) {
        setBodyMessage(
          context?.purchase?.id
            ? `${t("inventory.Material info updated Successfully")}`
            : `${t("inventory.Material info added Successfully")}`
        );
        formik.resetForm();
        timeOutPopUp(true);
      } else {
        setBodyMessage(errors);
        timeOutPopUp(false);
      }
    },
  });
  const timeOutPopUp = (value: any) => {
    let timedPopupSetTimeOut = setInterval(() => {
      clearTimeout(timedPopupSetTimeOut);
      if (value) {
        navigate(routes.inventoryPurchase);
        setOpenPopup(false);
        setBodyMessage("");
      } else {
        setOpenPopup(false);
        setBodyMessage("");
      }
    }, 2000);
  };

  const { errors, handleSubmit, isSubmitting, isValid, dirty, resetForm } =
    formik;

  const buttonDisabled = () =>
    context?.purchase?.id
      ? dirty ||
        (stocks?.filter((item) => !item?.isEdit)?.length &&
          stocks?.filter((item) => !item?.isEdit)?.length !==
            context?.purchase?.stocks?.length)
      : dirty && stocks?.filter((item) => item?.isEdit)?.length
      ? false
      : true;

  return (
    <>
      <div className="px-4 pb-5">
        <FormikProvider value={formik}>
          <Form noValidate={true} onSubmit={handleSubmit}>
            <div className="p-3 my-4" style={{ background: "#fefefe" }}>
              <div className="row ">
                <div className="col-md-6 col-12  pe-md-4 formprofile">
                  <FormGroup className="mt-4 roleForm position-relative">
                    <label>
                      {t("inventory.Supplier Name")}

                      <span className="">*</span>
                    </label>
                    <CommonAsyncSelect
                      className={"select-wrapper"}
                      isMulti={false}
                      values={{
                        label: formik?.values?.supplier_name,
                        value: formik?.values?.supplier_info,
                      }}
                      options={supplierList}
                      cacheOptions
                      loadOptionValue={getSupplierOptions}
                      updateField={(option: any) => {
                        formik?.setFieldValue("supplier_info", option.value);
                        formik?.setFieldValue("supplier_name", option.label);
                        formik?.setFieldValue("supplier_id", option.name);
                      }}
                      isFieldDisabled={pathName?.includes("/view")}
                    />
                  </FormGroup>
                </div>

                <div
                  className={
                    i18n.language === "en"
                      ? "col-md-6 col-12 ps-md-5 formprofile"
                      : "col-md-6 col-12  formprofile pe-md-5"
                  }
                >
                  <FormGroup className="mt-4 roleForm position-relative">
                    <label>
                      {t("inventory.Supplier ID")}
                      <span className="">*</span>
                    </label>
                    <Field
                      name="supplier_id"
                      type="text"
                      maxLength={20}
                      className={`form-control ${
                        errors.supplier_id ? "form-control-invalid" : ""
                      }`}
                      disabled
                    />
                    <Form.Control.Feedback
                      style={{
                        display: !!errors.supplier_id ? "block" : "none",
                      }}
                      type={!!errors.supplier_id ? "invalid" : "valid"}
                    >
                      <ErrorMessage name="supplier_id" />
                    </Form.Control.Feedback>
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className="p-3 my-4" style={{ background: "#fefefe" }}>
              <div className="row ">
                <div className="col-md-6 col-12  pe-md-4 formprofile">
                  <FormGroup className="mt-4 roleForm position-relative">
                    <label>
                      {t("inventory.Invoice No")}
                      <span className="">*</span>
                    </label>
                    <Field
                      name="invoice_no"
                      type="text"
                      maxLength={20}
                      className={`form-control ${
                        errors.invoice_no ? "form-control-invalid" : ""
                      }`}
                      disabled={pathName?.includes("/view")}
                    />
                    <Form.Control.Feedback
                      style={{
                        display: !!errors.invoice_no ? "block" : "none",
                      }}
                      type={!!errors.invoice_no ? "invalid" : "valid"}
                    >
                      <ErrorMessage name="invoice_no" />
                    </Form.Control.Feedback>
                  </FormGroup>
                </div>

                <div
                  className={
                    i18n.language === "en"
                      ? "col-md-6 col-12 ps-md-5 formprofile"
                      : "col-md-6 col-12  formprofile pe-md-5"
                  }
                >
                  <FormGroup className="mt-4 roleForm position-relative">
                    <label>
                      {t("inventory.Invoice date")}
                      <span className="">*</span>
                    </label>

                    <DatePicker
                      editable={false}
                      placeholder="DD-MM-YYYY"
                      name={"invoice_date"}
                      format="DD-MM-YYYY"
                      value={new Date(formik?.values?.invoice_date)}
                      className={`form-control ${
                        errors.invoice_date ? "form-control-invalid" : ""
                      }`}
                      onChange={(e: any) => {
                        formik.setFieldValue(
                          "invoice_date",
                          moment.unix(e?.unix).format("yyyy-MM-DD")
                        );
                      }}
                      disabled={pathName?.includes("/view")}
                    />
                    <Form.Control.Feedback
                      style={{
                        display: !!errors.invoice_date ? "block" : "none",
                      }}
                      type={!!errors.invoice_date ? "invalid" : "valid"}
                    >
                      <ErrorMessage name="invoice_date" />
                    </Form.Control.Feedback>
                  </FormGroup>
                </div>
              </div>

              <div className="row ">
                <div className="col-md-6 col-12  pe-md-4 formprofile">
                  <FormGroup className="mt-4 roleForm position-relative">
                    <label>
                      {t("inventory.Purchase Number")}
                      <span className="">*</span>
                    </label>
                    <Field
                      name="po_no"
                      type="text"
                      maxLength={20}
                      className={`form-control ${
                        errors.po_no ? "form-control-invalid" : ""
                      }`}
                      disabled={pathName?.includes("/view")}
                    />
                    <Form.Control.Feedback
                      style={{
                        display: !!errors.po_no ? "block" : "none",
                      }}
                      type={!!errors.po_no ? "invalid" : "valid"}
                    >
                      <ErrorMessage name="po_no" />
                    </Form.Control.Feedback>
                  </FormGroup>
                </div>

                <div
                  className={
                    i18n.language === "en"
                      ? "col-md-6 col-12 ps-md-5 formprofile"
                      : "col-md-6 col-12  formprofile pe-md-5"
                  }
                >
                  <FormGroup className="mt-4 roleForm position-relative">
                    <label>
                      {t("inventory.Purchase date")}
                      <span className="">*</span>
                    </label>

                    <DatePicker
                      editable={false}
                      placeholder="DD-MM-YYYY"
                      name={"po_date"}
                      format="DD-MM-YYYY"
                      value={new Date(formik?.values?.po_date)}
                      className={`form-control ${
                        errors.po_date ? "form-control-invalid" : ""
                      }`}
                      onChange={(e: any) => {
                        formik.setFieldValue(
                          "po_date",
                          moment.unix(e?.unix).format("yyyy-MM-DD")
                        );
                      }}
                      disabled={pathName?.includes("/view")}
                    />
                    <Form.Control.Feedback
                      style={{
                        display: !!errors.po_date ? "block" : "none",
                      }}
                      type={!!errors.po_date ? "invalid" : "valid"}
                    >
                      <ErrorMessage name="po_date" />
                    </Form.Control.Feedback>
                  </FormGroup>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-6 col-12  pe-md-4 formprofile">
                  <FormGroup className="mt-4 roleForm position-relative">
                    <label>
                      {t("inventory.Consignment batch no")}
                      <span className="">*</span>
                    </label>
                    <Field
                      name="consignment_batch_no"
                      type="text"
                      maxLength={20}
                      className={`form-control ${
                        errors.consignment_batch_no
                          ? "form-control-invalid"
                          : ""
                      }`}
                      disabled={pathName?.includes("/view")}
                    />
                    <Form.Control.Feedback
                      style={{
                        display: !!errors.consignment_batch_no
                          ? "block"
                          : "none",
                      }}
                      type={!!errors.consignment_batch_no ? "invalid" : "valid"}
                    >
                      <ErrorMessage name="consignment_batch_no" />
                    </Form.Control.Feedback>
                  </FormGroup>
                </div>

                <div
                  className={
                    i18n.language === "en"
                      ? "col-md-6 col-12 ps-md-5 formprofile"
                      : "col-md-6 col-12  formprofile pe-md-5"
                  }
                >
                  <FormGroup className="mt-4 roleForm position-relative">
                    <label>
                      {t("inventory.Date recevied")}
                      <span className="">*</span>
                    </label>

                    <DatePicker
                      editable={false}
                      placeholder="DD-MM-YYYY"
                      name={"date_recevied"}
                      format="DD-MM-YYYY"
                      value={new Date(formik?.values?.date_recevied)}
                      className={`form-control ${
                        errors.date_recevied ? "form-control-invalid" : ""
                      }`}
                      onChange={(e: any) => {
                        formik.setFieldValue(
                          "date_recevied",
                          moment.unix(e?.unix).format("yyyy-MM-DD")
                        );
                      }}
                      disabled={pathName?.includes("/view")}
                    />
                    <Form.Control.Feedback
                      style={{
                        display: !!errors.date_recevied ? "block" : "none",
                      }}
                      type={!!errors.date_recevied ? "invalid" : "valid"}
                    >
                      <ErrorMessage name="date_recevied" />
                    </Form.Control.Feedback>
                  </FormGroup>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-6 col-12  pe-md-4 formprofile">
                  <FormGroup className="mt-4 roleForm position-relative">
                    <label>
                      {t("inventory.Project Name")}
                      <span className="">*</span>
                    </label>
                    <Field
                      name="project_name"
                      type="text"
                      maxLength={20}
                      className={`form-control ${
                        errors.project_name ? "form-control-invalid" : ""
                      }`}
                      disabled={pathName?.includes("/view")}
                    />
                    <Form.Control.Feedback
                      style={{
                        display: !!errors.project_name ? "block" : "none",
                      }}
                      type={!!errors.project_name ? "invalid" : "valid"}
                    >
                      <ErrorMessage name="project_name" />
                    </Form.Control.Feedback>
                  </FormGroup>
                </div>

                <div
                  className={
                    i18n.language === "en"
                      ? "col-md-6 col-12 ps-md-5 formprofile"
                      : "col-md-6 col-12  formprofile pe-md-5"
                  }
                >
                  <FormGroup className="mt-4 roleForm position-relative">
                    <label>{t("inventory.Remarks")}</label>
                    <Field
                      name="remarks"
                      type="text"
                      maxLength={20}
                      className={`form-control ${
                        errors.remarks ? "form-control-invalid" : ""
                      }`}
                      disabled={pathName?.includes("/view")}
                    />
                    <Form.Control.Feedback
                      style={{
                        display: !!errors.remarks ? "block" : "none",
                      }}
                      type={!!errors.remarks ? "invalid" : "valid"}
                    >
                      <ErrorMessage name="remarks" />
                    </Form.Control.Feedback>
                  </FormGroup>
                </div>
              </div>
            </div>
            <StockList
              type="purchases"
              context={context}
              setStocks={setStocks}
              stocks={stocks}
              initialStockValue={initialStockValue}
              supplier_info={formik?.values?.supplier_info}
              isSupplierInfo={true}
            />
            <div className="pb-5">
              {!pathName?.includes("/view") && (
                <div className="d-flex justify-content-end py-5">
                  <CommonButton
                    text={t("inventory.Cancel")}
                    isLoading={isSubmitting}
                    variant="outline-danger"
                    className="bg-transparent text-danger"
                    isDisabled={isSubmitting}
                    onClick={() => {
                      resetForm();
                      setStocks([...[initialStockValue]]);
                    }}
                  />
                  <CommonButton
                    text={
                      context?.purchase?.id
                        ? `${t("inventory.Update Purchase")}`
                        : `${t("inventory.Add Purchase")}`
                    }
                    isLoading={isSubmitting}
                    type="submit"
                    variant="success"
                    className="btn-i18n text-white"
                    isDisabled={isSubmitting || !isValid || !buttonDisabled()}
                  />
                </div>
              )}
            </div>
          </Form>
        </FormikProvider>
        <ToastPopup trigger={openPopup} setTrigger={setOpenPopup}>
          <SuccessErrorToast
            bodyMessage={bodyMessage}
            isSuccess={isSuccess}
            footButton={false}
          />
        </ToastPopup>
      </div>
    </>
  );
};

export default CreateNewPurchase;
