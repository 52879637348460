import { routes } from "../../constants/routes";
import { useLocation } from "react-router-dom";
import TimeSheetNav from "./timeSheetNav";
import TimeSheet from "./timeSheet";

const ListTimeSheet = ({ userContext }: any) => {
  const location = useLocation();
  return (
    <>
      <TimeSheetNav userContext={userContext}>
        {location.pathname === routes.timeSheet && (
          <TimeSheet userContext={userContext} />
        )}
        {location.pathname === routes.timeSheetUpload && (
          <TimeSheet userContext={userContext} />
        )}
      </TimeSheetNav>
    </>
  );
};

export default ListTimeSheet;
