import { Dropdown } from "react-bootstrap";
import AudioUpload from "../../../components/Audiofile/AudioUpload";
import { BsEmojiSmile, BsMic } from "react-icons/bs";
import Modal from "../../../components/PopupToast/Modal";
import Picker from "emoji-picker-react";
import { MdAttachFile, MdOutlineClose } from "react-icons/md";
import { RiReplyFill, RiShareForwardFill } from "react-icons/ri";
import { FiCamera, FiVideo } from "react-icons/fi";
import { FaCopy } from "react-icons/fa";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const MessageBox = ({ context, userContext, setShow, chatContext }: any) => {
  const inputRef = useRef<any>();
  const { t } = useTranslation();

  useEffect(() => {
    if (chatContext?.action === "Reply") {
      inputRef?.current.focus();
    }
  }, [chatContext?.action]);

  const getMessageDetailById = async (value: any) => {
    const data = await context?.getMessageDetailById(
      chatContext?.checkBoxValue[0]
    );
    if (data?.sucess) {
      setShow(false);
      chatContext?.setCheckBoxValue([]);
      chatContext?.setAction("");
      if (value === "Copy") {
        navigator.clipboard.writeText(data?.message?.message);
      } else {
        chatContext?.setAction("Reply");
        context?.setReplyObject(data?.message);
      }
    }
  };

  return (
    <>
      <div
        className={
          (chatContext?.showEmoji ? "emoji-text-input " : "") +
          "custom-form__send-wrapper position-relative"
        }
      >
        {/* -------------------------------emojiBox----------------------------------- */}
        <div className="emojiPosition">
          {chatContext?.showEmoji && (
            <Picker
              onEmojiClick={(e: any) => {
                context?.emojiHandler(e);
              }}
              autoFocusSearch={false}
              searchDisabled
              lazyLoadEmojis={true}
            />
          )}
        </div>
        {/* -------------------------------emojiBox----------------------------------- */}

        {chatContext?.action === "Forward" && (
          <>
            <div className="d-flex justify-content-between align-items-center p-2 forslected">
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="ps-1"
                  onClick={() => {
                    chatContext?.setCheckBoxValue([]);
                    chatContext?.setAction("");
                  }}
                >
                  <MdOutlineClose color="#6b5ca4" />
                </div>
                <div className="ps-2">
                  {chatContext?.checkBoxValue?.length} {t("chat.Selected")}
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                {chatContext?.checkBoxValue?.length > 0 &&
                  !(chatContext?.checkBoxValue?.length >= 2) && (
                    <>
                      <div
                        className="px-2 py-2"
                        onClick={() => {
                          getMessageDetailById("Reply");
                        }}
                      >
                        <RiReplyFill color="#6b5ca4" />
                      </div>
                      <div
                        className="px-2 py-2"
                        onClick={() => {
                          getMessageDetailById("Copy");
                        }}
                      >
                        <FaCopy color="#6b5ca4" />
                      </div>
                    </>
                  )}
                {chatContext?.checkBoxValue?.length > 0 && (
                  <div
                    className="px-2 py-2"
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    <RiShareForwardFill color="#6b5ca4" />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {chatContext?.action !== "Forward" && (
          <>
            <div className="">
              <input
                ref={inputRef}
                autoFocus
                type="text"
                className="form-control custom-form"
                id="message"
                placeholder={t("chat.Type your message here") || ""}
                value={context?.messageValue}
                onChange={(e: any) => {
                  context?.setMessageValue(e?.target?.value);
                  chatContext?.setShowEmoji(false);
                }}
                onKeyDown={(e: any) => {
                  context?.handleEnterKey(e);
                  chatContext?.setShowEmoji(false);
                }}
              />
            </div>

            <div
              className="custom-form__send-img"
              onClick={() => {
                context?.setShowAttachment(true);
                // chatContext?.setShowEmoji(false);
              }}
            >
              <div className="d-flex align-items-center">
                {chatContext?.showEmoji && (
                  <span
                    className="ps-2"
                    onClick={() => {
                      chatContext?.setShowEmoji(false);
                    }}
                  >
                    <MdOutlineClose color="#6b5ca4" />
                  </span>
                )}
                <div
                  onClick={() => {
                    chatContext?.setShowEmoji(true);
                  }}
                  style={{ marginInlineStart: "10px" }}
                >
                  <BsEmojiSmile color="#6b5ca4" fontSize={18} />
                </div>
                <div className="Chatattchment">
                  <Dropdown className="" drop="up">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <MdAttachFile color="#6b5ca4" fontSize={18} />
                    </Dropdown.Toggle>
                    {context?.showAttachment && (
                      <Dropdown.Menu>
                        <div className="d-flex justify-content-evenly py-1 ">
                          <div className="position-relative fileupload">
                            <FiCamera color="#6b5ca4" />
                            <input
                              type={"file"}
                              name="myfile"
                              multiple
                              accept="image/png, image/gif, image/jpeg"
                              onChange={(e: any) => {
                                context?.setAttachmentType("IMAGE");
                                context?.getImageUpload(e.target.files);
                              }}
                            />
                          </div>
                          <div className="  position-relative fileupload ">
                            <FiVideo color="#6b5ca4" />
                            <input
                              type={"file"}
                              name="myfile"
                              multiple
                              accept=".mp4,.mov,.wmv,.avi,.avchd,.flv,.f4v,.swf,.mkv,.webm"
                              onChange={(e: any) => {
                                context?.setAttachmentType("VIDEO");
                                context?.getVideoUpload(e.target.files);
                              }}
                            />
                          </div>
                          <div className="  position-relative fileupload">
                            <BsMic color="#6b5ca4" />
                            <input
                              type={"file"}
                              name="myfile"
                              multiple
                              accept=".mp3,.aiff,.alac,.dvf,.m4p,.msv,.wav,.wma,.webm"
                              onChange={(e: any) => {
                                context?.setAttachmentType("AUDIO");
                                context?.getAudioUpload(e.target.files);
                              }}
                            />
                          </div>
                        </div>
                      </Dropdown.Menu>
                    )}
                  </Dropdown>
                </div>
              </div>
            </div>
            {context?.messageValue?.length === 0 && (
              <div className="custom-form__send-emoji">
                <span
                  className="recorder p-1"
                  onClick={() => {
                    context?.setAttachmentType("AUDIO");
                    context?.setButtonPopup(true);
                  }}
                >
                  <BsMic color="#6b5ca4" fontSize={18} />
                </span>
              </div>
            )}
            {context?.messageValue?.length > 0 && (
              <button
                type="submit"
                className="custom-form__send-submit"
                disabled={!context?.messageValue?.length}
                id="send-message"
                onClick={() => {
                  context?.sendValue();
                  chatContext?.setShowEmoji(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="svg-icon svg-icon--send"
                  viewBox="0 0 45.6 45.6"
                >
                  <g>
                    <path
                      d="M20.7,26.7a1.4,1.4,0,0,1-1.2-.6,1.6,1.6,0,0,1,0-2.4L42.6.5a1.8,1.8,0,0,1,2.5,0,1.8,1.8,0,0,1,0,2.5L21.9,26.1A1.6,1.6,0,0,1,20.7,26.7Z"
                      fill="#d87232"
                    />
                    <path
                      d="M29.1,45.6a1.8,1.8,0,0,1-1.6-1L19.4,26.2,1,18.1a1.9,1.9,0,0,1-1-1.7,1.8,1.8,0,0,1,1.2-1.6L43.3.1a1.7,1.7,0,0,1,1.8.4,1.7,1.7,0,0,1,.4,1.8L30.8,44.4a1.8,1.8,0,0,1-1.6,1.2ZM6.5,16.7l14.9,6.6a2,2,0,0,1,.9.9l6.6,14.9L41,4.6Z"
                      fill="#d87232"
                    />
                  </g>
                </svg>
              </button>
            )}
          </>
        )}
      </div>
      <Modal trigger={context?.buttonPopup}>
        <AudioUpload
          setTrigger={(data: any) => context?.setButtonPopup(data)}
          userContext={userContext}
          isChat={true}
          setButtonPopup={(data: any) => context?.setButtonPopup(data)}
          context={context}
        />
      </Modal>
    </>
  );
};

export default MessageBox;
