import { isMobile } from "react-device-detect";
import { NavLink } from "react-router-dom";
import { routes } from "../../constants/routes";
import AppLayout from "../../layouts/AppLayout";
import { useTranslation } from "react-i18next";

const MastersNestedTab = (props: any) => {
  const { t, i18n } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const configType = queryParams.get("config_type");

  return (
    <AppLayout>
      <div
        className={`${
          isMobile ? "list_members position-relative  " : "list_members p-3 "
        }`}
      >
        <div
          className={`${isMobile ? "container-fluid p-0 " : "container p-3 "}`}
        >
          <div
            className={`${
              isMobile ? " overflow-scroll" : " overflow-scroll px-2"
            }`}
          >
            <ul
              className="d-flex justify-content-between nestedulInventory pt-3"
              style={isMobile ? { paddingInlineStart: "1.5rem" } : {}}
            >
              <li
                className=" m-0"
                style={
                  i18n.language === "en"
                    ? { minWidth: "60px" }
                    : { minWidth: "25px" }
                }
              >
                <NavLink
                  to={routes.mastersSupplier}
                  className="text-decoration-none  "
                >
                  <span
                    className={`edit_link_routes ${
                      window.location.pathname.includes("/masters/suppliers") &&
                      "active"
                    }`}
                  >
                    {t("inventory.Supplier info")}
                  </span>
                </NavLink>
              </li>
              <li
                className="mx-4"
                style={
                  i18n.language === "en"
                    ? { minWidth: "40px" }
                    : { minWidth: "20px" }
                }
              >
                <NavLink
                  to={routes.mastersMaterial}
                  className="text-decoration-none "
                >
                  <span
                    className={`edit_link_routes  ${
                      window.location.pathname.includes("/masters/materials") &&
                      "active"
                    }`}
                  >
                    {t("inventory.stocks Master")}
                  </span>
                </NavLink>
              </li>
              <li
                className="me-4"
                style={
                  i18n.language === "en"
                    ? { minWidth: "70px" }
                    : { minWidth: "30px" }
                }
              >
                <NavLink
                  to={routes.getMasterUrl({ config_type: "DEPARTMENT" })}
                  className="text-decoration-none "
                >
                  <span
                    className={`edit_link_routes  ${
                      configType === "DEPARTMENT" && "active"
                    }`}
                  >
                    {t(`masters.Department`)}
                  </span>
                </NavLink>
              </li>
              <li
                className="mx-3"
                style={
                  i18n.language === "en"
                    ? { minWidth: "81px" }
                    : { minWidth: "70px" }
                }
              >
                <NavLink
                  to={routes.getMasterUrl({ config_type: "TICKET_TITLE" })}
                  className="text-decoration-none "
                >
                  <span
                    className={`edit_link_routes  ${
                      configType === "TICKET_TITLE" && "active"
                    }`}
                  >
                    {t(`masters.Ticket Title`)}
                  </span>
                </NavLink>
              </li>
              <li
                className="mx-3"
                style={
                  i18n.language === "en"
                    ? { minWidth: "132px" }
                    : { minWidth: "54px" }
                }
              >
                <NavLink
                  to={routes.getMasterUrl({ config_type: "SCREEN_RESOLUTION" })}
                  className="text-decoration-none "
                >
                  <span
                    className={`edit_link_routes  ${
                      configType === "SCREEN_RESOLUTION" && "active"
                    }`}
                  >
                    {t(`masters.Screen Resolution`)}
                  </span>
                </NavLink>
              </li>
              <li
                className="mx-4"
                style={
                  i18n.language === "en"
                    ? { minWidth: "90px" }
                    : { minWidth: "65px" }
                }
              >
                <NavLink
                  to={routes.getMasterUrl({ config_type: "SCREEN_PIXEL" })}
                  className="text-decoration-none "
                >
                  <span
                    className={`edit_link_routes  ${
                      configType === "SCREEN_PIXEL" && "active"
                    }`}
                  >
                    {t(`masters.Screen Pixel`)}
                  </span>
                </NavLink>
              </li>
              <li
                className="mx-4"
                style={
                  i18n.language === "en"
                    ? { minWidth: "104px" }
                    : { minWidth: "105px" }
                }
              >
                <NavLink
                  to={routes.listMasterBillBoardType}
                  className="text-decoration-none "
                >
                  <span
                    className={`edit_link_routes  ${
                      window?.location?.pathname?.includes(
                        "/masters/billBoardType"
                      ) && "active"
                    }`}
                  >
                    {t(`CreateBillBoardForm.BillBoard_Type`)}{" "}
                  </span>
                </NavLink>
              </li>
              <li
                className="mx-3"
                style={
                  i18n.language === "en"
                    ? { minWidth: "71px" }
                    : { minWidth: "0px" }
                }
              >
                <NavLink
                  to={routes.getMasterUrl({ config_type: "BOM_UNIT" })}
                  className="text-decoration-none "
                >
                  <span
                    className={`edit_link_routes  ${
                      configType === "BOM_UNIT" && "active"
                    }`}
                  >
                    {t(`masters.BOM Unit`)}
                  </span>
                </NavLink>
              </li>
              <li
                className="mx-3"
                style={
                  i18n.language === "en"
                    ? { minWidth: "125px" }
                    : { minWidth: "115px" }
                }
              >
                <NavLink
                  to={routes.getBOMList({})}
                  className="text-decoration-none "
                >
                  <span
                    className={`edit_link_routes  ${
                      window.location.pathname.includes("/bom") && "active"
                    }`}
                  >
                    {t(`masters.BOM`)}
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>

          {props.children}
        </div>
      </div>
    </AppLayout>
  );
};

export default MastersNestedTab;
