import React from "react";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";

const DeleteBlockToast = (props: any) => {
  const {
    closePopUp,
    submit,
    textMessage,
    cancelMessage = "",
    headerMessage = "",
  } = props || {};
  const { t } = useTranslation();

  return (
    <div className="deleteblock">
      <div className="pt-3">
        <h4
          className={
            isMobileOnly
              ? " fw-semibold text-center text-black fs-6"
              : " fw-semibold text-center text-black"
          }
        >
          {isMobileOnly
            ? textMessage ===
                t("successError.Updated personal profile details") ||
              textMessage ===
                t("successError.Updated vehicle information details")
              ? `${t("successError.doyou")}`
              : t("successError.areyousure")
            : textMessage === t("successError.cancel it") ||
              textMessage === t("successError.logout")
            ? `${t("successError.doyou")}`
            : t("successError.areyousure")}
          {headerMessage === t("cancel the changes")
            ? headerMessage
            : headerMessage}
          ?
        </h4>
      </div>
      <div
        className={`${
          isMobileOnly
            ? " d-flex align-items-center justify-content-center  py-3"
            : " d-flex align-items-center justify-content-center py-3"
        }`}
      >
        <h6
          className={isMobileOnly ? "nobtn mx-2 my-0 p-2 " : "nobtn m-0  mx-2"}
          onClick={() => closePopUp()}
        >
          {isMobileOnly
            ? `${t("successError.no")}`
            : `${
                t("successError.notext") +
                " " +
                (cancelMessage ? cancelMessage : textMessage)
              }`}
          {"! "}
        </h6>
        <h6
          className={
            isMobileOnly ? "yesbtn mx-2 my-0 p-2 " : "yesbtn m-0  mx-2"
          }
          onClick={() => submit()}
        >
          {isMobileOnly
            ? `${t("successError.yes")}`
            : `${t("successError.yestext") + " " + textMessage}`}
          {"."}
        </h6>
      </div>
    </div>
  );
};

export default DeleteBlockToast;
