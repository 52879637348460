import { isMobile, isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";

interface LinkItemProps {
  value: string;
  label: string;
}

const LinkItem = ({ value, label }: LinkItemProps) => {
  const { i18n } = useTranslation();
  document.dir = i18n.dir();
  let colorClassName = `${
    [
      "/forget_password",
      "/change_password",
      "/reset_password",
      "/login",
      "/accept_invitation",
    ].includes(window.location.pathname)
      ? i18n.language === value
        ? "primary fw-semibold"
        : "color-black"
      : i18n.language === value
      ? "text-white fw-semibold"
      : "text-white"
  }`;
  return (
    <a
      href="/#"
      className={`${
        isMobile
          ? ` text-decoration-none  ${colorClassName}`
          : ` text-decoration-none px-3 ${colorClassName}`
      }`}
      onClick={(e) => {
        e.preventDefault();
        i18n.changeLanguage(value);
        window.dispatchEvent(new Event("storage"));
      }}
    >
      {label}
    </a>
  );
};

const SwitchLanguage = () => {
  const { t } = useTranslation();

  let englishLanguageDetails = `${
    [
      "/forget_password",
      "/change_password",
      "/reset_password",
      "/login",
      "/accept_invitation",
    ].includes(window.location.pathname)
      ? t("language.english")
      : t("language.en")
  }`;
  let arabicLanguageDetails = `${
    [
      "/forget_password",
      "/change_password",
      "/reset_password",
      "/login",
      "/accept_invitation",
    ].includes(window.location.pathname)
      ? t("language.arabic")
      : t("language.ar")
  }`;
  return (
    <div className={`${isMobileOnly ? "" : ""}`}>
      <LinkItem value={"en"} label={englishLanguageDetails} />
      <span className={`${isMobile ? "langpadding" : ""}`}>
        <LinkItem value={"ar"} label={arabicLanguageDetails} />
      </span>
    </div>
  );
};

export default SwitchLanguage;
