import { getIn } from "formik";
import { ChangeEvent, ReactNode } from "react";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { AnySchema } from "yup";

export interface InputFieldProps {
  validationSchema: AnySchema<any> | undefined;
  label: string;
  type?: string;
  field: {
    name: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
  isValid: boolean;
  error: string | undefined;
  autoFocus?: boolean;
  placeholder?: string;
  component?: ReactNode;
  disabled?: boolean;
  as?: string;
  isPassword?: boolean;
  setPasswordIcon?: (data: boolean) => void;
  passwordIcon?: boolean;
  maxLength: number;
  isAuth?: boolean;
  rightIcon?: boolean;
  icon?: React.ReactNode;
  onBlur?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isRequiredField = (validationSchema: any, name: string) => {
  return !!getIn(validationSchema.describe().fields, name)?.tests?.find(
    (obj: { name: string }) => obj.name === "required"
  );
};

export function InputField({
  validationSchema,
  label,
  type = "text",
  field,
  isValid,
  autoFocus,
  error,
  placeholder,
  disabled = false,
  isPassword,
  setPasswordIcon,
  passwordIcon,
  maxLength,
  rightIcon,
  icon,
  onBlur,
  as = "input",
  isAuth = false,
  onChange,
}: InputFieldProps) {
  const { i18n } = useTranslation();
  return (
    <FormGroup controlId={field.name} className="">
      <FormLabel className="form_label">
        {label} {isRequiredField(validationSchema, field.name) && "*"}
      </FormLabel>
      <FormControl
        type={type}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        as={as as any}
        autoFocus={!!autoFocus}
        value={field.value}
        onChange={onChange ? onChange : field.onChange}
        isInvalid={!isValid}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
        autoComplete="none"
        onBlur={onBlur}
        onFocus={onBlur}
      />
      {field?.value?.length > 0 && isPassword && (
        <>
          {passwordIcon ? (
            <span
              className={i18n.language === "en" ? "eyeicon" : "eyeicon-right"}
              data-testid={"password-eye-icon"}
              onClick={() => setPasswordIcon && setPasswordIcon(!passwordIcon)}
            >
              <AiOutlineEye color={error?.length ? "#db2c59" : "#2a2b2f80"} />
            </span>
          ) : (
            <span
              className={i18n.language === "en" ? "eyeicon" : "eyeicon-right"}
              data-testid="password-eye-invisible-icon"
              onClick={() => setPasswordIcon && setPasswordIcon(!passwordIcon)}
            >
              <AiOutlineEyeInvisible
                color={error?.length ? "#db2c59" : "#2a2b2f80"}
              />
            </span>
          )}
        </>
      )}
      {rightIcon && (
        <span style={{ position: "absolute", right: 25, top: 100 }}>
          {icon}
        </span>
      )}
      {error && (
        <small className={`form-text text-danger form_error`}>{error}</small>
      )}
    </FormGroup>
  );
}

export default InputField;
