import React, { useEffect } from "react";
import AppLayout from "../../layouts/AppLayout";
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import { isDesktop, isMobile, isMobileOnly } from "react-device-detect";
import { routes } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import ToastPopup from "../../components/PopupToast/ToastPopup";
import SuccessErrorToast from "../../components/PopupToast/SuccessErrorToast";
import CreateNewGroupForm from "./CreateNewGroupForm";
import { useCleaning } from "../../context/CleaningProvider";
import TicketViewAndActivityFeed from "./TicketViewAndActivityFeed";
import TicketTable from "./TicketsTable";

const CreateOrViewCleaning = ({ userContext }: any) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const urlParams = useParams();
  const context = useCleaning();

  const navigate = useNavigate();

  //

  return (
    <AppLayout>
      <>
        <div
          className="edit_members p-3 "
          style={!isDesktop ? {} : { background: "#FAFAFA", height: "100%" }}
        >
          <div className="members py-4 mt-2 d-flex align-items-center ">
            <h5
              className={`${
                isMobile ? "fs-6  mb-0 membertitle" : "  mb-0 membertitle"
              }`}
              onClick={() => navigate(routes.listCleaning)}
            >
              {t("cleaning.Cleaning")}
            </h5>

            <span className={i18n.language === "en" ? "px-2 " : "d-none"}>
              <MdOutlineArrowForwardIos />
            </span>
            <span className={i18n.language === "en" ? "d-none" : "px-2"}>
              <MdArrowBackIos />
            </span>
            <h5
              className={`${isMobile ? "fs-6 text-capitalize m-0 " : " m-0"}`}
            >
              {urlParams?.cleaningId
                ? context?.cleaning?.group_no
                : t("cleaning.New Group")}
            </h5>
          </div>
          <div
            style={!isMobile ? { background: "#fefefe" } : {}}
            className={`${isDesktop ? " mt-4 rounded-1 " : " p-0"}`}
          >
            {urlParams?.cleaningId && (
              <div
                className={`${
                  isMobile ? "container-fluid p-0 " : "container p-0 px-5 pt-5"
                }`}
              >
                <div
                  className={`${
                    isMobile ? " overflow-scroll" : " overflow-scroll"
                  }`}
                >
                  <ul className="d-flex nestedul">
                    <li className=" m-0">
                      <NavLink
                        to={routes.editCleaningUrl(urlParams?.cleaningId)}
                        className="text-decoration-none  "
                      >
                        <span
                          className={`edit_link_routes ${
                            window.location.pathname.includes(
                              `/cleaning/${urlParams?.cleaningId}`
                            ) &&
                            !urlParams?.ticketId &&
                            "active"
                          }`}
                        >
                          {t("cleaning.Edit")}
                        </span>
                      </NavLink>
                    </li>
                    <li className="  mx-4 ">
                      <NavLink
                        to={routes.getCleaningTicketsIdUrl(
                          urlParams?.cleaningId,
                          urlParams?.ticketId || 0
                        )}
                        className="text-decoration-none "
                      >
                        <span
                          className={`edit_link_routes ${
                            window.location.pathname.includes("/edit") &&
                            "active"
                          }`}
                        >
                          {t("new_tickets.View_ticket")}
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {(location.pathname.includes("/new") ||
              (window.location.pathname.includes(
                `/cleaning/${urlParams?.cleaningId}`
              ) &&
                !window.location.pathname.includes(
                  `/cleaning/${urlParams?.cleaningId}/tickets`
                ))) && (
              <>
                {
                  <>
                    <CreateNewGroupForm
                      context={context}
                      userContext={userContext}
                    />
                    {urlParams?.cleaningId &&
                      !window.location.pathname.includes(
                        `/cleaning/${urlParams?.cleaningId}/tickets`
                      ) && (
                        <>
                          <TicketTable
                            context={context}
                            userContext={userContext}
                          />
                        </>
                      )}
                  </>
                }
              </>
            )}
            {location.pathname ===
              routes.getCleaningTicketsIdUrl(
                urlParams?.cleaningId,
                urlParams?.ticketId || 0
              ) && (
              <TicketViewAndActivityFeed
                userContext={userContext}
                context={context}
              />
            )}

            {location.pathname ===
              routes.getCleaningTicketsActivityFeedUrl(
                urlParams.cleaningId,
                urlParams.ticketId || 0
              ) && (
              <TicketViewAndActivityFeed
                userContext={userContext}
                context={context}
              />
            )}
          </div>
          <ToastPopup
            trigger={context?.isLoading}
            setTrigger={context?.setIsLoading}
          >
            <SuccessErrorToast isLoading={true} />
          </ToastPopup>
        </div>
      </>
    </AppLayout>
  );
};

export default CreateOrViewCleaning;
