import { FieldInputProps } from "formik";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import { CustomStyles } from "../CustomStyles/CustomStyles";

export interface Option {
  label: string;
  value: string;
  id?: number;
}

export interface CustomSelectProps {
  options: Option[] | undefined;
  isMulti?: boolean;
  className?: string;
  placeholder?: string;
  id: string;
  callback?: (value: Option) => void;
  isDisabled: boolean;
  value?: Option | null | undefined | string;
  isClearable?: boolean;
  onFieldUpdate?: (value: Option[] | Option) => void;
  field?: FieldInputProps<Option | null | undefined | string> & {
    onBlur?: () => void;
  };
  form?: {
    setFieldValue: (name: string, value: string | string[]) => void;
  };
  isAsync?: boolean;
  isShow?: boolean;
  isSearchDisabled?: boolean;
  isCacheOptions?: boolean;
  loadOptionValue?: (
    inputValue: string,
    callback: (options: Option[] | Option | any) => void
  ) => void;
}

const CustomSelect = ({
  className = "",
  placeholder = "",
  field,
  form,
  id,
  isDisabled,
  options,
  isMulti = false,
  value,
  isClearable = true,
  onFieldUpdate,
  isAsync,
  isShow,
  isSearchDisabled,
  isCacheOptions,
  loadOptionValue,
}: CustomSelectProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (option: any) => {
    if (onFieldUpdate) {
      onFieldUpdate(option);
    }

    form?.setFieldValue(
      field?.name ?? "",
      isMulti
        ? (option as Option[])?.map((item) => item?.value)
        : (option as Option)?.value
    );
  };
  const Option = (props: any) => {
    return (
      <components.Option {...props}>
        <div className="border-0">
          <div className="p-0">
            <div className=" d-flex align-items-center justify-content-between  ">
              <div className=" d-flex align-items-center">
                <div style={{ paddingInlineStart: ".7rem" }}>
                  <div>
                    <h6 className="fw-semibold m-0 ">
                      {props?.data?.label}
                      {props?.data?.name && (
                        <span className="ps-2 fw-lighter m-0 ">
                          ({props?.data?.name})
                        </span>
                      )}
                      {props?.data?.material_name && (
                        <span className="ps-2 fw-lighter m-0 ">
                          ({props?.data?.material_name})
                        </span>
                      )}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </components.Option>
    );
  };

  return isAsync ? (
    <AsyncSelect
      className={className}
      name={field?.name}
      id={id}
      value={getValue(options, isMulti, field, value)}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      isDisabled={isDisabled}
      isClearable={isClearable}
      styles={CustomStyles}
      classNamePrefix={"custom_select_input"}
      data-testid="customSelect"
      inputId={field?.name}
      isSearchable={isSearchDisabled}
      cacheOptions={isCacheOptions}
      loadOptions={loadOptionValue}
      components={isShow ? { Option } : {}}
    />
  ) : (
    <Select
      className={className}
      name={field?.name}
      id={id}
      value={getValue(options, isMulti, field, value)}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      isDisabled={isDisabled}
      isClearable={isClearable}
      styles={CustomStyles}
      classNamePrefix={"custom_select_input"}
      data-testid="customSelect"
      inputId={field?.name}
    />
  );
};

export default CustomSelect;

export const getValue = (
  options: Option[] | undefined,
  isMulti: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: FieldInputProps<any> | undefined,
  value: Option | null | undefined | string
): Option | Option[] | undefined | null | string => {
  if (!options) {
    return isMulti ? [] : null || "";
  }

  if (!field?.value) {
    if (isMulti) {
      return (
        (options as Option[]).filter((option) =>
          Array.isArray(value)
            ? (value as Option[]).some(
                (val) =>
                  val.value === option.value || val.label === option.label
              )
            : false
        ) || ""
      );
    } else {
      return (
        options.find((option) =>
          typeof value === "object"
            ? option?.value === value?.value || option?.label === value?.label
            : option?.value === value || option?.label === value
        ) || ""
      );
    }
  }

  if (isMulti) {
    return (
      (options as Option[]).filter((option) =>
        Array.isArray(field.value)
          ? field.value?.some(
              (val: string) => val === option.value || val === option.label
            )
          : option.value === field?.value
      ) || ""
    );
  } else {
    return (
      options.find(
        (option) =>
          option?.value === field.value ||
          (typeof value === "object" && option?.label === value?.label) ||
          (typeof value === "string" && option?.label === value)
      ) || ""
    );
  }
};
