import React, { ReactElement, useEffect, useState } from "react";
import { SOCKET_URL, api, stringToHexColor } from "../utils/api";
import { io } from "socket.io-client";
const socket = io(SOCKET_URL || "");

interface DashboardProviderProps {
  children: ReactElement;
}

export default interface DashboardContextProp {
  isLoading: boolean;
  setIsLoading: Function;
  data: any;
  getStatsOverview: Function;
  getStatusTicket: Function;
  getPriorityTicket: Function;
  getStatusPriorityTicket: Function;
  disConnectSocket: Function;
  getDepartmenTicket: Function;
  socketData: any;
  getLocationBasedTicket: Function;
  mapData: any;
  getAreaWiseBillboard: Function;
  loadBillBoards: Function;
}

const DashboardContext = React.createContext<DashboardContextProp | null>(null);

const DashboardProvider = ({ children }: DashboardProviderProps) => {
  const [isLoading, setIsLoading]: [boolean, Function] = useState(false);
  const [data, setData] = useState<any>({});
  const [socketData, setSocketData] = useState<any>({});
  const [mapData, setMapData] = useState<any>([]);

  const getStatsOverview = async (dateValue: any, data: any) => {
    if (data && dateValue) {
      setIsLoading(true);
      const { success, dashboard } = await api.getStatsOverview(
        dateValue,
        data
      );
      if (success) {
        setData(dashboard);
      }
      setIsLoading(false);
      return { success, dashboard };
    }
  };

  const getAreaWiseBillboard = async (dateValue: any, Date: any) => {
    if (data && dateValue) {
      setIsLoading(true);
      const dashboardData = await api.getAreaWiseBillboard(dateValue, Date);
      setIsLoading(false);
      const data = {
        label: dashboardData?.data?.map(
          (item: { count: number }) => item?.count
        ),
        datasets: dashboardData?.data?.map(
          (item: { city: string; count: string }) => ({
            label: item?.city,
            backgroundColor: [...Array(30)].map((s: any) => randomColor()),
            data: dashboardData?.data?.map(
              (_data: { city: string; count: string }) =>
                item?.city === _data?.city ? _data?.count : 0
            ),
          })
        ),
      };
      return data;
    }
  };

  const getStatusTicket = async (dateValue: any, _data: any) => {
    if (data && dateValue) {
      setIsLoading(true);
      const { success, data } = await api.getStatusTicket(dateValue, _data);
      let label: any = [];
      let values: any = [];

      let _temp: any = {
        CREATED: [],
        VIEWED: [],
        SUBMITTED: [],
        CLOSED: [],
        REASSIGNED: [],
        VERIFIED: [],
        OVERDUE: [],
      };
      let datasets: any = [];
      if (success) {
        label = Object.keys(data);
        values = Object.values(data);

        values.forEach((val: any) => {
          Object.keys(val).forEach((_status: string) => {
            _temp[_status] = _temp[_status].concat(val[_status]);
          });
        });

        // if (_data === "month") {
        //   label = Object.keys(data).map((str) => {
        //     return parseInt(str);
        //   });
        //   label.sort((a: any, b: any) => {
        //     return a - b;
        //   });
        // }

        datasets = [
          {
            label: "CREATED",
            backgroundColor: "#2ad2d4",
            data: _temp.CREATED,
          },
          {
            label: "VIEWED",
            backgroundColor: "#6289F1",
            data: _temp.VIEWED,
          },
          {
            label: "REASSIGNED",
            backgroundColor: "#ff6c37",
            data: _temp.REASSIGNED,
          },
          {
            label: "SUBMITTED",
            backgroundColor: "#ff4567",
            data: _temp.SUBMITTED,
          },
          {
            label: "VERIFIED",
            backgroundColor: "#ff9e3f",
            data: _temp.VERIFIED,
          },
          {
            label: "CLOSED",
            backgroundColor: "#2ae1aa",
            data: _temp.CLOSED,
          },
          {
            label: "OVERDUE",
            backgroundColor: "gray",
            data: _temp.OVERDUE,
          },
        ];
      }
      setIsLoading(false);
      return { label, datasets };
    }
  };

  const getPriorityTicket = async (dateValue: any, _data: any) => {
    if (data && dateValue) {
      setIsLoading(true);
      const { success, data, error } = await api.getPriorityTicket(
        dateValue,
        _data
      );
      let label: any = [];
      let values: any = [];

      let _temp: any = {
        HIGH: [],
        LOW: [],
        MEDIUM: [],
      };
      let datasets: any = [];
      // if (success) {
      //   setData({ data });
      // }
      if (success) {
        label = Object.keys(data);
        values = Object.values(data);

        values.forEach((val: any) => {
          Object.keys(val).forEach((_status: string) => {
            _temp[_status] = _temp[_status].concat(val[_status]);
          });
        });

        // [0, 4, 0, 5, 0, 2, 4]

        // [0, 3, 1, 1, 0, 1, 1]

        // [0, 3, 2, 5, 0, 1, 3]
        datasets = [
          {
            label: "HIGH",
            backgroundColor: "#ff2d55",
            borderColor: "#ff2d55",
            data: _temp.HIGH,
            // stack: "Stack 0",
          },

          {
            label: "LOW",
            backgroundColor: "#f9ea68",
            borderColor: "#f9ea68",
            data: _temp.LOW,
          },
          {
            label: "MEDIUM",
            backgroundColor: "#ff8900",
            borderColor: "#ff8900",
            data: _temp.MEDIUM,
          },
        ];
      }
      setIsLoading(false);
      return { label, datasets };
    }
  };

  const getDepartmenTicket = async (dateValue: any, _data: any) => {
    if (data && dateValue) {
      setIsLoading(true);
      const { success, data, error } = await api.getDepartmenTicket(
        dateValue,
        _data
      );
      let label: any = [];
      let values: any = [];
      const { config } = await api.getAllConfig({
        config_type: "DEPARTMENT",
        limit: 100,
      });
      let _temp: any = {};
      config
        ?.map((item: any) => item?.title)
        ?.forEach((item: any) => {
          _temp[item] = [];
        });
      let datasets: any = [];
      if (success) {
        label = Object.keys(data);
        values = Object.values(data);
        values.forEach((val: any) => {
          Object.keys(val).forEach((_status: string) => {
            _temp[_status] =
              _temp[_status] && _temp[_status]?.concat(val[_status]);
          });
        });
        datasets = config
          ?.map((item: any) => item?.title?.replace("_", " "))
          ?.map((item: any) => ({
            label: item,
            type: "bar" as const,
            backgroundColor: stringToHexColor(item),
            borderColor: stringToHexColor(item),
            data: _temp[item],
          }));
      }
      setIsLoading(false);
      return { label, datasets };
    }
  };

  const getStatusPriorityTicket = async (dateValue: any, _data: any) => {
    setIsLoading(true);
    const { success, data, error } = await api.getStatusPriorityTicket(
      dateValue,
      _data
    );

    let label: any = [];
    let values: any = [];

    // debugger;

    let _temp: any = {
      CREATED: [],
      VIEWED: [],
      SUBMITTED: [],
      REASSIGNED: [],
      VERIFIED: [],
      OVERDUE: [],
    };
    let datasets: any = [];
    if (success) {
      label = Object.keys(data);
      values = Object.values(data);

      values.forEach((val: any) => {
        Object.keys(val).forEach((_status: string) => {
          _temp[_status] = _temp[_status].concat(val[_status]);
        });
      });
      // debugger;
      datasets = [
        {
          label: "CREATED",
          backgroundColor: "#2ad2d4",
          data: _temp.CREATED,
          // type: "bar" as const,
        },
        {
          label: "VIEWED",
          backgroundColor: "#ff87d4",
          data: _temp.VIEWED,
          // type: "bar" as const,
        },
        {
          label: "REASSIGNED",
          backgroundColor: "#ff6c37",
          data: _temp.REASSIGNED,
          // type: "bar" as const,
        },
        {
          label: "SUBMTTED",
          backgroundColor: "#ff4567",
          data: _temp.SUBMTTED,
          // type: "bar" as const,
        },
        {
          label: "VERIFIED",
          backgroundColor: "#f00c87",
          data: _temp.VERIFIED,
          // type: "bar" as const,
        },
      ];

      setIsLoading(false);
      return { label, datasets };
    }
  };

  const getLocationBasedTicket = async (dateValue: any, _data: any) => {
    setIsLoading(true);
    const { data, success } = await api?.getLocationBasedTicket({
      date: dateValue,
      type: _data,
      data_type: "billboard",
    });
    if (success) {
      setMapData(data);
    }
    setIsLoading(false);
  };

  socket.on("billboard_stats", (data: any) => {
    setSocketData(data?.data);
  });

  useEffect(() => {
    return () => {
      disConnectSocket("billboard_stats");
    };
  }, []);

  const disConnectSocket = (value: any) => {
    socket.off(value);
  };

  const loadBillBoards = async (params: any) => {
    const { billboards, meta, success } = await api.billBoards(params);
    return { success, billboards };
  };

  const value: DashboardContextProp = {
    isLoading,
    setIsLoading,
    data,
    getStatsOverview,
    getStatusTicket,
    getPriorityTicket,
    getStatusPriorityTicket,
    disConnectSocket,
    getDepartmenTicket,
    socketData,
    getLocationBasedTicket,
    mapData,
    getAreaWiseBillboard,
    loadBillBoards,
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

const useDashboard = () => React.useContext(DashboardContext);

export { DashboardProvider, useDashboard };

function randomColor() {
  const getRgb = () => Math.floor(Math.random() * 256);
  const rgbToHex = (r: any, g: any, b: any) =>
    "#" +
    [r, g, b]
      .map((x: any) => {
        const hex = x.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("");

  const handleGenerate = () => {
    const color = {
      r: getRgb(),
      g: getRgb(),
      b: getRgb(),
    };
    return rgbToHex(color.r, color.g, color.b);
  };
  return handleGenerate();
}
