import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "../../App";
import { routes } from "../../constants/routes";
import { CircuitProvider } from "../../context/CircuitProvider";
import ListCircuits from "./ListCircuits";

const CircuitsPages = ({userContext}: any) => {
  return (
    <CircuitProvider>
      <Routes>
        <Route
          path={routes.listCircuits}
          element={
            <RequireAuth>
              <ListCircuits />
            </RequireAuth>
          }
        />
        <Route path={routes.newTicket} element={<RequireAuth></RequireAuth>} />
        <Route path={routes.editTicket} element={<RequireAuth></RequireAuth>} />
        <Route
          path={routes.activityTicket}
          element={<RequireAuth></RequireAuth>}
        />
      </Routes>
    </CircuitProvider>
  );
};

export default CircuitsPages;
