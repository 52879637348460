import React from "react";
import { useTranslation } from "react-i18next";
import LoginForm from "./LoginForm";
import LoginLayout from "../../layouts/LoginLayout";

const LoginPage = () => {
  const { t } = useTranslation();
  return (
    <LoginLayout title={t("login.title")} subTitle={t("login.subtitle")}>
      <LoginForm />
    </LoginLayout>
  );
};

export default LoginPage;
