import Avatar from "react-avatar";
export default function CommonAvatar({
  name,
  imageSrc,
  size,
  style,
  className,
}: {
  name: string;
  imageSrc?: string;
  size?: string;
  style: any;
  className?: string;
}) {
  return (
    <Avatar
      name={name}
      src={imageSrc}
      size={size}
      style={style}
      className={className}
    />
  );
}
