import React, { ReactElement, useState } from "react";
import User from "../lib/User";
import { api } from "../utils/api";

interface CircuitProviderProps {
  children: ReactElement;
}

export interface CircuitContextProp {
  isLoading: boolean;
  meta: any;
  billBoards: any;
  billBoard: any;
 
}

const CircuitContext = React.createContext<CircuitContextProp | null>(null);

const CircuitProvider = ({ children }: CircuitProviderProps) => {
  const [isLoading, setIsLoading]: [boolean, Function] = useState(false);
  const [billBoards, setBillBoards] = useState<any>([]);
  const [billBoard, setBillBoard] = useState<any>({});
  const [meta, setMeta] = useState<any>({});

  
  const value: CircuitContextProp = {
    isLoading,
    meta,
    billBoards,
    billBoard,
   
  };

  return (
    <CircuitContext.Provider value={value}>{children}</CircuitContext.Provider>
  );
};

const useCircuit = () => React.useContext(CircuitContext);

export { CircuitProvider, useCircuit };
