import { useReactMediaRecorder } from "react-media-recorder";
import { useEffect, useState } from "react";
import x from "../../assets/x.png";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
// import WaveSurfer from "wavesurfer.js";

const AudioUpload = ({
  setTrigger,
  userContext,
  setButtonPopup,
  isChat = false,
  context = () => {},
  uploadAudio = () => {},
}: any) => {
  const { t } = useTranslation();

  const [second, setSecond] = useState<any>("00");
  const [minute, setMinute] = useState<any>("00");
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(0);
  const [audioFile, setAudioFile] = useState<any>({});

  const getAudioUpload = async () => {
    const { success, url } = await userContext?.uploadFiles(audioFile, "audio");
    if (success) {
      if (isChat) {
        context?.sendValue(
          {
            url: url,
          },
          "AUDIO"
        );
      } else {
        uploadAudio(url);
      }
      setButtonPopup(false);
    }
    // if (success) {
    //   const data = await userContext?.urlToBase64(url);
    //   if (data?.success) {
    //     if (isChat) {
    //       context?.setAudioFile({
    //         base64: "data:audio/mp3;base64," + data.base64,
    //         url: url,
    //       });
    //     } else {
    //       audioFileArray.push({
    //         base64: "data:audio/mp3;base64," + data.base64,
    //         url: url,
    //       });
    //     }
    //     if (isChat) {
    //       context?.sendValue(
    //         {
    //           base64: "data:audio/mp3;base64," + data.base64,
    //           url: url,
    //         },
    //         "AUDIO"
    //       );
    //     } else {
    //       setButtonPopup(false);
    //     }
    //   }
    // }
  };

  useEffect(() => {
    let intervalId: string | number | NodeJS.Timeout | undefined;

    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        let computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        let computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);

        setCounter((counter) => counter + 1);
        if (minuteCounter >= 3) {
          stopRecording();
          setIsActive(false);
        }
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  function stopTimer() {
    setIsActive(false);
    setCounter(0);
    setSecond("00");
    setMinute("00");
  }

  const {
    status,
    startRecording,
    stopRecording,
    pauseRecording,
    mediaBlobUrl,
  } = useReactMediaRecorder({
    video: false,
    audio: true,
    // echoCancellation: true
    blobPropertyBag: { type: "audio/mp3" },
  });

  useEffect(() => {
    if (mediaBlobUrl) {
      handleSave(mediaBlobUrl);
    }
  }, [mediaBlobUrl]);

  const handleSave = async (mediaBlobUrl: any) => {
    const audioBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
    const audiofile = new File([audioBlob], "audiofile.mp3", {
      type: "audio/mp3",
    });
    const formData = new FormData();
    formData.append("file", audiofile);
    setAudioFile(formData);
  };

  return (
    <div>
      <div
        className=" d-flex justify-content-end"
        onClick={() => setTrigger(false)}
      >
        <img src={x} alt="" width={20} />
      </div>

      {mediaBlobUrl && status === "stopped" && (
        <div className=" d-flex justify-content-center py-3">
          <audio src={mediaBlobUrl} controls loop></audio>
        </div>
      )}

      <div className="d-flex flex-column justify-content-center align-items-center ">
        <div className=" d-flex align-items-center timeload">
          <h1 className="minute fs-1 m-0">{minute}</h1>
          <h1 className="fs-1 m-0">:</h1>
          <h1 className="second fs-1 m-0">{second}</h1>
        </div>
        <div>
          <label htmlFor="icon-button-file">
            <h5 className=" m-0 py-3">
              {status === "idle"
                ? t("Audio.press")
                : status === "recording"
                ? t("Audio.recording")
                : status === "paused"
                ? t("Audio.pauseText")
                : status === "stopped"
                ? t("Audio.press")
                : ""}
            </h5>
            <div className=" d-flex justify-content-evenly">
              {status !== "idle" && status !== "stopped" && (
                <h6
                  className=" btn stopbtn fw-semibold"
                  onClick={() => {
                    stopRecording();
                    setIsActive(false);
                    stopTimer();
                  }}
                >
                  {`${t("Audio.stop")}`}
                </h6>
              )}
              <h6
                className=" btn pausebtn fw-semibold mx-3"
                onClick={() => {
                  if (!isActive) {
                    startRecording();
                  } else {
                    pauseRecording();
                  }
                  setIsActive(!isActive);
                }}
              >
                {(status === "idle" || status === "stopped") &&
                  `${t("Audio.record")}`}
                {isActive && status === "recording" && `${t("Audio.pause")}`}
                {!isActive && status === "paused" && `${t("Audio.record")}`}
              </h6>
            </div>
          </label>
        </div>
      </div>
      <div className="  d-flex justify-content-end d-inline-block pt-5">
        <Button
          disabled={!mediaBlobUrl}
          onClick={() => getAudioUpload()}
          className=" btn_update rounded-1 d-flex justify-content-center align-items-center"
        >
          <span className="fw-semibold">{t("Audio.uploadbtn")}</span>
        </Button>
      </div>
    </div>
  );
};
export default AudioUpload;
