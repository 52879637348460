import { PropsWithChildren } from "react";
import "./CommonStatusButton.scss";

type IProps = PropsWithChildren<{
  pillText: string;
  activeText?: any;
  text?: string;
  isPill?: boolean;
  onClick?: (role: string) => void;
  className?: string;
}>;

export const CommonStatusButton = ({
  pillText,
  activeText = [],
  onClick = () => {},
  text,
  isPill,
  className,
}: IProps) => {
  const statusColor = getStatusColor(pillText);

  return (
    <>
      {isPill ? (
        <span
          className={`rounded-1 fs-14 px-2 py-1 ${className} ${statusColor}`}
          id={pillText}
        >
          {convertToPascalCase(
            pillText.replaceAll("_", " ").replaceAll("-", " ")
          )}
        </span>
      ) : (
        <span
          onClick={() => onClick(pillText)}
          className={`rounded-1 pill_view mb-3 ${
            activeText.includes(pillText) ? statusColor : ""
          }`}
          id={pillText}
        >
          {text}
        </span>
      )}
    </>
  );
};

export const convertToPascalCase = (data: string): string => {
  return data
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const getStatusColor = (status: string): any => {
  switch (status) {
    case "ACTIVE":
    case "Completed":
    case "Success":
    case "INVITED":
    case "CLOSED":
    case "SUBMITTED":
    case "online":
    case "installed":
      return "success-pill";
    // --------------------------------------color-danger---------------
    case "inactive":
    case "InActive":
    case "BLOCKED":
    case "Rejected":
    case "ERROR":
    case "Error Found":
    case "ErrorFound":
    case "New File Found":
    case "CANCELLED":
    case "FAILED":
    case "Closed":
    case "Not Initiated":
    case "Terminated":
    case "offline":
    case "not_installed":
    case "HIGH":
    case "OVERDUE":
      return "danger-pill";
    // --------------------------------------color-warning---------------
    case "In Progress":
      return "warning-pill";
    case "alaram":
      return "alaram-pill";
    case "fault":
      return "Fault-pill";
    // --------------------------------------color-pending---------------
    case "PENDING":
    case "PROCESSED":
    case "REASSIGNED":
    case "VERIFIED":
    case "REQUEST":
      return "pending-pill";
    case "EXPIRED":
      return "expired-pill";
    case "ADMIN":
    case "MANAGER":
    case "SUPERVISOR":
    case "TECHNICIAN":
    case "All":
    case "AUTOMATIC":
    case "MANUAL":
    case "DRY":
    case "WET":
    case "Both":
    case "NON_OPERATIONAL":
      return "role-pill";
    case "MEDIUM":
    case "VIEWED":
      return "medium-pill";
    case "LOW":
    case "CREATED":
      return "low-pill";
  }
};
