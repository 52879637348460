import React, { useEffect, useState } from "react";

interface BootstrapLayoutInterface {
  isXS: boolean;
  isSM: boolean;
  isMD: boolean;
  isLG: boolean;
  isXL: boolean;
  isXXL: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

interface BootstrapLayoutProps {
  children?: JSX.Element;
}

const BootstrapLayoutContext = React.createContext<BootstrapLayoutInterface>({
  isXS: false,
  isSM: false,
  isMD: false,
  isLG: false,
  isXL: false,
  isXXL: false,
  isMobile: false,
  isTablet: false,
  isDesktop: false,
});

const BootstrapLayoutProvider = ({ children }: BootstrapLayoutProps) => {
  const [isXS, setIsXS]: [boolean, Function] = useState(false);
  const [isSM, setIsSM]: [boolean, Function] = useState(false);
  const [isMD, setIsMD]: [boolean, Function] = useState(false);
  const [isLG, setIsLG]: [boolean, Function] = useState(false);
  const [isXL, setIsXL]: [boolean, Function] = useState(false);
  const [isXXL, setIsXXL]: [boolean, Function] = useState(false);

  const reset = () => {
    setIsXS(false);
    setIsSM(false);
    setIsMD(false);
    setIsLG(false);
    setIsXL(false);
    setIsXXL(false);
  };

  const handleResize = () => {
    reset();
    if (window.innerWidth < 576) {
      setIsXS(true);
    } else if (window.innerWidth >= 576 && window.innerWidth < 768) {
      setIsSM(true);
    } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
      setIsMD(true);
    } else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
      setIsLG(true);
    } else if (window.innerWidth >= 1200 && window.innerWidth < 1400) {
      setIsXL(true);
    } else {
      setIsXXL(true);
    }
  };

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize);
  });

  const value: BootstrapLayoutInterface = {
    isXS,
    isSM,
    isMD,
    isLG,
    isXL,
    isXXL,
    isMobile: isXS,
    isTablet: isSM,
    isDesktop: isMD || isLG || isXL || isXXL,
  };

  return (
    <BootstrapLayoutContext.Provider value={value}>
      {children}
    </BootstrapLayoutContext.Provider>
  );
};
const useBootStrapLayout = () => React.useContext(BootstrapLayoutContext);

export { BootstrapLayoutProvider, useBootStrapLayout };
