import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "../../App";
import { routes } from "../../constants/routes";
import { ChatProvider } from "./context";
// import TicketsPages from "../Chat/Tickets/TicketPage";
import ChatPage from "./People/ChatPage";

const ChatPages = ({ userContext }: any) => {
  return (
    <ChatProvider>
      <Routes>
        <Route
          path={routes.chat}
          element={
            <RequireAuth>
              <ChatPage userContext={userContext} />
            </RequireAuth>
          }
        />
      </Routes>
    </ChatProvider>
  );
};

export default ChatPages;
