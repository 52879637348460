import { Modal } from "react-bootstrap";
import NewChatPopup from "./NewChatPopup";
import { AiOutlineSearch } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";

const ModelNewPopUp = ({
  show,
  setShow,
  setCheckBoxMemberValue,
  searchMember,
  setSearchMember,
  context,
  createNewConversation,
  forwordMessage,
  getUserToForword,
  currentGroupId,
  addMemberGroup,
  checkBoxMemberValue,
  userContext,
  setCurrentGroupId,
  createGroup,
  setCreateGroup,
  groupName,
  setGroupName,
  createGroupConversation,
  showName,
  setShowName,
}: any) => {
  const { t } = useTranslation();
  let result: any = [];
  let combineArray: any =
    context?.action === "Forward" ? context?.forwardList : context?.members;
  if (combineArray?.length > 0) {
    let data = (
      !createGroup && context?.groupInfo?.group_member?.length > 0
        ? combineArray?.filter(
            (u: any) =>
              context?.groupInfo?.group_member.findIndex(
                (lu: any) => lu.id === u.id
              ) === -1
          )
        : combineArray?.filter(
            (value: any) => value?.id !== userContext?.user?.id
          )
    ).reduce((r: any, e: any) => {
      let group: any = e?.group_name
        ? e?.group_name[0].toUpperCase()
        : e?.name[0].toUpperCase();
      if (!r[group]) r[group] = { group, children: [e] };
      else r[group].children.push(e);
      return r;
    }, {});
    result = Object.values(data);
  }
  return (
    <Modal
      show={show}
      onHide={() => {
        context?.setCheckBoxValue([]);
        setShow(false);
        setGroupName("");
        setCreateGroup(false);
        setShowName(false);
      }}
    >
      <Modal.Body>
        <div className=" d-flex justify-content-between align-items-center py-3">
          <h4 className=" fw-semibold m-0">
            {createGroup
              ? "Create Group"
              : currentGroupId?.length > 0
              ? `${t("chat.Members List")}`
              : !context?.checkBoxValue?.length
              ? `${t("chat.New Conversation")}`
              : `${t("chat.Forword To")}`}
          </h4>
          <div
            onClick={() => {
              context?.setCheckBoxValue([]);
              setShow(!show);
              setGroupName("");
              setCreateGroup(false);
              setShowName(false);
            }}
            className=" pointer"
          >
            <RxCross2 fontSize={24} color="#b1b1b1" />
          </div>
        </div>
        {!showName ? (
          <>
            <Modal.Title className="pb-3">
              <div className="container-fluid p-0">
                <div className="position-relative">
                  <div className="msg-lens position-absolute">
                    <AiOutlineSearch fontSize={20} />
                  </div>
                  <div className="">
                    <input
                      id="doc_searchQueryChat"
                      type="text"
                      name="doc_searchQueryChat"
                      placeholder={t("chat.Search Members") || ""}
                      maxLength={50}
                      value={searchMember}
                      onChange={(e: any) => {
                        setSearchMember(e?.target?.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Modal.Title>
            <div className="convolist">
              {result?.length > 0 &&
                result?.map((groupValue: any) => {
                  return (
                    <>
                      <div className=" p-2">{groupValue?.group}</div>
                      {groupValue?.children?.map((member: any) => {
                        return (
                          <div className="d-flex align-items-center">
                            {(createGroup ||
                              context?.checkBoxValue?.length > 0 ||
                              currentGroupId?.length > 0) && (
                              <div>
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={
                                    member?.group_name
                                      ? member?.id + "_group"
                                      : member?.id
                                  }
                                  value={
                                    member?.group_name
                                      ? member?.id + "_group"
                                      : member?.id
                                  }
                                  checked={checkBoxMemberValue?.includes(
                                    member?.group_name
                                      ? member?.id + "_group"
                                      : member?.id
                                  )}
                                  onChange={getUserToForword}
                                />
                              </div>
                            )}
                            <NewChatPopup
                              member={member}
                              createNewConversation={(data: any) => {
                                if (
                                  !context?.checkBoxValue?.length &&
                                  !currentGroupId?.length
                                ) {
                                  createNewConversation(data);
                                }
                              }}
                            />
                          </div>
                        );
                      })}
                      <hr />
                    </>
                  );
                })}
              {result?.length > 0 &&
                context?.action !== "Forward" &&
                !(context?.memberMeta?.total_count <= context?.memberLimit) && (
                  <div
                    className="d-flex justify-content-center membertitle"
                    onClick={() =>
                      context?.setMemberLimit(context?.memberLimit + 10)
                    }
                  >
                    {t("chat.View more")}
                  </div>
                )}
            </div>
          </>
        ) : (
          <Modal.Title className="pb-3">
            <div className="container-fluid p-0">
              <div className="position-relative">
                <div className="msg-lensforword position-absolute">
                  <AiOutlineSearch fontSize={20} />
                </div>
                <div className="">
                  <input
                    id="doc_searchQueryChat"
                    type="text"
                    name="doc_searchQueryChat"
                    placeholder={"Enter group name" || ""}
                    maxLength={50}
                    value={groupName}
                    onChange={(e: any) => {
                      setGroupName(e?.target?.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal.Title>
        )}
      </Modal.Body>

      {(createGroup ||
        context?.checkBoxValue?.length > 0 ||
        currentGroupId?.length > 0) &&
        checkBoxMemberValue?.length > 0 && (
          <Modal.Footer>
            <div className="d-flex justify-content-between my-3">
              <button
                type="submit"
                className={
                  showName
                    ? "btn pausebtn fw-semibold "
                    : "custom-form__forword"
                }
                id="send-message"
                onClick={() => {
                  if (!showName && createGroup) {
                    setShowName(true);
                  } else if (showName && createGroup) {
                    createGroupConversation();
                  } else if (
                    !showName &&
                    !createGroup &&
                    currentGroupId?.length > 0
                  ) {
                    addMemberGroup();
                    setCheckBoxMemberValue([]);
                  } else if (
                    !showName &&
                    !createGroup &&
                    context?.checkBoxValue?.length > 0
                  ) {
                    forwordMessage();
                    setCheckBoxMemberValue([]);
                  }
                }}
              >
                {showName ? (
                  "Create Group"
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-icon svg-icon--send"
                    viewBox="0 0 45.6 45.6"
                  >
                    <g>
                      <path
                        d="M20.7,26.7a1.4,1.4,0,0,1-1.2-.6,1.6,1.6,0,0,1,0-2.4L42.6.5a1.8,1.8,0,0,1,2.5,0,1.8,1.8,0,0,1,0,2.5L21.9,26.1A1.6,1.6,0,0,1,20.7,26.7Z"
                        fill="#d87232"
                      />
                      <path
                        d="M29.1,45.6a1.8,1.8,0,0,1-1.6-1L19.4,26.2,1,18.1a1.9,1.9,0,0,1-1-1.7,1.8,1.8,0,0,1,1.2-1.6L43.3.1a1.7,1.7,0,0,1,1.8.4,1.7,1.7,0,0,1,.4,1.8L30.8,44.4a1.8,1.8,0,0,1-1.6,1.2ZM6.5,16.7l14.9,6.6a2,2,0,0,1,.9.9l6.6,14.9L41,4.6Z"
                        fill="#d87232"
                      />
                    </g>
                  </svg>
                )}
              </button>
            </div>
          </Modal.Footer>
        )}
    </Modal>
  );
};

export default ModelNewPopUp;
