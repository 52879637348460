import { Moment } from "moment";
export interface IUser {
  id: string;
  name: string;
  email: string;
  avatar: string;
  gender: IGender;
  role: IRole;
  biography: string;
  confirmedAt: Moment | null;
  contactNumber: string;
  dateOfJoining: Moment | null;
  iqamaNo: string;
  location: string;
  responsibleArea: string;
  roleAccess: [];
  status: string;
  department: string;
  whatappNumber: string;
  createdAt: Moment;
  updatedAt: Moment;
  board_no: string;
  board_location: string;
  board_type: string;
  screen_size: string;
  screen_resolution: string;
}

export enum IGender {
  Male = "MALE",
  Female = "FEMALE",
}

export enum IDepartment {
  Cleaning = "CLEANING",
  CleaningWithArabic = "Cleaning/تنظيف",
  Electrical = "ELECTRICAL",
  ElectricalWithArabic = "Electrical/الكهرباء",
  Monitoring = "MONITORING",
  MonitoringWithArabic = "Monitoring/يراقب",
  StaffManagement = "STAFF_MANAGEMENT",
  StaffManagementWithArabic = "Staff Management/إدارة الموظفين",
  StoreManagement = "STORE_MANAGEMENT",
  StoreManagementWithArabic = "Store Management/إدارة المخزن",
}
export enum IRole {
  Admin = "ADMIN",
  Staff = "STAFF",
  StaffWithArabic = "Staff/طاقم عمل",
  Manager = "MANAGER",
  ManagerWithArabic = "Manager/مدير",
  Supervisor = "SUPERVISOR",
  SupervisorWithArabic = "Supervisor/مشرف",
  Technician = "TECHNICIAN",
  TechnicianWithArabic = "Technician/فني",
}

export enum IRole {}
// Admin = "ADMIN",
// Staff = "STAFF",
// StaffWithArabic = "Staff/طاقم عمل",

export const customStyles = {
  option: (styles: any, { isFocused, isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: isSelected
        ? "#6b5ca42e"
        : isFocused
        ? "#6b5ca42e"
        : undefined,
      color: isSelected ? "#fefefe" : isFocused ? "#6b5ca4" : undefined,
    };
  },
};

export const titleArray: any = [
  { label: "Computer off/إيقاف تشغيل الكمبيوتر", value: "computer off" },
  { label: "Controller damaged/تحكم معطوب", value: "controller damaged" },
  {
    label: "Fiber optic cable damaged/تلف كابل الألياف البصرية",
    value: "fiber optic cable damaged",
  },
  {
    label: "Flat cable damaged/تلف الكابل المسطح",
    value: "flat cable damaged",
  },
  { label: "Generator off/المولد معطلة", value: "generator off" },
  { label: "High brigthness/إضاءة عالية", value: "high brigthness" },
  { label: "Hub Damaged/المحور تالف", value: "hub damaged" },
  { label: "Low brightness/سطوع منخفض", value: "low brightness" },
  { label: "Module damaged/وحدة معطوبة", value: "module damaged" },
  {
    label: "No ads playing broadsign/لا توجد إعلانات تلعب برودساين",
    value: "no ads playing broadsign",
  },
  { label: "No display/لا يوجد عرض", value: "no display" },
  { label: "No power sceco/لا السلطة sceco", value: "no power sceco" },
  {
    label: "Power supply cabinet damaged/خزانة إمداد الطاقة تالفة",
    value: "power supply cabinet damaged",
  },
  {
    label: "Receiver card damaged/تلف بطاقة جهاز الاستقبال",
    value: "receiver card damaged",
  },
  {
    label:
      "Router 12v power adaptor damaged/جهاز التوجيه 12 فولت محول الطاقة معطوب",
    value: "router 12v power adaptor damaged",
  },
  { label: "Router damaged/جهاز التوجيه تالف", value: "router damaged" },
  {
    label: "Router no signal/جهاز التوجيه لا توجد إشارة",
    value: "router no signal",
  },
  { label: "Sata cable damaged/تلف كابل ساتا", value: "sata cable damaged" },
  { label: "Screen breaker off/الشاشة متوقفة", value: "screen breaker off" },
  {
    label: "Screen breaker tripped/تعثر قواطع الشاشة",
    value: "screen breaker tripped",
  },
  {
    label: "Screen display size not correct/حجم عرض الشاشة غير صحيح",
    value: "screen display size not correct",
  },
  { label: "Screen flickering/وميض الشاشة", value: "screen flickering" },
  {
    label: "Screen not full screen/الشاشة ليست بملء الشاشة",
    value: "screen not full screen",
  },
  {
    label: "Sim card not working/بطاقة sim لا تعمل",
    value: "sim card not working",
  },
];

// export const billBoardScreenPixel = [
//   { value: "P10", label: "P10" },
//   { value: "P3.9", label: "P3.9" },
// ];

// export const billBoardScreenSize = [
//   { value: "1.24X1.74", label: "1.24X1.74" },
//   { value: "1.99X2.9", label: "1.99X2.9" },
//   { value: "2.88X3.84", label: "2.88X3.84" },
//   { value: "5X7", label: "5X7" },
//   { value: "11.6X2.8", label: "11.6X2.8" },
//   { value: "14.4X3.60", label: "14.4X3.60" },
//   { value: "1152X288", label: "1152X288" },
//   { value: "384X288", label: "384X288" },
// ];

export const YOUR_GOOGLE_MAPS_API_KEY =
  "AIzaSyCTJbFhAajVuTpGYjAtxaTZNNHQSnnzpfA";
