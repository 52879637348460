import React, { ReactElement, useEffect, useState } from "react";
import { SOCKET_URL, api } from "../../utils/api";
import { io } from "socket.io-client";
import { useAuthentication } from "../../context/AuthenticationProvider";
import useSound from "use-sound";
import { useChat } from "./context";
const socket = io(SOCKET_URL || "");
interface ChatMessageProviderProps {
  children: ReactElement;
}

export interface ChatMessageContextProp {
  isLoading: any;
  setIsLoading: Function;
  getConversation: Function;
  conversation: any;
  updateConversation: Function;
  messageId: any;
  metaData: any;
  emitSeenSocket: Function;
  emitTypingSocket: Function;
  disConnectSocket: Function;
  readMessage: Function;
  setConversationId: Function;
  conversationId: Function;
  setSearchMessage: Function;
  searchMessage: any;
  showAttachment: any;
  setShowAttachment: Function;
  buttonPopup: any;
  setButtonPopup: Function;
  audioFile: any;
  setAudioFile: Function;
  messageValue: any;
  setMessageValue: Function;
  attachmentType: any;
  setAttachmentType: Function;
  replyObject: any;
  setReplyObject: Function;
  base64Value: any;
  setBase64Value: Function;
  handleEnterKey: Function;
  getImageUpload: Function;
  getVideoUpload: Function;
  emojiHandler: Function;
  sendValue: Function;
  limit: any;
  setLimit: Function;
  getMessageDetailById: Function;
  getAudioUpload: Function;
  isLoadMore: any;
  setIsLoadMore: Function;
}

const ChatMessageContext = React.createContext<ChatMessageContextProp | null>(
  null
);

const ChatMessageProvider = ({ children }: ChatMessageProviderProps) => {
  const Notification = require("../../assets/Notification.mp3");
  const [play] = useSound(Notification);
  const context = useAuthentication();
  const chatContext = useChat();
  const [isLoading, setIsLoading]: [boolean, Function] = useState(false);
  const [metaData, setMetaData] = useState<any>({});
  const [conversation, setConversation] = useState<any>(null);
  const [messageId, setMessageId] = useState<any>("");
  const [conversationId, setConversationId] = useState<any>(
    context?.groupConversationInfo?.conversation
      ? context?.groupConversationInfo?.conversation
      : ""
  );
  const [searchMessage, setSearchMessage] = useState<any>("");
  const [showAttachment, setShowAttachment] = useState(false);
  const [buttonPopup, setButtonPopup] = useState(false);
  const [audioFile, setAudioFile] = useState<any>({});
  const [messageValue, setMessageValue] = useState("");
  const [attachmentType, setAttachmentType] = useState("");
  const [replyObject, setReplyObject] = useState<any>({});
  const [base64Value, setBase64Value] = useState("");
  const [limit, setLimit] = useState<any>(50);
  const [isLoadMore, setIsLoadMore] = useState(false);

  socket.on(conversationId, (data: any) => {
    if (data?.success) {
      const index = conversation?.findIndex((item: any) => {
        return item?.id === data?.data?.id || item?._id === data?.data?._id;
      });
      if (index > -1 && index !== undefined) {
        conversation[index] = data?.data;
      } else {
        conversation?.push(data?.data);
      }
      emitSeenSocket(data?.data?.id);
    }
  });

  socket.on(conversationId + "_seen", (data: any) => {
    if (data?.success) {
      const index = conversation?.findIndex((item: any) => {
        return item?.id === data?.data?.id || item?._id === data?.data?._id;
      });
      if (index > -1 && index !== undefined) {
        conversation[index] = data?.data;
      } else {
        conversation?.push(data?.data);
      }
    }
  });

  useEffect(() => {
    setLimit(20);
  }, [conversationId]);

  useEffect(() => {
    if (conversationId) {
      getConversationDetails();
    }
  }, [searchMessage, conversationId, limit]);

  const getConversationDetails = async () => {
    const { success } = await getConversation(conversationId, {
      search: searchMessage,
      limit,
    });
    if (success) {
      await readMessage(conversationId);
    }
  };

  useEffect(() => {
    return () => {
      disConnectSocket(conversationId);
      disConnectSocket(conversationId + "_seen");
      disConnectSocket(conversationId + "_typing");
    };
  }, [conversationId]);

  useEffect(() => {
    emitTypingSocket();
    return () => {
      disConnectSocket(conversationId + "_typing");
    };
  }, [messageValue]);

  const emojiHandler = (emoji: any) => {
    setMessageValue(() => messageValue + emoji?.emoji);
  };

  const sendValue = async (data?: any, attachmentData?: any) => {
    let response: any = {};
    let params: any = {};
    if (messageValue) {
      params = {
        message: messageValue,
      };
      if (chatContext?.action === "Reply") {
        params["is_reply"] = true;
        params["reply_message_id"] = replyObject?.id;
      }
      response = await updateConversation(conversationId, params);
    }
    if (attachmentData === "AUDIO" && data?.url) {
      params["attachment_type"] = attachmentData;
      params["attachment"] = data?.url;
      response = await updateConversation(conversationId, params);
    }
    if (data?.url && attachmentData === "IMAGE") {
      params["attachment_type"] = attachmentData;
      params["attachment"] = data.url;
      response = await updateConversation(conversationId, params);
    } else if (data?.url && attachmentData === "VIDEO") {
      params["attachment_type"] = attachmentData;
      params["attachment"] = data?.url;
      response = await updateConversation(conversationId, params);
    }
    setMessageValue("");
    setShowAttachment(false);
    chatContext?.setShowEmoji(false);
    if (response?.success) {
      play();
      setReplyObject({});
      chatContext?.setAction("");
      setMessageValue("");
      setButtonPopup(false);
    }
  };

  const handleEnterKey = (e: any) => {
    if (e.target.value.length === 0) {
      setMessageValue(e.target.value);
      sendValue();
    }
    if (e.key === "Enter") {
      setMessageValue(e.target.value);
      sendValue();
    }
  };

  const getImageUpload = async (file: any) => {
    setIsLoading(true);
    if (file?.length > 0) {
      for (let i = 0; i < file.length; i++) {
        const formData = new FormData();
        formData.append("file", file[i]);
        const data = await context?.uploadFiles(formData, "image");
        if (data?.success) {
          setAttachmentType("IMAGE");
          sendValue(data, "IMAGE");
        }
      }
    }
    setIsLoading(false);
  };

  const getVideoUpload = async (file: any) => {
    setIsLoading(true);
    if (file?.length > 0) {
      for (let i = 0; i < file.length; i++) {
        const formData = new FormData();
        formData.append("file", file[i]);
        const data = await context?.uploadFiles(formData, "video");
        if (data?.success) {
          setAttachmentType("VIDEO");
          sendValue(data, "VIDEO");
        }
      }
    }
    setIsLoading(false);
  };

  const getAudioUpload = async (file: any) => {
    setIsLoading(true);
    if (file?.length > 0) {
      for (let i = 0; i < file.length; i++) {
        const formData = new FormData();
        formData.append("file", file[i]);
        const data = await context?.uploadFiles(formData, "audio");
        if (data?.success) {
          setAttachmentType("AUDIO");
          sendValue(data, "AUDIO");
        }
      }
    }
    setIsLoading(false);
  };

  const getConversation = async (id: any, params: any) => {
    setConversation([]);
    setIsLoading(true);
    const { success, messages, meta } = await api?.getConversation(id, {
      ...params,
    });
    setIsLoadMore(!(metaData?.total_count <= limit));
    if (success) {
      setConversation(messages?.reverse());
      setMetaData(meta);
    }
    setIsLoading(false);
    return { success };
  };

  const updateConversation = async (id: any, params: any) => {
    const { data } = await api?.updateConversation(params, id);
    const { success } = data || {};
    if (success) {
      return data;
    }
  };

  const disConnectSocket = (data: any) => {
    socket.off(data);
  };

  const emitSeenSocket = (id: any) => {
    let params = {
      conversation_id: conversationId + "_seen",
      user_id: context?.user?.id,
      message_id: id,
    };
    socket.emit("seen", params);
    setMessageId("");
  };

  const emitTypingSocket = () => {
    let params = {
      conversation_id: conversationId + "_typing",
      userName: context?.user?.name,
      userId: context?.user?.id,
      isTyping: messageValue?.length > 0 ? true : false,
      isGroup: metaData?.participants?.group_id !== null,
    };
    socket.emit("typing", params);
    setMessageId("");
  };

  const readMessage = async (id: any) => {
    const data = await api?.readMessage(id);
    return data;
  };

  const getMessageDetailById = async (id: any) => {
    const data = await api?.getMessageDetailById(id);
    return data;
  };

  const value: ChatMessageContextProp = {
    isLoading,
    setIsLoading,
    getConversation,
    conversation,
    updateConversation,
    messageId,
    metaData,
    emitSeenSocket,
    emitTypingSocket,
    disConnectSocket,
    readMessage,
    setConversationId,
    conversationId,
    setSearchMessage,
    searchMessage,
    showAttachment,
    setShowAttachment,
    buttonPopup,
    setButtonPopup,
    audioFile,
    setAudioFile,
    messageValue,
    setMessageValue,
    attachmentType,
    setAttachmentType,
    replyObject,
    setReplyObject,
    base64Value,
    setBase64Value,
    handleEnterKey,
    getImageUpload,
    getVideoUpload,
    emojiHandler,
    sendValue,
    limit,
    setLimit,
    getMessageDetailById,
    getAudioUpload,
    isLoadMore,
    setIsLoadMore,
  };

  return (
    <ChatMessageContext.Provider value={value}>
      {children}
    </ChatMessageContext.Provider>
  );
};

const useMessageChat = () => React.useContext(ChatMessageContext);

export { ChatMessageProvider, useMessageChat };
